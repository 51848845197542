import { useState, useEffect } from "react";
import { useGetCardHistory } from "features/card";
import { useParam } from "lib/utils/useRouter";
import CardInfo from "./CardInfo";
import CardHistoryItem from "./CardHistoryItem";
import SearchMonthPopup from "components/Modal/SearchMonthPopup";
import { useSearchParams } from "react-router-dom";

const CardHistoryContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSearchPopup, setIsSearchPopup] = useState(false);
  const [isExecution, setIsExecution] = useState(false);
  const [selectMonth, setSelectMonth] = useState("");
  const params = useParam();

  const cardHistoryQuery = useGetCardHistory(
    params.getPathParameter().id,
    {
      orgCode: searchParams.get("orgCode"),
      basDtYm: selectMonth,
    },
    isExecution
  );

  useEffect(() => {
    setSelectMonth(searchParams.get("searchDate"));
    setIsExecution(true);
  }, [searchParams.get("searchDate")]);

  return (
    <div className="card_container">
      <CardInfo
        cardHistoryQuery={cardHistoryQuery}
        orgCode={searchParams.get("orgCode")}
        setIsSearchPopup={setIsSearchPopup}
        selectMonth={selectMonth}
      />
      <div className="block_line"></div>
      <div className="card_history_article">
        {/* <div className="card_hisotry_summary">검색</div> */}
        {cardHistoryQuery?.data?.cardUseAmts.length === 0 ? (
          <div className="no_list">사용 내역이 없습니다.</div>
        ) : (
          <ul className="card_history_list">
            {cardHistoryQuery?.data?.cardUseAmts.map(
              (cardListByDate, index) => {
                return (
                  <CardHistoryItem
                    cardListByDate={cardListByDate}
                    key={index}
                  />
                );
              }
            )}
          </ul>
        )}
      </div>

      {isSearchPopup && (
        <SearchMonthPopup
          isOpen={isSearchPopup}
          onClose={() => {
            setIsSearchPopup(false);
          }}
          onChange={(e) => {
            setTimeout(() => {
              setSelectMonth(e.target.value);
              searchParams.set("searchDate", e.target.value);

              setSearchParams(searchParams);
            }, 150);
          }}
          selectMonth={selectMonth}
        />
      )}
    </div>
  );
};

export default CardHistoryContainer;
