import React, { useRef, useState, useEffect, useMemo } from "react";
import army from "../../assets/images/addPage/army.png";
import grandfather from "../../assets/images/addPage/grandfather.png";
import man from "../../assets/images/addPage/man.png";
import { useLocation, useNavigate, Link } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { jsonFetch, paramFetch } from "apis/common/fetchInstance";
import BottomSelect from "../../components/BottomSelect";
import { CSSTransition } from "react-transition-group";
import { forEach, stubString, throttle } from "lodash";
import { setDateFormat } from "utils/utils";
// 무한스크롤할때 사용

// SwiperCore.use([Navigation, Pagination, Autoplay]);

function Tc_review(props) {
  let navigate = useNavigate();

  const [isLoanBottomOpen, setIsLoanBottomOpen] = useState(false);
  const [isAgeBottomOpen, setIsAgeBottomOpen] = useState(false);
  const [isPurposeBottomOpen, setIsPurposeBottomOpen] = useState(false);
  const [moreWatch, setMoreWatch] = useState(false);
  const [reviewList, setReviewList] = useState([]);
  const [reviewDetail, setReviewDetail] = useState([]);
  const [bestReviewList, setBestReviewList] = useState([]);
  const [bestReviewDetail, setBestReviewDetail] = useState([]);
  const [age, setAge] = useState({ id: 0, code: "all_age", nm: "전체 연령" });
  const [loan, setLoan] = useState({
    id: 0,
    code: "all_loan",
    nm: "전체 대출금액",
  });
  const [purpose, setPurpose] = useState({
    id: 0,
    code: "all_pur",
    nm: "전체 대출목적",
  });
  const [swiperIndex, setSwiperIndex] = useState(); // 스와이퍼 순서 값 저장
  const [autoPlay, setAutoplay] = useState(true); // 스와이퍼 자동 동작 상태값(베스트 후기 자세히보기 클릭시 멈추기 위함)
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const defaultPage = 1;
  const defaultRows = 10;

  const [page, setPage] = useState(defaultPage);
  const [pageing, setPageging] = useState(true);
  const lastRef = useRef(null);

  const handleScroll = useMemo(
    () =>
      throttle(() => {
        if (pageing && lastRef.current !== null) {
          let scrollTop = Math.max(
            document.scrollingElement.scrollTop,
            document.body.scrollTop
          );
          let clientHeight = document.documentElement.clientHeight;
          if (scrollTop + clientHeight >= lastRef.current.offsetTop) {
            setPage((prev) => prev + 1);
          }
        }
      }, 500),
    [pageing]
  );

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll); //clean up
    };
  }, [handleScroll]);

  const [ageBottomData, setAgeBottomData] = useState([
    {
      id: 0,
      code: "all_age",
      nm: "전체 연령",
    },
    {
      id: 1,
      code: "20",
      nm: "20대",
    },
    {
      id: 2,
      code: "30",
      nm: "30대",
    },
    {
      id: 3,
      code: "40",
      nm: "40대",
    },
    {
      id: 4,
      code: "50",
      nm: "50대",
    },
    {
      id: 5,
      code: "60",
      nm: "60대 이상",
    },
  ]);
  const [loanBottomData, setLoanBottomData] = useState([
    {
      id: 0,
      code: "all_loan",
      nm: "전체 대출금액",
    },
    {
      id: 1,
      code: "1",
      nm: "1000만원 이하",
    },
    {
      id: 2,
      code: "2",
      nm: "1,000만원 ~ 5,000만원",
    },
    {
      id: 3,
      code: "3",
      nm: "5000만원 ~ 1억원",
    },
    {
      id: 4,
      code: "4",
      nm: "1억원 이상",
    },
  ]);
  const [purposeBottomData, setPurposeBottomData] = useState([
    {
      id: 0,
      code: "all_pur",
      nm: "전체 대출목적",
    },
    {
      id: 1,
      code: "생활비",
      nm: "생활비",
    },
    {
      id: 2,
      code: "대환대출",
      nm: "대환대출",
    },
    {
      id: 3,
      code: "전월세보증금",
      nm: "전월세보증금",
    },
    {
      id: 4,
      code: "주택구입",
      nm: "주택구입",
    },
    {
      id: 5,
      code: "자동차구입",
      nm: "자동차구입",
    },
    {
      id: 6,
      code: "투자",
      nm: "투자",
    },
    {
      id: 7,
      code: "사업자금",
      nm: "사업자금",
    },
    {
      id: 8,
      code: "기타",
      nm: "기타",
    },
  ]);

  const handleMoreBtn = (obj) => {
    // const user = [...reviewList];

    // const userFind = user.findIndex(filter => filter.no == no);

    // user[userFind].isTrue = !user[userFind].isTrue;

    // setReviewList(user);
    setIsPopupOpen(true);
    setReviewDetail(obj);
  };

  const handleBestMoreBtn = (obj) => {
    // const user = [...bestReviewList];
    // const userFind = user.findIndex(filter => filter.no == no);

    // user[userFind].isTrue = !user[userFind].isTrue;

    // setBestReviewList(user);
    // setSwiperIndex(i);
    setAutoplay(false);
    // setClickNo(no);
    setIsPopupOpen(true);
    setReviewDetail(obj);
  };

  useEffect(() => {
    reviewApiCall();
  }, [age, loan, purpose, page]);

  useEffect(() => {
    bestReviewApiCall();
  }, []);

  const bestReviewApiCall = async () => {
    const { response, data } = await jsonFetch("/api/bestReview");
    let emptyArray = [];
    if (response.status === 200) {
      for (let i = 0; i < data.length; i++) {
        const tempData = data[i];
        emptyArray.push({
          no: tempData.NO,
          name: tempData.NAME,
          age: tempData.AGE,
          loanNm: tempData.LOAN_NAME,
          loan: tempData.LOAN,
          purpose: tempData.PURPOSE,
          reviewText: tempData.LOAN_REVIEW_TEXT,
          regDate: tempData.REG_DATE,
          updDate: tempData.UPD_DATE,
          deleteYn: tempData.DELETE_YN,
          userNo: tempData.USER_NO,
          isTrue: false,
        });
      }
      setBestReviewList(emptyArray);
    }
  };

  useEffect(() => {
    setPage(defaultPage);
  }, [age, loan, purpose]);

  const reviewApiCall = async () => {
    const sendData = {
      age: age.code,
      loan: loan.code,
      purpose: purpose.code,
      page: page,
      rows: defaultRows,
    };
    const { response, data } = await paramFetch("/api/review", sendData);
    if (response.status === 200) {
      let emptyArray = [];
      if (defaultPage === page) {
        for (let i = 0; i < data.length; i++) {
          const tempData = data[i];
          emptyArray.push({
            no: tempData.NO,
            name: tempData.NAME,
            age: tempData.AGE,
            loanNm: tempData.LOAN_NAME,
            loan: tempData.LOAN,
            purpose: tempData.PURPOSE,
            reviewText: tempData.LOAN_REVIEW_TEXT,
            regDate: tempData.REG_DATE,
            updDate: tempData.UPD_DATE,
            deleteYn: tempData.DELETE_YN,
            userNo: tempData.USER_NO,
            isTrue: false,
          });
        }
        setPageging(true);
        setReviewList(emptyArray);
      } else {
        if (data !== undefined && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const tempData = data[i];
            emptyArray.push({
              no: tempData.NO,
              name: tempData.NAME,
              age: tempData.AGE,
              loanNm: tempData.LOAN_NAME,
              loan: tempData.LOAN,
              purpose: tempData.PURPOSE,
              reviewText: tempData.LOAN_REVIEW_TEXT,
              regDate: tempData.REG_DATE,
              updDate: tempData.UPD_DATE,
              deleteYn: tempData.DELETE_YN,
              userNo: tempData.USER_NO,
              isTrue: false,
            });
          }
          setReviewList((reviewList) => [...reviewList, ...emptyArray]);
        } else {
          for (let i = 0; i < data.length; i++) {
            const tempData = data[i];
            emptyArray.push({
              no: tempData.NO,
              name: tempData.NAME,
              age: tempData.AGE,
              loanNm: tempData.LOAN_NAME,
              loan: tempData.LOAN,
              purpose: tempData.PURPOSE,
              reviewText: tempData.LOAN_REVIEW_TEXT,
              regDate: tempData.REG_DATE,
              updDate: tempData.UPD_DATE,
              deleteYn: tempData.DELETE_YN,
              userNo: tempData.USER_NO,
              isTrue: false,
            });
          }
          setPageging(false);
        }
      }
    }
  };

  const loanAsc = () => {
    setIsLoanBottomOpen(true);
  };

  const AgeAsc = () => {
    setIsAgeBottomOpen(true);
  };

  const usingAsc = () => {
    setIsPurposeBottomOpen(true);
  };

  const bottomClose = () => {
    setIsLoanBottomOpen(false);
    setIsAgeBottomOpen(false);
    setIsPurposeBottomOpen(false);
  };

  const modalClose = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <div id="goto"></div>
      <div className="front_body">
        <div className="page_wrap has_botpos">
          <div
            className={
              isLoanBottomOpen ||
              isAgeBottomOpen ||
              isPurposeBottomOpen ||
              isPopupOpen
                ? "bg_layout"
                : null
            }
            onClick={bottomClose}
          ></div>
          <header className="page_header">
            <Link
              to=""
              onClick={() => {
                navigate(-1);
              }}
              className="btn_back"
            >
              <span className="hdtext">뒤로가기</span>
            </Link>
            <h1 className="page_headtitle">
              <strong className="teaching_title">티칭캐시</strong>의 생생한
              대출후기
            </h1>
          </header>
          <section className="page_midsection_review">
            <div className="my_review_box">
              <p
                className="my_review_p"
                style={{ margin: "0 auto 1rem auto", width: "93%" }}
              >
                BEST 후기
              </p>
              {/* <GetBestListTag
                bestReviewList={bestReviewList}
                handleBestMoreBtn={handleBestMoreBtn}
                Swiper={Swiper}
                SwiperCore={SwiperCore}
                autoPlay={autoPlay}
                swiperIndex={swiperIndex}
                SwiperSlide={SwiperSlide}
              /> */}
            </div>

            <div className="my_like_review_box">
              <p
                className="my_review_p like_review_p"
                style={{ margin: "1rem auto", width: "93%" }}
              >
                실시간 대출 후기
              </p>

              <div className="review_selectSd">
                <ul className="clearfix bottomBtn_area">
                  <li className="review_box" onClick={loanAsc}>
                    {loan.nm}
                    {/* <a className='review_box' onClick={loanAsc}>{loan.nm}</a> */}
                  </li>
                  <li className="review_box" onClick={AgeAsc}>
                    {age.nm}
                  </li>
                  <li className="review_box" onClick={usingAsc}>
                    {purpose.nm}
                  </li>
                </ul>
              </div>
              <BottomSelect
                isOpen={isLoanBottomOpen}
                bottomClose={bottomClose}
                bottomData={loanBottomData}
                name={"bottomRadio"}
                title={"대출금액"}
                onChange={(e) => {
                  const targetValue = e.target.value;
                  const timeId = setInterval(() => {
                    for (let i = 0; i < loanBottomData.length; i++) {
                      const loanObj = loanBottomData[i];
                      if (targetValue === loanObj.code) {
                        setLoan(loanObj);
                      }
                    }
                    setIsLoanBottomOpen(false);
                    clearTimeout(timeId);
                  }, 100);
                }}
              />
              <BottomSelect
                isOpen={isAgeBottomOpen}
                bottomClose={bottomClose}
                bottomData={ageBottomData}
                name={"bottomRadio"}
                title={"연령"}
                onChange={(e) => {
                  const targetValue = e.target.value;
                  const timeId = setInterval(() => {
                    for (let i = 0; i < ageBottomData.length; i++) {
                      const ageObj = ageBottomData[i];
                      if (targetValue === ageObj.code) {
                        setAge(ageObj);
                      }
                    }
                    setIsAgeBottomOpen(false);
                    clearTimeout(timeId);
                  }, 100);
                }}
              />
              <BottomSelect
                isOpen={isPurposeBottomOpen}
                bottomClose={bottomClose}
                bottomData={purposeBottomData}
                name={"bottomRadio"}
                title={"대출목적"}
                onChange={(e) => {
                  const targetValue = e.target.value;

                  const timeId = setInterval(() => {
                    for (let i = 0; i < purposeBottomData.length; i++) {
                      const purposeObj = purposeBottomData[i];
                      if (targetValue === purposeObj.code) {
                        setPurpose(purposeObj);
                      }
                    }
                    setIsPurposeBottomOpen(false);
                    clearTimeout(timeId);
                  }, 100);
                }}
              />
            </div>
            <GetListTag
              reviewList={reviewList}
              lastRef={lastRef}
              handleMoreBtn={handleMoreBtn}
            />
            <ReviewDetail
              reviewDetail={reviewDetail}
              isOpen={isPopupOpen}
              modalClose={modalClose}
            />
          </section>

          <Link
            to="/loan/loanMain"
            style={{ width: "100%", position: "fixed", bottom: "0px" }}
          >
            <div className="btn_botpos_wrap">
              <button className="btn_bquadbox" id="btn_myoptimum_search">
                <span className="review_bquadbox_in">내 최저금리 보러가기</span>
              </button>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

const GetBestListTag = ({
  bestReviewList,
  handleBestMoreBtn,
  Swiper,
  SwiperCore,
  autoPlay,
  swiperIndex,
  SwiperSlide,
}) => {
  // SwiperCore.use([Navigation, Pagination, Autoplay]);
  let listTag = [];
  for (let i = 0; i < bestReviewList.length; i++) {
    let obj = bestReviewList[i];
    const innerTAG = (
      <SwiperSlide className="swiper-bullet-review" key={`best_${i}`}>
        <div className="my_like_review">
          <ul
            className="content-contaienr myreview"
            style={{ boxShadow: "5px 5px 5px #ccc", width: "93%" }}
          >
            <div className="reviewbox">
              <div className="textbox">
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img src={man} width="100px" height="120px" />
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      flexDirection: "column",
                      width: "100%",
                      height: "50%",
                      paddingRight: "2rem",
                    }}
                  >
                    <p className="user_name">
                      {obj.name} ({obj.age}대) {setDateFormat(obj.regDate)}
                    </p>
                    <div className="user_bank_wrap">
                      <div className="user_loan_name">{obj.loanNm}</div>
                      <div className="vertical_line"></div>
                      <div className="user_loan_price">{obj.loan}만원</div>
                      <div className="vertical_line"></div>
                      <div className="user_loan_purpose">{obj.purpose}</div>
                    </div>
                    <hr
                      style={{
                        display: "block",
                        margin: "0.5rem 0 0.5rem 0",
                        border: "0.8px solid #efefef",
                      }}
                    />
                    <p className="review_text">{obj.reviewText}</p>
                    <div
                      onClick={(e) => handleBestMoreBtn(obj)}
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        marginTop: "0.3rem",
                      }}
                      className="review_text"
                    >
                      자세히 보기 {">"}
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
              <div className="tagbox"></div>
              {/* </div> */}
            </div>
          </ul>
        </div>
      </SwiperSlide>
    );
    listTag.push(innerTAG);
  }
  if (listTag.length === 0) {
    return (
      <div className="no_data">
        <div
          className="inner"
          style={{ marginTop: "1rem", textAlign: "center" }}
        >
          <h3>검색 결과가 없습니다.</h3>
        </div>
      </div>
    );
  } else {
    return (
      <Swiper
        pagination={{ clickable: true }}
        observer={true}
        observeParents={true}
        autoplay={{ delay: 5000 }}
      >
        {listTag}
      </Swiper>
    );
  }
};

const GetListTag = ({ reviewList, lastRef, handleMoreBtn }) => {
  let listTag = [];
  for (let i = 0; i < reviewList.length; i++) {
    let obj = reviewList[i];
    const innerTAG = (
      <React.Fragment key={`review_${i}`}>
        <ul
          className="content-contaienr myreview"
          style={{ boxShadow: "5px 5px 5px #ccc", width: "93%" }}
          ref={reviewList.length - 1 === i ? lastRef : null}
        >
          <div className="reviewbox">
            <div className="textbox">
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img src={man} width="100px" height="120px" />
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    flexDirection: "column",
                    width: "100%",
                    height: "50%",
                    paddingRight: "2rem",
                  }}
                >
                  <p className="user_name">
                    {obj.name} ({obj.age}대) {setDateFormat(obj.regDate)}
                  </p>
                  <div className="user_bank_wrap">
                    <div className="user_loan_name">{obj.loanNm}</div>
                    <div className="vertical_line"></div>
                    <div className="user_loan_price">{obj.loan}만원</div>
                    <div className="vertical_line"></div>
                    <div className="user_loan_purpose">{obj.purpose}</div>
                  </div>
                  <hr
                    style={{
                      display: "block",
                      margin: "0.5rem 0 0.5rem 0",
                      border: "0.8px solid #efefef",
                    }}
                  />
                  <div>
                    <p className="review_text">{obj.reviewText}</p>
                  </div>
                  <div
                    onClick={() => handleMoreBtn(obj)}
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      marginTop: "0.3rem",
                    }}
                    className="review_text"
                  >
                    자세히 보기 {">"}
                  </div>
                  <div className="tag_wrap">
                    <div className="review_tag">#이자줄임</div>
                    <div className="review_tag">#티캐에서만 승인</div>
                    <div className="review_tag">#기타</div>
                    <div className="review_tag">#낮은이자</div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div className="tagbox"></div>
          </div>
        </ul>
        {i + 1 === reviewList.length ? (
          <div style={{ height: "4rem" }}></div>
        ) : null}
      </React.Fragment>
    );
    listTag.push(innerTAG);
  }
  if (listTag.length === 0) {
    return (
      <div className="no_data">
        <div
          className="inner"
          style={{ marginTop: "1rem", textAlign: "center" }}
        >
          <h3>검색 결과가 없습니다.</h3>
        </div>
      </div>
    );
  } else {
    return <ul>{listTag}</ul>;
  }
};

const ReviewDetail = ({ isOpen, reviewDetail, modalClose }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.cssText = `
            position:fixed; 
            width:100%;
            top: -${window.scrollY}px;
            overflow-y: scroll;
            `;
    } else {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    }
  }, [isOpen]);

  return (
    <div
      className="loginModal"
      style={{
        zIndex: 99,
        height: "40%",
        border: "none",
        borderRadius: "10px",
        display: isOpen ? "block" : "none",
        maxHeight: "35rem",
        position: "fixed",
      }}
    >
      <div className="review_modal_wrap">
        <div className="review_detail_content_wrap">
          <div className="review_detail_content">
            <p className="user_name">
              {reviewDetail.name} ({reviewDetail.age}대){" "}
              {setDateFormat(reviewDetail.regDate)}
            </p>
            <div className="detail_user_bank_wrap">
              <div className="user_loan_name">{reviewDetail.loanNm}</div>
              <div className="vertical_line"></div>
              <div className="user_loan_price">{reviewDetail.loan}만원</div>
              <div className="vertical_line"></div>
              <div className="user_loan_purpose">{reviewDetail.purpose}</div>
            </div>
            <hr
              style={{
                display: "block",
                margin: "0.5rem 0 0.5rem 0",
                border: "0.8px solid #efefef",
              }}
            />
            <div className="text_wrap">
              <p className="review_text_detail">{reviewDetail.reviewText}</p>
            </div>
            <div className="detail_tag_wrap">
              <p className="detail_review_tag">#테스트1</p>
              <p className="detail_review_tag">#테스트1</p>
              <p className="detail_review_tag">#테스트1</p>
              <p className="detail_review_tag">#테스트1</p>
            </div>
          </div>
        </div>
        <div>
          <button
            type="button"
            className="btn_botpos_close"
            style={{ right: "0rem" }}
            onClick={modalClose}
          >
            <span className="hdtext">닫기</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Tc_review;
