import { Layout } from "layout";
import { CardDetailsContainer, CardDetailsSkeleton } from "containers/Card";
import { Suspense } from "react";

const CardDetails = () => {
  return (
    <Layout header={{ title: "카드정보" }}>
      <Suspense fallback={<CardDetailsSkeleton />}>
        <CardDetailsContainer />
      </Suspense>
    </Layout>
  );
};

export default CardDetails;
