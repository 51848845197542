import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "components/Button";
import "components/Popup/popup.css";
import Modal from "components/Modal/Modal";
import { useGetTerms } from "features/terms";
import TermsContentModal from "components/Modal/TermsContentModal";
import termsJson from "assets/data/terms.json";

const CertificatePopup = ({
  isOpen,
  bottomClose,
  selectCert,
  setSelectCert,
  setIsAgree,
  handleAgree,
}) => {
  const [isPopup, setIsPopup] = useState(false);
  const [selectTerms, setSelectTerms] = useState(0);

  const certificateList = [
    {
      id: "NAVER",
      title: "네이버 인증서",
    },
    {
      id: "KAKAO",
      title: "카카오 인증서",
    },
    {
      id: "CERT",
      title: "공동 인증서",
    },
  ];

  return (
    <>
      <Modal
        bottomModal
        selectModal
        onClose={bottomClose}
        title="인증서를 선택해주세요"
      >
        <ul className="list_certificate">
          {certificateList.map((cert, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  setSelectCert(cert.id);
                }}
                className={cert.id === selectCert ? "active" : ""}
              >
                <div className="certificate_item">
                  <i className={["cert_icon", "icon_" + cert.id].join(" ")}></i>
                  {cert.title}
                </div>
              </li>
            );
          })}
        </ul>
        <div className="terms_list_wrap">
          <ul className="terms_list">
            {termsJson.cert
              ?.filter((terms) => terms.termsType === selectCert)
              .map((terms) => {
                return (
                  <li
                    className=""
                    key={terms.id}
                    onClick={() => {
                      setSelectTerms(terms);
                      setIsPopup(true);
                    }}
                  >
                    {terms.title}
                  </li>
                );
              })}
          </ul>
        </div>
        <Button
          title={"동의하고 진행하기"}
          className={"btn_cert_bottom"}
          disabled={selectCert === ""}
          type="button"
          onClick={handleAgree}
        />
      </Modal>

      {isPopup && (
        <TermsContentModal
          isOpen={isPopup}
          onClose={() => {
            setIsPopup(false);
          }}
          terms={selectTerms}
        />
      )}
    </>
  );
};

export default CertificatePopup;
