import sampleCardImg from "assets/images/bankq/kbank-card.png";
import React from "react";

const CardHistorySkeleton = () => {
  return (
    <div className="card_container">
      <section className="card_wrap">
        <div className="card_article">
          <div className="card_summary_box">
            <p className="skeleton skeleton_subtitle"></p>
            <div className="skeleton skeleton_title"></div>
          </div>
          <div className="card_info_box">
            <div className="skeleton skeleton_card_img"></div>
            <div className="card_info" style={{ marginLeft: "12px" }}>
              <div className="skeleton skeleton_content"></div>
              <div className="skeleton skeleton_content "></div>
            </div>
          </div>
        </div>
      </section>
      <div className="block_line"></div>
      <div className="card_history_article">
        {/* <div className="card_hisotry_summary">검색</div> */}
        <ul className="card_history_list">
          <li>
            <div className="card_history_item">
              <div className="card_history_item_summary skeleton skeleton_subtitle"></div>
              <ul className="card_history_product_list">
                <li>
                  <div className="payment_info ">
                    <div className="payment_info_row skeleton_row">
                      <p className="skeleton skeleton_subtitle "></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                    <div className="payment_info_row info_desc skeleton_row">
                      <p className="skeleton skeleton_subtitle"></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div className="card_history_item">
              <div className="card_history_item_summary skeleton skeleton_subtitle"></div>
              <ul className="card_history_product_list">
                <li>
                  <div className="payment_info ">
                    <div className="payment_info_row skeleton_row">
                      <p className="skeleton skeleton_subtitle "></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                    <div className="payment_info_row info_desc skeleton_row">
                      <p className="skeleton skeleton_subtitle"></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div className="card_history_item">
              <div className="card_history_item_summary skeleton skeleton_subtitle"></div>
              <ul className="card_history_product_list">
                <li>
                  <div className="payment_info ">
                    <div className="payment_info_row skeleton_row">
                      <p className="skeleton skeleton_subtitle "></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                    <div className="payment_info_row info_desc skeleton_row">
                      <p className="skeleton skeleton_subtitle"></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div className="card_history_item">
              <div className="card_history_item_summary skeleton skeleton_subtitle"></div>
              <ul className="card_history_product_list">
                <li>
                  <div className="payment_info ">
                    <div className="payment_info_row skeleton_row">
                      <p className="skeleton skeleton_subtitle "></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                    <div className="payment_info_row info_desc skeleton_row">
                      <p className="skeleton skeleton_subtitle"></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div className="card_history_item">
              <div className="card_history_item_summary skeleton skeleton_subtitle"></div>
              <ul className="card_history_product_list">
                <li>
                  <div className="payment_info ">
                    <div className="payment_info_row skeleton_row">
                      <p className="skeleton skeleton_subtitle "></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                    <div className="payment_info_row info_desc skeleton_row">
                      <p className="skeleton skeleton_subtitle"></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div className="card_history_item">
              <div className="card_history_item_summary skeleton skeleton_subtitle"></div>
              <ul className="card_history_product_list">
                <li>
                  <div className="payment_info ">
                    <div className="payment_info_row skeleton_row">
                      <p className="skeleton skeleton_subtitle "></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                    <div className="payment_info_row info_desc skeleton_row">
                      <p className="skeleton skeleton_subtitle"></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CardHistorySkeleton;
