import { useState, useEffect } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import Layout from "./Layout";
import Footer from "./Footer";
import { useCheckMydataJoin } from "features/mydata";
import { useRouter } from "lib/utils/useRouter";
import ScrollToTop from "routes/ScrollToTop";

const MydataUserLayout = () => {
  const checkMydataJoinQuery = useCheckMydataJoin();
  const useRoute = useRouter();

  useEffect(() => {
    if (!checkMydataJoinQuery?.data) {
      useRoute.changePath("/mydata/join");
    }
  }, [checkMydataJoinQuery?.data]);

  if (checkMydataJoinQuery?.data) {
    return (
      <>
        <ScrollToTop>
          <Outlet />
        </ScrollToTop>
      </>
    );
  }

  return null;
};

export default MydataUserLayout;
