import Modal from "components/Modal/Modal";
import { Button } from "components/Button";

const OrgRevokePopup = (props) => {
  return (
    <Modal onClose={props.onClose} title="연결 해제 안내">
      <div style={{ color: "#333", marginBottom: "24px" }}>
        정보 가져오기를 종료하시겠습니까?<br></br>
        연결을 끊어도 수집한 개인신용정보는 삭제되지 않습니다.
      </div>

      <div className="btn_management_wrap">
        <Button
          type={"button"}
          onClick={props.onClose}
          title={"취소"}
          className={"btn_management_delete"}
        ></Button>
        <Button
          type={"button"}
          onClick={props.submitClick}
          title={"연결 끊기"}
          className={"btn_management_update"}
        ></Button>
      </div>
    </Modal>
  );
};

export default OrgRevokePopup;
