import React, { useEffect, useState } from "react";
import { CustomButton } from "components/Button";
import { Button } from "components/Button";
import "./popup.css";

const ConfirmPopup = ({ isOpen, bottomClose, yesClick, noClick }) => {
  const [isAnimation, setIsAnimation] = useState(false);
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    const body = document.body;

    if (isOpen) {
      body.classList.add("body_hidden");
      setAnimationClass("mount");
      setIsAnimation(true);
    } else {
      body.classList.remove("body_hidden");
      setAnimationClass("unmount");
      setTimeout(() => {
        setIsAnimation(false);
      }, 200);
    }
  }, [isOpen]);

  return (
    <div className={["popup-layout", isAnimation ? "active" : ""].join(" ")}>
      <div className="popup-dim"></div>
      <div className={["popup-wrap", animationClass, "popup-bottom"].join(" ")}>
        <div className="botpos_vtitlow popup-has-close">
          <p className="botpos_vtit title">
            더 자세한 소비내역을 보려면,<br></br>선택항목도 동의해주세요
          </p>
        </div>

        {/* TODO 동의한 약관에 따라 보여주는 값이 달라짐 */}
        <div className="botpos_vcontlow merchant_body">
          <ul className="merchant_example">
            <li>가맹점명/사업자등록번호 전송 및 수집 이용</li>
          </ul>
          <div className="consent_agree_box">
            <ul>
              <li className="consent_agree_content">
                <p>결제한 곳 정보</p>
                <p className="withdraw">70,000원</p>
                <p className="sub_title">2023.09.01 17:00</p>
              </li>
            </ul>
          </div>

          <div className="btn_confirm_wrap">
            <Button
              title={"괜찮아요"}
              className={"btn_confirm_cancel"}
              onClick={noClick}
            ></Button>
            <Button
              title={"네, 좋습니다 "}
              className={"btn_confirm_ok"}
              onClick={yesClick}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
