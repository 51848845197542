import MydataQueryFactory from "./MydataQueryFactory";
import APIInstance from "lib/apis/APIInstance";
import useMyDataQuery from "./mydataQuery";

const fetchGetAsset = async (param) => {
  const { data } = await APIInstance.get("/mydata/asset", {
    params: param,
  });
  return data?.data;
};

const useGetAsset = (param) => {
  const queryKey = MydataQueryFactory.queries.getHavingAssets(param);
  const query = useMyDataQuery(
    queryKey,
    async () => await fetchGetAsset(param),
    (data) => data,
    true,
    true
  );

  return query;
};

export { useGetAsset };
