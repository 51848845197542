import { Outlet, createBrowserRouter } from "react-router-dom";
import HomePage from "pages/home/HomePage";
import * as Splash from "pages/splash";
import * as Payment from "pages/payment";
import * as Mydata from "pages/mydata";
import * as Card from "pages/card";
import * as Billing from "pages/billing";
import * as TransHistory from "pages/transferHistory";
import * as Management from "pages/management";
import * as Terms from "pages/terms";
import * as Cert from "pages/certification";
import { LogoutPage } from "pages/user";
import Statistics from "pages/statistics";
import Root from "routes/Root";
import { MydataUserLayout, ErrorLayout } from "layout";
import CreditInformation from "pages/creditInformation";
import ScrollToTop from "./ScrollToTop";

const AppLayout = () => {
  return (
    <ScrollToTop>
      <Outlet />
    </ScrollToTop>
  );
};

const router = createBrowserRouter([
  {
    baseName: "/",
  },
  {
    path: "/home",
    element: <HomePage />,
  },
  {
    path: "",
    children: [
      { index: true, element: <Splash.Splash /> },
      { path: "start", element: <Splash.OnboardingSplash /> },
    ],
  },
  {
    path: "mydata",
    element: <AppLayout />,
    errorElement: <ErrorLayout />,
    children: [
      { path: "join", element: <Mydata.ServiceJoin /> },
      { path: "loading", element: <Mydata.Loading /> },
      {
        path: "register/organization",
        element: <Mydata.RegisterOrganization />,
      },
      {
        path: "consent/organization",
        element: <Mydata.ConsentForOrganization />,
      },
      { path: "register/asset", element: <Mydata.RegisterAsset /> },
      { path: "consent/asset", element: <Mydata.ConsentForAsset /> },
      { path: "result", element: <Mydata.Result /> },
    ],
  },
  {
    path: "card",
    element: <MydataUserLayout />,
    errorElement: <LogoutPage />,
    children: [
      { index: true, element: <Card.CardMainPage /> },
      { path: ":id", element: <Card.CardDetails /> },
      { path: ":id/history", element: <Card.CardHistory /> },
      { path: "history/:cardId", element: <Card.CardHistoryDetail /> },
    ],
  },
  {
    path: "billing",
    element: <MydataUserLayout />,
    errorElement: <LogoutPage />,
    children: [
      { path: ":orgCode", element: <Billing.Billing /> },
      { path: ":orgCode/:chargeMonth", element: <Billing.BillingHistory /> },
      { path: "details", element: <Billing.BillingDetails /> },
    ],
  },
  {
    path: "payment",
    element: <MydataUserLayout />,
    errorElement: <LogoutPage />,
    children: [
      { index: true, element: <Payment.PaymentHistory /> },
      { path: "details", element: <Payment.PaymentDetails /> },
    ],
  },
  {
    path: "statistics",
    element: <Statistics />,
  },
  {
    path: "transferHistory",
    element: <MydataUserLayout />,
    children: [
      { index: true, element: <TransHistory.TransferHistory /> },
      {
        path: ":id/:no/:hisDttm",
        element: <TransHistory.TransferHistoryDetail />,
      },
    ],
  },
  {
    path: "management",
    element: <MydataUserLayout />,
    children: [
      { index: true, element: <Management.Management /> },
      { path: ":code", element: <Management.ManagementDetails /> },
    ],
  },
  {
    path: "terms",
    children: [
      { index: true, element: <Terms.TermsList /> },
      { path: ":id", element: <Terms.TermsDetail /> },
    ],
  },
  {
    path: "Cert",
    children: [
      { index: true, element: <Cert.CertificationList /> },
      { path: "add/step1", element: <Cert.AddCertification /> },
    ],
  },
  {
    path: "credit",
    element: <CreditInformation />,
  },
  {
    path: "logout",
    element: <LogoutPage />,
  },
  {
    path: "*",
    Component: Root,
  },
]);

export default router;
