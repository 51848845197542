import React, { useEffect, useState } from "react";

const handlePopupClose = () => {};

const usePopup = ({ isOpen, onClose, selectRef }) => {
  const [isAnimation, setIsAnimation] = useState(false);
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    const body = document.body;

    if (isOpen) {
      body.classList.add("body_hidden");
      setAnimationClass("mount");
      setIsAnimation(true);
    } else {
      body.classList.remove("body_hidden");
      setAnimationClass("unmount");
      setTimeout(() => {
        setIsAnimation(false);
      }, 200);
    }
  }, [isOpen]);

  // const handleCloseSelect = (e) => {
  //   if (
  //     isOpen &&
  //     e.target.classList.contains("popup-dim") &&
  //     !selectRef.current.contains(e.target)
  //   ) {
  //     handleClose();
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("click", handleCloseSelect);
  //   return () => {
  //     window.removeEventListener("click", handleCloseSelect);
  //   };
  // });

  return { isAnimation, animationClass };
};

export default usePopup;
