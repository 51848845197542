import { OrganizationItem, useGetOrganization } from "features/mydata";

const OrganizationContent = (props) => {
  const { data: organizationData } = useGetOrganization();

  const setSelectItemList = props.setSelectList;
  const selectList = props.selectList;

  const handleSelectAll = (checked) => {
    setSelectItemList(checked ? organizationData?.org_list : []);
  };

  const handleSelect = (checked, item) => {
    document.getElementById("selectAllCard").checked = false;
    if (checked) {
      setSelectItemList([...selectList, item]);
    } else {
      setSelectItemList(
        selectList.filter((el) => el.org_code !== item.org_code)
      );
    }
  };

  return (
    <div className="select_container">
      <div className="select_wrap">
        <div className="select_title_wrap">
          <div className="">
            <input
              type="checkbox"
              id="selectAllCard"
              className="select_all_industry"
              onChange={(e) => handleSelectAll(e.target.checked)}
            ></input>
            <label htmlFor="selectAllCard" className="select_category_all">
              전체 선택
            </label>
          </div>
        </div>
        <ul className="company_container">
          {(organizationData || [])?.org_list?.map((org) => {
            return (
              <OrganizationItem
                organization={org}
                onChange={handleSelect}
                selectList={selectList}
                key={org.org_code}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default OrganizationContent;
