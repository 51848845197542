import React from "react";

const Service = () => {
  return (
    <div className="terms_contents_body">
      <h2>제1장 총칙</h2>
      <h3>제1조 (목적)</h3>
      <p>
        이 약관은 주식회사 뱅큐(이하 “회사”)가 모바일로 제공하는 뱅큐 서비스 및
        이에 부수된 제반 서비스(이하 “서비스”)의 이용과 관련하여 회사와 회원
        간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로
        합니다.
      </p>
      <h3>제2조 (용어의 정의)</h3>
      <ul>
        <li>
          ① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
          <ul>
            <li>
              1. “회사”라 함은 모바일 애플리케이션을 통하여 서비스를 제공하는
              사업자인 주식회사 뱅큐를 의미합니다.
            </li>
            <li>
              2. “뱅큐” 애플리케이션이란 모바일 디바이스에 설치되어 회사가
              제공하는 서비스를 회원이 이용할 수 있게 하는 애플리케이션을
              말합니다.
            </li>
            <li>
              3. “회원”이라 함은 회사의 서비스에 접속하여 이 약관에 따라 회사와
              이용계약을 체결하여 회사가 제공하는 서비스를 이용하는 고객을
              의미합니다.
            </li>
            <li>
              4. “계정(ID)”이라 함은 회원의 식별과 서비스 이용을 위하여 회원
              가입 시 입력한 이메일 주소나 문자, 숫자 또는 특수문자의 조합을
              의미합니다.
            </li>
            <li>
              5. “계정정보“라 함은 회원의 계정, 비밀번호, 성명 등 회원이 회사에
              제공한 일반정보 및 서비스이용정보, 이용요금 결제 상태 등 생성
              정보를 통칭합니다.
            </li>
            <li>
              6. “비밀번호”라 함은 회원이 부여받은 계정과 일치되는 회원임을
              확인하고 회원의 정보 및 권익보호를 위해 회원 자신이 설정한 문자,
              숫자 또는 특수 문자의 조합을 의미합니다.
            </li>
            <li>
              7. “간편비밀번호”라 함은 회원이 서비스 이용을 위해 어플리케이션에
              입력하는 본인확인용 개인식별번호를 말합니다.
            </li>
            <li>
              8. “서비스”라 함은 회사가 회원에게 모바일 어플리케이션이나 웹으로
              제공하는 뱅큐 서비스 및 이에 부수된 제반 서비스를 통칭하며,
              <ul>
                <li>1) 회원의 뱅큐 계정정보를 이용한 금융조회 서비스</li>
                <li>
                  2) 회원의 지출내역을 통합하여 조회하고 분석하여 금융상품을
                  추천하는 서비스
                </li>
              </ul>
              등으로 구성되며, 그 구체적인 내용은 이 약관 제3조에서 정한 것과
              같습니다.
              <ul>
                <li>
                  1. “콘텐츠”라 함은 회사가 서비스에 제공하는 문자, 문서, 그림,
                  음성, 음향, 영상 또는 이들의 조합으로 이루어진 모든 정보를
                  말합니다.
                </li>
                <li>
                  2. “서비스 이용 수수료”라 함은 서비스의 이용 대가로 이용자가
                  회사에 지불하는 수수료를 말합니다.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          ② 제1항 각호에서 정하는 것을 제외하고 이 약관에서 사용하는 용어의
          정의는 전자금융거래법 등 관계법령 및 기타 상관례에 따릅니다.
        </li>
      </ul>
      <h3>제3조 (서비스의 종류)</h3>
      회사는 회원에게 아래와 같은 서비스를 제공합니다.
      <ul>
        <li>
          ① 개인의 자산 및 거래 현황을 공인인증서 및 실명인증, 비밀번호 등록
          등의 방법을 통하여 조회하는 서비스
        </li>
        <li>
          ② 회원의 금융 거래내역 분석을 통하여 맞춤형 상품 및 금융거래를
          추천하는 서비스
        </li>
        <li>③ 금융정보, 이벤트 등 금융관련 콘텐츠 제공 서비스</li>
        <li>
          ④ 기타 회사가 추가 개발하거나 다른 회사와의 제휴를 통해 회원에게
          제공하는 일체의 서비스
        </li>
      </ul>
      <h3>제4조 (약관의 명시와 개정)</h3>
      <ul>
        <li>
          ① 회사는 이 약관의 내용을 회원이 알 수 있도록 서비스의 최초 가입 시에
          고지하며, 서비스 이용 도중 쉽게 열람 가능하도록 서비스 화면 내에
          게시합니다.
        </li>
        <li>
          ② 회사는 회원이 회사와 이 약관의 내용에 관하여 질의 및 응답을 할 수
          있도록 조치를 취합니다.
        </li>
        <li>
          ③ 회사는 서비스를 이용하고자 하는 자(이하 “이용자”)가 약관의 내용을 알
          수 있도록 작성하고 약관에 동의하기에 앞서 약관에 정해져 있는 내용 중
          중요한 내용을 회원이 알 수 있도록 합니다.
        </li>
        <li>
          ④ 회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의
          규제에 관한 법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」,
          「콘텐츠산업진흥법」, 「신용정보의 보호 및 이용에 관한 법률」 등 관련
          법령에 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
        </li>
        <li>
          ⑤ 회사가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유 등을
          현행 약관과 함께 명시하여 그 적용일자로부터 최소한 7일 이전(단,
          회원에게 불리하거나 중대한 사항의 변경은 30일 이전)에 공지합니다.
        </li>
        <li>
          ⑥ 회사가 약관을 개정할 경우에는 개정약관 공지 후 개정약관의 적용에
          대한 회원의 동의 여부를 확인합니다. 회원이 약관 시행일까지 동의 또는
          거부의사를 표시하지 않는다면 개정약관에 부동의한 것으로 간주할 수
          있습니다. 단, 법령의 개정으로 인한 약관의 개정 및 회원의 권리•의무와
          관련 없는 사항과 관련한 약관의 개정의 경우에는 회원의 별도의 동의 여부
          확인없이 개정약관 공지 후 개정약관을 시행할 수 있습니다
        </li>
        <li>
          ⑦ 회원이 개정약관의 적용에 동의하지 않는 경우 회사 또는 회원은 서비스
          이용계약을 해지할 수 있습니다.
        </li>
      </ul>
      <h3>제5조 (약관 외 준칙)</h3>이 약관에서 정하지 아니한 사항과 이 약관의
      해석에 관하여는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의
      규제에 관한 법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」,
      「콘텐츠산업진흥법」, 「신용정보의 보호 및 이용에 관한 법률」 등 관련
      법령에 따릅니다.
      <h3>제6조 (운영정책)</h3>
      <li>
        ① 약관을 적용하기 위하여 필요한 사항과 회원의 권익을 보호하고 서비스 내
        질서를 유지하기 위하여 회사는 약관에서 구체적 범위를 정하여 위임한
        사항을 서비스 운영정책(이하 “운영정책”이라 합니다)으로 정할 수 있습니다.
      </li>
      <li>
        ② 회사는 운영정책의 내용을 회원이 알 수 있도록 서비스 초기 화면이나
        서비스 홈페이지에 게시하거나 연결화면을 제공하는 방법으로 회원에게
        공지하여야 합니다.
      </li>
      <li>
        ③ 회원의 권리 또는 의무에 중대한 변경을 가져오거나 약관 내용을 변경하는
        것과 동일한 효력이 발생하는 운영정책 개정의 경우에는 제4조의 절차에
        따릅니다. 단, 운영정책 개정이 다음 각 호의 어느 하나에 해당하는 경우에는
        제2항의 방법으로 사전에 공지합니다.
        <ul>
          <li>
            1. 약관에서 구체적으로 범위를 정하여 위임한 사항을 개정하는 경우
          </li>
          <li>2. 회원의 권리·의무와 관련 없는 사항을 개정하는 경우</li>
          <li>
            3. 운영정책의 내용이 약관에서 정한 내용과 근본적으로 다르지 않고
            회원이 예측 가능한 범위 내에서 운영정책을 개정하는 경우
          </li>
        </ul>
      </li>
      <h2>제2장 이용계약의 체결</h2>
      <h3>제7조 (서비스 이용계약의 체결)</h3>
      <ul>
        <li>
          ① 서비스에 대한 이용계약(이하 “이용계약”)은 회원이 되고자 하는 자(이하
          “가입신청자”)가 본 “약관”, “개인정보처리방침”, “개인정보 수집 이용”에
          동의하고 정해진 가입양식에 회원정보를 기입하면 회사가 이에 대해
          승낙함으로써 체결됩니다.
        </li>
        <li>
          ② 본 “약관”, “개인정보처리방침”, “개인정보 수집 이용”에 대한 동의의
          의사표시는 가입신청자가 회원가입신청 당시 “서비스 이용약관”,
          “개인정보처리방침”, “개인정보 수집 이용”에 동의 버튼을 누르는 것으로
          이루어지며, 이와 동시에 위 가입신청자가 본 “약관”, “개인정보처리방침”
          및 “개인정보 수집 이용”에 대하여 동의한 것으로 간주합니다.
        </li>
        <li>
          ③ 가입신청자는 회사가 요구하는 개인정보를 성실히 제공하여야 합니다.
          <ul>
            <li>
              1. 가입신청자는 반드시 신청자 본인의 명의로 가입하여야 합니다.
            </li>
            <li>
              2. 가입신청자는 본인 명의의 금융자산, 상품을 소유하여야 합니다.
            </li>
            <li>
              3. 회사는 본인확인기관 등을 통한 “본인확인”을 요청합니다.
              본인확인의 경우 개인정보보호 법령에 따라 본인확인기관 등이
              제공하는 별도의 이용약관 동의에 의거하여 본인확인 절차를 진행하며,
              회사는 본인확인 절차를 취할 수 없는 이용신청에 대해서는 해당
              신청자에게 추가적으로 증빙자료를 요청할 수 있습니다.
            </li>
          </ul>
        </li>
        <li>
          ④ 회사는 가입신청자의 이용신청이 다음 각 호에 해당하는 경우 승낙하지
          않거나 승낙을 유보할 수 있습니다.
          <ul>
            <li>1. 실명이 아니거나 타인의 명의를 이용한 경우</li>
            <li>
              2. 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은
              경우
            </li>
            <li>
              3. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이
              있는 경우, 단 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 함
            </li>
            <li>
              4. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한
              제반사항을 위반하여 신청하는 경우
            </li>
          </ul>
        </li>
      </ul>
      <h3>제8조 (회원 계정(ID) 및 비밀번호)</h3>
      <ul>
        <li>
          ① 회사는 회원에 대하여 회원의 정보 보호, 서비스 이용안내 등의 편의를
          위해 회원의 본인확인 후 회원에게 고유한 계정을 부여합니다.
        </li>
        <li>
          ② 회사는 계정정보를 통하여 당해 회원의 서비스 이용가능 여부 등의 제반
          회원 관리업무를 수행하며, 부여된 계정은 회원 식별을 위한 정보로만
          사용됩니다.
        </li>
        <li>
          ③ 회원은 자신의 계정정보에 대해 주의, 의무를 다하여 관리하여야 합니다.
          회원이 본인의 계정정보를 소홀히 관리하거나 제3자에게 이용을
          승낙함으로써 발생하는 손해에 대하여는 회원에게 책임이 있습니다.
        </li>
        <li>
          ④ 비밀번호의 관리책임은 회원에게 있으며, 회원이 원하는 경우에는
          보안상의 이유 등으로 언제든지 변경이 가능합니다.
        </li>
      </ul>
      <h3>제9조 (회원 정보의 수집)</h3>
      <ul>
        <li>
          ① 회사는 이용계약을 위하여 회원이 제공한 정보 외에도 수집목적 또는
          이용목적을 밝혀 회원으로부터 필요한 정보를 수집할 수 있습니다. 이
          경우, 회사는 회원으로부터 정보수집에 대한 동의를 받습니다.
        </li>
        <li>
          ② 회사가 정보수집을 위하여 회원의 동의를 받는 경우, 회사는 수집하는
          개인정보의 항목 및 수집방법, 수집목적 및 이용목적, 개인정보의 보유 및
          이용기간, 제3자에 대한 정보제공 사항(제공받는 자, 제공받는 자의
          이용목적, 제공정보의 항목, 보유 및 이용기간)을 개인정보처리방침으로
          미리 명시하거나 고지합니다.
        </li>
        <li>
          ③ 회원은 회사에게 정보를 제공하고자 하는 경우 사실대로 제공하여야
          합니다.
        </li>
        <li>
          ④ 회원은 정보제공에 동의하더라도 언제든지 그 동의를 철회할 수
          있습니다.
        </li>
      </ul>
      <h3>제10조 (회원 정보의 제공 및 변경)</h3>
      <ul>
        <li>
          ① 회원은 이 약관에 의하여 회사에 정보를 제공하여야 하는 경우에는
          진실된 정보를 제공하여야 하며, 허위정보 제공으로 인해 발생한 불이익에
          대해서는 보호받지 못합니다.
        </li>
        <li>
          ② 회원은 개인정보관리화면을 통하여 언제든지 자신의 개인정보를 열람하고
          수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 생년월일,
          성별 등은 수정이 불가능합니다.
        </li>
        <li>
          ③ 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 어플리케이션을
          통해 수정을 하거나 기타 방법으로 회사에 대하여 그 변경 사항을 알려야
          하며, 변경 불가한 항목에 변경사항이 있을 시 고객센터에서 확인 후
          변경내역을 처리합니다.
        </li>
        <li>
          ④ 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는
          책임을 지지 않습니다.
        </li>
      </ul>
      <h2>제3장 계약 당사자의 의무</h2>
      <h3>제11조 (회사의 의무)</h3>
      <ul>
        <li>
          ① 회사는 관련 법령을 준수하고, 이 약관이 정하는 권리의 행사와 의무의
          이행을 신의에 따라 성실하게 합니다.
        </li>
        <li>
          ② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보
          포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을
          공시하고 준수합니다. 회사는 이 약관 및 개인정보처리방침에서 정한
          경우를 제외하고는 회원의 개인정보가 제3자에게 공개 또는 제공되지
          않도록 합니다.
        </li>
        <li>
          ③ 회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던
          중 설비에 장애가 생기거나 데이터 등이 멸실된 때에는 천재지변,
          비상사태, 현재의 기술로는 해결이 불가능한 결함 및 장애 등 부득이한
          사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을
          다합니다.
        </li>
        <li>
          ④ 회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 객관적으로
          인정될 경우에는 합리적인 기간 내에 신속하게 처리하여야 합니다. 다만,
          처리에 장기간이 소요되는 경우 이용자에게 게시판 또는 전자우편 등을
          통하여 지체 사유를 안내하고 처리과정 및 처리 결과를 전달합니다.
        </li>
        <li>
          ⑤ 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 회원과의 계약관련
          절차 및 내용 등에 있어 회원에게 편의를 제공하도록 노력합니다.
        </li>
      </ul>
      <h3>제12조 (회원의 의무)</h3>
      <ul>
        <li>
          ① 회원은 회사에서 제공하는 서비스를 본래의 이용 목적 이외의 용도로
          사용하거나 다음 각 호에 해당하는 행위를 하여서는 안되며, 위반 시
          이용계약의 해지, 손해배상 및 관계법령에 의거하여 처벌받을 수 있습니다.
          <ul>
            <li>
              1. 가입신청 또는 변경 시 허위내용을 기재하거나, 타인의 정보를
              사용하는 경우
            </li>
            <li>2. 회사의 임직원, 운영자, 기타 관계자를 사칭하는 행위</li>
            <li>3. 회사가 게시한 정보의 변경</li>
            <li>4. 회사가 금지한 정보(컴퓨터 프로그램 등)의 송·수신</li>
            <li>
              5. 회사가 제공 또는 승인하지 아니한 컴퓨터 프로그램이나 기기 또는
              장치를 제작, 배포, 이용, 광고하는 행위
            </li>
            <li>6. 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
            <li>
              7. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
            </li>
            <li>
              8. 외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 사회질서에
              반하는 정보 등을 타인에게 유포하는 행위
            </li>
            <li>
              9. 서비스 데이터를 유상으로 처분하거나 권리의 객체로 하는 행위
            </li>
            <li>10. 9호의 행위를 유도하거나 광고하는 행위</li>
            <li>
              11. 회사의 동의 없이 영리, 영업, 광고, 정치활동 등을 목적으로
              서비스를 사용하는 행위
            </li>
            <li>
              12. 본 약관 및 전자금융거래법 등 서비스이용에 관련된 관계법령을
              위반한 행위
            </li>
            <li>
              13. 기타 관련 법령에서 금지하거나 선량한 풍속 기타 사회통념상
              허용되지 않는 행위
            </li>
          </ul>
        </li>
        <li>
          ② 회원은 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한
          주의사항, 회사가 통지하는 사항 등을 확인하고 준수할 의무가 있습니다.
        </li>
        <li>
          ③ 회사는 제1항, 제2항 및 다음 각 호의 어느 하나에 해당하는 행위의
          구체적인 유형을 운영정책에서 정할 수 있으며, 회원은 이를 준수할 의무가
          있습니다.
          <ul>
            <li>1. 회원의 계정명, 기타 서비스상 사용하는 명칭에 대한 제한</li>
            <li>2. 서비스 이용방법에 대한 제한</li>
            <li>
              3. 기타 회원의 서비스 이용에 대한 본질적 권리를 침해하지 않는 범위
              내에서 회사가 서비스 운영상 필요하다고 인정되는 사항
            </li>
          </ul>
        </li>
      </ul>
      <h2>제4장 서비스 이용</h2>
      <h3>제13조 (서비스의 변경 및 내용수정)</h3>
      <ul>
        <li>
          ① 회원은 회사가 제공하는 서비스를 이 약관, 운영정책 및 회사가 설정한
          서비스규칙에 따라 이용할 수 있습니다.
        </li>
        <li>
          ② 회사는 서비스 내용의 제작, 변경, 유지, 보수에 관한 포괄적인 권한을
          가집니다.
        </li>
        <li>
          ③ 회사가 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 서비스
          수정을 할 수 있으며, 서비스 수정을 하는 경우에는 변경 후 해당 서비스
          화면 등을 통하여 공지합니다.
        </li>
      </ul>
      <h3>제14조(서비스의 이용개시, 이용시간 및 수수료)</h3>
      <ul>
        <li>
          ① 제7조에 따라 회사가 신청인의 이용신청을 승낙함과 동시에 신청인은
          회사가 제공하는 서비스의 회원이 되며, 이때부터 회사는 당해 회원에게
          당해 약관이 정하는 서비스를 제공합니다.
        </li>
        <li>
          ② 회사는 당해 약관이 정한 서비스 외 추가 서비스를 제공하기 위해
          회원에게 별도 또는 추가적인 가입절차를 요청할 수 있으며, 회원이 추가
          서비스를 이용할 경우 해당 추가 서비스와 관련하여서는 각 서비스 별로
          추가되는 이용약관이 당해 약관보다 우선 적용됩니다.
        </li>
        <li>
          ③ 서비스의 이용은 회사의 업무상 또는 기술상의 특별한 지장이 없는 한
          연중무휴, 1일 24시간을 원칙으로 합니다. 다만, 서비스 운영상의
          필요성으로 회사가 정한 기간 동안 본 서비스가 일시 중지될 수 있고
          서비스 중지 여부는 그 내용을 홈페이지 또는 뱅큐 서비스를 통해
          공지합니다.
        </li>
        <li>
          ④ 회사는 회원의 서비스 이용에 따른 수수료를 부과할 수 있으며 수수료는
          서비스 페이지 등을 통해 미리 고지합니다.
        </li>
      </ul>
      <h3>제15조(공지사항 및 광고성 또는 맞춤형 정보의 제공)</h3>
      <ul>
        <li>
          ① 회사는 서비스의 운영과 관련한 공지사항을 홈페이지 또는 뱅큐 서비스
          화면에 게재하거나 SMS, 전자우편, Push알림 등을 통하여 통지할 수
          있습니다.
        </li>
        <li>
          ② 회사는 본 서비스의 운영과 관련한 광고성 정보 및 회원 맞춤형 정보를
          홈페이지 또는 뱅큐 서비스 화면에 게재하거나 SMS, 전자우편, Push알림
          등의 방법으로 회원에게 제공할 수 있습니다. 다만 광고성 또는 맞춤형
          정보의 SMS, 전자우편, Push알림은 사전에 수신에 동의한 회원에게만
          제공됩니다.
        </li>
      </ul>
      <h3>제16조(인증방법)</h3>
      <ul>
        <li>
          ① 서비스 이용을 위한 인증방법은 각 메뉴에 따라 비밀번호 입력,
          간편비밀번호 입력, 공인인증서 비밀번호 입력, 휴대전화번호를 통한
          본인확인 등 회사가 정한 방법이 이용됩니다.
        </li>
        <li>
          ② 회원은 서비스 이용을 위하여 이용신청 단계에서 회사가 정하는 바에
          따라 비밀번호와 간편비밀번호를 등록하여야 하며, 이용계약 체결 이후에는
          뱅큐 서비스에서 이를 변경할 수 있습니다.
        </li>
        <li>
          ③ 회원은 본인 인증수단의 관리 소홀이나 누설에 따른 모든 책임을
          부담합니다.
        </li>
      </ul>
      <h3>제17조 (서비스의 제공 및 중단 등)</h3>
      <ul>
        <li>① 회사는 영업방침에 따라 정해진 시간 동안 서비스를 제공합니다.</li>
        <li>
          ② 제1항에도 불구하고, 다음 각 호의 어느 하나에 해당하는 경우에는
          일정한 시간 동안 서비스가 제공되지 아니할 수 있으며, 해당 시간 동안
          회사는 서비스를 제공할 의무가 없습니다.
          <ul>
            <li>
              1. 컴퓨터 등 정보통신설비의 보수점검, 교체, 정기점검 또는 서비스
              내용이나 서비스의 수정을 위하여 필요한 경우
            </li>
            <li>
              2. 해킹 등의 전자적 침해사고, 통신사고, 회원들의 비정상적인 서비스
              이용행태, 미처 예상하지 못한 서비스의 불안정성에 대응하기 위하여
              필요한 경우
            </li>
            <li>
              3. 관련 법령에서 특정 시간 또는 방법으로 서비스 제공을 금지하는
              경우
            </li>
            <li>
              4. 천재지변, 비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의
              폭주 등으로 정상적인 서비스 제공이 불가능할 경우
            </li>
            <li>
              5. 회사의 분할, 합병, 영업양도, 영업의 폐지, 당해 서비스의 수익
              악화 등 회사의 경영상 중대한 필요에 의한 경우
            </li>
          </ul>
        </li>
        <li>
          ③ 회사는 제2항 제1호의 경우, 매주 또는 격주 단위로 일정 시간을 정하여
          서비스를 중지할 수 있습니다. 이 경우 회사는 최소한 24시간 전에 그
          사실을 회원에게 서비스 초기 화면이나 서비스 홈페이지에 고지합니다.
        </li>
        <li>
          ④ 제2항 제2호의 경우, 회사는 사전 고지 없이 서비스를 일시 중지할 수
          있습니다. 회사는 이러한 경우 그 사실을 서비스 초기 화면이나 서비스
          홈페이지에 사후 고지할 수 있습니다.
        </li>
        <li>
          ⑤ 회사는 회사가 제공하는 서비스 이용과 관련하여 이용자에게 발생한
          어떠한 손해에 대해서도 책임을 지지 않습니다. 다만, 회사의 고의 또는
          중대한 과실로 인하여 발생한 손해의 경우는 제외합니다. 제2항 제3호 내지
          제5호의 경우에 회사는 기술상, 운영상 필요에 의해 서비스 전부를 중단할
          수 있으며, 사전에 공지하고 서비스의 제공을 중단할 수 있습니다. 사전에
          공지할 수 없는 부득이한 사정이 있는 경우는 사후에 공지를 할 수
          있습니다.
        </li>
        <li>
          ⑥ 회사가 부득이한 사유에 따라 서비스를 종료하는 경우 회원은 서비스
          이용이 중단됨에 따른 손해배상을 청구할 수 없습니다.
        </li>
      </ul>
      <h3>제18조 (접근매체 분실 및 도난 관련 회원의 책임)</h3>
      <ul>
        <li>
          ① 회사는 서비스 제공 시 자신의 모바일 디바이스, 비밀번호 및
          간편비밀번호 등 접근매체(이하 “접근매체”)를 를 통하여 회원의 신원,
          권한 및 거래지시의 내용 등을 확인합니다.{" "}
        </li>
        <li>
          ② 회원은 자신의 접근매체를 제3자에게 누설 또는 노출하거나 제3자가 이용
          가능 하도록 방치하여서는 안되며, 접근매체의 도용이나 위조 또는 변조를
          방지하기 위하여 충분한 주의를 기울여야 합니다.
        </li>
        <li>
          ③ 회원은 자신의 비밀번호 및 간편비밀번호가 부정하게 사용되었음을
          확인한 즉시 서비스를 통하여 자신의 비밀번호 및 간편비밀번호를 바꾸고
          그 사실을 회사에 통지하여야 합니다.
        </li>
        <li>
          ④ 회원은 접근매체가 훼손, 분실, 도난 되는 등의 사고(이하 “사고”)가
          발생하는 경우 즉시 그 사고 사실을 서면 또는 회사가 정한 방법(본사 또는
          고객센터로 신고 전화)으로 회사에 통지하여야 합니다.
        </li>
        <li>
          ⑤ 회사는 회원으로부터 제4항에 따른 통지를 받은 이후에는 그 때부터
          제3자가 그 접근매체를 사용함으로 인하여 회원에게 발생한 손해를 배상할
          책임이 있습니다.
        </li>
      </ul>
      <h3>제19조 (서비스 부정사용에 대한 책임)</h3>
      <ul>
        <li>
          ① 서비스 부정사용으로 인하여 발생된 부정사용 금액에 대한 일차적 책임은
          회사에 있습니다. 다만, 회사가 다음 각 호에 해당하는 회원의 고의 또는
          과실을 입증하는 경우에는 그러하지 아니합니다.
          <ul>
            <li>
              1. 회원이 접근매체를 제3자에게 대여하거나 그 사용을 위임한 경우
              또는 양도나 담보의 목적으로 제공한 경우
            </li>
            <li>
              2. 회원의 고의 또는 중대한 과실에 의한 비밀번호, 간편비밀번호,
              공인인증서 유출 등 접근매체의 훼손, 분실, 도난, 유출 등으로 인한
              부정사용의 경우
            </li>
            <li>
              3. 회원이 서비스 부정사용의 피해조사를 위하여 회사가 요청한 조사에
              협조하지 아니하거나 방해한 경우
            </li>
            <li>
              4. 제3자가 권한 없이 회원의 접근매체를 이용하여 전자금융조회 및
              거래를 할 수 있음을 알았거나 쉽게 알 수 있었음에도 불구하고 회원이
              접근매체를 누설하거나 노출 또는 방치하고 정당한 사유 없이 회사에
              늦게 신고한 경우
            </li>
          </ul>
        </li>
      </ul>
      <h3>제20조 (서비스 이용 내역의 보존 및 확인)</h3>
      <ul>
        <li>
          ① 회사는 회원이 이용한 서비스의 내용을 추적, 검색하거나 그 내용에
          오류가 발생한 경우에 이를 확인하거나 정정할 수 있는 기록을 생성하여
          보존합니다.
        </li>
        <li>
          ② 회사는 회원이 서비스 이용내역에 대해 조회확인을 요청하는 경우, 요청
          받은 기간의 서비스 이용 내역을 조회하여 회원에게 기록을 제공합니다.
        </li>
      </ul>
      <h3>제21조 (저작권 등의 귀속)</h3>
      <ul>
        <li>
          ① 서비스 내 회사가 제공하는 모든 콘텐츠에 대한 저작권 기타
          지적재산권은 회사에 귀속합니다.
        </li>
        <li>
          ② 회원은 회사가 제공하는 서비스를 이용함으로써 얻은 정보 중 회사 또는
          제공업체에 지적재산권이 귀속된 정보를 회사 또는 제공업체의 사전승낙
          없이 복제, 전송, 출판, 배포, 방송 및 기타 방법에 의하여 영리목적으로
          이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
        </li>
      </ul>
      <h2>제5장 계약 해제·해지 및 이용제한</h2>
      <h3>제22조(회원의 계약해지, 이용계약의 제한 등)</h3>
      <ul>
        <li>
          ① 회원은 언제든지 서비스 내 계정 삭제화면을 통하여 이용계약 해지
          신청을 할 수 있으며, 회사는 관련법 등이 정하는 바에 따라 이를 즉시
          처리하여야 합니다.
        </li>
        <li>
          ② 회원이 계약을 해지할 경우, 관련법 및 개인정보처리방침에 따라 회사가
          회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 데이터는
          소멸되며, 다음 각 호에 해당하는 내역에 대해서는 승낙을 하지 않거나
          사후에 이용계약을 해지할 수 있습니다.
          <ul>
            <li>
              1. 가입신청자가 입력한 명의가 실명이 아니거나, 다른 사람의
              명의사용 등 허위로 이용신청 하는 경우
            </li>
            <li>2. 이미 가입된 회원이 중복하여 이용신청을 하는 경우</li>
            <li>3. 이용신청 사항을 누락하거나 오기하여 이용신청 하는 경우</li>
            <li>
              4. 기타 회원의 귀책사유로 인하여 승낙이 불가능하거나 법령, 본 약관
              및 기타 회사가 정한 제반 사항을 위반하여 신청하는 경우
            </li>
          </ul>
        </li>
        <li>
          ③ 회원이 임의로 서비스를 해지한 후 재차 이용신청을 하는 경우 회사는
          심사를 거쳐 당해 회원에 대하여 일정기간 재가입을 제한할 수 있습니다.
        </li>
        <li>
          ④ 회원탈퇴로 인해 발생한 불이익에 대한 책임은 회원 본인이 져야 하며,
          이용계약이 종료되면 회사는 회원에게 부가적으로 제공한 각종 혜택을
          회수할 수 있습니다.
        </li>
        <li>
          ⑤ 회원이 임의 해지, 재가입 등을 반복적으로 행함으로써 회사가 제공하는
          할인쿠폰, 이벤트 혜택 등의 경제상의 이익을 불/편법으로 수취하거나 이
          과정에서 타인의 명의를 도용하는 등의 불/편법 행위를 차단하기 위하여
          회사는 회원의 회원탈퇴 후 3개월 동안 해당 회원의 성명, 탈퇴일자,
          탈퇴사유 등 개인정보를 보관합니다.
        </li>
        <li>
          ⑥ 회원이 이용계약을 해지할 경우 회사는 제 7조 3항, 관련법 및
          개인정보처리방침에 따라 회원 정보를 보유하는 경우를 제외하고는 해지
          즉시 회원의 모든 데이터를 삭제합니다.
        </li>
        <li>
          ⑦ 회사의 해제, 해지 및 이용제한에 대하여 회원은 회사가 정한 절차에
          따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가
          인정하는 경우, 회사는 즉시 서비스의 이용을 재개합니다.
        </li>
      </ul>
      <h3>제23조 (회사의 해제 및 해지)</h3>
      <ul>
        <li>
          ① 회사는 회원이 이 약관에서 정한 회원의 의무를 위반한 경우에는 회원에
          대한 사전 통보 후 계약을 해지할 수 있습니다. 다만, 회원이 현행법 위반
          및 고의 또는 중대한 과실로 회사에 손해를 입힌 경우에는 사전 통보 없이
          이용계약을 해지할 수 있습니다.
        </li>
        <li>
          ② 회사가 이용계약을 해지하는 경우 회사는 회원에게 서면, 전자우편 또는
          이에 준하는 방법으로 다음 각 호의 사항을 회원에게 통보합니다.
          <ul>
            <li>1. 해지사유</li>
            <li>2. 해지일</li>
          </ul>
        </li>

        <li>
          ③ 제1항 단서의 경우, 회원은 유료서비스의 사용권한을 상실하고 이로 인한
          환불 및 손해배상을 청구할 수 없습니다.
        </li>
        <li>
          ④ 회원은 회사에 기존에 등록하였던 금융기관 정보제공 해제를 요청할 수
          있으며, 이 경우는 이용계약의 해제 또는 해지로 보지 않습니다.
        </li>
      </ul>
      <h3>제24조 (회원에 대한 서비스 이용제한)</h3>
      <ul>
        <li>
          ① 회사는 회원의 서비스 이용을 제한할 수 있으며, 이용제한이 이루어지는
          구체적인 회원의 의무위반 사유는 서비스의 운영정책에서 정하기로 합니다.
        </li>
        <li>
          ② 회사의 이용제한이 정당한 경우에 회사는 이용제한으로 인하여 회원이
          입은 손해를 배상하지 않습니다.
        </li>
        <li>
          ③ 회사는 다음 각 호의 어느 하나에 해당되는 경우 회사는 해당 사유가
          발생한 회원의 서비스 이용을 제한 또는 정지할 수 있습니다.
          <ul>
            <li>
              1. 회원에게서 제 22조에서 정한 이용계약의 승낙거부사유가 있음이
              확인된 경우
            </li>
            <li>
              2. 비밀번호, 간편비밀번호를 누적하여 연속 5회 이상 오류 입력하는
              경우
            </li>
            <li>3. 접근매체의 도난 분실 등 사고 신고된 경우</li>
            <li>
              4. 회사가 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인
              방법으로 뱅큐 서비스를 이용하거나 시스템에 접근하는 행위
            </li>
            <li>
              5. 타인의 명의, 공인인증서, 카드정보, 계좌정보 등을 도용하여
              회사가 제공하는 서비스를 이용하는 행위
            </li>
            <li>6. 회원이 회사의 서비스 운영을 고의로 방해하는 경우</li>
            <li>
              7. 다른 회원의 정당한 이익을 침해하거나, 대한민국 법령에 위배되는
              행위를 한 경우
            </li>
            <li>8. 본 약관에 위배되는 행위를 한 경우</li>
            <li>
              9. 이 약관에서 규정한 사항이나 별도페이지에 규정한 이용정책을
              위반한 경우
            </li>
            <li>
              10. 기타 회사가 합리적인 판단에 의해 서비스의 제공을 거부할 필요가
              있다고 인정할 경우
            </li>
          </ul>
        </li>
        <li>
          ④ 회사는 회원이 본 조의 금지행위를 행하는 경우 서비스 이용을 제한할 수
          있으며, 이 경우 발생하는 모든 책임은 회원이 부담합니다. 회사는 필요한
          경우 이용회원의 금지행위 사실을 관련 정부기관 또는 사법기관에 통지할
          수 있습니다.
        </li>
      </ul>
      <h3>제25조 (잠정조치로서의 이용제한)</h3>
      <ul>
        <li>
          회사는 다음 각 호에 해당하는 문제에 대한 조사가 완료될 때까지 계정을
          정지할 수 있습니다.
          <ul>
            <li>
              1. 계정이 해킹 또는 도용당하였다는 정당한 신고가 접수된 경우
            </li>
            <li>
              2. 불법프로그램 사용자, 작업장 등 위법행위자로 합리적으로 의심되는
              경우
            </li>
            <li>
              3. 그 밖에 위 각호에 준하는 사유로 계정의 잠정조치가 필요한 경우
            </li>
          </ul>
        </li>
      </ul>
      <h2>제6장 손해배상 등</h2>
      <h3>제26조 (손해배상)</h3>
      <ul>
        <li>
          ① 회원이 본 약관을 위반하거나 위법한 행위로 회사에 손해를 끼친 경우,
          회원은 회사에 대하여 그 손해에 대하여 배상할 책임이 있습니다.
        </li>
        <li>
          ② 회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로
          인하여 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을
          비롯한 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로
          회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로
          인하여 회사에 발생한 모든 손해를 배상하여야 합니다.
        </li>
      </ul>
      <h3>제27조 (회사의 면책)</h3>
      <ul>
        <li>
          ① 회사는 전시, 사변, 천재지변, 비상사태, 현재의 기술로는 해결이
          불가능한 기술적 결함 기타 불가항력적 사유로 서비스를 제공할 수 없는
          경우에는 책임이 면제됩니다.
        </li>
        <li>
          ② 회사는 회원의 귀책사유로 인한 서비스의 중지, 이용장애 및 계약해지에
          대하여 책임이 면제됩니다.
        </li>
        <li>
          ③ 회사는 기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로
          제공하지 아니하여 회원에게 손해가 발생한 경우에 대해서 회사의 고의
          또는 중대한 과실이 없는 한 책임이 면제됩니다.
        </li>
        <li>
          ④ 회사는 사전에 공지된 서비스용 설비의 보수, 교체, 정기점검, 공사 등
          부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우에 대해서 회사의
          고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
        </li>
        <li>
          ⑤ 회사는 회원의 컴퓨터, 모바일 디바이스 환경으로 인하여 발생하는 제반
          문제 또는 회사의 고의 또는 중대한 과실이 없는 네트워크 환경으로 인하여
          발생하는 문제에 대해서 책임이 면제됩니다.
        </li>
        <li>
          ⑥ 회사는 회원 또는 제3자가 서비스 내 또는 웹사이트 상에 게시 또는
          전송한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대해서는 회사의
          고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
        </li>
        <li>
          ⑦ 회사는 회원 상호간 또는 회원과 제3자간에 서비스를 매개로 발생한
          분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도
          없습니다.
        </li>
        <li>
          ⑧ 본 서비스 중 일부의 서비스는 다른 사업자가 제공하는 서비스를 통하여
          제공될 수 있으며, 회사는 다른 사업자가 제공하는 서비스로 인하여 발생한
          손해 등에 대해서는 회사의 고의 또는 중대한 과실이 없는 한 책임이
          면제됩니다.
        </li>
        <li>
          ⑨ 회사는 회원의 컴퓨터, 모바일 디바이스 오류에 의한 손해가 발생한 경우
          또는 신상정보 및 전자우편주소를 부정확하게 기재하거나 미기재하여
          손해가 발생한 경우에 대하여 회사의 고의 또는 중대한 과실이 없는 한
          책임이 면제됩니다.
        </li>
        <li>
          ⑩ 회사는 관련 법령, 정부 정책 등에 따라 서비스 또는 회원에 따라 서비스
          이용시간 등을 제한할 수 있으며, 이러한 제한사항 및 제한에 따라
          발생하는 서비스 이용 관련 제반사항에 대해서는 책임이 면제됩니다.
        </li>
      </ul>
      <h3>제28조 (면책사항)</h3>
      <ul>
        <li>
          ① 회사는 접근매체의 위조나 변조로 발생한 사고(단, 회사가 접근매체의
          발급 주체이거나 사용, 관리주체인 경우에 한함), 계약체결 또는
          거래지시의 전자적 전송이나 처리과정에서 발생한 사고로 인하여 회원에게
          손해가 발생한 경우에는 그 손해를 배상할 책임을 집니다.
        </li>
        <li>
          ② 회사는 제1항에도 불구하고 다음 각호의 어느 하나에 해당하는 경우에는
          그 책임의 전부 또는 일부를 회원이 부담하게 할 수 있습니다.
          <ul>
            <li>
              1. 천재지변, 전쟁, 테러 또는 회사의 귀책사유 없이 발생한 정전,
              화재, 건물의 훼손 등 불가항력으로 인한 경우
            </li>
            <li>
              2. 회원이 접근매체를 제3자에게 대여하거나 사용을 위임하거나 양도
              또는 담보 목적으로 제공한 경우
            </li>
            <li>
              3. 제3자가 권한 없이 회원의 접근매체를 이용하여
              전자금융조회/거래를 할 수 있음을 쉽게 알았거나 알 수 있었음에도
              불구하고 회원이 자신의 접근매체를 누설 또는 노출하거나 방치한 경우
            </li>
            <li>
              4. 법인('중소기업기본법' 제2조 제2항에 의한 소기업을 제외)인
              회원에게 손해가 발생한 경우로서 회사가 사고를 방지하기 위하여
              보안절차를 수립하고 이를 철저히 준수하는 등 합리적으로 요구되는
              충분한 주의의무를 다한 경우
            </li>
          </ul>
        </li>
        <li>
          ③ 회사는 회원 상호간, 회원과 가맹점 상호간 또는 회원과 제3자 상호간에
          본 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로
          인한 손해를 배상할 책임이 없습니다.
        </li>
      </ul>
      <h3>29조 (회원에 대한 통지)</h3>
      <ul>
        <li>
          ① 회사가 회원에게 통지를 하는 경우 회원이 지정한 전자우편주소, 서비스
          내의 알림 등으로 할 수 있습니다.
        </li>
        <li>
          ② 회사는 회원 전체에게 통지를 하는 경우 서비스 초기화면에 게시하거나
          팝업화면 등을 제시함으로써 제1항의 통지에 갈음할 수 있습니다.
        </li>
      </ul>
      <h3>제30조 (재판권 및 준거법)</h3>
      <p>
        이 약관의 해석 및 적용에 대해서는 대한민국 법을 적용합니다. 본 서비스
        이용과 관련한 분쟁이 있을 경우 양 당사자간의 합의로 해결하며, 양
        당사자간에 법률상 분쟁이 발생할 경우 관할법원은 회사의 본점 소재지 관할
        법원으로 합니다.
      </p>
      <h2>제7장 개인정보 보호 및 관리</h2>
      <h3>제31조 (개인정보의 처리 위탁)</h3>
      <ul>
        <li>
          ① 회사는 관계 법령이 정하는 바에 따라 계정정보를 포함한 회원의
          개인정보를 보호하기 위해 노력합니다. 회원 개인정보의 보호 및 사용에
          대해서는 관계법령 및 회사가 별도로 고지하는 개인정보처리방침이
          적용됩니다.
        </li>
        <li>
          ② 서비스의 일부로 제공되는 개별 서비스를 제외한 것으로서 서비스 내
          화면에서 단순히 링크된 제3자 제공의 서비스에 대하여는 회사의
          개인정보처리방침이 적용되지 않습니다.
        </li>
        <li>
          ③ 회사는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 모든
          정보에 대해서 일체의 책임을 지지 않습니다.
        </li>
        <li>
          ④ 회사는 수집된 개인정보의 처리 및 관리 등의 업무(이하 업무)를 스스로
          수행함을 원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를 회사가
          선정한 회사에 위탁할 수 있습니다. 위탁에 대한 세부내용은 별도의
          개인정보보호지침을 통해 공지합니다.
        </li>
      </ul>
      <h2>제8장 기타</h2>
      <h3>제32조 (이 약관에서 정하지 아니한 사항)</h3>
      <ul>
        <li>
          ① 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 관계법령
          또는 상관례에 따릅니다.
        </li>
        <li>
          ② 회사와 이용자 사이에 개별적으로 합의한 사항이 이 약관에 정한 사항과
          다를 때에는 그 합의사항을 이 약관에 우선하여 적용합니다.
        </li>
      </ul>
      <p>부칙</p>
      <p>본 약관은 2021년 00월 00일부터 적용됩니다.</p>
      <p>2019년 7월 1일부터 시행되던 종전의 약관은 본 약관으로 대체됩니다.</p>
      <p>2018년 11월 8일부터 시행되던 종전의 약관은 본 약관으로 대체됩니다.</p>
      <p>2018년 2월 9일부터 시행되던 종전의 약관은 본 약관으로 대체됩니다.</p>
    </div>
  );
};

export default Service;
