const OrgCreditItem = (props) => {
  const org = props.org;
  const setSelectOrgList = props.setSelectOrgList;
  const selectOrgList = props.selectOrgList;
  const setSelectRevokeOrgList = props.setSelectRevokeOrgList;
  const selectRevokeOrgList = props.selectRevokeOrgList;

  const onChangeHandleOrg = (e) => {
    if (e.target.checked) {
      if (
        e.target.classList.contains("01") ||
        e.target.classList.contains("02")
      )
        setSelectOrgList([...selectOrgList, e.target.value]);
      else setSelectRevokeOrgList([...selectRevokeOrgList, e.target.value]);
    } else {
      if (
        e.target.classList.contains("01") ||
        e.target.classList.contains("02")
      )
        setSelectOrgList(selectOrgList.filter((el) => el !== e.target.value));
      else
        setSelectRevokeOrgList(
          selectRevokeOrgList.filter((el) => el !== e.target.value)
        );
    }
  };

  return (
    <li>
      <div className="credit_item">
        <input
          type="checkbox"
          id={org.orgCode}
          className={`mydata_checkbox ${org.transStatusCode}`}
          value={org.orgCode}
          onChange={(e) => {
            onChangeHandleOrg(e);
          }}
        />
        <label htmlFor={org.orgCode} className="credit_label">
          <div className="manage_org_img">
            <img src={org.orgImg} alt={org.orgName} />
          </div>
          <p style={{ flexGrow: 1, color: "#000" }}>{org.orgName}</p>
          {org.transStatusCode === "01" || org.transStatusCode === "02" ? (
            <span style={{ flexShrink: 0, color: "#6e62e9", fontSize: "14px" }}>
              연결됨
            </span>
          ) : null}
          {org.transStatusCode === "03" || org.transStatusCode === "04" ? (
            <span style={{ flexShrink: 0, fontSize: "14px" }}>연결해제됨</span>
          ) : null}
        </label>
      </div>
    </li>
  );

  //   if (org.transStatusCode === "03" || org.transStatusCode === "04") {
  //     return (
  //       <li className="withdraw">
  //         <div
  //           className="manage_item"
  //           style={{ justifyContent: "space-between" }}
  //         >
  //           <div style={{ display: "flex", alignItems: "center" }}>
  //             <div className="manage_org_img">
  //               <img src={sampleImg} alt="" />
  //               {/* <img src={org.orgImg} alt={org.orgName} /> */}
  //             </div>

  //             <p>{org.orgName}</p>
  //           </div>

  //           <div className="withdraw_btn">
  //             <Button
  //               title="데이터 삭제"
  //               className="btn_delete"
  //               onClick={() => {
  //                 setOrgCord(org.orgCode);
  //                 deleteOrgDataMutation.mutate(orgCord, true);
  //               }}
  //             />
  //           </div>
  //         </div>
  //       </li>
  //     );
  //   }
};

export default OrgCreditItem;
