import React, { useEffect, useRef } from "react";
import "components/Popup/popup.css";
import usePopup from "hooks/usePopup";
import SelectItem from "components/Select/SelectItem";

const CustomPopupSelect = ({
  isOpen,
  bottomClose,
  bottomData,
  name,
  title,
  onChange,
  selectItem,
}) => {
  const { isAnimation, animationClass } = usePopup({ isOpen });
  const selectRef = useRef(null);

  const handleCloseSelect = (e) => {
    if (
      isOpen &&
      e.target.classList.contains("popup-dim") &&
      !selectRef.current.contains(e.target)
    ) {
      bottomClose();
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleCloseSelect);
    return () => {
      window.removeEventListener("click", handleCloseSelect);
    };
  });

  return (
    <div className={["popup-layout", isAnimation ? "active" : ""].join(" ")}>
      <div className={["popup-dim", name].join(" ")}></div>
      <div
        className={["popup-wrap", animationClass, "popup-bottom"].join(" ")}
        ref={selectRef}
      >
        <div className="botpos_vtitlow popup-has-close">
          <p className="botpos_vtit sub_title">{title}</p>
        </div>

        <div className="botpos_vcontlow">
          <ul>
            {bottomData.map((item) => (
              <SelectItem
                key={item.id}
                item={item}
                name={name}
                onChange={onChange}
                selectItem={selectItem}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CustomPopupSelect;
