import React, { useEffect, useRef, useState } from "react";
import AgreeView from "../../components/AgreeView";
import { useNavigate } from "react-router-dom";
import { printMsg, confirmMsg } from "utils/utils";
import { commonFetch } from "apis/common/fetchInstance";
import { CustomPopupSelect } from "components/Select";
import mobileList from "assets/data/mobile.json";
import $ from "jquery";

function ResetPage() {
  const navigate = useNavigate();

  // const token_version_id = useRef(); //nice 인증 창 호출에 필요한 데이터(토큰id)
  // const enc_data = useRef(); //nice 인증 창 호출에 필요한 데이터(요청 데이터)
  // const integrity_value = useRef(); //nice 인증 창 호출에 필요한 데이터(암호화)
  const nameInput = useRef();
  const frontInput = useRef();
  const genderInput = useRef();
  const mobileInput = useRef();
  const mobileNoInput = useRef();
  const [genderFlag, setGenderFlag] = useState(false);
  const [mobileFlag, setMobileFlag] = useState(false);
  const [userName, setUserName] = useState(""); //사용자 이름 입력
  const [frontJumin, setFrontJumin] = useState(""); //주민번호 앞자리
  const [backJumin, setBackJumin] = useState(""); //주민번호 뒷자리(1자리)

  const [phoneNumber, setPhoneNumber] = useState(""); //휴대폰 번호
  const [isAgreeOpen, setIsAgreeOpen] = useState(false); //약관동의 리스트 오픈
  const [isBottomOpen, setIsBottomOpen] = useState(false); //통신사 리스트 오픈
  const [identifier, setIdentifier] = useState("");
  const [os, setOs] = useState("");
  const [isExistence, setIsExistence] = useState(false); //회원가입 중복 여부
  const [updateMobileNo, setUpdateMobileNo] = useState("");
  const [updateMobileCa, setUpdateMobileCa] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [nameVali, setNameVali] = useState(false);
  const [nameErrMsg, setNameErrMsg] = useState("");
  const [frontJuminVali, setFrontJuminVali] = useState(false);
  const [juminErrMsg, setJuminErrMsg] = useState("");
  const [backJuminVali, setBackJuminVali] = useState(false);
  const [mobileVali, setMobileVali] = useState(false);
  const [mobileErrMsg, setMobileErrMsg] = useState("");
  const [phoneVali, setPhoneVali] = useState(false);
  const [phoneErrMsg, setPhoneErrMsg] = useState("");
  const [selectMobile, setSelectMobile] = useState({});
  const [agreeList, setAgreeList] = useState([]);

  useEffect(() => {
    nameInput.current.focus();
    $(".na").addClass("active");
  }, []);

  useEffect(() => {
    if (step1) {
      frontInput.current.focus();
    }
  }, [step1]);

  useEffect(() => {
    if (genderFlag) {
      genderInput.current.focus();
    }
  }, [genderFlag]);

  useEffect(() => {
    if (mobileFlag) {
      setStep2(true);
      setIsBottomOpen(true);
      genderInput.current.blur();
    }
  }, [mobileFlag]);

  useEffect(() => {
    if (Object.keys(selectMobile).length !== 0) {
      setMobileVali(true);
      setMobileErrMsg("");
      setTimeout(() => {
        mobileNoInput.current.focus();
      }, 200);
    } else {
      setMobileErrMsg("통신사를 선택해주세요");
      setMobileVali(false);
    }
  }, [selectMobile]);

  useEffect(() => {
    if (phoneNumber.length === 11) {
      mobileNoInput.current.blur();
    }
  }, [phoneNumber]);

  const handleBottomSelect = () => {
    setIsBottomOpen(true);
  };

  const handleAgreeOpen = () => {
    //입력창 유효성 추가
    if (!userName) {
      printMsg("이름을 입력해주세요");
      setIsAgreeOpen(false);
      return false;
    }

    if (!frontJumin || !backJumin) {
      printMsg("주민등록번호를 입력해주세요");
      setIsAgreeOpen(false);
      return false;
    }

    //위에다가 휴대폰 인증이 안된 내용에 대해서 구현이 필요
    setIsAgreeOpen(true);
  };

  //이름 값 설정
  const handleSetName = (e) => {
    const name = e.target.value;
    const regex = /[^가-힣a-zA-Z]/g;

    if (name.length === 0 || regex.test(name)) {
      setNameVali(false);
    } else {
      setNameVali(true);
    }

    setUserName(name.replace(/[0-9.,(\/..*#!`@%^$&-=+/>?\s)]/g, ""));
  };

  //주민번호 앞자리 설정
  const handleSetForntJumin = (e) => {
    let juminText = e.target.value;

    if (juminText > e.target.maxLength) {
      juminText = juminText.slice(0, e.target.maxLength);
    }
    if (juminText.length === e.target.maxLength) {
      setFrontJuminVali(true);
      setGenderFlag(true);
    } else {
      setFrontJuminVali(false);
      setGenderFlag(false);
    }

    setFrontJumin(juminText.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")); //숫자만 입력 정규식
  };

  //주민번호 뒷자리 설정
  const handleSetBackJumin = (e) => {
    let BackJuminText = e.target.value;
    const regex = /[1-4.]/g;

    if (BackJuminText > e.target.maxLength) {
      BackJuminText = BackJuminText.slice(0, e.target.maxLength);
    }

    if (
      BackJuminText.length === e.target.maxLength &&
      regex.test(BackJuminText)
    ) {
      setBackJuminVali(true);
      setMobileFlag(true);
    } else {
      setBackJuminVali(false);
      setMobileFlag(false);
    }

    setBackJumin(
      BackJuminText.replace(/[^1-4.]/g, "").replace(/(\..*)\./g, "$1")
    ); //숫자만 입력 정규식
  };

  const handleSetPhone = (e) => {
    let phoneText = e.target.value;

    if (phoneText > e.target.maxLength) {
      setStep4(true);
      phoneText = phoneText.slice(0, e.target.maxLength);
    }

    if (phoneText.length === e.target.maxLength) {
      setPhoneVali(true);
      setStep4(true);
    } else {
      setPhoneVali(false);
      setStep4(false);
    }

    setPhoneNumber(
      phoneText.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
    ); //숫자만 입력 정규식
  };

  const AgreeClose = () => {
    setIsAgreeOpen(false);
  };

  const bottomClose = () => {
    setIsBottomOpen(false);
  };

  const joinClose = (e) => {
    const msg = [
      "비밀번호 재설정이 완료되지 않았습니다.",
      "시작화면으로 돌아가시겠습니까?",
    ];
    confirmMsg(msg, "아니요", "예", yesClick);
  };

  const yesClick = () => {
    navigate("/splash/start");
  };

  const nextBtn = async (agreeN) => {
    let params = {
      name: userName,
      birthday: frontJumin + backJumin,
      gender: backJumin,
      mobile: selectMobile.value,
      phone: phoneNumber,
      rqst_caus_cd: "00",
      sms_resend_yn: "N",
      type: "JOIN",
      resetPassword: true,
    };

    let props;

    props = params;

    const { response, data } = await commonFetch(
      "/api/auth/verification",
      "post",
      params
    );
    if (response.status === 200) {
      props.tx_seq_no = data.tx_SEQ_NO;
      navigate("/join/cert", { state: { propsData: props } });
    } else {
      setIsAgreeOpen(false);
      printMsg(response.message);
    }
  };

  useEffect(() => {
    agreeApiCall();
  }, []);

  const agreeApiCall = async () => {
    const { response, data } = await commonFetch("/api/auth/terms", "get");
    if (response.status === 200) {
      let emptyArray = [];
      for (let i = 0; i < data.length; i++) {
        const tempData = data[i];
        emptyArray.push({
          id: tempData.no,
          code: tempData.code,
          title: tempData.title,
          content: tempData.content,
          importantYn: tempData.importantYn,
        });
      }
      setAgreeList(emptyArray);
    }
  };

  const actionClick = async () => {
    navigate("/login");
    // window.localStorage.removeItem("acc_token");
    // window.localStorage.removeItem("ref_token");
    // const sendData = {
    //   name: updateName,
    //   identifier: identifier,
    //   mobileNo: updateMobileNo,
    //   mobileCa: updateMobileCa,
    // };
    // const { response, data } = await paramFetch(
    //   "/api/user/existence",
    //   sendData
    // );
    // if (response.status === 200) {
    //   if (data.message.status === "success") {
    //     window.localStorage.setItem("ref_token", data.message.refresh);
    //     window.localStorage.setItem("acc_token", data.message.access);
    //     navigate("/login");
    //   } else {
    //   }
    // }
  };

  const onChangeSelect = (e) => {
    const targetValue = e.target.value;
    const selectValue = mobileList.mobiles.filter(
      (item) => item.value === targetValue
    )[0];
    setSelectMobile(selectValue);
    setIsBottomOpen(false);
    setStep3(true);
  };

  const handleStep1 = () => {
    setStep1(true);
  };

  return (
    <div className="front_body">
      <div className="has_botpos">
        <header className="page_header"></header>
        <section className="page_midsection">
          <div className="form_veritem_w">
            <div className="form_veritem" style={{ marginBottom: "1.8rem" }}>
              <div className="form_verlablow">
                <p className="form_verlabel">이름</p>
              </div>
              <div className="form_fieldlow">
                <div className="field_multi_fxwrap form_field na">
                  <input
                    type="text"
                    className="form_input"
                    placeholder="이름"
                    onChange={handleSetName}
                    value={userName}
                    ref={nameInput}
                    onFocus={() => {
                      $(".na").addClass("active");
                    }}
                    onBlur={() => {
                      $(".na").removeClass("active");
                      if (!nameVali) {
                        setNameErrMsg("정확한 이름을 입력해주세요");
                      } else {
                        setNameErrMsg("");
                      }
                    }}
                  />
                </div>
                {
                  // nameVali
                  <span className="form_vali_msg">{nameErrMsg}</span>
                  // : null
                }
              </div>
            </div>
            {step1 ? (
              <div
                className="form_veritem form_jumin"
                style={{ marginBottom: "1.8rem" }}
              >
                <div className="form_verlablow">
                  <p className="form_verlabel">주민등록번호</p>
                </div>
                <div className="form_fieldlow">
                  <div className="form_inwrap">
                    <div className="form_incell_long form_field ju">
                      <input
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        min="0"
                        className="form_input"
                        ref={frontInput}
                        placeholder="주민등록번호"
                        value={frontJumin}
                        onChange={handleSetForntJumin}
                        maxLength="6"
                        onFocus={() => {
                          $(".ju").addClass("active");
                          // $(".btn_botpos_wrap").addClass("click");
                        }}
                        onBlur={() => {
                          $(".ju").removeClass("active");
                          // $(".btn_botpos_wrap").removeClass("click");
                          if (!frontJuminVali) {
                            setJuminErrMsg(
                              "올바른 주민등록번호를 입력해주세요"
                            );
                          } else {
                            setJuminErrMsg("");
                          }
                        }}
                      />
                    </div>
                    <div className="form_incell_short">
                      <span className="form_inelse">-</span>
                    </div>
                    <div className="form_incell_long etri_text">
                      <div className="form_field ju2">
                        <input
                          type="number"
                          pattern="[0-9]*"
                          inputMode="numeric"
                          min="0"
                          className="form_input ftype2"
                          placeholder="1자리"
                          value={backJumin}
                          maxLength="1"
                          ref={genderInput}
                          onChange={handleSetBackJumin}
                          onFocus={() => {
                            $(".ju2").addClass("active");
                            // $(".btn_botpos_wrap").addClass("click");
                          }}
                          onBlur={() => {
                            $(".ju2").removeClass("active");
                            // $(".btn_botpos_wrap").removeClass("click");
                            if (!backJuminVali) {
                              setJuminErrMsg(
                                "올바른 주민등록번호를 입력해주세요"
                              );
                            } else {
                              setJuminErrMsg("");
                            }
                          }}
                        />
                      </div>
                      <div className="form_etri_text_w">
                        <span className="form_etri_text"></span>
                        <span className="form_etri_text"></span>
                        <span className="form_etri_text"></span>
                        <span className="form_etri_text"></span>
                        <span className="form_etri_text"></span>
                        <span className="form_etri_text"></span>
                      </div>
                    </div>
                  </div>
                  <span className="form_vali_msg">{juminErrMsg}</span>
                </div>
              </div>
            ) : null}
            {step2 ? (
              <div
                className="form_veritem form_mobile"
                style={{ marginBottom: "1.8rem" }}
              >
                <div className="form_verlablow">
                  <p className="form_verlabel">통신사</p>
                </div>
                <div className="form_fieldlow">
                  <div className="form_field">
                    <button
                      type="button"
                      id="btn_tele_select"
                      className="btn_select_call d_select"
                      ref={mobileInput}
                      onClick={handleBottomSelect}
                    >
                      <input
                        type="text"
                        className="form_input"
                        value={selectMobile.name || ""}
                        readOnly
                        placeholder="통신사 선택"
                      />
                    </button>
                  </div>
                </div>
                <span className="form_vali_msg">
                  {!isBottomOpen ? mobileErrMsg : null}
                </span>
              </div>
            ) : null}
            {step3 ? (
              <div
                className="form_veritem form_phone"
                style={{ marginBottom: "1.8rem" }}
              >
                <div className="form_verlablow">
                  <p className="form_verlabel">휴대폰번호</p>
                </div>
                <div className="form_fieldlow">
                  <div className="field_multi_fxwrap form_field ph">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      min="0"
                      ref={mobileNoInput}
                      className="form_input"
                      placeholder="휴대폰번호"
                      maxLength="11"
                      value={phoneNumber}
                      onChange={handleSetPhone}
                      onFocus={() => {
                        $(".ph").addClass("active");
                        // $(".btn_botpos_wrap").addClass("click");
                      }}
                      onBlur={() => {
                        $(".ph").removeClass("active");
                        // $(".btn_botpos_wrap").removeClass("click");
                        if (!phoneVali) {
                          setPhoneErrMsg("올바른 휴대폰 번호를 입력해주세요");
                        } else {
                          setPhoneErrMsg("");
                        }
                      }}
                    />
                  </div>
                  <span className="form_vali_msg">{phoneErrMsg}</span>
                </div>
              </div>
            ) : null}

            {/* <form name="form_chk" id="form_chk">
              <input type="hidden" id="m" name="m" value="service" />
              <input
                type="hidden"
                ref={token_version_id}
                id="token_version_id"
                name="token_version_id"
                value=""
              />
              <input
                type="hidden"
                ref={enc_data}
                id="enc_data"
                name="enc_data"
                value=""
              />
              <input
                type="hidden"
                ref={integrity_value}
                id="integrity_value"
                name="integrity_value"
                value=""
              />
            </form> */}
          </div>
        </section>
      </div>

      <div className="btn_botpos_wrap">
        <div className="botpos_in">
          {!step1 ? (
            <button
              className="btn_quadbox"
              id="btn_agreecall"
              disabled={!nameVali}
              onClick={handleStep1}
            >
              다음
            </button>
          ) : (
            <button
              className="btn_quadbox"
              id="btn_agreecall"
              disabled={
                step1 &&
                nameVali &&
                frontJuminVali &&
                backJuminVali &&
                mobileVali &&
                phoneVali
                  ? false
                  : true
              }
              onClick={handleAgreeOpen}
            >
              다음
            </button>
          )}
        </div>
      </div>

      <CustomPopupSelect
        isOpen={isBottomOpen}
        bottomClose={bottomClose}
        bottomData={mobileList.mobiles}
        name={"bottomRadio"}
        title={"통신사"}
        onChange={(e) => onChangeSelect(e)}
      />

      <AgreeView
        isOpen={isAgreeOpen}
        AgreeClose={AgreeClose}
        agreeList={agreeList}
        nextBtn={nextBtn}
        title={"약관동의"}
        mobileCode={selectMobile.code}
      ></AgreeView>
    </div>
  );
}
export default ResetPage;
