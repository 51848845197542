import { useRouter } from "lib/utils/useRouter";
import { isEmpty } from "utils/utils";

const ChargeItemByDate = (props) => {
  const router = useRouter();
  const chargeByDate = props.chargeByDate;

  return (
    <li>
      <div className="card_history_item">
        <div className="card_history_item_summary">
          <span>{chargeByDate.paidDate}</span>
        </div>
        <ul className="card_history_product_list">
          {chargeByDate?.billAmtList?.map((charge, index) => {
            return <ChargeItem charge={charge} key={index} router={router} />;
          })}
        </ul>
      </div>
    </li>
  );
};

const ChargeItem = (props) => {
  const charge = props.charge;

  return (
    <li>
      <div className="payment_info">
        <div className="payment_info_row">
          <p>{isEmpty(charge.merchantName) ? "?" : charge.merchantName}</p>
          <strong>{charge.paidAmt + "원"}</strong>
        </div>
        <div className="payment_info_row info_desc">
          <span>
            {charge.prodType === "02" && charge.totalInstallCnt === 0
              ? "할부"
              : charge.prodTypeName}
          </span>
        </div>
        <p
          className="link"
          onClick={() => {
            props.router.routeIncludeState("/billing/details", {
              paidDtimeStr: charge.paidDtimeStr,
              transNo: charge.transNo,
            });
          }}
        >
          <span>자세히 보기</span>
        </p>
      </div>
    </li>
  );
};

export default ChargeItemByDate;
