import { useRouter } from "lib/utils/useRouter";
import { Layout } from "layout";
import { Button } from "components/Button";

const LogoutPage = () => {
  const router = useRouter();

  return (
    <Layout>
      <div className="logout_container">
        <div>
          <h2 className="logout_title">정상적으로 로그아웃 되었습니다.</h2>
          <div className="logout_content">
            고객님의 안전한 금융거래를 위해 로그인 후 10분 동안 서비스 이용이
            없어 자동 로그아웃 되었습니다.
            <br></br>
            [확인]을 누르면 다시 로그인 할 수 있습니다.
          </div>
        </div>

        <div className="btn_management_wrap">
          <Button
            type={"button"}
            onClick={() => {
              router.routeTo("/login");
            }}
            title={"확인"}
            className={"btn_management_update"}
          ></Button>
        </div>
      </div>
    </Layout>
  );
};

export default LogoutPage;
