import axiosInstance from "lib/apis/AxiosInstance";
import { useMutation } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";
import { printMsg } from "utils/utils";

const QUERY_KEY = "mydata-join";

const fetchMydataJoin = async () => {
  try {
    return await axiosInstance
      .post("/mydata/register")
      .then((response) => response.data)
      .then((response) => response.data);
  } catch (error) {
    printMsg(error.response.data.message);
  }
};

const useMydataJoin = () => {
  const mutation = useMutation({
    mutationKey: getQueryKey(QUERY_KEY),
    mutationFn: () => fetchMydataJoin(),
  });

  return { mutation };
};

export { useMydataJoin };
