import ApprovedItem from "./ApprovedItem";

const CardHistoryItem = (props) => {
  const cardListByDate = props.cardListByDate;

  return (
    <li>
      <div className="card_history_item">
        <div className="card_history_item_summary">
          <span>{cardListByDate.approvedDate}</span>
        </div>
        <ul className="card_history_product_list">
          {cardListByDate?.cardUseAmtList.map((approvedItem, index) => {
            return (
              <ApprovedItem
                approvedItem={approvedItem}
                key={approvedItem.approvedDate + index}
              />
            );
          })}
        </ul>
      </div>
    </li>
  );
};

export default CardHistoryItem;
