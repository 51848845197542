import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";
import { useRouter } from "lib/utils/useRouter";

const QUERY_KEY = "transferHistory";

const fetchTransferHistory = async (orgId, no, useRoute) => {
  try {
    return await axiosInstance
      .get(`/transHistory/list`)
      .then((response) => response.data)
      .then((response) => response.data);
  } catch (error) {
    if (error.response.status === 405) {
      useRoute.changePath("/mydata/join");
    }
  }
};

const useGetTransferHistory = (orgId, no, page) => {
  const useRoute = useRouter();
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY, page),
    queryFn: async () => await fetchTransferHistory(orgId, no, useRoute),
  });
  return query;
};

export { useGetTransferHistory };
