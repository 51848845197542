import { MyDataHeader } from "layout";
import { useEffect } from "react";

import { useLocate, useRouter } from "lib/utils/useRouter";
import useHandleButton from "hooks/useHandleButton";
// import { Loading, useNaverConsent, useOAuthConsent } from "features/mydata";
import { ConsentOrganizationContainer } from "containers/Mydata";
import { queryClient } from "lib/apis/QueryClient";

const ConsentForOrganization = () => {
  // const stateData = useLocate().getStateData();
  // const orgCodeList = stateData.orgList.map((org) => {
  //   return { orgCode: org.org_code };
  // });
  // const { naverMutation } = useNaverConsent("token", orgCodeList);
  // const { oAuthMutation } = useOAuthConsent("token", orgCodeList);
  const useRoute = useRouter();
  // const cert = stateData.cert;

  // useEffect(() => {
  //   const nativeEventCallbackCert = (event) => {
  //     useRoute.routeIncludeState("/mydata/register/asset", {
  //       data: { group_id: event.detail.data },
  //       cert: cert,
  //       orgList: stateData.orgList,
  //     });
  //   };

  //   window.addEventListener("handleCustomEventCert", nativeEventCallbackCert);

  //   return () => {
  //     window.removeEventListener(
  //       "handleCustomEventCert",
  //       nativeEventCallbackCert
  //     );
  //   };
  // }, []);

  // useEffect(() => {
  //   if (oAuthMutation?.data) {
  //     queryClient.invalidateQueries(["mydata-get-asset"]);
  //     useRoute.routeIncludeState("/mydata/register/asset", {
  //       data: oAuthMutation.data,
  //       cert: cert,
  //       orgList: stateData.orgList,
  //     });
  //   }
  // }, [oAuthMutation.data, oAuthMutation.isSuccess, cert, useRoute]);

  // useEffect(() => {
  //   if (naverMutation?.isSuccess) {
  //     useRoute.routeIncludeState("/mydata/loading", {
  //       naverSchemeUrl: naverMutation?.data?.sign_aos_app_scheme_url,
  //       type: "0",
  //       orgList: stateData.orgList,
  //     });
  //   }
  // }, [naverMutation?.data, naverMutation?.isSuccess, useRoute]);

  // const handleAgreeEvent = () => {
  //   const agree1 = document.querySelector("#agree1");
  //   const agree2 = document.querySelector("#agree2");

  //   if (!agree1.checked) agree1.checked = true;
  //   if (!agree2.checked) agree2.checked = true;

  //   setTimeout(() => {
  //     if (stateData.cert === "NAVER") {
  //       queryClient.invalidateQueries(["mydata-naver-asset"]);
  //       naverMutation.mutate("token", orgCodeList);
  //     }
  //     if (stateData.cert === "CERT") {
  //       const orgCode = JSON.stringify(orgCodeList);
  //       const params = ["1", orgCode];
  //       const token = localStorage.getItem("acc_token");
  //       window.Android.getCert(params, token);
  //       // oAuthMutation.mutate("token", orgCodeList);
  //     }
  //   }, 300);
  // };

  // // const [title, renderButton] = useHandleButton(
  // //   "동의하고 계속하기",
  // //   handleAgreeEvent
  // // );

  // if (oAuthMutation.isPending) {
  //   return <Loading />;
  // }

  return (
    <div className="front_body">
      <div className="page_wrap">
        <MyDataHeader
          onClick={() => useRoute.changePath("/mydata/register/organization")}
        />
        <ConsentOrganizationContainer />
      </div>
    </div>
  );
};

export default ConsentForOrganization;
