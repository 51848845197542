import { useRef } from "react";
import { useEffect, useState } from "react";

const ToastUi = ({ content, isOpen, setIsOpen }) => {
  const [animationClass, setAnimationClass] = useState("");
  const [isAnimation, setIsAnimation] = useState(false);

  const timerRef = useRef(null);
  const testRef = useRef(null);

  useEffect(() => {
    setAnimationClass("toast");
    setIsAnimation(true);
    timerRef.current = setTimeout(() => {
      setAnimationClass("untoast");
      testRef.current = setTimeout(() => {
        setIsAnimation(false);
        setIsOpen(false);
      }, 100);
    }, 2500);
    return () => {
      if (isOpen) {
        clearTimeout(timerRef.current);
        clearTimeout(testRef.current);
      }
    };
  }, [isOpen]);

  return (
    <div
      className={
        isAnimation
          ? `toast_wrap active ${animationClass}`
          : `toast_wrap ${animationClass}`
      }
    >
      <div className="toast_content">{content}</div>
    </div>
  );
};

export default ToastUi;
