import React, { useEffect, useReducer, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  printMsg,
  getCalculationInterest,
  getCalculationPrincipal,
  setMoney,
} from "utils/utils";
import { useNavigate } from "react-router-dom";

function Tc_calcLoan() {
  const [loanPrice, setLoanPrice] = useState(""); //대출금액
  const [loanRate, setLoanRate] = useState(""); //대출금리
  const [rateLen, setRateLen] = useState(); //대출금리 길이 설정
  const [loanTerm, setLoanTerm] = useState(""); //대출기간
  const [repayment, setRepayment] = useState("inte"); //상환방식
  // const [loanHoldPeriod, setLoanHoldPeriod] = useState('') 거치기간
  const [totalInterestFornt, setTotalInterestFornt] = useState(""); //총 이자 앞자리
  const [totalInterestBack, setTotalInterestBack] = useState(""); //총 이자 뒷자리
  const [totalInterestAdd, setTotalInterestAdd] = useState(""); //총 이자가 억단위 넘어가면 사용 할 변수
  const [calList, setCalList] = useState([]);
  const [sw, setSw] = useState(false);
  const [isCalculation, setIsCalculation] = useState(false);
  const scrollRef = useRef();

  let navigate = useNavigate();

  const handleLoanPrice = (e) => {
    const price = e.target.value;

    if (price.toString().length >= 7) {
      setLoanPrice("99999".replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1"));
    } else {
      setLoanPrice(price.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1"));
    }
  };

  const handleLoanRate = (e) => {
    const rate = e.target.value;

    if (rate.toString().includes(".")) {
      const data = rate.toString().split(".");

      if (data[0].toString().length === 1) {
        setRateLen(4);
      } else if (data[0].toString().length === 2) {
        setRateLen(5);
      }
      setLoanRate(rate.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"));
    }

    if (!rate.toString().includes(".") && rate.toString().length >= 3) {
      setLoanRate("99.99".replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"));
    } else {
      setLoanRate(rate.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"));
    }
  };

  const handleLoanTerm = (e) => {
    const term = e.target.value;

    if (term.toString().length >= 3) {
      setLoanTerm("99".replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1"));
    } else {
      setLoanTerm(term.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1"));
    }
  };

  const handleRepayment = (e) => {
    setRepayment(e.target.value);
  };

  // const handleLoanHoldPeriod = (e) => {
  // 	setLoanHoldPeriod(e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
  // }

  const handelCalculation = () => {
    if (loanPrice === "" || loanPrice === undefined) {
      printMsg("대출금액을 입력해주세요.");
    } else if (loanRate === "" || loanRate === undefined) {
      printMsg("연금리를 입력해주세요.");
    } else if (loanTerm === "" || loanTerm === undefined) {
      printMsg("대출기간을 입력해주세요.");
    } else {
      setTotalInterestAdd("");
      setTotalInterestFornt("");
      setTotalInterestBack("");
      const listTag = [];
      let total = 0;
      let price = setMoney(loanPrice);
      let interestRate = Number(loanRate);
      let term = parseInt(loanTerm);
      // let holdPeriod = parseInt(loanHoldPeriod);

      let [den_1, den_2, num_1, result] = getCalculationInterest(
        price,
        interestRate,
        term
      );
      let interest = 0; //이자
      let principal = 0; //납입원금

      if (repayment === "inte") {
        for (let i = 1; i <= term; i++) {
          interest = Math.round(price * den_1); // 이자계산
          principal = result - interest; //납입원금
          price -= principal; // 잔액 계산
          if (i === term) {
            // 오차가 있으므로 마지막 회차는 0원으로0 표시
            price = 0;
          }
          total += interest;

          const innerTag = (
            <tr key={i}>
              <td>
                <span className="data_tb_text">{i}</span>
              </td>
              <td>
                <span className="data_tb_num">
                  {principal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                </span>
              </td>
              <td>
                <span className="data_tb_num">
                  {interest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                </span>
              </td>
              <td>
                <span className="data_tb_num">
                  {result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                </span>
              </td>
              <td>
                <span className="data_tb_num">
                  {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                </span>
              </td>
            </tr>
          );
          listTag.push(innerTag);
        }
        setCalList(listTag);
      } else if (repayment === "prin") {
        let [den_1, den_2, num_1] = getCalculationPrincipal(
          price,
          interestRate,
          term
        );
        let interest = 0; //이자
        let monthPrice = 0; //월 상환금

        for (let i = 1; i <= term; i++) {
          interest = Math.round(price * den_2);
          monthPrice = interest + den_1;
          price -= den_1; // 잔액 계산
          if (i === term) {
            // 오차가 있으므로 마지막 회차는 0원으로 표시
            price = 0;
          }
          total += interest;

          const innerTag = (
            <tr key={i}>
              <td>
                <span className="data_tb_text">{i}</span>
              </td>
              <td>
                <span className="data_tb_num">
                  {den_1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                </span>
              </td>
              <td>
                <span className="data_tb_num">
                  {interest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                </span>
              </td>
              <td>
                <span className="data_tb_num">
                  {monthPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  원
                </span>
              </td>
              <td>
                <span className="data_tb_num">
                  {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                </span>
              </td>
            </tr>
          );
          listTag.push(innerTag);
        }
        setCalList(listTag);
      } else if (repayment === "matu") {
        let monthPrice = 0;

        for (let i = 1; i <= term; i++) {
          interest = Math.round(price * den_1);

          if (i === term) {
            principal = price;
            monthPrice = interest + price;
            price = 0;
          }
          total += interest;

          const innerTag = (
            <tr key={i}>
              <td>
                <span className="data_tb_text">{i}</span>
              </td>
              <td>
                <span className="data_tb_num">
                  {principal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                </span>
              </td>
              <td>
                <span className="data_tb_num">
                  {interest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                </span>
              </td>
              <td>
                <span className="data_tb_num">
                  {monthPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  원
                </span>
              </td>
              <td>
                <span className="data_tb_num">
                  {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                </span>
              </td>
            </tr>
          );
          listTag.push(innerTag);
        }
        setCalList(listTag);
      }

      //총이자 설정

      if (total.toString().length === 2) {
        let totalBack = total.toString();
        setTotalInterestBack(totalBack);
      } else if (total.toString().length === 3) {
        let totalBack = total.toString();
        setTotalInterestBack(totalBack);
      } else if (total.toString().length === 4) {
        let totalBack = total;
        setTotalInterestBack(totalBack);
      } else if (total.toString().length === 5) {
        let totalFornt = total.toString().substring(0, 1);
        let totalBack = total.toString().substring(1, total.toString().length);
        setTotalInterestFornt(totalFornt);
        setTotalInterestBack(totalBack);
      } else if (total.toString().length === 6) {
        let totalFornt = total.toString().substring(0, 2);
        let totalBack = total.toString().substring(2, total.toString().length);
        setTotalInterestFornt(totalFornt);
        setTotalInterestBack(totalBack);
      } else if (total.toString().length === 7) {
        let totalFornt = total.toString().substring(0, 3);
        let totalBack = total.toString().substring(3, total.toString().length);
        setTotalInterestFornt(totalFornt);
        setTotalInterestBack(totalBack);
      } else if (total.toString().length === 8) {
        let totalFornt = total.toString().substring(0, 4);
        let totalBack = total.toString().substring(4, total.toString().length);
        setTotalInterestFornt(totalFornt);
        setTotalInterestBack(totalBack);
      } else if (total.toString().length === 9) {
        let totalAdd = total.toString().substring(0, 1);
        let totalFornt = total.toString().substring(1, 5);
        let totalBack = total.toString().substring(5, total.toString().length);
        setTotalInterestAdd(totalAdd);
        setTotalInterestFornt(totalFornt);
        setTotalInterestBack(totalBack);
      }

      setIsCalculation(true);
      setSw(!sw);
    }
  };

  const scrollToBottom = () => {
    if (isCalculation) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
    // scrollRef.current.scrollTop = test.current.scrollHeight;
  };

  useEffect(() => {
    scrollToBottom();
  }, [sw]);

  useEffect(() => {
    scrollToBottom();
  }, [isCalculation]);

  return (
    <div className="front_body">
      <div className="page_wrap has_botpos">
        <header className="page_header">
          <Link to="#" className="btn_back" onClick={() => navigate(-1)}>
            <span className="hdtext">뒤로가기</span>
          </Link>
        </header>
        <section className="page_midsection">
          <div className="form_copy_calc">
            <p className="sig_calc_form_copy">
              <strong>대출이자 계산기</strong>
            </p>
            <p className="sub_calc_form_copy">
              상환하실 금액을 미리 살펴보세요!
            </p>
          </div>
          <div className="vtform_list">
            <div className="vtform_calc_low">
              <div className="vtform_calc_titlow">
                <p className="vtform_calc_tit">대출금액</p>
              </div>
              <div className="vtform_contlow">
                <div className="hor_calc_formbox">
                  <input
                    type="text"
                    value={loanPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    onChange={handleLoanPrice}
                    className="hor_calc_forminput"
                    placeholder="대출금액 입력"
                  />
                  <span className="hor_calc_formunit">만원</span>
                </div>
              </div>
            </div>
            <div className="vtform_calc_low">
              <div className="vtform_calc_titlow">
                <p className="vtform_calc_tit">연 금리</p>
              </div>
              <div className="vtform_calc_contlow">
                <div className="hor_calc_formbox">
                  <input
                    type="text"
                    maxLength={rateLen}
                    value={loanRate}
                    onChange={handleLoanRate}
                    className="hor_calc_forminput"
                    placeholder="연 금리 입력"
                  />
                  <span className="hor_calc_formunit">%</span>
                </div>
              </div>
            </div>
            <div className="vtform_calc_low">
              <div className="vtform_calc_titlow">
                <p className="vtform_calc_tit">대출기간</p>
              </div>
              <div className="vtform_calc_contlow">
                <div className="hor_calc_formbox">
                  <input
                    type="text"
                    value={loanTerm}
                    onChange={handleLoanTerm}
                    className="hor_calc_forminput"
                    placeholder="대출기간 입력"
                  />
                  <span className="hor_calc_formunit">개월</span>
                </div>
              </div>
            </div>

            <div className="vtform_calc_low">
              <div className="vtform_calc_titlow">
                <p className="vtform_calc_tit">상환방식</p>
              </div>
              <div className="vtform_calc_contlow">
                <div className="cbox_calc_formitem_w">
                  <div className="cbox_calc_formitem">
                    <input
                      type="radio"
                      value="inte"
                      className="cbox_calc_input"
                      name="repayment"
                      id="job_check01"
                      defaultChecked
                      onChange={handleRepayment}
                    />
                    <label htmlFor="job_check01" className="cbox_calc_label">
                      원리금균등
                      <br />
                      상환
                    </label>
                  </div>
                  <div className="cbox_calc_formitem">
                    <input
                      type="radio"
                      value="prin"
                      className="cbox_calc_input"
                      name="repayment"
                      id="job_check02"
                      onChange={handleRepayment}
                    />
                    <label htmlFor="job_check02" className="cbox_calc_label">
                      원금균등
                      <br />
                      상환
                    </label>
                  </div>
                  <div className="cbox_calc_formitem">
                    <input
                      type="radio"
                      value="matu"
                      className="cbox_calc_input"
                      name="repayment"
                      id="job_check03"
                      onChange={handleRepayment}
                    />
                    <label htmlFor="job_check03" className="cbox_calc_label">
                      만기일시
                      <br />
                      상환
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="vtform_calc_low">
							<div className="vtform_calc_titlow">
								<p className="vtform_calc_tit">거치기간</p>
							</div>
							<div className="vtform_calc_contlow">
								<div className="hor_calc_formbox">
									<input type="text" value={loanHoldPeriod} onChange={handleLoanHoldPeriod} className="hor_calc_forminput" placeholder="거치기간 입력" />
									<span className="hor_calc_formunit">개월</span>
								</div>
							</div>
						</div> */}
          </div>
        </section>
        {isCalculation ? (
          <div className="detail_calcul" id="charge_vitem">
            <div className="detail_title_low">
              <h3 className="detail_title">이자계산 결과</h3>
            </div>
            <div className="detail_cont_low">
              <ul className="vdot_guide_vlist"></ul>
            </div>
            <div className="detail_vspecbox">
              <ul className="detail_vhlist type2">
                <li>
                  <p className="detail_vhlab">총 이자</p>
                  <div className="detail_vhvalue">
                    {totalInterestAdd === "" ||
                    totalInterestAdd === undefined ? null : (
                      <div className="detail_vhitem">
                        <span className="detail_vnum">{totalInterestAdd}</span>
                        <span className="detail_vunit">억</span>
                      </div>
                    )}
                    {totalInterestFornt === "" ? null : (
                      <div className="detail_vhitem">
                        <span className="detail_vnum">
                          {totalInterestFornt}
                        </span>
                        <span className="detail_vunit">만</span>
                      </div>
                    )}
                    {totalInterestBack.toString().substring(0, 1) === "0" ? (
                      <div
                        className="detail_vhitem"
                        style={{ marginLeft: "-9px" }}
                      >
                        <span
                          className="detail_vnum"
                          style={{ display: "revert" }}
                        ></span>
                        <span className="detail_vunit">원</span>
                      </div>
                    ) : (
                      <div className="detail_vhitem">
                        <span className="detail_vnum">{totalInterestBack}</span>
                        <span className="detail_vunit">원</span>
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </div>

            <div className="detail_vobj_item">
              <div className="detail_vobj_tit">상환일정</div>
              <div
                className="detail_vobj_cont"
                style={{
                  height: loanTerm > 24 ? "640px" : null,
                  overflowY: loanTerm > 24 ? "auto" : null,
                }}
              >
                <table className="data_tb">
                  <colgroup>
                    <col style={{ width: "18%" }} />
                    <col style={{ width: "30%" }} />
                    <col style={{ width: "22%" }} />
                    <col style={{ width: "30%" }} />
                    <col style={{ width: "31%" }} />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>회차</th>
                      <th>납입원금</th>
                      <th>대출이자</th>
                      <th>납부액</th>
                      <th>잔액</th>
                    </tr>
                  </thead>
                  <tbody>{calList}</tbody>
                </table>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div ref={scrollRef}></div>
      <div className="btn_botpos_wrap">
        <button onClick={handelCalculation} className="btn_bquadbox type2">
          <span className="bquadbox_in">계산하기</span>
        </button>
      </div>
    </div>
  );
}
export default Tc_calcLoan;
