import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {commonFetch} from "../../apis/common/fetchInstance";
import {printMsg} from "../../utils/utils";
import $ from "jquery";


const Tc_phoneCertReset = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [min, setMin] = useState(3);  //타이머 분
    const [sec, setSec] = useState(0); //타이머 초
    const time = useRef(180); //타이머 전체 시간(초)
    const timerId = useRef(null); //setInterval 변수
    const [certKey, setCertKey] = useState(""); //인증번호 저장
    const certInput = useRef(null);
    const [errorMsg, setErrorMsg] = useState("");
    const [certCount, setCertCount] = useState(0);
    const [isReset, setIsReset] = useState(false);
    const [initReset, setInitReset] = useState(true); //재요청 버튼 20초 후 활성화
    const [isTimeOut, setIsTimeOut] = useState(false);
    const [txSeqNo, setTxSeqNo] = useState("") //본인인증 요청 일련번호



    useEffect(()=> {
        setTxSeqNo(location.state.propsData.tx_seq_no);
        setCertCount((prev)=> prev +1);
        certInput.current.focus();
    },[])

    //타이머 동작
    useEffect(()=>{
        if(certCount <= 6){
            if(certCount === 6){
                setIsReset(true);
                setErrorMsg("재요청 횟수를 초과 했습니다.")
            }
            timerId.current = setInterval(()=>{
                setMin(parseInt(time.current / 60));
                setSec(time.current % 60);
                time.current -= 1;
            }, 1000)
        }

        return () => clearInterval(timerId.current);
    },[certCount])

    //타이머 시간 체크
    useEffect(()=>{
        if(time.current <= 159){
            setInitReset(false);
        }

        if(time.current === -1){
            setErrorMsg("인증시간이 만료되었습니다. 재요청 해주세요")
            setIsTimeOut(true);
            clearInterval(timerId.current);
        }
    },[sec])

    //인증번호 6자리 입력 시 동작
    useEffect(()=> {
        if(certKey.length === 6){
            handleSendSms();
        }
    },[certKey])

    //인증번호 전송 함수
    const handleSendSms = async () => {


        if(isTimeOut){
            setErrorMsg("인증시간이 만료되었습니다. 재요청 해주세요");
            return;
        }

        let params = {
            tx_seq_no : txSeqNo,
            phone:location.state.propsData.phone,
            otp_no: certKey,
        }

        const {response,data} = await commonFetch("/api/auth/sms", 'post', params);
        if(response.status === 200){
            let props = {
                name: data.rslt_NAME,
                birthday: data.rslt_BIRTHDAY,
                gender: data.rslt_SEX_CD,
                mobile: location.state.propsData.mobile,
                phone: data.tel_NO,
                ci: data.ci,
                di: data.di,
            }

            navigate('/reset/pwd', { state: { propsData: props } });

        }else{
            if(response.code === "ERROR_OTPNO_NOT_EQUALS"){
                setErrorMsg(response.message)
                setCertKey("")
                return;
            }
            setCertKey("")
            setErrorMsg(response.message + " 재요청 해주세요")
        }

    }

    //재요청 버튼 함수
    const handleReset = async () => {
        if(isReset){
            setErrorMsg("재요청 횟수를 초과 했습니다.")
            return;
        }

        let params = {
            name: location.state.propsData.name,
            birthday: location.state.propsData.birthday,
            gender: location.state.propsData.gender,
            mobile: location.state.propsData.mobile,
            phone: location.state.propsData.phone,
            rqst_caus_cd : "00",
            sms_resend_yn : "N"
        }

        const {response, data} = await commonFetch("/api/auth/verification", 'post', params)
        if(response.status === 200){
            setTxSeqNo(data.tx_SEQ_NO);
            setIsTimeOut(false); // 타임아웃 여부
            setInitReset(true); //재요청 20초 후 활성화
            setCertCount((prev) => prev + 1); // 재요청 횟수 +1
            setErrorMsg(""); //에러메세지 초기화
            time.current = 180; //타이머 시간 초기화
            setMin(parseInt(time.current / 60)); //타이머 시간 초기화
            setSec(time.current % 60); //타이머 시간 초기화
            certInput.current.focus(); //인풋창 포커스
            setCertKey(""); //인증번호 초기화
        }else{
            printMsg(response.message);
        }

    }

    //인증번호 저장 함수
    const handleSetKey = (e) => {
        setCertKey(e.target.value);
    }

    //인증번호 글자수 제한 (6자리)
    const maxLengthCheck = (e) => {
        if (e.target.value.length > e.target.maxLength)
            e.target.value = e.target.value.slice(0, e.target.maxLength);
    }

    return (
        <div className="front_body">
            <div className="has_botpos">
                {/*<div className={isBottomOpen || isAgreeOpen ? "bg_layout" : null} onClick={bottomClose}></div>*/}
                <header className="page_header">
                    <a className="btn_back" ><span className="hdtext">뒤로가기</span></a>
                </header>
                <div className="join_sub_title">
                    인증번호 6자리를 입력해주세요
                </div>
                <section className="page_midsection">
                    <div className="form_veritem_w">
                        <div className="form_veritem" style={{marginBottom:"1.8rem"}}>
                            <div className="form_verlablow">
                                <p className="form_verlabel">인증번호</p>
                            </div>
                            <div className="form_fieldlow">
                                <div className="field_multi_fxwrap form_field cert">
                                    <input type="number"
                                           onInput={maxLengthCheck}
                                           pattern='[0-9]*'
                                           inputMode='numeric'
                                           min="0"
                                           ref={certInput}
                                           className="form_input"
                                           placeholder="인증번호"
                                           maxLength='6'
                                           value={certKey}
                                           onChange={handleSetKey}
                                           onFocus={() => {
                                               $('.cert').addClass('active');
                                               $('.btn_botpos_wrap').addClass('click');
                                           }} onBlur={() => {
                                        $('.cert').removeClass('active');
                                        $('.btn_botpos_wrap').removeClass('click');
                                    }}
                                    />
                                    <div className="cert_timer">
                                        <span>{min}</span>
                                        <span>:</span>
                                        <span>{sec < 10 ? `0${sec}` : sec}</span>
                                    </div>
                                    <button className="btn_sm" onClick={handleReset} disabled={initReset}
                                            style={{border: isReset || initReset ? "0.0625rem solid #c5c5d0" : null,
                                                color: isReset || initReset ? "#c5c5d0" : null}}>재요청</button>
                                </div>
                                {
                                    // nameVali
                                    // <span className="form_vali_msg">{nameErrMsg}</span>
                                    // : null
                                }
                            </div>
                            <span className="form_vali_msg">{errorMsg}</span>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
export default Tc_phoneCertReset;