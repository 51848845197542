import APIInstance from "lib/apis/APIInstance";
import MydataQueryFactory from "./MydataQueryFactory";
import useMyDataQuery from "./mydataQuery";

const fetchConsentContent = async (type) => {
  const { data } = await APIInstance.post(`/mydata/consent/${type}`, [
    { industryName: "card" },
  ]);
  return data.data;
};

const useGetConsent = (type) => {
  const queryKey = MydataQueryFactory.queries.getConsent(type);
  const query = useMyDataQuery(
    queryKey,
    async () => await fetchConsentContent(type),
    (data) => data
  );

  return query;
};

export { useGetConsent };
