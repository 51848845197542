import queryKeys from "constants/queryKeys";
import { generateQueryKey } from "lib/apis/QueryClient";

const ORGANIZATION = queryKeys.ORGANIZATION;
const OrganizationQueryFactory = {
  queries: {
    list: () => generateQueryKey(ORGANIZATION.BASE, ORGANIZATION.LIST),
    detail: (requestParams) =>
      generateQueryKey(ORGANIZATION.BASE, ORGANIZATION.DETAIL, requestParams),
    revoke: (requestParams) =>
      generateQueryKey(ORGANIZATION.BASE, ORGANIZATION.REVOKE, requestParams),
    deleteOfRevokedOrganization: (requestParams) =>
      generateQueryKey(
        ORGANIZATION.BASE,
        ORGANIZATION.DELETE_OF_REVOKED,
        requestParams
      ),
    deleteOfLinkedOrganization: (requestParams) =>
      generateQueryKey(
        ORGANIZATION.BASE,
        ORGANIZATION.DELETE_OF_LINKED,
        requestParams
      ),
  },
  invalidateQueries: (queryClient) => ({
    scope: queryClient.invalidateQueries({ scope: ORGANIZATION.BASE }),
  }),
};

export default OrganizationQueryFactory;
