import React from "react";
import { CustomButton } from "components/Button";

const OrganizationButton = (props) => {
  const selectList = props.selectList;
  const maxSelectNumber = 50;
  return (
    <CustomButton
      title={
        (selectList.length > 0 ? selectList.length + "개 " : "") + "연결하기"
      }
      buttonClass={
        selectList.length > 0 && selectList.length <= maxSelectNumber
          ? "btn_company_bottom"
          : ""
      }
      onClick={props.showPopup}
    />
  );
};

export default OrganizationButton;
