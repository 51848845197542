import React from "react";
import { useNavigate } from 'react-router-dom';

const Tc_noticeHistory01 = () => {

    let navigate = useNavigate();

    return (
        <div className="front_body type2">
            <div className="page_wrap has_botpos">
                <header className="page_header">
                    {/* <a href="#!" className="btn_back"><span className="hdtext">뒤로가기</span></a> */}
                    <button className="btn_back" onClick={() => navigate(-1)}><span className="hdtext">뒤로가기</span></button>
                    <h1 className="page_headtitle">알림내역</h1>
                </header>
                <section className="page_midsection dtype3">
                    <div className="ico_column_w">
                        <div className="ico_obj_w ico_bell">
                            <div className="ico_obj"></div>
                        </div>
                        <div className="ico_copy_w">
                            <p className="ico_copy01">앗! 알림이 꺼져있어요</p>
                            <p className="ico_copy02">티칭캐시의 이벤트, 혜택에 대한 <br />푸시 알림을 받아보세요.</p>
                        </div>
                    </div>
                </section>
            </div>
            <div className="btn_botpos_wrap">
                <div className="botpos_in">
                    <a href="#!" className="btn_quadbox">이벤트 혜택 알림 받기</a>
                </div>
            </div>
        </div>
    );
}
export default Tc_noticeHistory01;
