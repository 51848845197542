import { useRouter } from "lib/utils/useRouter";
import { useCheckJoin } from "features/user";
import useDevice from "lib/utils/useDevice";
import { isEmpty, printMsg } from "utils/utils";
import { useEffect } from "react";

const checkInstall = () => {
  const checkInstallApp = localStorage.getItem("appName");
  return isEmpty(checkInstallApp);
};

function Splash() {
  const useRoute = useRouter();
  const identifier = useDevice();

  useEffect(() => {
    printMsg("점검 중입니다.", "", () => {
      window.Android.appFinish();
    });
  }, []);

  // const { isSuccess, data, isError } = useCheckJoin(identifier);

  // if (checkInstall()) {
  //   setTimeout(() => {
  //     useRoute.routeTo("/start");
  //   }, 150);
  // }

  // if (isSuccess) {
  //   let link = "/join";

  //   if (data.member) {
  //     link = data.reset ? "/reset" : "/login";
  //   }

  //   setTimeout(() => {
  //     useRoute.routeTo(link);
  //   }, 150);
  // }

  return (
    <></>
    // <div className="front_body">
    //   <div className="splash_content"></div>
    // </div>
  );
}
export default Splash;
