import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";

const QUERY_KEY = "billing-details";

const fetchGetBillingDetails = async (param) => {
  return await axiosInstance
    .get(`/mydata/bill/detail`, {
      params: param,
    })
    .then((response) => response.data)
    .then((response) => response.data);
};

const useGetBillingDetails = (param) => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY, param),
    queryFn: async () => await fetchGetBillingDetails(param),
    suspense: true,
  });
  return query;
};

export { useGetBillingDetails };
