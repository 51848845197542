import React from "react";

const CheckboxItem = (props) => {
  const terms = props.terms;

  return (
    <li key={terms.id}>
      <input
        type="checkbox"
        id={terms.value}
        value={terms.value}
        className="mydata_checkbox"
      />
      <label htmlFor={terms.value} className="mydata_checkbox_label">
        {terms.title}
      </label>
    </li>
  );
};

export default CheckboxItem;
