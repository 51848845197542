import { Layout } from "layout";
import { PaymentContainer, PaymentSkeleton } from "containers/billing";
import { Suspense } from "react";

const Billing = () => {
  return (
    <Layout header={{}}>
      <Suspense fallback={<PaymentSkeleton />}>
        <PaymentContainer />
      </Suspense>
    </Layout>
  );
};

export default Billing;
