import { React, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import usePopup from "hooks/usePopup";
import mobileTermsList from "assets/data/mobile.json";
import TermsContentModal from "./Modal/TermsContentModal";

const AgreeView = ({
  isOpen,
  AgreeClose,
  agreeList,
  nextBtn,
  agreeCodeList,
  title,
  mobileCode,
}) => {
  const [chkList, setChkList] = useState([]);
  const [selectChkList, setSelectChkList] = useState([]);
  const [isSelect, setIsSelect] = useState(true);
  const [agreeNo, setAgreeNo] = useState("");
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [isButton, setIsButton] = useState(true);

  const [isImportantN, setIsImportantN] = useState(false); //선택 전체동의 데이터가 있는지 없는지 여부 변수

  const [agreeListY, setAgreeListY] = useState([]);
  const [agreeListN, setAgreeListN] = useState([]);
  const [agreeDetail, setAgreeDetail] = useState({});

  const { isAnimation, animationClass } = usePopup({ isOpen });
  const [isPopup, setIsPopup] = useState(false);

  useEffect(() => {
    const agreeY = [];
    const telecom = [];

    agreeList.map((terms) => {
      agreeY.push(terms);
    });

    mobileTermsList.terms.map((terms) => {
      telecom.push(terms);
    });

    setAgreeListY(agreeY);
    setAgreeListN(telecom);
  }, [isOpen]);

  //전체 선택
  const AgreeAllCheck = (checked) => {
    const checkboxList = document.querySelectorAll("input[type='checkbox']");
    checkboxList.forEach((checkbox) => {
      checkbox.checked = checked;
    });

    setIsButton(checked);
  };

  //개별 선택
  const AgreeCheck = (checked, item, text) => {
    const checkboxNode = document.querySelectorAll(".chkrd_sm");
    const checkedCheckboxNode = document.querySelectorAll(".chkrd_sm:checked");
    const allCheck = document.querySelector("#import_itemchk");
    const checkedValue = checkboxNode.length === checkedCheckboxNode.length;

    if (text === "Y") {
      if (checked) {
        setChkList([...chkList, item]);
      } else {
        setChkList(chkList.filter((el) => el !== item));
      }
    }

    if (text === "N") {
      if (checked) {
        setSelectChkList([...selectChkList, item]);
      } else {
        setSelectChkList(selectChkList.filter((el) => el !== item));
      }
    }

    setIsButton(checkedValue);
    allCheck.checked = checkedValue;
  };

  useEffect(() => {
    const checkboxList = document.querySelectorAll("input[type='checkbox']");
    checkboxList.forEach((checkbox) => {
      checkbox.checked = true;
    });
  }, [agreeList]);

  const agreeClose = () => {
    setChkList([]);
    setSelectChkList([]);
    setAgreeNo("");
    AgreeClose();
  };

  const handleSetAgreeDetail = (item) => {
    setAgreeDetail(item);
    setIsDetailOpen(true);
  };

  //   const agreeDetailClose = () => {
  //     setIsDetailOpen(false);
  //   };

  // const showTerms = (url) => {
  //   window.open(url, "_blank", "noopenr, noreferrer");
  // };

  return (
    <>
      <div className={["popup-layout", isAnimation ? "active" : ""].join(" ")}>
        <div className="popup-dim"></div>
        <div
          className={["popup-wrap", animationClass, "popup-bottom"].join(" ")}
        >
          <div className="botpos_vtitlow popup-has-close">
            <p className="botpos_vtit sub_title">{title}</p>
            <span className="popup-close" onClick={agreeClose}></span>
          </div>

          <div className="botpos_vcontlow">
            <div className="agree_chk_zone" id="agree_chk_zone">
              <div className="agree_chk_low">
                <p className="agree_chkcopy">
                  선택목적에 대한 동의를 거부해도 서비스 이용이 가능합니다.
                  <br />
                  다만, 서비스 제공을 위한 필수 사항 미동의시 해당 서비스 이용이
                  불가능합니다.
                </p>
              </div>
              <div className="agree_chk_low">
                <div className="agree_sigchk_low">
                  <div className="chkrd_item">
                    <input
                      type="checkbox"
                      className="chkrd"
                      id="import_itemchk"
                      onChange={(e) => AgreeAllCheck(e.target.checked, "Y")}
                    />
                    <label htmlFor="import_itemchk" className="chkrd_label">
                      모두 동의
                    </label>
                  </div>
                </div>
                <div className="agree_subchk_low_zone" id="agree01_wrap">
                  {agreeList.map((item, index) => (
                    <div className="agree_subchk_low" key={index}>
                      <div className="chkrd_sm_item">
                        <input
                          type="checkbox"
                          className="chkrd_sm"
                          id={item.code}
                          name={item.code}
                          onChange={(e) =>
                            AgreeCheck(e.target.checked, item.code, "Y")
                          }
                        />
                        <label
                          htmlFor={item.code}
                          className="chkrd_sm_label mobile_label"
                        >
                          {item.importantYn === "Y"
                            ? item.title + " (필수)"
                            : item.title + " (선택)"}
                        </label>
                      </div>
                      <p
                        onClick={() => handleSetAgreeDetail(item)}
                        className="btn_allow_ico"
                      >
                        <span className="hdtext">내용보기</span>
                      </p>
                    </div>
                  ))}{" "}
                  <div className="agree_subchk_low">
                    <div className="chkrd_sm_item">
                      <input
                        type="checkbox"
                        className="chkrd_sm"
                        id={"telecom_agree"}
                        name={""}
                        onChange={(e) =>
                          AgreeCheck(e.target.checked, "telecom_agree", "Y")
                        }
                      />
                      <label
                        htmlFor={"telecom_agree"}
                        className="chkrd_sm_label mobile_label"
                      >
                        본인 확인 서비스 약관 및 동의사항 (필수)
                      </label>
                    </div>
                  </div>
                  <div style={{ marginLeft: "14px" }}>
                    {agreeListN.map((item, index) => (
                      <div className="agree_subchk_low" key={index}>
                        <div className="chkrd_sm_item">
                          <p
                            htmlFor={item.code}
                            className="chkrd_sm_label mobile_label"
                          >
                            {item.title}
                          </p>
                        </div>
                        <p
                          onClick={() => handleSetAgreeDetail(item)}
                          className="btn_allow_ico"
                        >
                          <span className="hdtext">내용보기</span>
                        </p>
                      </div>
                    ))}{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="btn_botpos_inwrap">
              <button
                type="button"
                className="btn_quadbox"
                onClick={() => nextBtn(selectChkList)}
                disabled={!isButton}
                id="btn_agree_finish"
              >
                동의하기
              </button>
            </div>
          </div>
        </div>
      </div>
      {isDetailOpen && (
        <TermsContentModal
          isOpen={isDetailOpen}
          onClose={() => {
            setIsDetailOpen(false);
          }}
          terms={agreeDetail}
        />
      )}
    </>
  );
};

export default AgreeView;
