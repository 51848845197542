import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey, queryClient } from "lib/apis/QueryClient";

const QUERY_KEY = "mydata-asset";

const fetchGetAssetExecution = async () => {
  return await axiosInstance
    .get("/mydata/asset/execution")
    .then((response) => response.data)
    .then((response) => response.data);
};

const useGetAssetExecution = (isExecution, suspense) => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY),
    queryFn: async () => await fetchGetAssetExecution(),
    enabled: isExecution,
    refetchOnReconnect: false,
    // refetchOnWindowFocus: true,
    staleTime: 600 * 1000,
    suspense: suspense !== undefined ? suspense : false,
  });

  return query;
};

export { useGetAssetExecution };
