import React, { isValidElement, useRef, useEffect, useState } from "react";
import usePopup from "hooks/usePopup";
import Button from "components/Button/Button";
import "./popup.css";

const PopupDimmed = () => {
  return <div className="popup-dim"></div>;
};

const PopupTitle = ({ closeEvent, children }) => {
  return (
    <div className="botpos_vtitlow popup-has-close">
      <p className="botpos_vtit sub_title">{children}</p>
      {closeEvent && <span className="popup-close" onClick={closeEvent}></span>}
    </div>
  );
};

const PopupDescription = ({ children }) => {
  return <div className="popup_contents">{children}</div>;
};

const PopupButton = ({ isCloseButton, isSubmitButton, onClick, children }) => {
  const btnClass = isCloseButton
    ? "btn_close"
    : isSubmitButton
    ? "btn_submit"
    : "";

  return (
    <Button className={btnClass} title={children} onClick={onClick}></Button>
  );
};

const PopupButtonWrap = ({ children }) => {
  const popupButtons = getPopupButtons(children);
  return <div className="popup_btn_wrap">{popupButtons}</div>;
};

const getPopupButtons = (children) => {
  const PopupButtonType = (<PopupButton />).type;
  const array = React.Children.toArray(children);
  return array.filter(
    (child) => isValidElement(child) && child.type === PopupButtonType
  );
};

const PopupMain = (props) => {
  const isOpen = props.isOpen;
  const onClose = props.onClose;

  // const { isAnimation, animationClass } = usePopup({ isOpen, onClose });
  const [fadeOut, setFadeOut] = useState(false);
  const selectRef = useRef(null);
  const popupPosition = props.popupMiddle ? "popup-middle" : "popup-bottom";

  useEffect(() => {
    const body = document.body;

    if (isOpen) {
      body.classList.add("body_hidden");
      setFadeOut(true);
    } else {
      setTimeout(() => {
        setFadeOut(false);
        body.classList.remove("body_hidden");
      }, 300);
    }
  }, [isOpen]);

  const handleCloseSelect = (e) => {
    if (
      isOpen &&
      e.target.classList.contains("popup-dim") &&
      !selectRef.current.contains(e.target)
    ) {
      onClose?.();
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleCloseSelect);
    return () => {
      window.removeEventListener("click", handleCloseSelect);
    };
  });

  if (!isOpen) {
    return null;
  }

  return (
    <div className={["popup-layout", fadeOut ? "active" : ""].join(" ")}>
      <PopupDimmed />
      <div
        className={[
          "popup-wrap",
          isOpen ? "mount" : "unmount",
          popupPosition,
        ].join(" ")}
        ref={selectRef}
      >
        {props.children}
      </div>
    </div>
  );
};

export const Popup = Object.assign(PopupMain, {
  Title: PopupTitle,
  Contents: PopupDescription,
  ButtonWrap: PopupButtonWrap,
  Button: PopupButton,
});
