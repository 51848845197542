import MydataQueryFactory from "./MydataQueryFactory";
import useMyDataQuery from "./mydataQuery";
import APIInstance from "lib/apis/APIInstance";

const fetchNaverConsentStatus = async (cert, type) => {
  const { data } = await APIInstance.get(`/mydata/${cert}/status/${type}`);
  return data.data;
};

const useGetNaverConsentStatus = (cert, type) => {
  const queryKey = MydataQueryFactory.queries.checkConsentResult(type);
  const query = useMyDataQuery(
    queryKey,
    async () => await fetchNaverConsentStatus(cert, type),
    (data) => data,
    false
  );

  return query;
};

export { useGetNaverConsentStatus };
