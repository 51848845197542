import { Suspense } from "react";
import {
  InitPaymentHistoryContainer,
  PaymentHistorySkeleton,
} from "containers/payment";
import "assets/css/payment.scss";
import { Footer, Layout } from "layout";
import { useRouter } from "lib/utils/useRouter";

const PaymentHistory = () => {
  const useRoute = useRouter();
  return (
    <Layout
      header={{
        onClick: () => {
          useRoute.routeTo("/home");
        },
      }}
    >
      <Suspense fallback={<PaymentHistorySkeleton />}>
        <InitPaymentHistoryContainer />
      </Suspense>
      <Footer />
    </Layout>
  );
};

export default PaymentHistory;
