import { MyDataHeader } from "layout";
import { useLocate, useRouter } from "lib/utils/useRouter";
import { RegisterAssetContainer, RegisterAssetInfo } from "containers/Mydata";
import { Suspense } from "react";

const RegisterAsset = () => {
  const stateData = useLocate().getStateData();
  const useRoute = useRouter();

  return (
    <div className="front_body">
      <div className="page_wrap">
        <MyDataHeader
          onClick={() => {
            let url = "/mydata/consent/organization";
            if (stateData.type === "update") {
              url = `/management/${stateData.orgCode}`;
            }

            useRoute.changePath(url, {
              orgList: stateData.orgList,
              cert: stateData.cert,
            });
          }}
        />

        <Suspense fallback={<Loading />}>
          <RegisterAssetContainer
            param={{
              orgList: stateData?.orgCode,
              groupId: stateData?.data?.group_id,
            }}
            cert={stateData.cert}
          />
        </Suspense>
      </div>
    </div>
  );
};

export default RegisterAsset;

const Loading = () => {
  return (
    <div className="front_body">
      <div className="loading_content">
        <p>자산을 불러오고 있습니다.</p>
      </div>
    </div>
  );
};
