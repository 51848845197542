import React from "react";

const NoDataBoard = ({ message }) => {
  return (
    <div className="board_no_data">
      <h3>{message}</h3>
    </div>
  );
};

export default NoDataBoard;
