import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";

const QUERY_KEY = "card-main";

const fetchGetCardInMain = async () => {
  return await axiosInstance
    .get("/mydata/card/main")
    .then((response) => response.data)
    .then((response) => response.data);
};

const useGetCardInMain = () => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY),
    queryFn: async () => await fetchGetCardInMain(),
  });
  return query;
};

export { useGetCardInMain };
