import React, { useState, useEffect } from "react";
import Footer from "layout/Footer";
import { useNavigate, Link } from "react-router-dom";
import { confirmMsg, printMsg, successMsg, actionMsg } from "utils/utils";
import { confirmAlert } from "react-confirm-alert";
import BottomToggle from "../../components/BottomToggle";
import { commonFetch } from "apis/common/fetchInstance";
import { CSSTransition } from "react-transition-group";
import { useRouter } from "lib/utils/useRouter";

const MenuPage = () => {
  let navigate = useNavigate();
  const useRoute = useRouter();

  const [user, setUser] = useState([]);
  const [alarmOpen, setAlarmOpen] = useState(false);
  const [smsToggle, setSmsToggle] = useState(false);
  const [talkToggle, setTalkToggle] = useState(false);

  useEffect(() => {
    setUser(sessionStorage.getItem("user"));
  }, []);

  const gotoChangePW = () => {
    setTimeout(() => {
      navigate("/update/pwdChange");
    });
  };

  const gotoQuestions = () => {
    setTimeout(() => {
      navigate("/QandA");
    });
  };

  const gotoNotice = () => {
    setTimeout(() => {
      navigate("/notice");
    });
  };

  const gotoCalc = () => {
    setTimeout(() => {
      navigate("/calc");
    });
  };
  const gotoReview = () => {
    setTimeout(() => {
      navigate("/review");
    });
  };

  const gotoEvent = () => {
    navigate("/event");
  };

  const gotoMypage = () => {
    navigate("/mypage");
  };

  const gotoMyloan = () => {
    navigate("/myloan");
  };

  const gotoUserSecession = () => {
    navigate("/memberSecession");
  };

  const gotoInquiry = () => {
    navigate("/inquiry");
  };

  const gotoAgree = () => {
    navigate("/agree");
  };

  const handleCreditDel = () => {
    actionMsg(
      "연결했던 기관의 데이터를 삭제하려면 회원 탈퇴가 필요합니다. 탈퇴 시 모든 전송요구는 철회되며, 개인신용정보 또한 모두 삭제됩니다.",
      "취소",
      "탈퇴하기",
      actionClick
    );
  };

  const actionClick = () => {
    navigate("/memberSecession");
  };

  const gotoTransHistory = () => {
    navigate("/transferHistory");
  };

  return (
    <div className="front_body">
      <div className="page_wrap_menu">
        <div className="pagetop_sigzone">
          <div className="prof_fxwrap">
            <div className="prof_thum"></div>
            <div className="prof_text_w">
              <p className="prof_text_sub">안녕하세요!</p>
              <p className="prof_text_sub">
                <strong>{user}</strong>님
              </p>
            </div>
          </div>
          <div className="btn_ptopelse_w">
            <div className="btn_ptopelse_fxwrap">
              <a className="btn_ptopelse" onClick={gotoMypage}>
                내정보
              </a>
            </div>
          </div>
        </div>

        <div className="page_totalmenu_z">
          <div className="page_total_item">
            <div className="page_cate_low">
              <p className="page_cate">사용자 설정</p>
            </div>
            <div className="page_menu_low">
              <ul className="ptotal_menu_vlist">
                <li>
                  <a className="ptotal_menu" onClick={gotoChangePW}>
                    비밀번호 변경
                  </a>
                </li>
                <li>
                  <a
                    className="ptotal_menu"
                    onClick={() => {
                      window.Android.getCertManagement();
                    }}
                  >
                    공동인증서 관리
                  </a>
                </li>

                {/* <li>
                  <a className="ptotal_menu" onClick={gotoChangePW}>
                    비밀번호 변경
                  </a>
                </li>
                <li>
                  <a className="ptotal_menu" onClick={gotoChangePW}>
                    비밀번호 변경
                  </a>
                </li> */}

                {/* <li style={{ marginBottom: "1.5rem" }}>
                  <a className="ptotal_menu" onClick={gotoUserSecession}>
                    회원탈퇴
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          {/* <div className="page_total_item">
            <div className="page_cate_low">
              <p className="page_cate">대출</p>
            </div>
            <div className="page_menu_low">
              <ul className="ptotal_menu_vlist">
                <li>
                  <a className="ptotal_menu" onClick={gotoMyloan}>
                    마이대출
                  </a>
                </li>
                <li>
                  <a className="ptotal_menu" onClick={gotoCalc}>
                    이자 계산기
                  </a>
                </li>
                <li>
                  <a className="ptotal_menu" onClick={gotoReview}>
                    대출후기
                  </a>
                </li>
              </ul>
            </div>
          </div> */}

          <div className="page_total_item">
            <div className="page_cate_low">
              <p className="page_cate">자산 설정</p>
            </div>
            <div className="page_menu_low">
              <ul className="ptotal_menu_vlist">
                <li>
                  <Link to="/management" className="ptotal_menu">
                    자산 연결 관리
                  </Link>
                </li>

                <li>
                  <Link to="/transferHistory" className="ptotal_menu">
                    전송내역 조회
                  </Link>
                </li>

                <li>
                  <Link to="/credit" className="ptotal_menu">
                    신용정보 삭제
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="page_total_item">
            <div className="page_cate_low">
              <p className="page_cate">이용안내</p>
            </div>
            <div className="page_menu_low">
              <ul className="ptotal_menu_vlist">
                <li>
                  <a className="ptotal_menu" onClick={gotoNotice}>
                    공지사항
                  </a>
                </li>
                <li>
                  <Link to="/terms" className="ptotal_menu">
                    이용약관
                  </Link>
                </li>
                {/* <li>
                  <a className="ptotal_menu" onClick={gotoQuestions}>
                    자주 묻는 질문
                  </a>
                </li>
                <li>
                  <a className="ptotal_menu" onClick={gotoInquiry}>
                    1대1 문의
                  </a>
                </li>
                <li>
                  <a className="ptotal_menu" onClick={gotoEvent}>
                    이벤트
                  </a>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="page_total_item">
            <div className="page_menu_low">
              <ul className="ptotal_menu_vlist">
                <li style={{ marginBottom: "1.5rem" }}>
                  <a className="ptotal_menu" onClick={gotoUserSecession}>
                    회원탈퇴
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="btn_botpos_wrap">
        <Footer />
      </div>
    </div>
  );
};

export default MenuPage;
