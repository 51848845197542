import { useRouter } from "lib/utils/useRouter";

const PaymentItem = (props) => {
  const paymentDto = props.paymentDto;
  const useRoute = useRouter();

  const handleClick = (billing) => {
    useRoute.routeIncludeState(`/billing/${billing.orgCode}`, {
      basDate: billing.basDate,
      orgCode: billing.orgCode,
    });
  };

  return (
    <div className="billing_summary_box">
      <div className="billing_summary_date">
        <span>{paymentDto.payDueDateFormat}</span>
        <p>{paymentDto.payAmtSumFormat + "원"}</p>
      </div>
      <ul className="billing_summary_list">
        {paymentDto?.paymentList?.map((billing, index) => {
          return (
            <BillingItem
              billing={billing}
              key={index}
              onClick={() => {
                handleClick(billing);
              }}
            />
          );
        })}
      </ul>
    </div>
  );
};

const BillingItem = (props) => {
  const billing = props.billing;
  return (
    <li>
      <div className="billing_product has_link">
        <div className="billing_product_info">
          <strong>{billing.orgName}</strong>
        </div>
        <div className="billing_product_price ">
          <p>{billing.payAmtFormat + "원"}</p>
        </div>
        <p onClick={props.onClick} className="link">
          <span>자세히 보기</span>
        </p>
      </div>
    </li>
  );
};

export default PaymentItem;
