import { useState, useEffect } from "react";
import { MyDataHeader } from "layout";
import {
  OrganizationContent,
  OrganizationButton,
  CertificatePopup,
} from "containers/Mydata";
import { isEmpty } from "utils/utils";
import { useRouter } from "lib/utils/useRouter";
import ToastUi from "components/ToastUi";

const RegisterOrganization = () => {
  const [selectList, setSelectList] = useState([]); //선택된 금융사
  const [isPopup, setIsPopup] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [selectCert, setSelectCert] = useState("");
  const [isToast, setIsToast] = useState(false);
  const useRoute = useRouter();
  const maxSelectNumber = 50;

  const showPopup = (event) => {
    if (selectList.length > 0 && selectList.length <= maxSelectNumber) {
      setIsPopup(true);
    } else {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    if (selectList.length > maxSelectNumber) {
      setIsToast(true);
    }
  }, [selectList]);

  useEffect(() => {
    if (isAgree && !isEmpty(selectCert)) {
      useRoute.routeIncludeState("/mydata/consent/organization", {
        cert: selectCert,
        orgList: selectList,
      });
    }
  }, [isPopup]);

  const handleAgree = () => {
    useRoute.routeIncludeState("/mydata/consent/organization", {
      cert: selectCert,
      orgList: selectList,
    });
  };

  return (
    <div className="front_body">
      <div className="page_wrap">
        <MyDataHeader title="카드사 선택"></MyDataHeader>

        <section className="page_midsection_mydata">
          <div className="org_header">
            <h2>연동할 카드사를 선택해주세요.</h2>
          </div>

          <OrganizationContent
            selectList={selectList}
            setSelectList={setSelectList}
          />

          <OrganizationButton selectList={selectList} showPopup={showPopup} />
        </section>

        {isPopup ? (
          <CertificatePopup
            isOpen={isPopup}
            bottomClose={() => {
              setIsPopup(false);
            }}
            selectCert={selectCert}
            setSelectCert={setSelectCert}
            setIsAgree={setIsAgree}
            handleAgree={handleAgree}
          />
        ) : null}

        {isToast ? (
          <ToastUi
            content={"선택은 최대 50개까지 선택 가능합니다."}
            isOpen={isToast}
            setIsOpen={setIsToast}
          />
        ) : null}
      </div>
    </div>
  );
};

export default RegisterOrganization;
