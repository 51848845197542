import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import IsLoding from "components/IsLoading";
import { commonFetch } from "apis/common/fetchInstance";
import { setDateFormat } from "utils/utils";
import { Layout } from "layout";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { Button } from "components/Button";

const NoticePage = () => {
  const [noticeList, setNoticeList] = useState([]);
  const [loading, setLoading] = useState(true);

  const defaultRows = 10;
  const [pageing, setPageging] = useState(false);
  const lastRef = useRef(null);

  // const { page, isFetching } = useInfiniteScroll(pageing, lastRef);
  // const { page, isFetching } = useInfiniteScroll(pageing, lastRef);
  const [page, setPage] = useState(1);

  const fetchNoticeList = async (page) => {
    setLoading(true);

    const { response, data } = await commonFetch(
      `/api/notice?page=${page}&rows=${defaultRows}`,
      "get"
    );

    if (response.status === 200) {
      setNoticeList(noticeList.concat(data));
      // setNoticeList([...noticeList, data]);
      // setPageging(isFetching);
    }

    console.log(noticeList);
    setLoading(false);
  };

  useMemo(() => {
    fetchNoticeList(page);
  }, [page]);

  return (
    <Layout header={{ title: "공지사항" }}>
      <div className="notice_wrap">
        <NoticeBody>
          {noticeList.map((notice, index) => (
            <NoticItem notice={notice} key={notice.no} index={index} />
          ))}
          {loading ? <IsLoding /> : null}
        </NoticeBody>
        <Button
          title="더 보기"
          className="btn_more"
          onClick={() => {
            setPage((prev) => prev + 1);
          }}
        ></Button>
      </div>
    </Layout>
  );
};

const NoticeBody = ({ children }) => {
  return <ul className="annc_vlist_notice">{children}</ul>;
};

const NoticItem = ({ notice }) => {
  return (
    <li>
      <Link to={{ pathname: `/notice/${notice.no}` }}>
        <div className="annc_vitem_notice">
          <div className="annc_spec_w_notice">
            <p className="notice_title_text">{notice.noticeTitle}</p>
            <p className="annc_spec02">{setDateFormat(notice.createdDate)}</p>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default NoticePage;
