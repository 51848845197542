import React from 'react';
import { useEffect } from 'react';

const Coin_div = ({coin}) => {
    const coinURL = "/coin_img/"+coin.coin_id+".png";
    return(
        <>
            <tr>
                <td>
                    <img src= {coinURL}  alt={coin.name} title={coin.name} />
                </td>
                <td>{coin.name}</td>
                <td id={coin && coin.coin_color === 'blue' ? 'down':'up'}>{coin && coin.closing_price}</td>
                <td id={coin && coin.coin_color === 'blue' ? 'down':'up'}>{coin && coin.percent}%</td>
            </tr>
        </>
    )
}

const Get_coinAPI = ({get_coinList}) => {
    
    useEffect(()=>{
        get_coinList.sort(function (a,b){
            return a.coin_id < b.coin_id ? -1 : a.coin_id > b.coin_id ? 1 : 0;
        });    
    })

    //중복 제거
    let get_coinList_dup = get_coinList.filter((item, index, array) => array.findIndex(compared => (compared.name === item.name && compared.value === item.value)) === index);

    return (
        <div>
            <table className='coin_table'>
                <thead>
                    <th></th>
                    <th>코인</th>
                    <th>일별코인</th>
                    <th>등락률</th>
                </thead>
                <tbody>
                    {get_coinList_dup&&get_coinList_dup.map((coin,index)=>(
                        // eslint-disable-next-line react/jsx-pascal-case
                        <Coin_div coin = {coin} key={index}/>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Get_coinAPI;