import React from "react";

const Consent2 = (orgList) => {
  return (
    <div className="mydata_terms_contents">
      <div className="consent_wrap has_bottom_line">
        <h3>개인신용정보 제공 동의서</h3>
        <p className="terms_title">거부할 권리 및 불이익</p>
        개인신용정보 수집이용에 관한 동의를 거부하실 수 있습니다. 하지만, 서비스
        이용을 위해 동의가 필요합니다. 동의하지 않으면 본인신용정보 통합조회,
        데이터분석 서비스의 이용이 제한될 수 있습니다.
        <p className="terms_title">정보를 받는 곳</p>
        <p className="terms_em">
          {orgList?.orgList?.map((org) => org.org_nm).join(", ")}
        </p>
        <p className="terms_title">목적</p>
        <p className="terms_em">본인 확인 및 개인신용정보의 전송</p>
        <p className="terms_title">보유 · 이용 기간</p>
        <p className="terms_em">
          본인확인 및 개인신용정보의 전송 목적 달성시까지
        </p>
        <p className="terms_title">제공</p>
        <p className="terms_subtitle">개인식별정보</p>
        <ul className="terms_detail_list">
          <li>전자서명, CI, 인증서, 전송요구서</li>
        </ul>
      </div>

      <div className="consent_wrap">
        <p className="terms_title">정보를 받는 곳</p>
        <p className="terms_em">한국신용정보원</p>
        <p className="terms_title">목적</p>
        <p className="terms_em">
          마이데이터서비스 가입현황 안내, 전송요구내역 통합조회 서비스 제공
        </p>
        <p className="terms_title">보유 · 이용기간</p>
        <p className="terms_em">
          한국신용정보원의 마이데이터서비스 가입현황 안내 및 전송 요구내역
          통합조회 서비스 목적 달성시 까지
        </p>
        <p className="terms_title">제공</p>
        <p className="terms_subtitle">전송요구 내역정보</p>
        <ul className="terms_detail_list">
          <li>
            회원 가입여부, 서비스목록수, 서비스목록, 클라이언트ID,
            전송요구내역수, 전송요구내역목록, 정보제공자기관코드, 권한범위,
            전송요구일자, 전송요구종료시점
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Consent2;
