import { Layout } from "layout";
import {
  BillingHistoryContainer,
  BillingHistorySkeleton,
} from "containers/billing";

import { Suspense, useState } from "react";

const BillingHistory = () => {
  const [pageTitle, setPageTitle] = useState("");

  return (
    <Layout
      header={{
        title: `${pageTitle + " 청구서"}`,
      }}
    >
      <Suspense fallback={<BillingHistorySkeleton />}>
        <BillingHistoryContainer setPageTitle={setPageTitle} />
      </Suspense>
    </Layout>
  );
};

export default BillingHistory;
