import Charts from "react-apexcharts";
import { useGetPieStatistics } from "features/statistics";
import { numComma } from "utils/utils";

const CategoryStatistics = (props) => {
  const categoryStatisticsQuery = useGetPieStatistics(props.searchDate);
  const categoryStatistics = categoryStatisticsQuery?.data;

  if (categoryStatisticsQuery?.isLoading) {
    return <></>;
  }

  return (
    <section className="stats_wrap">
      <div className="stats_category_article">
        <h3>카드별 지출</h3>
        <span>
          {"이 달에 가장 많이 사용한 카드는 " +
            categoryStatistics?.manyUseCard +
            "입니다."}
        </span>
        <div className="stats_category_graph">
          <Charts
            type="bar"
            series={[
              {
                name: "카드 이름",
                data: categoryStatistics?.cards?.map((card) => {
                  return card?.approvedAmtSum;
                }),
              },
            ]}
            options={{
              noData: {
                text: "사용한 카드 지출이 없습니다.",
              },
              chart: {
                toolbar: {
                  show: false,
                },
              },
              plotOptions: {
                bar: {
                  dataLabels: {
                    position: "top",
                  },

                  columnWidth: "15%",
                },
              },
              fill: {
                colors: ["#6e62e9"],
                opacity: "0.8",
              },
              dataLabels: {
                enabled: true,
                formatter: function (val) {
                  return numComma(val) + "원";
                },
                offsetY: -30,
                style: {
                  fontSize: "16px",
                  colors: ["#333"],
                  fontWeight: "400",
                },
              },
              xaxis: {
                axisBorder: {
                  show: true,
                },
                categories: categoryStatistics?.cards?.map((card) => {
                  return card?.cardName;
                }),
              },
              grid: {
                show: false,
              },
              yaxis: {
                show: false,
                axisBorder: {
                  show: false,
                },
              },
              tooltip: {
                enabled: false,
              },
            }}
          />
        </div>{" "}
        <div className="stats_category_summary">
          <ul className="stats_category_list">
            {categoryStatistics?.cards.map((card, index) => {
              return (
                <li key={index}>
                  <div className="stats_category_item">
                    <div className="payment_thumbnail">
                      <img src={card.orgImg} alt="" />
                    </div>
                    <div className="payment_info">
                      <p>{card.cardName}</p>
                    </div>
                    <div className="category_price">
                      <p>{card.approvedAmtSumFormat + "원"}</p>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default CategoryStatistics;
