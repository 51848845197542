import { useRouter } from "lib/utils/useRouter";
import sampleCardImg from "assets/images/bankq/kbank-card.png";

const CardInfo = (props) => {
  const useRoute = useRouter();
  const cardHistoryQuery = props.cardHistoryQuery;
  const cardHistory = cardHistoryQuery?.data;

  return (
    <section className="card_wrap">
      <div className="card_article">
        <div className="card_summary_box">
          <p
            className="search_box"
            onClick={() => {
              props.setIsSearchPopup(true);
            }}
          >
            {cardHistory?.basYear + "년 " + cardHistory?.basMon + "월 사용금액"}
          </p>
          <h2>{cardHistory?.totalUseAmt}원</h2>
        </div>
        <div className="card_info_box">
          <div className="card_img">
            {/* <img
              src="https://kr.object.fin-ncloudstorage.com/bucket-loca/card/logo_card_dummy.png"
              alt=""
            /> */}
            <img src={cardHistory?.cardImg} alt={cardHistory?.cardName} />
          </div>
          <div className="card_info">
            <div className="has_link">
              <p>{cardHistory?.cardName}</p>
              <p
                className="link"
                onClick={() => {
                  useRoute.routeIncludeState(
                    `/card/${cardHistory.cardId}`,
                    props.orgCode
                  );
                }}
              >
                <span>자세히 보기</span>
              </p>
            </div>

            <p className="card_number">{cardHistory?.cardNum}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardInfo;
