import { startTransition, useLayoutEffect, useState, useEffect } from "react";
import { useGetAsset, usePostAsset } from "features/mydata";
import OranizationItem from "./OrganizationItem";
import { CustomButton } from "components/Button";
import { useRouter } from "lib/utils/useRouter";
import RegisterAssetInfo from "./RegisterAssetInfo";
import LoadingSpinner from "components/Modal/LoadingSpinner";

const RegisterAssetContainer = (props) => {
  const getAssetQuery = useGetAsset(props.param);
  const [selectAssetList, setSelectAssetList] = useState([]);
  const mutation = usePostAsset(selectAssetList);
  const [assetCount, setAssetCount] = useState(0);
  const route = useRouter();
  const [isExistAsset, setIsExistAsset] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const handleSubmit = () => {
    setShowLoading(true);
    selectAssetList?.map((asset) => {
      asset.scopeArray = asset.scope;
      asset.scope = asset.scope.join(",");
    });
    selectAssetList?.map((asset) => {
      asset.assetInfoList = asset.assetList;
      asset.assetList = asset.assetList.map((item) => {
        return {
          asset: item.card_id,
        };
      });
    });

    setSelectAssetList(
      selectAssetList.filter(
        (asset) => asset.assetList.length > 0 || asset.scopeArray.length > 0
      )
    );

    mutation.mutate(selectAssetList);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      setShowLoading(false);
      route.routeIncludeState("/mydata/consent/asset", {
        assetList: selectAssetList,
        cert: props.cert,
      });
    }
  }, [mutation?.isSuccess]);

  useLayoutEffect(() => {
    if (!getAssetQuery?.isPending) {
      let sum = 0;
      setSelectAssetList(
        getAssetQuery?.data?.org_list?.map((org) => {
          const count = org.card_list?.length + 2;
          sum += count;
          return {
            orgCode: org.org_code,
            orgName: org.org_nm,
            orgImg: org.img_url,
            scope: ["card.point", "card.bill"],
            assetList: org.card_list,
            // assetList: org.card_list.map((card) => {
            //   return {
            //     asset: card.card_id,
            //   };
            // }),
          };
        })
      );

      setAssetCount(sum);

      setIsExistAsset(getAssetQuery?.data?.org_cnt === 0);
    }
  }, [getAssetQuery?.isPending]);

  if (isExistAsset) {
    return (
      <section className="page_midsection_mydata2">
        <div className="mydata_asset_section">
          <h2>
            연결할 카드를<br></br>불러올 수 없습니다.
          </h2>
          <div className="asset_wrap">
            <p>연결할 카드사를 다시 선택해주세요.</p>
          </div>
          <CustomButton
            title="확인"
            buttonClass="btn_company_bottom"
            onClick={() => {
              route.routeTo("/home");
            }}
          />
        </div>
      </section>
    );
  }

  if (getAssetQuery?.data?.org_cnt > 0) {
    return (
      <>
        <section className="page_midsection_mydata">
          <div className="mydata_asset_section">
            <h2>아래 카드를 선택해 주세요.</h2>
            <div className="asset_wrap">
              <h3>카드</h3>
              <div className="asset_content">
                {getAssetQuery?.data?.org_list?.map((org) => {
                  return (
                    <OranizationItem
                      org={org}
                      key={org.org_code}
                      selectAssetList={selectAssetList}
                      setSelectAssetList={setSelectAssetList}
                      assetCount={assetCount}
                      setAssetCount={setAssetCount}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <RegisterAssetInfo />
          <CustomButton
            title="다음"
            buttonClass="btn_company_bottom"
            onClick={handleSubmit}
            disabled={assetCount === 0}
          />
        </section>
        {showLoading && <LoadingSpinner />}
      </>
    );
  }
};

export default RegisterAssetContainer;
