import { useEffect } from "react";
import { useParam } from "lib/utils/useRouter";
import { useGetBillingsHistory } from "features/billing";
import HistorySummary from "./HistorySummary";
import BillingHistoryList from "./BillingHistoryList";

const BillingHistoryContainer = (props) => {
  const params = useParam();
  const getBillingHistoryQuery = useGetBillingsHistory(
    params.getPathParameter().orgCode,
    params.getPathParameter().chargeMonth
  );
  const setPageTitle = props.setPageTitle;

  useEffect(() => {
    if (getBillingHistoryQuery?.isSuccess) {
      setPageTitle(getBillingHistoryQuery?.data?.orgName);
    }
  }, [getBillingHistoryQuery?.isSuccess]);

  return (
    <div className="card_container">
      <HistorySummary BillingHistory={getBillingHistoryQuery} />
      <div className="block_line"></div>
      <BillingHistoryList BillingHistory={getBillingHistoryQuery} />
    </div>
  );
};

export default BillingHistoryContainer;
