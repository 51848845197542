import React, { Suspense } from "react";
import { Layout } from "layout";
import {
  ManagementDetailsContainer,
  ManagementDetailsSkeleton,
} from "containers/Management";

const ManagementDetails = () => {
  return (
    <Layout header={{}}>
      <Suspense fallback={<ManagementDetailsSkeleton />}>
        <ManagementDetailsContainer />
      </Suspense>
    </Layout>
  );
};

export default ManagementDetails;
