import sampleImg from "assets/images/bankq/finance/우리은행.png";

const HistorySummary = (props) => {
  const billingHistoryQuery = props.BillingHistory;
  const billingHistory = billingHistoryQuery?.data;

  return (
    <section className="card_wrap">
      <div className="billing_org_article">
        <div className="billing_org_header">
          <div>
            <div className="billing_org_img">
              <img src={billingHistory.orgImg} alt={billingHistory.orgName} />
            </div>
            <p>{billingHistory.orgName}</p>
          </div>
        </div>
        <div className="billing_org_body">
          <div className="charge_info">
            <p>{billingHistory.paidOutDateFormat + " 결제일"}</p>
            <h2>{billingHistory.chargeAmt + "원"}</h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HistorySummary;
