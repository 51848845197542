import Modal from "components/Modal/Modal";
import { numComma } from "utils/utils";

const RevolvingPopup = (props) => {
  const revolving = props.revolvingQuery?.data;

  if (props.revolvingQuery.isLoading) {
    return <>준비</>;
  }

  return (
    <Modal bottomModal onClose={props.onClose} title="리볼빙 신청 내역">
      <div className="revolving_article">
        <ul className="charge_desc_item_list">
          <li>
            <div className="charge_desc_box">
              <span>신청일자</span>
              <p>
                {revolving.revlRegDt.substring(0, 4) +
                  "년 " +
                  revolving.revlRegDt.substring(4, 6) +
                  "월 " +
                  revolving.revlRegDt.substring(6) +
                  "일"}
              </p>
            </div>
          </li>
          <li>
            <div className="charge_desc_box">
              <span>이월잔액</span>
              <p>{numComma(revolving.revlCrfwBal) + "원"}</p>
            </div>
          </li>
          <li>
            <div className="charge_desc_box">
              <span>약정결제비율</span>
              <p>{revolving.revlCnrtStlmRto + "%"}</p>
            </div>
          </li>
          <li>
            <div className="charge_desc_box">
              <span>약정결제금액</span>
              <p>{numComma(revolving.cnrtStlmAmt) + "원"}</p>
            </div>
          </li>
          <li>
            <div className="charge_desc_box">
              <span>최소결제비율</span>
              <p>{revolving.revlMinStlmRto + "%"}</p>
            </div>
          </li>
          <li>
            <div className="charge_desc_box">
              <span>최소결제금액</span>
              <p>{numComma(revolving.minStlmAmt) + "원"}</p>
            </div>
          </li>
        </ul>
        <ul className="card_desc_list">
          <li>리볼빙 이용 수수료는 제외된 금액입니다.</li>
        </ul>
      </div>
    </Modal>
  );
};

export default RevolvingPopup;
