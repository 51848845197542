import React from "react";

const KAKAOTerms = () => {
  return (
    <div className="terms_contents_body" style={{ padding: "0 1rem" }}>
      {/* <h3>본인 인증을 위한 제3자 제공 동의</h3> */}
      주식회사 뱅큐(이하 "회사")는 개인정보 보호법, 신용정보의 이용 및 보호에
      관한 법률 등 관련 법령에 따라 다음과 같이 귀하의 개인정보를 제3에게
      제공합니다.
      <table>
        <colgroup>
          <col style={{ width: "30%" }} />
          <col style={{ width: "70%" }} />
        </colgroup>
        <thead>
          <tr>
            <th>구분</th>
            <th>내용</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>제공항목</th>
            <td>CI</td>
          </tr>
          <tr>
            <th>제공받는 자</th>
            <td
              className="td_strong"
              style={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              (주)카카오
            </td>
          </tr>
          <tr>
            <th>제공목적</th>
            <td
              className="td_strong"
              style={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              본인인증을 위한 개인 식별
            </td>
          </tr>
          <tr>
            <th>제공받는 자의 보유기간</th>
            <td
              className="td_strong"
              style={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              식별 후 별도 보관 없이 삭제
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ marginTop: "30px" }}>
        귀하는 개인정보 제3자 제공 동의를 거부할 권리가 있으나, 본인 인증은
        전송요구권 행사와 직접적으로 관련된 필수적 사항이므로 거부 시 회사가
        제공하는 마이데이터 서비스 이용이 불가할 수 있습니다.
      </div>
    </div>
  );
};

export default KAKAOTerms;
