import React from "react";

const PaymentDetailsSkeleton = () => {
  return (
    <div className="payment_container">
      <section className="payment_wrap">
        <div className="product_summary_article">
          <div className="product_name">
            <div className="payment_thumbnail">
              <div className="skeleton skeleton_card_img"></div>
            </div>
            <p className="skeleton skeleton_subtitle"></p>
          </div>
          <p className="skeleton skeleton_approved_price"></p>
          <div className="product_category">
            <p className="skeleton skeleton_subtitle "></p>
            <p className="skeleton skeleton_subtitle"></p>
          </div>
        </div>
      </section>
      <section className="payment_wrap">
        <div className="payment_detail_article">
          <ul className="payment_detail_table">
            <li>
              <p className="skeleton skeleton_subtitle "></p>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
            <li>
              <p className="skeleton skeleton_subtitle "></p>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
            <li>
              <p className="skeleton skeleton_subtitle "></p>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
            <li>
              <p className="skeleton skeleton_subtitle "></p>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
            <li>
              <p className="skeleton skeleton_subtitle "></p>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
            <li>
              <p className="skeleton skeleton_subtitle "></p>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
            <li>
              <p className="skeleton skeleton_subtitle "></p>
              <p className="skeleton skeleton_subtitle"></p>
            </li>

            <li>
              <p className="skeleton skeleton_subtitle "></p>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default PaymentDetailsSkeleton;
