import { Layout } from "layout";
import { StatisticsContainer } from "containers/Statistics";
import "assets/css/stats.scss";

const Statistics = () => {
  return (
    <Layout header={{}}>
      <StatisticsContainer />
    </Layout>
  );
};

export default Statistics;
