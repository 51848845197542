import React from "react";

const SelectItem = ({ item, name, onChange, selectItem }) => {
  return (
    <li className="period_list">
      <input
        type="radio"
        id={item.code}
        value={item.value}
        className="period_radio"
        name={name}
        onChange={onChange}
        checked={
          selectItem !== undefined && item.code === selectItem.code
            ? "checked"
            : ""
        }
      />
      <label htmlFor={item.code} className="period_radio_label">
        {item.name}
      </label>
    </li>
  );
};

export default SelectItem;
