import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";

const QUERY_KEY = "payment-by-date";

const fetchGetPaymentByDate = async (basDt, orgCode) => {
  return await axiosInstance
    .get(`/mydata/payment/${basDt}`, {
      params: {
        orgCode: orgCode,
      },
    })
    .then((response) => response.data)
    .then((response) => response.data);
};

const useGetPaymentByDate = (basDt, orgCode) => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY, { basDt, orgCode }),
    queryFn: async () => await fetchGetPaymentByDate(basDt, orgCode),
    suspense: true,
  });
  return query;
};

export { useGetPaymentByDate };
