import { useQueryClient } from "@tanstack/react-query";
import OrganizationQueryFactory from "../queries/OrganizationQueryFactory";
import useOrganizationMutation from "../queries/useOrganizationMutation";
import APIInstance from "lib/apis/APIInstance";

const fetchDeleteData = async (orgCode) => {
  const { data } = await APIInstance.delete("/mydata/organization/delete", {
    data: JSON.stringify(orgCode),
  });
  return data?.data;
};

export const useDeleteDataOfRevoked = (orgCode) => {
  const queryKey =
    OrganizationQueryFactory.queries.deleteOfRevokedOrganization(orgCode);
  const queryClient = useQueryClient();
  const deleteDataOfRevokedMutation = useOrganizationMutation(
    queryKey,
    async () => await fetchDeleteData(orgCode),
    (data) => data,
    () => {
      OrganizationQueryFactory.invalidateQueries(queryClient).scope();
    }
  );

  return deleteDataOfRevokedMutation;
};
