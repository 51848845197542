import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ModalContainer from "./ModalContainer";

import { isEmpty } from "utils/utils";
import * as Terms from "containers/Terms";

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
`;

const Wrap = styled.div`
  height: 100%;
  max-height: 100%;
  /* max-height: 95%; */
  max-width: 600px;
  width: 100%;
  /* height: fit-content; */
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: slide-fade-in 0.2s ease-in-out;
  overflow: hidden;

  &.ModalBottom {
    bottom: 0;
    border-radius: 10px 10px 0 0;
    position: fixed;
    top: auto;
    transform: translate(-50%, 0);
  }

  &.confirmModal {
    max-width: 400px;
    width: 80%;

    & > .body {
      padding: 0;
      padding-top: 30px;
      overflow-y: hidden;
    }

    p {
      width: 83%;
      margin: 0 auto;
    }

    footer {
      width: 100%;
      display: flex;
      margin-top: 24px;

      button {
        width: 100%;
        background-color: rgb(23, 111, 255);
        color: #fff;
        line-height: 56px;
        height: 56px;
        padding: 0 16px;
        font-weight: 600;
        font-size: 18px;

        &.prev {
          background-color: #fff;
          color: rgb(23, 111, 255);
          border: 1px solid rgb(23, 111, 255);
        }
      }
    }
  }

  &.fadeOutDetail {
    animation: slide-fade-out 0.2s ease-in-out;
    opacity: 0;
  }
  &.fadeOutDetail > .body {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    padding: 16px;

    &::-webkit-scrollbar {
      width: 3px;
    }
  }

  &.ModalBottom > .body {
    /* max-height: 250px; */
  }

  &.selectModal > .body {
    max-height: 330px;
  }

  & > footer {
    padding: 16px;
  }

  @keyframes slide-fade-in {
    0% {
      opacity: 0;
      transform: translate3d(-50%, 100px, 0);
    }

    30% {
      opacity: 1;
      transform: translate3d(-50%, 70px, 0);
    }

    50% {
      opacity: 1;
      transform: translate3d(-50%, 40px, 0);
    }

    80% {
      opacity: 1;
      transform: translate3d(-50%, 10px, 0);
    }

    100% {
      opacity: 1;
      transform: translate3d(-50%, 0, 0);
    }
  }

  @keyframes slide-fade-out {
    0% {
      opacity: 1;
      transform: translate3d(-50%, 0, 0);
    }

    50% {
      opacity: 1;
      transform: translate3d(-50%, 40px, 0);
    }

    100% {
      opacity: 0;
      transform: translate3d(-50%, 100px, 0);
    }
  }
`;

const components = {
  SERVICE: Terms.Service,
  MYDATA: Terms.MydataService,
  PRIVACY: Terms.Privacy,
  PRIVACY_COLLECT: Terms.PrivacyCollect,
  NAVER_TERMS: Terms.NaverTerms1,
  CERT_TERMS: Terms.CertTerms,
  TELECOM: Terms.CertTerms,
};

const TermsContentModal = ({ title, children, onClose, termsNo, terms }) => {
  const [fadeOutDetail, setFadeOutDetail] = useState(false);
  const [component, setComponent] = useState(null);
  // const getTermsContentsQuery = useGetTermsContents(termsNo, terms);

  const handleClose = () => {
    setFadeOutDetail(true);
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    setTimeout(() => {
      onClose?.();
    }, 250);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    const overflow = body.style.overflow;
    body.style.overflow = "hidden";

    return () => {
      body.style.overflow = overflow;
    };
  }, []);

  useEffect(() => {
    if (Object.keys(terms).length > 0) {
      const SpecificComponent = components[terms.subType];
      setComponent(<SpecificComponent url={terms.url} />);
    }
  }, [terms]);

  return (
    <ModalContainer>
      <Backdrop>
        <Wrap>
          <div className="botpos_vtitlow popup-has-close">
            <p className="botpos_vtit sub_title">{terms?.title}</p>
            <span className="popup-close" onClick={handleClose}></span>
          </div>
          <div className={"popup_contents terms_contents_wrap body"}>
            {terms?.subType === "MYDATA" ? <Terms.MydataService /> : null}
            {terms?.subType === "NAVER_TERMS" ? <Terms.NaverTerms1 /> : null}
            {terms?.subType === "KAKAO_TERMS" ? <Terms.KAKAOTerms /> : null}
            {terms?.content === "" ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: `<iframe src="${terms?.url}" class="iframe_border" frameborder="0" ></iframe>`,
                }}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: terms?.content,
                }}
              ></div>
            )}
          </div>
        </Wrap>
      </Backdrop>
    </ModalContainer>
  );
};

export default TermsContentModal;
