import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { printMsg, confirmMsg } from "utils/utils";
import { setCookies } from "utils/Cookie";
import { CSSTransition } from "react-transition-group";
import BottomAlert from "../../components/BottomAlert";
import { commonFetch, paramFetch } from "apis/common/fetchInstance";
import { detect } from "detect-browser";
import { useDeviceData } from "react-device-detect";
import useDeviceInfo from "lib/utils/useDevice";

const browser = detect();

function PasswordSetting() {
  const navigate = useNavigate();
  const location = useLocation();

  const [numPad, setNumPad] = useState([]); //숫자 패드

  const [pwdText, setPwdText] = useState([]); //1자리씩 저장
  const [firstPwd, setFirstPwd] = useState(""); //1차 패스워드
  const [secondPwd, setSecondPwd] = useState(""); //2차 패스워드
  const [pwdChk, setPwdChk] = useState(false); //1차 패스워드 확인
  const [secondPwdChk, setSecondPwdChk] = useState(false); //2차 패스워드 확인
  const [isPwd, setIsPwd] = useState(false);
  const [password, setPassword] = useState(""); //최종 패스워드
  // const [isBottomAlert, setIsBottomAlert] = useState(false);
  const [alertText, setAlertText] = useState([]);

  const getIdentifier = useDeviceInfo();

  useEffect(() => {
    if (pwdText.length === 6) {
      handleSetPwd();
    }
  }, [pwdText]);

  useEffect(() => {
    if (secondPwdChk) {
      if (firstPwd === secondPwd) {
        setIsPwd(false);
        joinApi();
        setSecondPwdChk(true);
      } else {
        setPwdText([]);
        setSecondPwd("");
        setSecondPwdChk(false);
        setIsPwd(true);
        // setIsBottomAlert(true);
        let array = [];
        array.push("비밀번호가 일치하지 않습니다\n");
        array.push("간편 비밀번호를 다시 입력해주세요");
        setAlertText(array);
        printMsg(array);
      }
    }
  }, [secondPwdChk]);

  const joinApi = async () => {
    console.log();
    if (!location.state.propsData.resetPassword) {
      requestJoinApi();
    } else {
      requestResetPasswordApi();
    }
  };

  const requestJoinApi = async () => {
    let os;
    let identifier;

    if (browser.os !== "iOS") {
      // const getIdentifier = window.Android.getIdentifier();
      os = browser.os;
      if (getIdentifier !== null || getIdentifier !== undefined) {
        localStorage.setItem("identifier", getIdentifier);
        identifier = getIdentifier;
      }
    }

    const params = {
      name: location.state.propsData.name,
      birthday: location.state.propsData.birthday,
      gender: location.state.propsData.gender,
      mobile: location.state.propsData.mobile,
      phone: location.state.propsData.phone,
      ci: location.state.propsData.ci,
      di: location.state.propsData.di,
      os: os,
      identifier: identifier,
      password: secondPwd,
    };

    const { response, data } = await commonFetch(
      "/api/auth/join",
      "post",
      params
    );
    if (response.status === 200) {
      window.localStorage.setItem("ref_token", data.refreshToken);
      window.localStorage.setItem("acc_token", data.accessToken);
      window.sessionStorage.setItem("user", data.userName);
      navigate("/join/finish");
    }

    if (response.status === 405) {
      setFirstPwd("");
      setSecondPwd("");
      setPwdText([]);
      setPwdChk(false);
      setIsPwd(true);
      setSecondPwdChk(false);

      printMsg(response.message);
    }
  };

  const requestResetPasswordApi = async () => {
    let os;
    let identifier;

    if (browser.os !== "iOS") {
      // const getIdentifier = window.Android.getIdentifier();
      os = browser.os;
      if (getIdentifier !== null || getIdentifier !== undefined) {
        localStorage.setItem("identifier", getIdentifier);
        identifier = getIdentifier;
      }
    }

    const params = {
      identifier: identifier,
      password: secondPwd,
    };

    const { response, data } = await commonFetch(
      "/api/auth/reset",
      "post",
      params
    );
    if (response.status === 200) {
      window.localStorage.setItem("ref_token", data.refreshToken);
      window.localStorage.setItem("acc_token", data.accessToken);
      window.sessionStorage.setItem("user", data.userName);
      navigate("/home");
    }
  };

  useEffect(() => {
    randomNumber();
  }, []);

  const goBack = () => {
    const msg = [
      "회원가입이 완료되지 않았습니다.",
      "시작화면으로 돌아가시겠습니까?",
    ];
    confirmMsg(msg, "아니요", "예", yesClick);
  };

  const yesClick = () => {
    navigate(-1);
  };

  const randomNumber = (array) => {
    const numArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

    setNumPad(numArr.sort(() => Math.random() - 0.5)); //숫자 패드 섞기
  };

  const onClickNumPad = (num) => {
    setPwdText(pwdText.concat(num));
  };

  const handleSetPwd = () => {
    if (!pwdChk) {
      setPwdChk(true);
      setFirstPwd(pwdText.join(""));
      setPwdText([]);
    }
    if (pwdChk) {
      setSecondPwd(pwdText.join(""));
      setSecondPwdChk(true);
    }
  };

  const handleSetClear = () => {
    if (pwdText.length > 0) {
      const backNum = pwdText.slice(0, pwdText.length - 1);
      setPwdText(backNum);
    }
  };

  const pwdReset = () => {
    setFirstPwd("");
    setPwdText([]);
    setPwdChk(false);
    setIsPwd(false);
  };

  const bottomClose = () => {
    // setIsBottomAlert(false);
  };

  const NumDot = () => {
    return (
      <>
        <span
          className={"pfx_dot " + (pwdText[0] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[1] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[2] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[3] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[4] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[5] === undefined ? null : "active")}
        ></span>
      </>
    );
  };

  const NumberPad = () => {
    return (
      <div className="pfx_numkeyboard">
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[0])}
        >
          {numPad[0]}
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[1])}
        >
          {numPad[1]}
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[2])}
        >
          {numPad[2]}
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[3])}
        >
          {numPad[3]}
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[4])}
        >
          {numPad[4]}
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[5])}
        >
          {numPad[5]}
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[6])}
        >
          {numPad[6]}
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[7])}
        >
          {numPad[7]}
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[8])}
        >
          {numPad[8]}
        </button>
        <button
          type="button"
          className="pnum_key define_text"
          onClick={randomNumber}
        >
          재배열
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[9])}
        >
          {numPad[9]}
        </button>
        <button
          type="button"
          className="pnum_key define_ico ico_back"
          onClick={handleSetClear}
        >
          <span className="hdtext">뒤로가기</span>
        </button>
      </div>
    );
  };

  return (
    <div className="front_body">
      <div className="page_wrap has_botpos">
        <header className="page_header"></header>
        <section className="page_fxsection">
          <div className="pfx_top">
            <div className="pfx_copy">
              <h2 className="pfx_ctitle">간편비밀번호</h2>
              {pwdChk ? (
                <p className="pfx_csub">확인을 위해 한번 더 입력해주세요.</p>
              ) : (
                <p className="pfx_csub">6자리를 비밀번호를 입력해주세요.</p>
              )}
            </div>
            <div className="pfx_dot_w">
              <NumDot></NumDot>
            </div>

            <div className="pfx_orgin_input_w">
              <input type="text" className="pfx_origin_input" />
            </div>
          </div>
          <div className="pfx_bottom">
            <NumberPad />
          </div>

          {/* <BottomAlert
            isOpen={isBottomAlert}
            bottomClose={bottomClose}
            alertText={alertText}
          /> */}
        </section>
      </div>
    </div>
  );
}
export default PasswordSetting;
