import { useEffect, useState } from "react";
import { CustomButton } from "components/Button";
import { OrgListItem, useGetLinkedOrganizations } from "features/organization";
import { useRouter, useLocate } from "lib/utils/useRouter";
import NoDataBoard from "components/Board/NoDataBoard";

import LoadingSpinner from "components/Modal/LoadingSpinner";

const ManagementContainer = () => {
  const useRoute = useRouter();
  const linkedOrgQuery = useGetLinkedOrganizations();
  const linkedOrgData = linkedOrgQuery?.data;
  const [showLoading, setShowLoading] = useState(false);
  const [isUpdateList, setIsUpdateList] = useState(false);

  useEffect(() => {
    if (isUpdateList) {
      linkedOrgQuery.refetch();
      setIsUpdateList(!isUpdateList);
    }
  }, [isUpdateList]);

  return (
    <div className="asset_manage_wrap">
      <ul className="asset_manage_list">
        {linkedOrgData?.length === 0 ? (
          <NoDataBoard message={"연결된 자산이 없습니다."} />
        ) : null}
        {linkedOrgData?.map((org, index) => {
          return (
            <OrgListItem
              org={org}
              key={index}
              setShowLoading={setShowLoading}
              setIsUpdateList={setIsUpdateList}
            />
          );
        })}
      </ul>
      <CustomButton
        title="자산 연결하기"
        buttonClass={"btn_naver_terms"}
        onClick={() => {
          useRoute.routeTo("/mydata/register/organization");
        }}
      />
      {showLoading && <LoadingSpinner />}
    </div>
  );
};

export default ManagementContainer;
