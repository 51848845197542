import axiosInstance from "lib/apis/AxiosInstance";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";
import APIInstance from "lib/apis/APIInstance";
import MydataQueryFactory from "../queries/MydataQueryFactory";
import useMyDataMutation from "../queries/mydataMutation";

const NAVER_QUERY_KEY = "/mydata/naver/";
const OAUTH_QUERY_KEY = "/mydata/oauth/";

const fetchPostConsent = async (url, params) => {
  const { data } = await APIInstance.post(url, params);
  return data.data;
};

export const usePostConsent = (
  certType,
  certStatus,
  params,
  successCallBack
) => {
  const url = makeUrlByType(certType, certStatus);
  const queryKey = MydataQueryFactory.queries.postConsent(certType, certStatus);
  const queryClient = useQueryClient();
  const mutation = useMyDataMutation(
    queryKey,
    async () => await fetchPostConsent(url, params),
    (data) => data,
    () => {
      MydataQueryFactory.invalidateQueries(queryClient).consentScope();
    }
  );

  return mutation;
};

export const useNaverConsent = (type, params) => {
  const requestParams = params;
  const queryClient = useQueryClient();
  const queryKey = getQueryKey(NAVER_QUERY_KEY + type);

  const naverMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (url, obj) =>
      fetchPostConsent(NAVER_QUERY_KEY + type, requestParams),
  });

  return { naverMutation };
};

export const useOAuthConsent = (type, params) => {
  const queryKey = getQueryKey(OAUTH_QUERY_KEY + type);

  const oAuthMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (url, obj) => fetchPostConsent(OAUTH_QUERY_KEY + type, params),
  });

  return { oAuthMutation };
};

const makeUrlByType = (type, certStatus) => {
  switch (type) {
    case "KAKAO":
      return `/mydata/kakao/${certStatus}`;
    case "NAVER":
      return `/mydata/naver/${certStatus}`;
    case "CERT":
      return `/mydata/oauth/${certStatus}`;
    default:
      return "잘못된 파라미터"; // @TODO 추후 수정
  }
};
