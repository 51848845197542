import { Layout } from "layout";
import { Button } from "components/Button";
import { useRouter } from "lib/utils/useRouter";

const CertificationList = () => {
  const useRoute = useRouter();
  return (
    <Layout header={{ title: "공동인증서 관리" }}>
      <div className="cert_container">
        <div className="cert_emtpy_block">
          <p>
            계좌와 카드 내역 조회를 위해 <br></br>최초 1회 공동인증서 등록이
            필요합니다.
          </p>
        </div>

        <Button
          className="btn_cert"
          title="인증서 추가하기"
          onClick={() => {
            useRoute.routeTo("add/step1");
          }}
        ></Button>
      </div>
    </Layout>
  );
};

export default CertificationList;
