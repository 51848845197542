import { useState, useEffect } from "react";
import { Button } from "components/Button";
import { useRouter } from "lib/utils/useRouter";
import { useDeleteDataOfRevoked } from "../mutations";

const OrgListItem = (props) => {
  const [orgCode, setOrgCode] = useState("");
  const useRoute = useRouter();
  const deleteDataOfRevokedMutation = useDeleteDataOfRevoked([orgCode]);
  const org = props.org;
  const setShowLoading = props.setShowLoading;

  useEffect(() => {
    if (deleteDataOfRevokedMutation?.isSuccess) {
      setShowLoading(false);
      props.setIsUpdateList(true);
    }
  }, [deleteDataOfRevokedMutation?.isSuccess]);

  if (org.transStatusCode === "01" || org.transStatusCode === "02") {
    return (
      <li>
        <div className="manage_item">
          <div className="manage_org_img">
            <img src={org.orgImg} alt={org.orgName} />
          </div>
          <p>{org.orgName}</p>
          <p
            onClick={() => {
              useRoute.routeTo(`/management/${org.orgCode}`);
            }}
            className="link"
          >
            <span>자세히 보기</span>
          </p>
        </div>
      </li>
    );
  }

  if (org.transStatusCode === "03" || org.transStatusCode === "04") {
    return (
      <li className="withdraw">
        <div
          className="manage_item"
          style={{ justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="manage_org_img">
              <img src={org.orgImg} alt={org.orgName} />
            </div>

            <p>{org.orgName}</p>
          </div>

          <div className="withdraw_btn">
            <Button
              title="데이터 삭제"
              className="btn_delete"
              onClick={() => {
                setShowLoading(true);
                setOrgCode(org.orgCode);
                deleteDataOfRevokedMutation.mutate([orgCode]);
              }}
            />
          </div>
        </div>
      </li>
    );
  }
};

export default OrgListItem;
