import NoDataBoard from "components/Board/NoDataBoard";

const BillingList = (props) => {
  const billingQuery = props.billings;

  if (billingQuery?.data?.length === 0) {
    return (
      <section className="charge_month_wrap">
        <NoDataBoard message={"청구 내역이 없습니다."} />
      </section>
    );
  }

  return (
    <section className="charge_month_wrap">
      <ul className="table_list_article">
        {billingQuery?.data?.map((billing, index) => {
          return (
            <li key={index}>
              <div className="charge_month_item has_link">
                <p>{billing.chargeMonthFormat + " 청구서"}</p>
                <h3>{billing.chargeAmt + "원"}</h3>
                <a
                  className="link"
                  href={`/billing/${billing.orgCode}/${billing.chargeMonth}`}
                >
                  <span>자세히 보기</span>
                </a>
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default BillingList;
