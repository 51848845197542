import { QueryClient, useQuery, useMutation } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

export function getQueryKey(QUERY_KEY, page) {
  if (page === undefined) return [QUERY_KEY];
  return [QUERY_KEY, page];
}

export function deleteQuery(QUERY_KEY) {
  queryClient.invalidateQueries({ queryKey: QUERY_KEY });
}

export function generateQueryKey(scope, entity, uniqueValues) {
  if (uniqueValues === 0) {
    uniqueValues = "0";
  }

  return [{ scope: scope, entity: entity, uniqueValues: uniqueValues || {} }];
}

export function useCustomQuery(queryKey, queryFn, options = {}) {
  const { onError } = options;

  return useQuery({
    queryKey: queryKey,
    queryFn: queryFn,
    useErrorBoundary: !onError,
    ...options,
  });
}

export function useCustomMutation(queryKey, queryFn, options = {}) {
  const { onError } = options;

  return useMutation({
    mutationKey: queryKey,
    mutationFn: queryFn,
    useErrorBoundary: !onError,
    ...options,
  });
}
