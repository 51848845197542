import React from "react";

const MydataService = () => {
  return (
    <div className="terms_contents_body">
      <h3>제1조 (목적)</h3>
      <p>
        본 약관은 “주식회사 뱅큐”(이하 “회사”라 합니다)가 제공하는 마이데이터
        서비스 (이하 “서비스”라 합니다)의 이용과 관련하여 회사와 서비스를
        이용하는 고객(이하 “이용자”라 합니다) 사이의 권리, 의무 및 책임사항,
        서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.
      </p>
      <h3>제2조 (용어의 정의)</h3>
      <ul>
        <li>
          ① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
          <ul>
            <li>
              1. "서비스"란 이용자의 전송요구에 따라 회사가 정보제공자로부터
              전송 받은 이용자의 정보를 수집하여 이용자에게 제공하는
              개인(신용)정보 통합 조회, 관리서비스 등 제3조의 서비스를 말합니다.
            </li>

            <li>
              2. "이용자"란 본 약관의 이용 조건에 동의하고, 회사로부터 서비스를
              이용하는데 필요한 자격을 부여 받은 고객을 말합니다.
            </li>

            <li>
              3. “개인정보”란 「개인정보 보호법」 제2조 제1호에서 말하는
              개인정보를 말합니다.
            </li>

            <li>
              4. “개인신용정보”란 금융거래 등에서 신용정보주체의 신용, 거래내용,
              거래능력 등을 판단할 수 있는 개인정보를 말합니다.
            </li>
            <li>
              5. “전송요구”란 「신용정보의 이용 및 보호에 관한 법률」(이하
              “신용정보법”이라 합니다) 제33조의2 등에 의거하여 이용자가
              정보제공자를 상대로 그가 보유하고 있는 본인의 개인(신용)정보에
              대해 회사에 전송할 것을 요구하는 행위를 말합니다.
            </li>
            <li>
              6. "정보제공자"란 이용자의 동의 또는 전송요구에 따라 이용자의
              개인(신용)정보를 정보수신자인 회사에 전송하는 자를 말합니다.
            </li>
            <li>
              7. “중계기관”이란 관련 법령에 따라 정보제공자를 대신하여 이용자의
              전송요구에 따라 개인(신용)정보를 회사에 전송하는 자를 말합니다.
            </li>
            <li>
              8. “마이데이터”란 회사가 정보수신자로서 이용자의 전송요구에 따라
              정보제공자로부터 수집한 개인(신용)정보를 말합니다.
            </li>
            <li>
              9. “본인인증”이란 이용자가 정보제공자에게 개인(신용)정보의 전송을
              요구할 때, 이용자가 정보주체 본인인지 여부를 정보제공자가 확인하는
              절차를 말합니다.
            </li>
            <li>
              10. “통합인증”이란 이용자가 금융보안원 등이 지정한 인증 수단을
              활용, 인증 행위를 1회 수행함으로서 다수의 정보제공자에 인증이
              가능한 방식을 말합니다.
            </li>
            <li>
              11. “접근매체”란 서비스와 관련하여 거래 지시를 하거나 이용자 및
              거래내용의 진실성과 정확성을 확보하기 위해 사용되는, 다음 각 목의
              어느 하나에 해당하는 수단 또는 정보를 말합니다.
              <ul>
                <li>가. 전자식 카드 및 이에 준하는 전자적 정보</li>
                <li>
                  나. 「전자서명법」 제2조 제3호에 따른 전자서명생성정보 및 같은
                  조 제6호에 따른 인증서
                </li>
                <li>다. 등록된 이용자번호</li>
                <li>라. 이용자의 생체정보</li>
                <li>
                  마. 가목 또는 나목의 수단이나 정보를 사용하는데 필요한
                  비밀번호
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          ② 본 약관에서 별도로 정의하지 아니한 용어는 「전자금융거래법」,
          「신용정보의 이용 및 보호에 관한 법률」 등 관련 법률이 정하는 바에
          따릅니다{" "}
        </li>
      </ul>
      <h3>제3조 (서비스의 가입) </h3>
      <ul>
        <li>
          ① 이용자가 애플리케이션을 통해 회사가 정한 양식에 따라 서비스
          가입신청을 하고 회사가 이를 승인하면 서비스 가입이 완료됩니다.{" "}
        </li>
        <li>
          ② 회사는 다음 각 호의 어느 하나에 해당하는 경우 가입을 승낙하지 않을
          수 있습니다.
          <ul>
            <li>
              - 사용자가 과거에 이 약관을 위반하여 서비스 이용계약을 해지당한
              경우
            </li>
            <li>- 만 19세 미만의 자가 가입 신청하는 경우</li>
          </ul>
        </li>
      </ul>
      <h3>제4조 (서비스의 종류) </h3>
      회사가 제공하는 서비스는 자산관리, 지출관리 등이며 다음 각 호에 따라 주요
      서비스 내용을 구분하고 구체적인 서비스 종류는 애플리케이션을 통하여
      안내합니다.
      <ul>
        <li>
          1. 자산관리: 이용자의 금융∙비금융자산을 통합하여 조회하고 진단하는
          서비스{" "}
        </li>
        <li>
          2. 지출관리: 이용자의 지출내역을 통합하여 조회하고 분석하는 서비스{" "}
        </li>
      </ul>
      <h3>제5조 (전송요구) </h3>
      <ul>
        <li>
          ① 이용자는 정보제공자에게 서면∙전자문서∙개인비밀번호 및 기타 방법의
          수단으로 정보 제공동의의 안정성과 신뢰성이 확보될 수 있는 수단을
          활용하여 전송요구를 할 수 있습니다.{" "}
        </li>
        <li>
          ② 이용자는 다음 각 호의 사항을 특정하여 정보제공자에게 전송을 요구할
          수 있습니다.
          <ul>
            <li>1. 신용정보제공∙이용자 등으로서 전송요구를 받는자 </li>
            <li>2. 전송을 요구하는 개인신용정보 </li>
            <li>3. 전송요구에 따라 개인신용정보를 제공받는 자 </li>
            <li>
              4. 정기적인 전송을 요구하는지 여부 및 요구하는 경우 그 주기{" "}
            </li>
            <li>5. 전송요구의 종료시점 </li>
            <li>6. 전송을 요구하는 목적 </li>
            <li>7. 전송을 요구하는 개인신용정보의 보유기간 </li>
            <li>8. 기타 </li>
          </ul>
        </li>
        <li>
          ③ 이용자는 전송요구를 철회할 수 있으며 철회의 방법은 전송요구의 방법과
          같습니다.
        </li>
        <li>
          ④ 정보제공자는 다음사항의 경우 전송요구∙철회를 거절하거나 전송을
          정지∙중단하며 이용자에게 안내합니다.
          <ul>
            <li>1. 이용자 본인이 전송요구를 한 사실이 확인되지 않은 경우 </li>
            <li>
              2. 이용자 본인이 전송요구를 했으나 제3자의 기망이나 협박 때문에
              전송요구를 한 것을 의심한 경우{" "}
            </li>
            <li>
              3. 이용자의 인증정보 탈취 등 부당한 방법으로 인한 전송요구임을
              알게 된 경우{" "}
            </li>
            <li>
              4. 전송요구에 응하여 개인신용정보를 제공할 경우 제3자의 정당한
              권리 또는 이익을 부당하게 침해하는 경우
            </li>
          </ul>
        </li>
        <li>
          ⑤ 회사는 이용자가 개인신용정보 전송요구를 철회하더라도, 이용자가 최초
          요구 시 특정한 개인신용정보 유기간 동안 개인신용정보를 보유할 수
          있습니다. 단, 이용자가 삭제를 명시 적으로 요구할 경우 수집한
          개인신용정보를 즉시 삭제합니다.
        </li>
      </ul>
      <h3>제6조 (서비스 이용 수수료) </h3>
      <ul>
        <li>
          ① 회사는 이용자에게 서비스 이용에 따른 수수료를 부과할 수 있습니다.{" "}
        </li>
        <li>
          ② 회사는 제1항에서 정한 서비스 이용 수수료를 회사의 인터넷 홈페이지
          등에 게시하여 이용자에게 통지하고, 위 내용을 변경하는 경우 제14조의
          변경절차를 준용합니다.{" "}
        </li>
      </ul>
      <h3>제7조 (서비스 이용시간) </h3>
      <ul>
        <li>
          ① 이용자는 회사에서 정한 시간 내에서 서비스를 이용할 수 있습니다.{" "}
        </li>
        <li>
          ② 이용시간은 회사의 사정에 따라 달라질 수 있으며, 회사가 이용시간을
          변경하고자 하는 경우에는 그 내용을 이용자가 접근하기 용이한 전자적
          장치를 통하여 변경 1개월 전부터 1개월간 알립니다. 다만, 시스템
          장애복구, 긴급한 프로그램 보수, 외부 요인 등 불가피한 경우에는 예외로
          합니다.{" "}
        </li>
      </ul>
      <h3>제8조 (서비스의 이용중단) </h3>
      <ul>
        <li>
          ① 회사는 다음의 각 호의 사유에 해당하는 경우 해당 사유가 해소될 때까지
          서비스 제공을 중단할 수 있고, 해당 사유가 소멸되는 경우 최대한 빠른
          시간 내에 서비스 제공을 재개하도록 합니다.
          <ul>
            <li>
              1. 서비스 장비의 장애 및 유지보수 등 부득이한 사유가 발생한 경우{" "}
            </li>
            <li>
              2. 정보제공기관의 인터넷서비스 이용시간 제한, 전산장애, 서비스
              제한 등의 사유가 발생한 경우{" "}
            </li>
            <li>
              3. 타기관 제공 API의 이용시간 제한, 전산장애 및 유지보수 등의
              사유가 발생한 경우
            </li>
            <li>
              4. 기타 정전, 천재지변, 국가비상사태 등 기타 불가항력적 사유가
              발생한 경우{" "}
            </li>
          </ul>
        </li>
        <li>
          ② 서비스는 관련 법령, 정책 변화에 따라 달라질 수 있으며, 회사는 서비스
          제공을 위해 계약을 체결하고 있는 제휴업체 등의 시스템 운영 상황이나
          계약 체결 상황에 따라 서비스 운영상 상당한 이유가 있는 경우에는
          서비스의 전체 또는 일부의 제공을 일시적으로 중단할 수 있습니다.{" "}
        </li>
        <li>
          ③ 회사는 제1항 및 제2항에 따라 서비스의 이용을 중단하는 경우에 그
          사유와 내용을 애플리케이션에 게시하여 사전에 이용자에게 알립니다. 단,
          시스템 장애복구, 긴급한 프로그램 보수, 외부요인 등 불가피한 경우에는
          예외로 합니다.{" "}
        </li>
      </ul>
      <h3>제9조 (서비스 탈퇴 및 정지) </h3>
      <ul>
        <li>
          ① 이용자가 서비스 탈퇴를 원하는 경우 애플리케이션의 ‘서비스 탈퇴’
          메뉴를 통하여 서비스 계약을 해지할 수 있습니다.{" "}
        </li>
        <li>
          ② 이용자에게 다음 각 호의 사유가 발생한 경우 회사는 본 서비스를 탈퇴
          또는 정지시킬 수 있습니다. 서비스의 해지 또는 정지 예정일 7일전까지
          서비스가 해지 또는 정지되는 사실을 이메일, LMS, 우편 등을 통하여
          고객에게 통지하며, 서비스의 해지 또는 정지 예정일까지 고객이 회사에
          별도의 회신을 하지 않는 경우 서비스를 해지 또는 정지할 수 있습니다.
          <ul>
            <li>1. 범죄행위에 관련되는 경우 </li>
            <li>2. 타인의 명의를 도용한 경우 </li>
            <li>3. 이용자 정보를 허위로 등록한 경우 </li>
            <li>4. 서비스의 운영을 고의로 방해한 경우 </li>
            <li>5. 회사나 제3자의 권익 또는 법익을 침해하는 경우 </li>
            <li>
              6. 회사의 서비스를 이용하여 얻은 정보를 회사의 사전승낙 없이 복제
              또는 유통시키거나 상업적으로 이용하는 경우{" "}
            </li>
          </ul>
        </li>
      </ul>
      <h3>제10조 (회사의 의무) </h3>
      <ul>
        <li>
          ① 회사는 제8조 및 제9조에서 정한 경우를 제외하고 이 약관에서 정한 바에
          따라 서비스를 지속적, 안정적으로 제공하여야 합니다.{" "}
        </li>
        <li>② 회사는 서비스 장애 시 이를 신속히 복구하여야 합니다. </li>
        <li>
          ③ 회사는 이용자가 제공한 개인정보를 서비스 제공목적 이외의 용도로
          사용하지 아니하며 철저한 보안으로 고객정보를 성실히 보호합니다. 다만
          관계법령에 의해 허용되거나 이용자가 동의한 경우는 그러하지 아니합니다.{" "}
        </li>
      </ul>
      <h3>제11조 (이용자의 의무) </h3>
      이용자는 다음 각 호의 행위를 하여서는 안 됩니다.
      <ul>
        <li>1. 서비스 신청 또는 정보 변경 시 허위내용을 등록하는 행위 </li>
        <li>
          2. 회사 및 기타 제3자의 인격권 또는 지식재산권을 침해하거나 업무를
          방해하는 행위{" "}
        </li>
        <li>
          3. 관계법령에 의하여 그 전송 또는 게시가 금지되어 있는 정보를 전송하는
          행위{" "}
        </li>
        <li>
          4. 컴퓨터 소프트웨어, 하드웨어, 전기통신장비의 정상적인 가동을 방해,
          파괴할 목적으로 고안된 소프트웨어 바이러스를 유포하는 행위{" "}
        </li>
        <li>5. 이 약관 및 기타 관련 규정을 위반하는 행위 </li>
      </ul>
      <h3>제12조 (손해배상 및 면책) </h3>
      <ul>
        <li>
          ① 회사는 이용자로부터 접근매체의 분실이나 도난의 통지를 받은 후에
          제3자가 그 접근매체를 사용하여 이용자에게 손해가 발생한 경우 그 손해를
          배상합니다.{" "}
        </li>
        <li>
          ② 회사는 접근매체의 위조나 변조로 발생한 사고, 계약체결 또는
          거래지시의 전자적 전송이나 처리 과정에서 발생한 사고, 전자금융거래를
          위한 전자적 장치 또는 정보통신망 이용촉진 및 정보보호 등에 관한 법률
          제2조 제1항 제1호에 따른 정보통신망에 침입하여 거짓이나 그 밖의 부정한
          방법으로 획득한 접근매체의 이용으로 발생한 사고로 인하여 이용자에게
          손해가 발생한 경우에 그 손해를 배상할 책임을 집니다.{" "}
        </li>
        <li>
          ③ 제2항에도 불구하고 회사는 이용자가 고의∙중과실로 다음 각 호의 행위를
          하였음을 증명하는 경우 이용자에게 손해가 생기더라도 책임의 전부 또는
          일부를 지지 아니합니다.
          <ul>
            <li>
              1. 이용자가 이용기기 또는 접근매체를 제3자에게 대여하거나 그
              사용을 위임한 경우 또는 양도나 담보의 목적으로 제공한 경우{" "}
            </li>
            <li>
              2. 제3자가 권한 없이 이용자의 접근매체 등을 이용하여
              전자금융거래를 할 수 있음을 알았거나 쉽게 알 수 있었음에도
              불구하고 이용자가 접근매체 등을 누설하거나 노출 또는 방치한 경우{" "}
            </li>
          </ul>
        </li>
        <li>
          ④ 이 서비스는 마이데이터사업 추진으로 API 기술로 구현하는 것으로,
          제공하는 자료의 정확성을 보장하지 아니하며, 고의 또는 중과실에 의한
          경우를 제외하고 이로 인한 손해에 대하여 책임을 지지 않습니다. 회사는
          고의 또는 중과실에 의한 경우를 제외하고 이용자가 서비스를 이용하여
          기대하는 수익을 상실한 것에 대하여 책임을 지지 않습니다.{" "}
        </li>
      </ul>
      <h3>제13조 (관할법원)</h3>본 서비스 이용과 관련한 분쟁이 있을 경우 양
      당사자간 합의로 해결하며, 양 당사자간에 법률상 분쟁이 발생할 경우
      관할법원은 민사소송법에서 정하는 바에 따릅니다.
      <h3>제14조 (준거법) </h3>이 약관의 해석 및 적용에 대해서는 대한민국 법을
      적용합니다.
      <h3>제15조 (약관의 효력 및 변경) </h3>
      <ul>
        <li>
          ① 본 약관은 이용자가 회사에 서비스 가입 신청을 하고 회사가 이를
          승인함과 동시에 효력이 발생합니다.{" "}
        </li>
        <li>
          ② 이 약관에서 정하지 아니한 사항은 「전자금융거래법」, 「신용정보법」
          및 관련 법령 등이 정하는 바에 따릅니다.{" "}
        </li>
        <li>
          ③ 등록기관의 거래내역조회, 자금이체 등 금융거래는 해당 금융기관의
          약관을 따릅니다.{" "}
        </li>
        <li>
          ④ 회사가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유 등을
          현행 약관과 함께 명시하여 그 적용일자로부터 최소한 7일 이전(단,
          회원에게 불리하거나 중대한 사항의 변경은 30일 이전)에 공지합니다.
          다만, 이용자가 이의를 제기할 경우 회사는 이용자에게 적절한 방법으로
          약관 변경내용을 통지하였음을 확인해 주어야 합니다.{" "}
        </li>
        <li>
          ⑤ 제4항에도 불구하고 법령의 개정으로 인하여 긴급하게 약관을 변경한
          때에는 변경된 약관을 전자적 장치에 최소 1개월 이상 게시하고 이용자에게
          통지하여야 합니다.{" "}
        </li>
        <li>
          ⑥ 회사가 제4항 및 제5항의 게시하거나 통지를 하는 경우에는 “이용자가
          약관의 변경내용이 게시되거나 통지된 후부터 변경되는 약관의 시행일 전의
          영업일까지 이내에 계약을 해지할 수 있으며, 약관의 변경 내용에 이의를
          제기하지 아니하는 경우 약관의 변경내용에 승인한 것으로 본다.”라는
          취지의 내용을 통지하여야 합니다.{" "}
        </li>
        <li>
          ⑦ 이용자는 약관의 변경내용이 게시되거나 통지된 후부터 변경되는 약관의
          시행일 전의 영업일까지 서비스 이용을 해지할 수 있고, 약관의 변경내용에
          대하여 이의를 제기하지 아니하는 경우에는 약관의 변경을 승인한 것으로
          봅니다.{" "}
        </li>
        <li>
          ⑧ 회사는 애플리케이션에서 이용자가 약관을 요구할 경우 이를 교부하여야
          합니다.{" "}
        </li>
      </ul>
      <h3>부 칙</h3>본 약관은 2023년 7월 31일부터 시행합니다.
    </div>
  );
};

export default MydataService;
