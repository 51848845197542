import React from "react";

const CardItem = (props) => {
  const card = props.card;
  const organization = props.org;
  const selectAssetList = props.selectAssetList;
  const setSelectAssetList = props.setSelectAssetList;
  const setAssetCount = props.setAssetCount;
  const assetCount = props.assetCount;

  const handleChange = (checked, card) => {
    const selectOrg = selectAssetList.filter(
      (asset) => asset.orgCode === organization.org_code
    )[0];

    if (!checked) {
      const updateAssetList = selectOrg.assetList.filter(
        (el) => el.card_id !== card.card_id
      );
      selectOrg.assetList = updateAssetList;

      setAssetCount(assetCount - 1);
    } else {
      selectOrg.assetList.push({
        asset: card,
      });
      // selectOrg.assetList.push({
      //   asset: card.card_id,
      // });
      setAssetCount(assetCount + 1);
    }
    setSelectAssetList(selectAssetList);
  };

  return (
    <li className="register_asset_item">
      <label htmlFor={card.card_id} className="asset_checkbox_label">
        <img src={organization.img_url} alt={organization.org_nm} />
        <div>
          <p>{card.card_name}</p>
          <span>{card.card_num}</span>
        </div>
      </label>
      <input
        type="checkbox"
        id={card.card_id}
        value={card.card_id}
        className="mydata_checkbox"
        defaultChecked={true}
        onChange={(e) => handleChange(e.target.checked, card)}
      />
    </li>
  );
};

export default CardItem;
