import React, { useEffect, useState } from "react";
import { Layout } from "layout";
import { commonFetch } from "apis/common/fetchInstance";
import { Link } from "react-router-dom";
import down from "../../assets/images/addPage/Down-Arrow-PNG-Transparent-Image.png";

const TermsList = () => {
  const [termsList, setTermsList] = useState([]);

  useEffect(() => {
    agreeListApiCall();
  }, []);

  const agreeListApiCall = async () => {
    const { response, data } = await commonFetch("/api/terms", "get");
    if (response.status === 200) {
      setTermsList(data);
    }
  };

  return (
    <Layout header={{ title: "약관 및 정책" }}>
      <div className="asset_manage_wrap">
        <NoticeBody>
          {termsList.map((terms, index) => (
            <NoticItem terms={terms} key={terms.no} index={index} />
          ))}
        </NoticeBody>
      </div>
    </Layout>
  );
};

const NoticeBody = ({ children }) => {
  return <ul className="annc_vlist_notice">{children}</ul>;
};

const NoticItem = ({ terms }) => {
  return (
    <li>
      <Link to={{ pathname: `/terms/${terms.no}` }}>
        <div className="annc_vitem_notice post_nav">
          <p className="notice_title_text">{terms.title}</p>
        </div>
      </Link>
    </li>
  );
};

export default TermsList;
