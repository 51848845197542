import { useEffect, useState } from "react";
import { useGetAssetExecution } from "features/mydata";
import { queryClient } from "lib/apis/QueryClient";

const useAssetExecution = (isExecution) => {
  const assetExecutionQuery = useGetAssetExecution(isExecution);
  const [message, setMessage] = useState("최신 정보로 업데이트하세요");
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (assetExecutionQuery.isLoading || assetExecutionQuery.isFetching) {
      setStatus("start");
      setMessage("업데이트 중입니다.");
    }
  }, [assetExecutionQuery?.isLoading, assetExecutionQuery.isFetching]);

  useEffect(() => {
    if (status === "start" && assetExecutionQuery.isSuccess) {
      setTimeout(() => {
        setMessage("업데이트 완료");
        setStatus("end");
        queryClient.invalidateQueries({ queryKey: ["payment-main"] });
        queryClient.invalidateQueries({ queryKey: ["card-main"] });
      }, 3000);
    }
  }, [
    assetExecutionQuery?.isLoading,
    assetExecutionQuery.isFetching,
    assetExecutionQuery?.isSuccess,
  ]);

  useEffect(() => {
    if (assetExecutionQuery.isError) {
      setTimeout(() => {
        setMessage("연결된 카드사가 없습니다.");
        setStatus("end");
      }, 3000);
    }
  }, [assetExecutionQuery?.isError]);

  const refetch = () => {
    const now = new Date().getTime();
    const dataUpdatedAt = assetExecutionQuery.dataUpdatedAt;

    const diffSec = now - dataUpdatedAt;
    const diffMin = diffSec / (60 * 1000);

    if (diffMin > 10) {
      assetExecutionQuery.refetch();
    } else {
      setStatus("stale");
    }
  };

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ["mydata-asset"] });
  };
  return { message, status, refetch, invalidate };
};

export default useAssetExecution;
