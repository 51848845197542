import { Suspense, useEffect } from "react";
import { InitCardContainer, CardSkeleton } from "containers/Card";
import { Layout, Footer } from "layout";
import { useRouter } from "lib/utils/useRouter";

const CardMainPage = () => {
  const useRoute = useRouter();
  return (
    <Layout
      header={{
        title: "카드",
        onClick: () => {
          useRoute.routeTo("/home");
        },
      }}
    >
      <Suspense fallback={<CardSkeleton />}>
        <InitCardContainer />
      </Suspense>
      <Footer />
    </Layout>
  );
};

export default CardMainPage;
