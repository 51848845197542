import React from "react";
import useHandleButton from "hooks/useHandleButton";
import { CustomButton } from "components/Button";

const ButtonContainer = (props) => {
  const [title, renderButton] = useHandleButton("동의하고 계속하기", () => {
    props?.callback();
  });

  return (
    <CustomButton
      title={title}
      onClick={renderButton}
      buttonClass={"btn_company_bottom"}
    />
  );
};

export default ButtonContainer;
