import { MyDataHeader } from "layout";
import { CustomButton } from "components/Button";
import { useLocate, useRouter } from "lib/utils/useRouter";

const ResultContainer = () => {
  const stateData = useLocate().getStateData();
  const useRoute = useRouter();
  const resultOrgList = stateData?.data?.org_list;
  const assetList = stateData?.assetList;

  return (
    <div className="front_body">
      <div className="page_wrap">
        <MyDataHeader />

        <section className="page_midsection_mydata">
          <div className="mydata_asset_section">
            <h2>
              자산 불러오기를 <br></br>완료했습니다.
            </h2>
            <div className="asset_wrap">
              <h3>카드</h3>

              <div className="asset_content">
                {resultOrgList
                  ?.filter((org) => org.status_cd === "02")
                  .map((org, index) => {
                    return (
                      <div className="register_asset_block" key={org.org_code}>
                        <div className="register_asset_header">
                          <img
                            src={
                              assetList?.filter(
                                (asset) => asset.orgCode === org.org_code
                              )[0].orgImg
                            }
                            alt=""
                          />
                          <p>{org.org_nm}</p>
                        </div>
                        <div className="register_asset_box">
                          <ul>
                            {assetList
                              ?.filter(
                                (asset) => asset.orgCode === org.org_code
                              )
                              ?.map((asset) => {
                                return asset?.assetInfoList?.map((info) => {
                                  return (
                                    <li
                                      className="register_asset_item"
                                      key={info.card_id}
                                    >
                                      <div>
                                        <p>{info.card_name}</p>
                                        <span>{info.card_num}</span>
                                      </div>
                                    </li>
                                  );
                                });
                              })}
                          </ul>
                          <ul className="asset_additional_item">
                            {assetList
                              ?.filter(
                                (asset) => asset.orgCode === org.org_code
                              )
                              ?.map((asset) => {
                                return asset?.scopeArray?.map((scope) => {
                                  return (
                                    <li key={scope}>
                                      <p className="asset_checkbox_label ">
                                        {scope.indexOf("card.point")
                                          ? "포인트"
                                          : "결제 · 청구서 · 리볼빙"}
                                      </p>
                                    </li>
                                  );
                                });
                              })}
                          </ul>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <CustomButton
            title="확인"
            buttonClass="btn_company_bottom"
            onClick={() => {
              useRoute.routeIncludeState("/home", {
                "register-asset": true,
              });
            }}
          />
        </section>
      </div>
    </div>
  );
};

export default ResultContainer;
