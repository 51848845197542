import { Layout } from "layout";
import { CreditInformationContainer } from "containers/CreditInformation";
import "assets/css/transfer_history.scss";

const CreditInformation = () => {
  return (
    <Layout header={{ title: "신용정보 삭제" }}>
      <CreditInformationContainer />
    </Layout>
  );
};

export default CreditInformation;
