import { CardListItem } from "features/card";

const MainCard = (props) => {
  const cardQuery = props.cardQuery;
  const cardData = cardQuery?.data;

  return (
    <section className="card_wrap">
      <div className="card_article">
        <div className="card_summary_box ">
          <p>{cardData?.basYear + "년 " + cardData?.basMon + "월 사용금액"}</p>
          <h2>{cardData?.totalAmt + "원"}</h2>
        </div>
        <ul className="table_list_article">
          {cardData?.cardAmtList?.map((card) => {
            return (
              <CardListItem
                card={card}
                key={card.cardId}
                basDtYm={cardData?.basYear + cardData?.basMon}
              />
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default MainCard;
