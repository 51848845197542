import React from "react";
import CardItem from "./CardItem";

const OranizationItem = (props) => {
  const organization = props.org;
  const selectAssetList = props.selectAssetList;
  const setSelectAssetList = props.setSelectAssetList;
  const setAssetCount = props.setAssetCount;
  const assetCount = props.assetCount;

  const handleChange = (checked, orgCode, type) => {
    if (!checked) {
      const updateScope = selectAssetList
        .filter((asset) => asset.orgCode === orgCode)[0]
        .scope.filter((el) => el !== "card." + type);

      selectAssetList.filter((asset) => asset.orgCode === orgCode)[0].scope =
        updateScope;
      setAssetCount(assetCount - 1);
    } else {
      selectAssetList
        .filter((asset) => asset.orgCode === orgCode)[0]
        .scope.push("card." + type);
      setAssetCount(assetCount + 1);
    }
    setSelectAssetList(selectAssetList);
  };

  return (
    <div className="register_asset_block">
      <ul>
        {organization?.card_list.map((card) => {
          return (
            <CardItem
              key={card.card_id}
              card={card}
              org={organization}
              selectAssetList={selectAssetList}
              setSelectAssetList={setSelectAssetList}
              assetCount={assetCount}
              setAssetCount={setAssetCount}
            />
          );
        })}
      </ul>
      <ul className="asset_additional_item">
        <li key={organization.org_code + "_point"}>
          <label
            htmlFor={organization.org_code + "_point"}
            className="asset_checkbox_label"
          >
            포인트
          </label>
          <input
            type="checkbox"
            id={organization.org_code + "_point"}
            className="mydata_checkbox"
            defaultChecked={true}
            onChange={(e) =>
              handleChange(e.target.checked, organization.org_code, "point")
            }
          ></input>
        </li>
        <li key={organization.org_code + "_payment"}>
          <label
            htmlFor={organization.org_code + "_payment"}
            className="asset_checkbox_label"
          >
            결제 · 청구서 · 리볼빙
          </label>
          <input
            type="checkbox"
            id={organization.org_code + "_payment"}
            className="mydata_checkbox"
            defaultChecked={true}
            onChange={(e) =>
              handleChange(e.target.checked, organization.org_code, "bill")
            }
          ></input>
        </li>
      </ul>
    </div>
  );
};

export default OranizationItem;
