import React from "react";
import { Link, useNavigate } from "react-router-dom";
// import BackImg from "assets/images/bankq/arrow.svg";
import BackImg from "assets/images/ico_back.png";
import { useState, useEffect, useCallback, useRef } from "react";

const MyDataHeader = ({ onClick, title, activeSection, children }) => {
  const navigate = useNavigate();
  const progress = ["first", "second", "third"];
  const tabRef = useRef(null);
  const [fixTab, setFixTab] = useState(false);

  const handleEvent = (navigate, onClick) => {
    return onClick === undefined ? navigate(-1) : onClick();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { capture: true }); // 스크롤 이벤트 등록
    return () => {
      window.removeEventListener("scroll", handleScroll); // 스크롤 이벤트 등록 제거(성능저하방지)
    };
  }, []);

  const handleScroll = useCallback(() => {
    if (!tabRef.current || !document.getElementsByClassName("front_body")) {
      return;
    }

    // 스크롤의 실시간 위치
    const scrollTop = window.scrollY; // 최상단 div 기준으로 스크롤 위치를 감지

    // 스크롤 위치가 tabRef(하위메뉴 탭)의 위치보다 아래이면
    if (scrollTop > 0) {
      setFixTab(true);
    } else {
      setFixTab(false);
    }
  }, [tabRef.current]);

  return (
    <>
      <header className={"page_header_mydata"} ref={tabRef}>
        <button
          className="page_header_back"
          onClick={() => {
            handleEvent(navigate, onClick);
          }}
        >
          <img src={BackImg} alt="뒤로 가기" />
        </button>
        <div className="page_header_title">
          <h1>{title}</h1>
        </div>
        {/* <div className="progress_section">
          {progress.map((item, index) => {
            return (
              <div
                key={item}
                className={[
                  "progress",
                  item + "_progress",
                  activeSection === item ? "active" : "",
                ].join(" ")}
              >
                {index + 1}
              </div>
            );
          })}
        </div> */}
        {children}
      </header>
    </>
  );
};

export default MyDataHeader;
