import React, { useEffect, useState } from "react";
import ico_warn2 from "assets/images/ico_warn2.png";
import usePopup from "hooks/usePopup";

const BottomAlert = ({
  isOpen,
  bottomClose,
  alertText,
  status,
  yesClick,
  title,
}) => {
  const { isAnimation, animationClass } = usePopup({ isOpen });
  const [list, setList] = useState([{ code: "", nm: "" }]);

  return (
    <div className={["popup-layout", isAnimation ? "active" : ""].join(" ")}>
      <div className="popup-dim"></div>
      <div className={["popup-wrap", animationClass, "popup-bottom"].join(" ")}>
        <div className="botpos_vtitlow popup-has-close">
          <p className="botpos_vtit sub_title">{title}</p>
        </div>

        <div className="botpos_vcontlow">
          <ul className="form_option_vlist">
            <div className="bottom_alert">
              <div className="bottom_alert_img">
                <img src={ico_warn2}></img>
              </div>
              <div className="bottom_alert_text">
                {alertText.map((item, i) => (
                  <p key={i}>{item}</p>
                ))}
              </div>
              <div className="bottom_alert_button">
                {status === "fail" ? (
                  <button onClick={yesClick}>간편비밀번호 재설정</button>
                ) : (
                  <button onClick={bottomClose}>확인</button>
                )}
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default BottomAlert;
