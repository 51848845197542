import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import IsLoding from "../../components/IsLoading";
import { jsonFetch, paramFetch, commonFetch } from "apis/common/fetchInstance";
import { printMsg, setDateFormat } from "utils/utils";
import { throttle } from "lodash";
import temp_banner from "../../assets/images/temp_banner.png";

const Tc_event = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [loading, setLoading] = useState();
  const [eventList, setEventList] = useState([]); //진행중인 이벤트
  const [endEventList, setEndEventList] = useState([]); //종료된 이벤트
  const [isTab, setIsTab] = useState(0); //이벤트 진행 탭

  const defaultPage = 1;
  const defaultRows = 10;

  const [page, setPage] = useState(defaultPage);
  const [pageing, setPageging] = useState(true);

  const lastRef = useRef(null);

  useEffect(() => {
    eventApiCall();
  }, []);

  const eventApiCall = async () => {
    const sendData = {
      page: page,
      rows: defaultRows,
      isPosting: isTab,
    };

    setLoading(true);
    const { response, data } = await commonFetch(
      `/api/event?page=${page}&rows=${defaultRows}`,
      "get"
    );
    if (response.status === 200) {
      let eventData = [];
      let endEventData = [];
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        if (obj.postingYn === "Y") {
          eventData.push({
            no: obj.no,
            eventTitle: obj.eventTitle,
            eventContent: obj.eventContent,
            eventThumbnailLocation: obj.eventThumbnailLocation,
            eventStartDate: obj.eventStartDate,
            eventEndDate: obj.eventEndDate,
            postingYn: obj.postingYn,
          });
        } else {
          endEventData.push({
            no: obj.no,
            eventTitle: obj.eventTitle,
            eventContent: obj.eventContent,
            eventThumbnailLocation: obj.eventThumbnailLocation,
            eventStartDate: obj.eventStartDate,
            eventEndDate: obj.eventEndDate,
            postingYn: obj.postingYn,
          });
        }
      }
      setTimeout(() => {
        setLoading(false);
      }, 300);
      setEventList(eventData);
      setEndEventList(endEventData);
    }
  };

  const handleScroll = useMemo(
    () =>
      throttle(() => {
        if (pageing && lastRef.current !== null) {
          let scrollTop = Math.max(
            document.scrollingElement.scrollTop,
            document.body.scrollTop
          );
          let clientHeight = document.documentElement.clientHeight;
          if (scrollTop + clientHeight >= lastRef.current.offsetTop) {
            setPage((prev) => prev + 1);
          }
        }
      }, 500),
    [pageing]
  );

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll); //clean up
    };
  }, [handleScroll]);

  const handleEventTab = (tabIndex) => {
    setIsTab(tabIndex);
    setPage(defaultPage);
  };

  return (
    <>
      <div
        className="front_body"
        style={{
          overflow:
            (isTab == 0 && eventList.length <= 2) ||
            (isTab == 1 && endEventList.length <= 2)
              ? "hidden"
              : "scroll",
        }}
      >
        <div className="page_wrap">
          <header className="page_header_event">
            <Link to="/menu" className="btn_back">
              <span className="hdtext">뒤로가기</span>
            </Link>
            <h1 className="page_headtitle">이벤트</h1>
          </header>
          {loading ? (
            <IsLoding />
          ) : (
            <section className="page_midsection_qna dtype7">
              <div className="event_tab">
                <div
                  className={
                    isTab === 0 ? "event_tab_text on" : "event_tab_text"
                  }
                  onClick={() => handleEventTab(0)}
                >
                  <p>진행중</p>
                </div>
                <div
                  className={
                    isTab === 1 ? "event_tab_text on" : "event_tab_text"
                  }
                  onClick={() => handleEventTab(1)}
                >
                  <p>종료</p>
                </div>
              </div>
              {isTab === 0 ? (
                <EventList
                  list={eventList}
                  lastRef={lastRef}
                  loading={loading}
                />
              ) : (
                <EndEventList
                  list={endEventList}
                  lastRef={lastRef}
                  loading={loading}
                />
              )}
            </section>
          )}
        </div>
      </div>
    </>
  );
};

const EventList = ({ list, lastRef, loading }) => {
  let listTag = [];

  for (let i = 0; i < list.length; i++) {
    let obj = list[i];
    const innerTAG = (
      <React.Fragment key={obj.no}>
        <li style={{ listStyle: "none" }}>
          <Link to={{ pathname: `/event/${obj.no}` }}>
            <div className="annc_vitem_event">
              <div className="annc_spec_w_event">
                <img src={obj.eventThumbnailLocation} />
                <p className="annc_spec01_event">{obj.eventTitle}</p>
                <p className="annc_spec02">
                  {setDateFormat(obj.eventStartDate)} ~{" "}
                  {setDateFormat(obj.eventEndDate)}
                </p>
              </div>
            </div>
          </Link>
        </li>
        {i + 1 === list.length ? null : <div className="list_line"></div>}
      </React.Fragment>
    );
    listTag.push(innerTAG);
  }

  if (!loading && list.length === 0) {
    return (
      <div className="no_data">
        <div
          className="inner"
          style={{ marginTop: "7rem", textAlign: "center" }}
        >
          <h3>진행중인 이벤트가 없습니다.</h3>
        </div>
      </div>
    );
  } else {
    return <div className="event_list">{listTag}</div>;
  }
};

const EndEventList = ({ list, lastRef, loading }) => {
  let listTag = [];

  for (let i = 0; i < list.length; i++) {
    let obj = list[i];
    const innerTAG = (
      <React.Fragment key={obj.no}>
        <li style={{ listStyle: "none" }} key={obj.no}>
          <div
            className="annc_vitem_event"
            onClick={() => printMsg("이미 종료된 이벤트 입니다.")}
          >
            <div className="annc_spec_w_event">
              <img src={obj.eventThumbnailLocation} />
              <p className="annc_spec01_event">{obj.eventTitle}</p>
              <p className="annc_spec02">
                {setDateFormat(obj.eventStartDate)} ~{" "}
                {setDateFormat(obj.eventEndDate)}
              </p>
            </div>
          </div>
        </li>
        {i + 1 === list.length ? null : <div className="list_line"></div>}
      </React.Fragment>
    );
    listTag.push(innerTAG);
  }

  if (!loading && list.length === 0) {
    return (
      <div className="no_data">
        <div
          className="inner"
          style={{ marginTop: "7rem", textAlign: "center" }}
        >
          <h3>종료된 이벤트가 없습니다.</h3>
        </div>
      </div>
    );
  } else {
    return <div className="event_list">{listTag}</div>;
  }
};

export default Tc_event;
