import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";

const QUERY_KEY = "transferHistoryDetails";

const fetchTransferHistoryDetails = async (orgId, no, hisDttm) => {
  return await axiosInstance
    .get(`/transHistory/${orgId}/${no}/${hisDttm}`)
    .then((response) => response.data)
    .then((response) => response.data);
};

const useGetTransferHistoryDetails = (orgId, no, hisDttm) => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY, hisDttm),
    queryFn: async () => await fetchTransferHistoryDetails(orgId, no, hisDttm),
  });
  return query;
};

export { useGetTransferHistoryDetails };
