import OrganizationQueryFactory from "./OrganizationQueryFactory";
import useOrganizationQuery from "./useOrganizationQuery";
import APIInstance from "lib/apis/APIInstance";

const fetchLinkedOrgDetails = async (orgCode) => {
  const { data } = await APIInstance.get("/mydata/property/propertyView", {
    params: { orgCode: orgCode },
  });
  return data?.data;
};

const useGetLinkedOrgDetails = (orgCode) => {
  const queryKey = OrganizationQueryFactory.queries.detail(orgCode);
  const query = useOrganizationQuery(
    queryKey,
    async () => await fetchLinkedOrgDetails(orgCode),
    (data) => data,
    true,
    true
  );

  return query;
};

export default useGetLinkedOrgDetails;
