import React from "react";
import sampleImg from "assets/images/bankq/finance/우리은행.png";
import { useRouter } from "lib/utils/useRouter";

const TransferHistoryItem = (props) => {
  const transferItem = props.item;
  const router = useRouter();

  return (
    <li>
      <div className="transfer_item">
        <div className="transfer_item_header">{props.month}</div>
        <div className="transfer_item_body">
          <ul className="transfer_list">
            {transferItem
              ?.sort((a, b) =>
                b.mdatTrnfAgrmSqno.localeCompare(a.mdatTrnfAgrmSqno)
              )
              .map((transfer, index) => {
                return (
                  <li className="transfer_list_item" key={index}>
                    <div
                      className="transfer_item_article"
                      onClick={() => {
                        router.routeTo(
                          `${transfer.prvdOrgId}/${transfer.mdatTrnfAgrmSqno}/${transfer.hisDttm}`
                        );
                      }}
                    >
                      <div className="transfer_thumbnail">
                        {/* <img src={sampleImg} alt="" /> */}
                        <img src={transfer.imgUrl} alt="" />
                        {/* <img
                          src="https://kr.object.fin-ncloudstorage.com/bucket-loca/card/logo_card_dummy.png"
                          alt=""
                        /> */}
                      </div>
                      <div className="transfer_info">
                        <p>{transfer.orgNm}</p>
                        {transfer.mdatTrnfReqStatCd === "01" ? (
                          <span>등록</span>
                        ) : (
                          ""
                        )}
                        {transfer.mdatTrnfReqStatCd === "02" ? (
                          <span>연장폐기</span>
                        ) : (
                          ""
                        )}
                        {transfer.mdatTrnfReqStatCd === "03" ? (
                          <span>만료</span>
                        ) : (
                          ""
                        )}
                        {transfer.mdatTrnfReqStatCd === "04" ? (
                          <span>철회</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </li>
  );
};

export default TransferHistoryItem;
