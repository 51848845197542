import { useRouter } from "lib/utils/useRouter";

const CardListItem = (props) => {
  const card = props.card;
  const useRoute = useRouter();

  const handleClick = () => {
    useRoute.routeTo(
      `/card/${card.cardId}/history?orgCode=${card.orgCode}&searchDate=${props.basDtYm}`
    );
  };

  return (
    <li>
      <div className="table_list_item card_item has_link">
        <div className="card_img">
          <img src={card.orgImg} alt={card.cardName} />
        </div>
        <div className="card_info">
          <p>{card.cardName}</p>
        </div>
        <div className="card_price ">
          <p>{card.useAmt + "원"}</p>
        </div>
        <p onClick={handleClick} className="link">
          <span>자세히 보기</span>
        </p>
      </div>
    </li>
  );
};

export default CardListItem;
