import queryKeys from "constants/queryKeys";
import { generateQueryKey } from "lib/apis/QueryClient";

const PREFIX = {
  MYDATA: queryKeys.MYDATA,
  CONSENT: queryKeys.CONSENT,
};

const MydataQueryFactory = {
  queries: {
    join: () => generateQueryKey(PREFIX.MYDATA.BASE, PREFIX.MYDATA.JOIN),
    isCheckedJoin: () =>
      generateQueryKey(PREFIX.MYDATA.BASE, PREFIX.MYDATA.IS_CHECKED_JOIN),
    getOranizations: () =>
      generateQueryKey(PREFIX.MYDATA.BASE, PREFIX.MYDATA.GET_ORGANIZATIONS),
    getConsent: (requestParams) =>
      generateQueryKey(
        PREFIX.MYDATA.BASE,
        PREFIX.MYDATA.GET_CONSENT,
        requestParams
      ),
    getHavingAssets: (requestParams) =>
      generateQueryKey(
        PREFIX.MYDATA.BASE,
        PREFIX.MYDATA.GET_HAVEING_ASSETS,
        requestParams
      ),
    postConsent: (certType, certStatus) => {
      const entity = findConsentType(certType);
      generateQueryKey(PREFIX.CONSENT.BASE, entity, certStatus);
    },
    checkConsentResult: (status) =>
      generateQueryKey(
        PREFIX.CONSENT.BASE,
        PREFIX.CONSENT.CHECK_NAVER_RESULT,
        status
      ),
    postAsset: () =>
      generateQueryKey(PREFIX.MYDATA.BASE, PREFIX.MYDATA.POST_ASSET_OBJECT),
  },

  invalidateQueries: (queryClient) => ({
    consentScope: queryClient.invalidateQueries({
      queryKeys: [{ scope: PREFIX.CONSENT.BASE }],
    }),
    postAsset: queryClient.invalidateQueries({
      queryKeys: generateQueryKey(
        PREFIX.MYDATA.BASE,
        PREFIX.MYDATA.POST_ASSET_OBJECT
      ),
    }),
  }),
};

const findConsentType = (certType) => {
  switch (certType) {
    case "NAVER":
      return PREFIX.CONSENT.POST_NAVER;
    case "CERT":
      return PREFIX.CONSENT.POST_OAUTH;
    default:
      return "잘못된 정보";
  }
};

export default MydataQueryFactory;
