import { useLocate } from "lib/utils/useRouter";
import sampleIcon from "assets/images/category/etc.png";
import { useGetPaymentDetails } from "features/payment";

const PaymentDetailsContainer = () => {
  const stateData = useLocate().getStateData();

  const paymentDetailsQuery = useGetPaymentDetails(
    stateData.orgCode,
    stateData.approvedDateSeq,
    stateData.approvedNum
  );
  const paymentDetails = paymentDetailsQuery?.data;

  return (
    <div className="payment_container">
      <section className="payment_wrap">
        <div className="product_summary_article">
          <div className="product_name">
            <div className="payment_thumbnail">
              <img src={sampleIcon} alt="" />
            </div>
            <p>{paymentDetails.merchantName}</p>
          </div>
          <p className="product_price">{paymentDetails.approvedAmt + "원"}</p>
          <div className="product_category">
            <p>카테고리</p>
            <span>기타</span>
          </div>
        </div>
      </section>
      <section className="payment_wrap">
        <div className="payment_detail_article">
          <ul className="payment_detail_table">
            <li>
              <p>결제수단</p>
              <span>{paymentDetails.cardName}</span>
            </li>
            <li>
              <p>카드번호</p>
              <span>{paymentDetails.cardNum}</span>
            </li>
            <li>
              <p>승인번호</p>
              <span>{paymentDetails.approvedNum}</span>
            </li>
            <li>
              <p>승인일시</p>
              <span>{paymentDetails.approvedDate}</span>
            </li>
            <li>
              <p>승인상태</p>
              <span>{paymentDetails.statusName}</span>
            </li>
            <li>
              <p>사용구분</p>
              <span>{paymentDetails.payTypeName}</span>
            </li>
            <li>
              <p>결제방법</p>
              <span>{paymentDetails.prodTypeName}</span>
            </li>

            <li>
              <p>사업자번호</p>
              <span>{paymentDetails.merchantRegno}</span>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default PaymentDetailsContainer;
