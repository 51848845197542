import { useQueryClient } from "@tanstack/react-query";
import OrganizationQueryFactory from "../queries/OrganizationQueryFactory";
import useOrganizationMutation from "../queries/useOrganizationMutation";
import APIInstance from "lib/apis/APIInstance";

const fetchRevokeOrganization = async (orgCode) => {
  const { data } = await APIInstance.delete("/mydata/organization/revoke", {
    params: {
      orgCode: orgCode,
    },
  });
  return data?.data;
};

const useRevokeOrganization = (orgCode) => {
  const queryKey = OrganizationQueryFactory.queries.revoke(orgCode);
  const queryClient = useQueryClient();
  const revokeOrganitionMutation = useOrganizationMutation(
    queryKey,
    async () => await fetchRevokeOrganization(orgCode),
    (data) => data
  );

  return revokeOrganitionMutation;
};

export default useRevokeOrganization;
