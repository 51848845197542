import { NavLink, useLocation } from "react-router-dom";

const navList = [
  {
    id: "home",
    url: "/home",
    class: "ico_home",
    name: "홈",
  },
  {
    id: "payment",
    url: "/payment",
    class: "ico_loan",
    name: "지출",
  },
  {
    id: "card",
    url: "/card",
    class: "ico_card",
    name: "카드",
  },
  {
    id: "menu",
    url: "/menu",
    class: "ico_total",
    name: "메뉴",
  },
];

const Footer = () => {
  const location = useLocation();
  return (
    <div className="btn_botpos_wrap">
      <div className="pbotmenu_hlist_w">
        <ul className="pbotmenu_hlist">
          {navList.map((nav) => {
            return (
              <li key={nav.id}>
                <NavLink
                  to={nav.url}
                  className={[
                    "pbotmenu",
                    nav.class,
                    location.pathname.includes(nav.id) ? "active" : "",
                  ].join(" ")}
                >
                  <span className="pbotmenu_in">{nav.name}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default Footer;
