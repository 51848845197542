import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ mainLayout, header, children, footer }) => {
  return (
    <main className={mainLayout ? "front_body front_body_main" : "front_body"}>
      <div className="page_wrap">
        {header && <Header {...header} />}

        <div className="page_midsection">{children}</div>
        {footer && <Footer />}
      </div>
    </main>
  );
};

export default Layout;
