import OrganizationQueryFactory from "./OrganizationQueryFactory";
import useOrganizationQuery from "./useOrganizationQuery";
import APIInstance from "lib/apis/APIInstance";

const fetchLinkedOrganizations = async () => {
  const { data } = await APIInstance.get("/mydata/property/propertyList");
  return data?.data;
};

const useGetLinkedOrganizations = () => {
  const queryKey = OrganizationQueryFactory.queries.list();
  const query = useOrganizationQuery(
    queryKey,
    async () => await fetchLinkedOrganizations(),
    (data) => data,
    true,
    true,
    true
  );

  return query;
};

export default useGetLinkedOrganizations;
