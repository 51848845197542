import React from "react";
import { useNavigate } from 'react-router-dom';

const Tc_noticeHistory = () => {

    let navigate = useNavigate();

    const users = ([
        {
          id: 1,
          code: '이벤트',
        },
        {
          id: 2,
          code: '이벤트',
        },
        {
          id: 3,
          code: '렌탈',
        },
        {
          id: 4,
          code: '렌탈',
        },
        {
          id: 5,
          code: '렌탈',
        },
        {
          id: 6,
          code: '데이터',
        }
      ]);


    return (
        <div className="front_body type2">
            <div className="page_wrap">
                <header className="page_header">
                    {/* <a href="#!" className="btn_back"><span className="hdtext">뒤로가기</span></a> */}
                    <button className="btn_back" onClick={() => navigate(-1)}><span className="hdtext">뒤로가기</span></button>
                    <h1 className="page_headtitle">알림내역</h1>
                </header>
                <section className="page_midsection dtype4">
                    <p className="pmid_sigcopy">최근 30일동안의 알림 30개가 보관되며 <br />이후 자동으로 삭제됩니다.</p>
                    <ul className="nhis_vlist">
                        {/* <li>
                            <div className="nhis_vitem">
                                <span className="nhis_ico"></span>
                                <div className="nhis_spec_w">
                                    <p className="nhis_spec01"><span className="nhis_kind">렌탈</span>대출신청이 완료되었습니다!</p>
                                    <p className="nhis_spec02">안녕하세요 티칭캐시에서 신청하신 대출신청이 완료었습니다</p>
                                </div>
                                <button type="button" className="btn_nhisclose"><span className="hdtext">삭제</span></button>
                            </div>
                        </li>
                        <li>
                            <div className="nhis_vitem">
                                <span className="nhis_ico ico_02"></span>
                                <div className="nhis_spec_w">
                                    <p className="nhis_spec01"><span className="nhis_kind">이벤트</span>대출신청이 완료되었습니다!</p>
                                    <p className="nhis_spec02">안녕하세요 티칭캐시에서 신청하신 대출신청이 완료었습니다</p>
                                </div>
                                <button type="button" className="btn_nhisclose"><span className="hdtext">삭제</span></button>
                            </div>
                        </li>
                        <li>
                            <div className="nhis_vitem">
                                <span className="nhis_ico ico_03"></span>
                                <div className="nhis_spec_w">
                                    <p className="nhis_spec01"><span className="nhis_kind">데이터</span>대출신청이 완료되었습니다!</p>
                                    <p className="nhis_spec02">안녕하세요 티칭캐시에서 신청하신 대출신청이 완료었습니다</p>
                                </div>
                                <button type="button" className="btn_nhisclose"><span className="hdtext">삭제</span></button>
                            </div>
                        </li> */}
                        <li>
                            
                            {users.map(user =>(
                            <div key={user.id}>

                            <div className="nhis_vitem">
                                <span className="nhis_ico ico_03"></span>
                                <div className="nhis_spec_w">
                                    <div className="nhis_spec01"><span className="nhis_kind">

                                    <p>{user.code}</p>
                                        
                                    </span>대출신청이 완료되었습니다!</div>
                                    <p className="nhis_spec02">안녕하세요 티칭캐시에서 신청하신 대출신청이 완료었습니다</p>
                                </div>
                                <button type="button" className="btn_nhisclose"><span className="hdtext">삭제</span></button>
                            </div>
                            <br />
                            </div>



                            ))}
                        

                        </li>
                    </ul>
                </section>
            </div>
        </div>
    );
}
export default Tc_noticeHistory