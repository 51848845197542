import { PaymentItem } from "features/card";

const MainPayment = (props) => {
  const paymentQuery = props.paymentQuery;
  const payment = paymentQuery?.data;

  return (
    <section className="card_wrap">
      <div className="card_article">
        <div className="card_summary_box">
          <p>결제예정 금액</p>
          <h2>{payment?.totalPayAmt + "원"}</h2>
        </div>
        <div className="billing_summary_article">
          {payment?.paymentDtos?.map((paymentDto, index) => (
            <PaymentItem paymentDto={paymentDto} key={index} />
          ))}
        </div>
        <ul className="card_desc_list">
          <li>결제일이 주말/공휴일인 경우 다음 영업일에 출금됩니다.</li>
        </ul>
      </div>
    </section>
  );
};

export default MainPayment;
