import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import IsLoding from "../../components/IsLoading";
import { commonFetch, jsonFetch, paramFetch } from "apis/common/fetchInstance";
import {
  setDateFormat,
  setBirthHyphen,
  setMobileHyphen,
  confirmMsg,
  printMsg,
} from "utils/utils";
import qs from "qs";
import AgreeView from "../../components/AgreeView";
import CertView from "../../components/CertView";
import { Layout } from "layout";

const MyPage = () => {
  const navigate = useNavigate();

  const token_version_id = useRef(); //nice 인증 창 호출에 필요한 데이터(토큰id)
  const enc_data = useRef(); //nice 인증 창 호출에 필요한 데이터(요청 데이터)
  const integrity_value = useRef(); //nice 인증 창 호출에 필요한 데이터(암호화)

  const [myinfo, setMyinfo] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isAgreeOpen, setIsAgreeOpen] = useState(false);
  const [agreeList, setAgreeList] = useState([]);
  const [isCertOpen, setIsCertOpen] = useState(false);

  const handleAgree = () => {
    setIsAgreeOpen(true);
  };

  useEffect(() => {
    userApiCall();
    agreeApiCall();
  }, []);

  const AgreeClose = () => {
    setIsAgreeOpen(false);
  };

  const nextBtn = () => {
    setIsCertOpen(true);
    setIsAgreeOpen(false);
  };

  const agreeApiCall = async () => {
    // const { response, data } = await commonFetch(
    //   "/api/terms/list/mypage",
    //   "get"
    // );
    // if (response.status === 200) {
    //   let emptyArray = [];
    //   for (let i = 0; i < data.length; i++) {
    //     const tempData = data[i];
    //     emptyArray.push({
    //       id: tempData.no,
    //       code: tempData.code,
    //       title: tempData.title,
    //       content: tempData.content,
    //       importantYn: tempData.importantYn,
    //     });
    //   }
    //   setAgreeList(emptyArray);
    // }
  };

  const userApiCall = async () => {
    const { response, data } = await commonFetch("/api/app/profile", "get");
    if (response.status === 200) {
      setMyinfo(data);
    }
  };

  const successClick = (data) => {
    printMsg("수정되었습니다.");
    setMyinfo({ ...data });
    setIsCertOpen(false);
    setIsAgreeOpen(false);
  };

  const certClose = () => {
    setIsCertOpen(false);
  };

  return (
    <Layout header={{ title: "내 정보" }}>
      {/* <div className="form_copy_z">
                        <p className="sig_form_copy"><strong>주민등록번호 뒷자리</strong>를<br />입력해주세요</p>
                        <p className="sub_form_copy">해당 정보는 금리 비교의 목적으로만 활용됩니다.</p>
                    </div> */}
      <div className="mypage_container">
        <div className="form_veritem_w mypage_form">
          <div className="form_veritem">
            <div className="form_verlablow">
              <p className="form_verlabel">이름</p>
            </div>
            <div className="form_fieldlow">
              <div className="form_inwrap">
                <div className="form_incell_long form_field">
                  <input
                    type="text"
                    className="form_input"
                    defaultValue={myinfo.name}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form_veritem_w mypage_form">
          <div className="form_veritem">
            <div className="form_verlablow">
              <p className="form_verlabel">생년월일</p>
            </div>
            <div className="form_fieldlow">
              <div className="form_inwrap">
                <div className="form_incell_long form_field">
                  <input
                    type="text"
                    className="form_input"
                    defaultValue={setBirthHyphen(myinfo.birth)}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form_veritem_w mypage_form">
          <div className="form_veritem">
            <div className="form_verlablow">
              <p className="form_verlabel">통신사</p>
            </div>
            <div className="form_fieldlow">
              <div className="form_inwrap">
                <div className="form_incell_long form_field">
                  <input
                    type="text"
                    className="form_input"
                    defaultValue={myinfo.agency}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form_veritem_w mypage_form">
          <div className="form_veritem">
            <div className="form_verlablow">
              <p className="form_verlabel">휴대폰번호</p>
            </div>
            <div className="form_fieldlow">
              <div className="form_inwrap">
                <div className="form_incell_long form_field">
                  <input
                    type="text"
                    className="form_input"
                    defaultValue={setMobileHyphen(myinfo.phone)}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="btn_botpos_wrap">
          <div className="botpos_in">
            <button type="button" className="btn_quadbox" onClick={handleAgree}>
              내 정보 수정
            </button>
          </div>
        </div>
      </div>
      <AgreeView
        isOpen={isAgreeOpen}
        AgreeClose={AgreeClose}
        agreeList={agreeList}
        nextBtn={nextBtn}
        title={"정보 수정을 위한 본인 확인"}
      ></AgreeView>

      {isCertOpen ? (
        <CertView
          isOpen={isCertOpen}
          myInfo={myinfo}
          successClick={successClick}
          certClose={certClose}
        ></CertView>
      ) : null}
    </Layout>
  );
};
export default MyPage;
