import React from "react";

const PaymentHistorySkeleton = () => {
  return (
    <div className="payment_container">
      <section className="payment_wrap">
        <div className="payment_search_wrap">
          <p className="skeleton skeleton_subtitle"></p>
          <p className="skeleton skeleton_subtitle"></p>
        </div>

        <div className="payment_summary_box">
          <div className="payment_summary">
            <p className="skeleton skeleton_subtitle"></p>
            <p className="skeleton skeleton_subtitle"></p>
          </div>
        </div>
      </section>
      <section className="payment_history_wrap">
        <ul className="payment_history_list">
          <li className="payment_history_item">
            <div className="payment_item_header">
              <div className="skeleton skeleton_card_price "></div>
              <div className="skeleton skeleton_card_price "></div>
            </div>
            <div className="payment_item_body">
              <ul className="payment_list">
                <li className="payment_list_item">
                  <div className="payment_item_article">
                    <div className="skeleton skeleton_card_img"></div>
                    <div className="payment_info">
                      <div className="payment_info_row">
                        <p className="skeleton skeleton_subtitle"></p>
                        <div className="skeleton skeleton_card_price "></div>
                      </div>
                      <div
                        className="payment_info_row info_desc"
                        style={{ marginTop: "6px" }}
                      >
                        <p className="skeleton skeleton_subtitle"></p>
                        <div className="skeleton skeleton_card_price "></div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="payment_list_item">
                  <div className="payment_item_article">
                    <div className="skeleton skeleton_card_img"></div>
                    <div className="payment_info">
                      <div className="payment_info_row">
                        <p className="skeleton skeleton_subtitle"></p>
                        <div className="skeleton skeleton_card_price "></div>
                      </div>
                      <div
                        className="payment_info_row info_desc"
                        style={{ marginTop: "6px" }}
                      >
                        <p className="skeleton skeleton_subtitle"></p>
                        <div className="skeleton skeleton_card_price "></div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="payment_list_item">
                  <div className="payment_item_article">
                    <div className="skeleton skeleton_card_img"></div>
                    <div className="payment_info">
                      <div className="payment_info_row">
                        <p className="skeleton skeleton_subtitle"></p>
                        <div className="skeleton skeleton_card_price "></div>
                      </div>
                      <div
                        className="payment_info_row info_desc"
                        style={{ marginTop: "6px" }}
                      >
                        <p className="skeleton skeleton_subtitle"></p>
                        <div className="skeleton skeleton_card_price "></div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li className="payment_history_item">
            <div className="payment_item_header">
              <div className="skeleton skeleton_card_price "></div>
              <div className="skeleton skeleton_card_price "></div>
            </div>
            <div className="payment_item_body">
              <ul className="payment_list">
                <li className="payment_list_item">
                  <div className="payment_item_article">
                    <div className="skeleton skeleton_card_img"></div>
                    <div className="payment_info">
                      <div className="payment_info_row">
                        <p className="skeleton skeleton_subtitle"></p>
                        <div className="skeleton skeleton_card_price "></div>
                      </div>
                      <div
                        className="payment_info_row info_desc"
                        style={{ marginTop: "6px" }}
                      >
                        <p className="skeleton skeleton_subtitle"></p>
                        <div className="skeleton skeleton_card_price "></div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="payment_list_item">
                  <div className="payment_item_article">
                    <div className="skeleton skeleton_card_img"></div>
                    <div className="payment_info">
                      <div className="payment_info_row">
                        <p className="skeleton skeleton_subtitle"></p>
                        <div className="skeleton skeleton_card_price "></div>
                      </div>
                      <div
                        className="payment_info_row info_desc"
                        style={{ marginTop: "6px" }}
                      >
                        <p className="skeleton skeleton_subtitle"></p>
                        <div className="skeleton skeleton_card_price "></div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="payment_list_item">
                  <div className="payment_item_article">
                    <div className="skeleton skeleton_card_img"></div>
                    <div className="payment_info">
                      <div className="payment_info_row">
                        <p className="skeleton skeleton_subtitle"></p>
                        <div className="skeleton skeleton_card_price "></div>
                      </div>
                      <div
                        className="payment_info_row info_desc"
                        style={{ marginTop: "6px" }}
                      >
                        <p className="skeleton skeleton_subtitle"></p>
                        <div className="skeleton skeleton_card_price "></div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default PaymentHistorySkeleton;
