import { Suspense } from "react";
import { HomeContainer, HomeSkeleton } from "containers/Home";

const HomePage = () => {
  return (
    <Suspense fallback={<HomeSkeleton />}>
      <HomeContainer />
    </Suspense>
  );
};
export default HomePage;
