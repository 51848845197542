import { Layout } from "layout";
import { useGetTransferHistoryDetails } from "features/transferHistory";
import { useParam } from "lib/utils/useRouter";

const TransferHistoryDetail = () => {
  const params = useParam();
  const { id, no, hisDttm } = params.getPathParameter();
  const transferHistoryDetailsQuery = useGetTransferHistoryDetails(
    id,
    no,
    hisDttm
  );
  const transDetail = transferHistoryDetailsQuery?.data;

  return (
    <Layout header={{ title: transDetail && transDetail.transferHistoryTitle }}>
      <div className="asset_manage_wrap">
        <div className="asset_info_contents">
          <ul className="asset_info_list">
            <li>
              <strong>일시</strong>
              <span>{transDetail && transDetail.regDttm.substring(0, 10)}</span>
            </li>
            <li>
              <strong>구분</strong>
              {transDetail && transDetail.mdatTrnfReqStatCd === "01" ? (
                <span>동의</span>
              ) : null}
              {transDetail && transDetail.mdatTrnfReqStatCd === "02" ? (
                <span>연장</span>
              ) : null}
              {transDetail && transDetail.mdatTrnfReqStatCd === "03" ? (
                <span>만료</span>
              ) : null}
              {transDetail && transDetail.mdatTrnfReqStatCd === "04" ? (
                <span>철회</span>
              ) : null}
            </li>
            <li>
              <strong>데이터를 제공하는 자</strong>
              <span>{transDetail && "뱅큐"}</span>
            </li>
            <li>
              <strong>전송요구를 받는 자</strong>
              <span>{transDetail && transDetail.orgNm}</span>
            </li>
            <li>
              <strong>정기전송여부</strong>
              <span>
                {transDetail && transDetail.mdatFxprTrnfYn === "Y"
                  ? "동의(주 1회)"
                  : "미동의"}
              </span>
            </li>
            <li>
              <strong>전송요구기간</strong>
              <span>
                {transDetail &&
                  [
                    transDetail.mdatTrnfReqSrtDt.substring(0, 4),
                    transDetail.mdatTrnfReqSrtDt.substring(4, 6),
                    transDetail.mdatTrnfReqSrtDt.substring(6, 8),
                  ].join(".") +
                    " ~ " +
                    [
                      transDetail.mdatTrnfReqEndDt.substring(0, 4),
                      transDetail.mdatTrnfReqEndDt.substring(4, 6),
                      transDetail.mdatTrnfReqEndDt.substring(6, 8),
                    ].join(".")}
              </span>
            </li>
            <li>
              <strong>전송을 요구하는 목적</strong>
              <span>{transDetail && transDetail.crtReqObjCn}</span>
            </li>
            <li>
              <strong>전송을 요구하는 개인신용정보</strong>

              <span>
                {transDetail && transDetail.aphAthrCatgCn.includes("card.card")
                  ? "카드목록정보, 카드정보"
                  : null}
                {transDetail && transDetail.aphAthrCatgCn.includes("card.point")
                  ? ", 포인트정보"
                  : null}
                {transDetail && transDetail.aphAthrCatgCn.includes("card.bill")
                  ? ", 청구/리볼빙 정보"
                  : null}
              </span>
            </li>
            <li>
              <strong>전송을 요구하는 개인신용정보의 보유기간</strong>
              <span>
                {transDetail && "서비스 이용 종료 시 또는 삭제요구시까지"}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default TransferHistoryDetail;
