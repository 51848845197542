import TestImg from "assets/images/bankq/finance/우리은행.png";
import { useRouter } from "lib/utils/useRouter";

const PaymentWrap = (props) => {
  const useRoute = useRouter();
  const paymentInMainQuery = props.paymentInMainQuery;
  const filteredOrg = props.filteredOrg;
  const paymentData = paymentInMainQuery?.data;

  return (
    <div className="main_box asset_box">
      <div className="asset_title">
        <span>결제예정금액</span>
        <p
          className="link"
          onClick={() => {
            useRoute.routeTo("/card");
          }}
        >
          {paymentData?.totalPayAmt + "원"}
        </p>
      </div>
      <div className="main_asset_contents">
        <ul className="main_asset_list">
          {paymentData?.paymentList?.map((payment, index) => {
            return (
              <li className="main_asset_item" key={index}>
                <img src={payment.orgImg} alt={payment.orgName} />
                {/* <img src={TestImg}></img> */}
                <p>
                  {payment.orgName}

                  {filteredOrg?.filter(
                    (linked) => linked.orgCode === "DUMMYCARD"
                  ).length > 0 ? (
                    <span
                      style={{
                        color: "#666",
                        fontSize: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      연결 해제된 기관
                    </span>
                  ) : (
                    ""
                  )}
                </p>
                <span>{payment.payAmtFormat + "원"}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default PaymentWrap;
