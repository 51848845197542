import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { confirmMsg, printMsg } from "utils/utils";
import commonApi from "apis/common/commonApi";
import { getCookies } from "utils/Cookie";
import { commonFetch, jsonFetch, paramFetch } from "apis/common/fetchInstance";

import BottomAlert from "../../components/BottomAlert";
import { detect } from "detect-browser";
import useDeviceInfo from "lib/utils/useDevice";

const browser = detect();

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [numPad, setNumPad] = useState([]); //숫자 패드
  const [pwdText, setPwdText] = useState([]); //패스워드 1자리씩 저장
  const [password, setPassword] = useState(""); //패스워드
  const [pwdChk, setPwdChk] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [failCnt, setFailCnt] = useState();
  const [isFailAlert, setIsFailAlert] = useState(false);
  const [alertText, setAlertText] = useState([]);
  const getIdentifier = useDeviceInfo();

  useEffect(() => {
    if (pwdText.length === 6) {
      handleSetPwd();
    }
  }, [pwdText]);

  useEffect(() => {
    randomNumber();
  }, []);

  const randomNumber = (array) => {
    const numArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

    setNumPad(numArr.sort(() => Math.random() - 0.5)); //숫자 패드 섞기
  };

  const onClickNumPad = (num) => {
    setPwdText(pwdText.concat(num));
  };

  const handleSetPwd = () => {
    setPassword(pwdText.join(""));
    setPwdChk(true);
  };

  const handleSetClear = () => {
    if (pwdText.length > 0) {
      const backNum = pwdText.slice(0, pwdText.length - 1);
      setPwdText(backNum);
    }
  };

  const handlePwdFind = () => {
    confirmMsg(
      "본인인증 후 비밀번호를 변경해주세요",
      "취소",
      "본인인증",
      yesClick
    );
  };

  const yesClick = () => {
    navigate("/reset");
  };

  const NumDot = () => {
    return (
      <>
        <span
          className={"pfx_dot " + (pwdText[0] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[1] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[2] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[3] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[4] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[5] === undefined ? null : "active")}
        ></span>
      </>
    );
  };

  const NumberPad = () => {
    return (
      <div className="pfx_numkeyboard">
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[0])}
        >
          {numPad[0]}
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[1])}
        >
          {numPad[1]}
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[2])}
        >
          {numPad[2]}
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[3])}
        >
          {numPad[3]}
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[4])}
        >
          {numPad[4]}
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[5])}
        >
          {numPad[5]}
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[6])}
        >
          {numPad[6]}
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[7])}
        >
          {numPad[7]}
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[8])}
        >
          {numPad[8]}
        </button>
        <button
          type="button"
          className="pnum_key define_text"
          onClick={randomNumber}
        >
          재배열
        </button>
        <button
          type="button"
          className="pnum_key define_num"
          onClick={() => onClickNumPad(numPad[9])}
        >
          {numPad[9]}
        </button>
        <button
          type="button"
          className="pnum_key define_ico ico_back"
          onClick={handleSetClear}
        >
          <span className="hdtext">뒤로가기</span>
        </button>
      </div>
    );
  };

  useEffect(() => {
    loginApi();
  }, [pwdChk]);

  useEffect(() => {
    // @TODO 나중에 바꿈
    // const getIdentifier = window.Android.getIdentifier();
      if(localStorage.getItem("google_test") !==null){
          setIdentifier("bankq_test")
          return;
      }

    if (getIdentifier !== null || getIdentifier !== undefined) {
      localStorage.setItem("identifier", getIdentifier);
      setIdentifier(getIdentifier);
    }
  }, []);

  const loginApi = async () => {
    if (pwdChk) {
      const params = {
        password: password,
        identifier: identifier,
      };

      const { response, data } = await commonFetch(
        "/api/auth/login",
        "post",
        params
      );

      if (response.status === 200) {
        if (data.failCount >= 5) {
          setPwdChk(!pwdChk);
          setIsFailAlert(true);
          setPwdText([]);
        } else {
          sessionStorage.setItem("user", data.userName);
          localStorage.setItem("ref_token", data.refreshToken);
          localStorage.setItem("acc_token", data.accessToken);
          navigate("/home");
        }
      } else {
        setFailCnt(data);
        setPwdText([]);
        setPwdChk(!pwdChk);
        setIsFailAlert(true);
      }
    }
  };

  useEffect(() => {
    if (failCnt === 5) {
      let fail_array = [];
      fail_array.push("비밀번호 틀린 횟수가 5회 초과되어");
      fail_array.push("간편비밀번호 재설정이 필요합니다.");
      setAlertText(fail_array);
    }
  }, [failCnt]);

  const bottomClose = () => {
    setIsFailAlert(!isFailAlert);
  };

  return (
    <div className="front_body">
      <div className="page_wrap has_botpos">
        <header className="page_header"></header>
        <section className="page_fxsection">
          <div className="pfx_top">
            <div className="pfx_copy">
              {/* <img src={splash_logo} width="46%" /> */}
              <h2 className="pfx_ctitle">비밀번호 입력</h2>
              {failCnt > 0 ? (
                <p className="pfx_csub" style={{ color: "red" }}>
                  비밀번호가 일치하지 않습니다.({failCnt}/5)
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="pfx_dot_w">
              <NumDot></NumDot>
            </div>
            <div className="pfx_copy">
              <p
                className="pfx_csub"
                onClick={handlePwdFind}
                style={{
                  fontSize: "15px",
                  color: "black",
                  textDecoration: "underline",
                }}
              >
                비밀번호를 잊으셨나요?
              </p>
            </div>
            <div className="pfx_orgin_input_w">
              <input type="text" className="pfx_origin_input" />
            </div>
          </div>
          <div className="pfx_bottom">
            <NumberPad />
          </div>
          {failCnt === 5 ? (
            <BottomAlert
              isOpen={isFailAlert}
              bottomClose={bottomClose}
              alertText={alertText}
              status="fail"
              yesClick={yesClick}
            />
          ) : null}
        </section>
      </div>
    </div>
  );
};
export default LoginPage;
