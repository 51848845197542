import APIInstance from "lib/apis/APIInstance";
import useMyDataQuery from "./mydataQuery";
import MydataQueryFactory from "./MydataQueryFactory";

const fetchGetOrganization = async () => {
  const { data } = await APIInstance.get("/mydata/organization");
  return data.data;
};

const useGetOrganization = () => {
  const queryKey = MydataQueryFactory.queries.getOranizations();
  const query = useMyDataQuery(queryKey, fetchGetOrganization, (data) => data);

  return query;
};

export { useGetOrganization };
