import { Suspense } from "react";
import { Layout, Footer } from "layout";
import {
  PaymentDetailsContainer,
  PaymentDetailsSkeleton,
} from "containers/payment";
import "assets/css/payment.scss";

const PaymentDetails = () => {
  return (
    <Layout header={{ title: "상세 내역" }}>
      <Suspense fallback={<PaymentDetailsSkeleton />}>
        <PaymentDetailsContainer />
      </Suspense>
    </Layout>
  );
};

export default PaymentDetails;
