import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { commonFetch } from "apis/common/fetchInstance";
import { Layout } from "layout";
import * as Terms from "containers/Terms";

const components = {
  SERVICE: Terms.Service,
  MYDATA: Terms.MydataService,
  PRIVACY: Terms.Privacy,
  PRIVACY_COLLECT: Terms.PrivacyCollect,
};

const TermsDetail = () => {
  const { id } = useParams();
  const [terms, setTerms] = useState({});
  const [component, setComponent] = useState(null);

  useEffect(() => {
    fetchTerms(id);
  }, [id]);

  const fetchTerms = async (id) => {
    const { response, data } = await commonFetch(`/api/terms/${id}`, "GET");
    if (response.status === 200) {
      setTerms(data);
    }
  };

  useEffect(() => {
    if (Object.keys(terms).length > 0) {
      const SpecificComponent = components[terms.subType];
      setComponent(<SpecificComponent url={terms.url} />);
    }
  }, [terms]);

  return (
    <Layout header={{ title: terms.title }}>
      <div className="asset_manage_wrap">
        <div
          className="asset_info_contents"
          dangerouslySetInnerHTML={{
            __html: terms.content,
          }}
        ></div>
      </div>
    </Layout>
  );
};

export default TermsDetail;
