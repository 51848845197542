import axiosInstance from "./AxiosInstance";

const APIInstance = {
  get: (url, config) => {
    return axiosInstance.get(url, {
      signal: config?.signal,
      params: config?.params,
    });
  },
  post: (url, data, config) => {
    return axiosInstance.post(url, data, {
      signal: config?.signal,
    });
  },
  delete: (url, data, config) => {
    return axiosInstance.delete(url, data, {
      signal: config?.signal,
    });
  },
};

export default APIInstance;
