import { Layout } from "layout";
import { TransferHistoryContainer } from "containers/TransferHistory";
import "assets/css/transfer_history.scss";

const TransferHistory = () => {
  return (
    <Layout header={{ title: "전송내역" }}>
      <TransferHistoryContainer />
    </Layout>
  );
};

export default TransferHistory;
