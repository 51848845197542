import { useCustomQuery } from "lib/apis/QueryClient";

export default function useMyDataQuery(
  queryKey,
  queryFn,
  selectCallbackFn,
  enabled,
  suspense
) {
  // @TODO 옵션 추가 예정
  const options = {
    useErrorBoundary: false,
    refetchOnMount: false,
    gcTime: 0,
    select: selectCallbackFn,
    enabled: enabled === undefined ? true : enabled,
    suspense: suspense === undefined ? false : suspense,
  };

  return useCustomQuery(queryKey, queryFn, options);
}
