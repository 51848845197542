import React from "react";

const CertTerms = (props) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<iframe src="${props?.url}" class="iframe_border" frameborder="0" ></iframe>`,
      }}
    ></div>
  );
};

export default CertTerms;
