import { Suspense } from "react";
import { Layout } from "layout";
import {
  BillingDetailsContainer,
  BillingDetailsSkeleton,
} from "containers/billing";

const BillingDetails = () => {
  return (
    <Layout header={{ title: "상세 내역" }}>
      <Suspense fallback={<BillingDetailsSkeleton />}>
        <BillingDetailsContainer />
      </Suspense>
    </Layout>
  );
};

export default BillingDetails;
