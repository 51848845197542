import PaymentWrap from "./PaymentWrap";
import CardWrap from "./CardWrap";
import { useGetPaymentInMain } from "features/payment";
import { useGetCardInMain } from "features/card";
import { useGetLinkedOrganizations } from "features/organization";
import { useEffect, useState } from "react";

const MydataHomeContent = (props) => {
  const paymentInMainQuery = useGetPaymentInMain();
  const cardInMainQuery = useGetCardInMain();
  const linkedOrganizationsQuery = useGetLinkedOrganizations();
  const [filteredOrg, setFilteredOrg] = useState([]);

  useEffect(() => {
    setFilteredOrg(
      linkedOrganizationsQuery?.data?.filter(
        (org) => org.transStatusCode === "04"
      )
    );
  }, [linkedOrganizationsQuery?.data]);

  return (
    <>
      <CardWrap cardInMainQuery={cardInMainQuery} filteredOrg={filteredOrg} />
      <PaymentWrap
        paymentInMainQuery={paymentInMainQuery}
        filteredOrg={filteredOrg}
      />
    </>
  );
};

export default MydataHomeContent;
