import React from "react";
import Modal from "components/Modal/Modal";

const PeriodSelectPopup = (props) => {
  return (
    <Modal onClose={props.onClose} bottomModal noClose title="유효기간">
      <ul>
        {props.bottomData.map((item) => (
          <li className="period_list" key={item.id}>
            <input
              type="radio"
              id={item.value}
              value={item.value}
              className="period_radio"
              name={props.name}
              onChange={props.onChange}
              checked={item.value === props.selectItem.value ? "checked" : ""}
            />
            <label htmlFor={item.value} className="period_radio_label">
              {item.title}
            </label>
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export default PeriodSelectPopup;
