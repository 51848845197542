import React from "react";

const BillingDetailsSkeleton = () => {
  return (
    <div className="payment_container">
      <section className="payment_wrap">
        <div className="product_summary_article">
          <div className="product_name">
            <p className="skeleton skeleton_subtitle"></p>
          </div>
          <p className="skeleton skeleton_approved_price"></p>
        </div>
      </section>
      <section className="payment_wrap">
        <div className="payment_detail_article">
          <ul className="payment_detail_table">
            <li>
              <p className="skeleton skeleton_subtitle "></p>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
            <li>
              <p className="skeleton skeleton_subtitle "></p>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
            <li>
              <p className="skeleton skeleton_subtitle "></p>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
            <li>
              <p className="skeleton skeleton_subtitle "></p>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
            <li>
              <p className="skeleton skeleton_subtitle "></p>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
            <li>
              <p className="skeleton skeleton_subtitle "></p>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
            <li>
              <p className="skeleton skeleton_subtitle "></p>
              <p className="skeleton skeleton_subtitle"></p>
            </li>

            <li>
              <p className="skeleton skeleton_subtitle "></p>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default BillingDetailsSkeleton;
