import { startTransition } from "react";

const PaymentSummary = (props) => {
  const paymentByDateQuery = props.paymentByDate;
  const paymentByDate = paymentByDateQuery?.data;

  return (
    <section className="card_wrap">
      <div className="billing_org_article">
        <div className="billing_org_header">
          <div>
            <div className="billing_org_img">
              <img src={paymentByDate?.orgImg} alt={paymentByDate.orgName} />
            </div>

            <p>{paymentByDate.orgName}</p>
          </div>
        </div>
        <div className="billing_org_body">
          <div className="charge_info">
            <p>{paymentByDate.payDuDateYm} 결제예정금액</p>
            <h2>{paymentByDate.payAmt + "원"}</h2>
          </div>
          <div className="charge_desc_list">
            <ul className="table_list_article">
              <li>
                <ul className="charge_desc_item_list">
                  <li>
                    <div className="charge_desc_box">
                      <span>결제일</span>
                      <p>{paymentByDate.payDueDateFormat}</p>
                    </div>
                  </li>
                  <li>
                    <div className="charge_desc_box">
                      <span>결제은행</span>
                      <p>{paymentByDate.linkedBankName}</p>
                    </div>
                  </li>
                  <li>
                    <div className="charge_desc_box">
                      <span>계좌번호</span>
                      <p>{paymentByDate.accountNum}</p>
                    </div>
                  </li>
                  {paymentByDate.revolvingYn === "Y" ? (
                    <li style={{ textAlign: "right" }}>
                      <div
                        className="has_revolving has_link"
                        onClick={() => {
                          startTransition(() => {
                            props.setIsOpenRevolving(true);
                          });
                        }}
                      >
                        <p>리볼빙 정보</p>
                      </div>
                    </li>
                  ) : null}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentSummary;
