import { React, useEffect, useRef, useState } from "react";
import $ from "jquery";
import { printMsg, successMsg } from "../utils/utils";
import BottomSelect from "./BottomSelect";
import { commonFetch } from "../apis/common/fetchInstance";

const CertView = ({ myInfo, isOpen, successClick, certClose }) => {
  const [min, setMin] = useState(3); //타이머 분
  const [sec, setSec] = useState(0); //타이머 초
  const time = useRef(180); //타이머 전체 시간(초)
  const timerId = useRef(null); //setInterval 변수
  const [certKey, setCertKey] = useState(""); //인증번호 저장
  const certInput = useRef(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [certCount, setCertCount] = useState(0);
  const [isReset, setIsReset] = useState(false);
  const [initReset, setInitReset] = useState(true); //재요청 버튼 20초 후 활성화
  const [isTimeOut, setIsTimeOut] = useState(false);

  const nameInput = useRef();
  const frontInput = useRef();
  const genderInput = useRef();
  const mobileInput = useRef();
  const mobileNoInput = useRef();
  const [genderFlag, setGenderFlag] = useState(false);
  const [mobileFlag, setMobileFlag] = useState(false);

  const [isAnimation, setIsAnimation] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const [userName, setUserName] = useState(""); //사용자 이름 입력
  const [frontJumin, setFrontJumin] = useState(""); //주민번호 앞자리
  const [backJumin, setBackJumin] = useState(""); //주민번호 뒷자리(1자리)
  const [mobile, setMobile] = useState(""); //통신사 종류
  const [mobileValue, setMobileValue] = useState(""); //통신사 값 (01, 02, 03, 04 ,05 ,06)
  const [phoneNumber, setPhoneNumber] = useState(""); //휴대폰 번호
  const [phoneAuth, setPhoneAuth] = useState(""); //휴대폰 인증번호
  const [isAutClick, setIsAutClick] = useState(false); //인증번호 받기 버튼 클릭 여부
  const [isAgreeOpen, setIsAgreeOpen] = useState(false); //약관동의 리스트 오픈
  const [isBottomOpen, setIsBottomOpen] = useState(false); //통신사 리스트 오픈
  const [btnCheck, setBtnCheck] = useState(false); //약관동의 버튼 활성화 체크
  const [identifier, setIdentifier] = useState("");
  const [os, setOs] = useState("");
  const [isAuthCompl, setIsAuthCompl] = useState(false);
  const [totalAut, setTotalAut] = useState(false);
  const [isExistence, setIsExistence] = useState(false); //회원가입 중복 여부
  const [updateMobileNo, setUpdateMobileNo] = useState("");
  const [updateMobileCa, setUpdateMobileCa] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [isAutBtn, setIsAutBtn] = useState(true);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [nameVali, setNameVali] = useState(false);
  const [nameErrMsg, setNameErrMsg] = useState("");
  const [frontJuminVali, setFrontJuminVali] = useState(false);
  const [juminErrMsg, setJuminErrMsg] = useState("");
  const [backJuminVali, setBackJuminVali] = useState(false);
  const [mobileVali, setMobileVali] = useState(false);
  const [mobileErrMsg, setMobileErrMsg] = useState("");
  const [phoneVali, setPhoneVali] = useState(false);
  const [phoneErrMsg, setPhoneErrMsg] = useState("");
  const [tx_seq_no, setTx_seq_no] = useState("");
  const [isFocus, setIsFocus] = useState(false);

  const [agreeList, setAgreeList] = useState([]);

  const [bottomData, setBottomData] = useState([
    {
      id: 1,
      code: "skt",
      val: "01",
      nm: "SKT",
    },
    {
      id: 2,
      code: "kt",
      val: "02",
      nm: "KT",
    },
    {
      id: 3,
      code: "lg",
      val: "03",
      nm: "LG U+",
    },
    {
      id: 4,
      code: "skt_save",
      val: "04",
      nm: "SKT 알뜰폰",
    },
    {
      id: 5,
      code: "kt_save",
      val: "05",
      nm: "KT 알뜰폰",
    },
    {
      id: 6,
      code: "lg_save",
      val: "06",
      nm: "LG U+ 알뜰폰",
    },
  ]);

  useEffect(() => {
    const birth = myInfo.birth.substring(2);
    setUserName(myInfo.name);
    setFrontJumin(birth);
    setNameVali(true);
    setFrontJuminVali(true);
    genderInput.current.focus();
    $(".ju2").addClass("active");
  }, []);

  useEffect(() => {
    if (isOpen) {
      setAnimationClass("mount2");
      setIsAnimation(true);
    } else {
      setAnimationClass("unmount");
      setTimeout(() => {
        setIsAnimation(false);
      }, 100);
    }
  }, [isOpen]);

  useEffect(() => {
    if (step1) {
      frontInput.current.focus();
    }
  }, [step1]);

  useEffect(() => {
    if (genderFlag) {
      genderInput.current.focus();
    }
  }, [genderFlag]);

  useEffect(() => {
    if (mobileFlag) {
      setStep2(true);
      setIsBottomOpen(true);
      genderInput.current.blur();
    }
  }, [mobileFlag]);

  useEffect(() => {
    if (mobile !== "") {
      setMobileVali(true);
      setMobileErrMsg("");
      setTimeout(() => {
        mobileNoInput.current.focus();
      }, 200);
    } else {
      setMobileErrMsg("통신사를 선택해주세요");
      setMobileVali(false);
    }
  }, [mobile]);

  const handleBottomSelect = () => {
    setIsBottomOpen(true);
  };

  const handelSetAgree = () => {
    //입력창 유효성 추가
    if (!userName) {
      printMsg("이름을 입력해주세요");
      setIsAgreeOpen(false);
    } else if (!frontJumin || !backJumin) {
      printMsg("주민등록번호를 입력해주세요");
      setIsAgreeOpen(false);
    } else {
      //위에다가 휴대폰 인증이 안된 내용에 대해서 구현이 필요

      setIsAgreeOpen(true);
    }
  };

  //이름 값 설정
  const handleSetName = (e) => {
    const name = e.target.value;
    const regex = /[^가-힣a-zA-Z]/g;

    if (name.length === 0 || regex.test(name)) {
      setNameVali(false);
    } else {
      setNameVali(true);
    }

    setUserName(name.replace(/[0-9.,(\/..*#!`@%^$&-=+/>?\s)]/g, ""));
  };

  //주민번호 앞자리 설정
  const handleSetForntJumin = (e) => {
    let juminText = e.target.value;

    if (juminText > e.target.maxLength) {
      juminText = juminText.slice(0, e.target.maxLength);
    }
    if (juminText.length === e.target.maxLength) {
      setFrontJuminVali(true);
      setGenderFlag(true);
    } else {
      setFrontJuminVali(false);
      setGenderFlag(false);
    }

    setFrontJumin(juminText.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")); //숫자만 입력 정규식
  };

  //주민번호 뒷자리 설정
  const handleSetBackJumin = (e) => {
    let BackJuminText = e.target.value;
    const regex = /[1-4.]/g;

    if (BackJuminText > e.target.maxLength) {
      BackJuminText = BackJuminText.slice(0, e.target.maxLength);
    }

    if (
      BackJuminText.length === e.target.maxLength &&
      regex.test(BackJuminText)
    ) {
      setBackJuminVali(true);
      setMobileFlag(true);
    } else {
      setBackJuminVali(false);
      setMobileFlag(false);
    }

    setBackJumin(
      BackJuminText.replace(/[^1-4.]/g, "").replace(/(\..*)\./g, "$1")
    ); //숫자만 입력 정규식
  };

  const handleSetPhone = (e) => {
    let phoneText = e.target.value;

    if (phoneText > e.target.maxLength) {
      setStep4(true);
      phoneText = phoneText.slice(0, e.target.maxLength);
    }

    if (phoneText.length === e.target.maxLength) {
      setPhoneVali(true);
      setStep4(true);
    } else {
      setPhoneVali(false);
      setStep4(false);
    }

    setPhoneNumber(
      phoneText.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
    ); //숫자만 입력 정규식
  };

  const bottomClose = () => {
    setIsBottomOpen(!isBottomOpen);
  };

  const handleReset = () => {
    if (isReset) {
      setErrorMsg("재요청 횟수를 초과 했습니다.");
      return;
    }
    handleCert();
  };

  //인증번호 저장 함수
  const handleSetKey = (e) => {
    setCertKey(e.target.value);
  };

  //인증번호 글자수 제한 (6자리)
  const maxLengthCheck = (e) => {
    if (e.target.value.length > e.target.maxLength)
      e.target.value = e.target.value.slice(0, e.target.maxLength);
  };

  const handleCert = async () => {
    let params = {
      name: userName,
      birthday: frontJumin + backJumin,
      gender: backJumin,
      mobile: mobileValue,
      phone: phoneNumber,
      rqst_caus_cd: "02",
      sms_resend_yn: "N",
    };

    const { response, data } = await commonFetch(
      "/api/auth/verification",
      "post",
      params
    );
    if (response.status === 200) {
      setTx_seq_no(data.tx_SEQ_NO);
      setCertCount((prev) => prev + 1);
      setIsAutClick(true);
    } else {
      printMsg(response.message);
    }
  };

  //인증번호 전송 함수
  const handleSendSms = async () => {
    if (isTimeOut) {
      setErrorMsg("인증시간이 만료되었습니다. 재요청 해주세요");
      return;
    }

    let params = {
      tx_seq_no: tx_seq_no,
      phone: phoneNumber,
      otp_no: certKey,
    };

    const { response, data } = await commonFetch(
      "/api/app/update",
      "put",
      params
    );
    if (response.status === 200) {
      successClick(data);
    } else {
      if (response.code === "ERROR_OTPNO_NOT_EQUALS") {
        setErrorMsg(response.message);
        setCertKey("");
        return;
      }
      setCertKey("");
      setErrorMsg(response.message + " 재요청 해주세요");
    }
  };

  //인증번호 6자리 입력 시 동작
  useEffect(() => {
    if (certKey.length === 6) {
      handleSendSms();
    }
  }, [certKey]);

  useEffect(() => {
    if (certCount <= 6 && isAutClick) {
      if (certCount === 6) {
        setIsReset(true);
        setErrorMsg("재요청 횟수를 초과 했습니다.");
      }
      timerId.current = setInterval(() => {
        setMin(parseInt(time.current / 60));
        setSec(time.current % 60);
        time.current -= 1;
      }, 1000);
    }

    return () => clearInterval(timerId.current);
  }, [certCount]);

  //타이머 시간 체크
  useEffect(() => {
    if (time.current <= 159) {
      setInitReset(false);
    }

    if (time.current === -1) {
      setErrorMsg("인증시간이 만료되었습니다. 재요청 해주세요");
      setIsTimeOut(true);
      clearInterval(timerId.current);
    }
  }, [sec]);

  const handleCertFocus = () => {
    $(".cert").addClass("active");
    $(".btn_botpos_wrap").addClass("click");
    setIsFocus(true);
  };

  const handleCertBlur = () => {
    $(".cert").removeClass("active");
    $(".btn_botpos_wrap").removeClass("click");
    setIsFocus(false);
  };

  return (
    <div
      className={
        isAnimation
          ? `dimlayer_z define_bot active motion ${animationClass}`
          : `dimlayer_z define_bot ${animationClass}`
      }
      id="option_layer_tele"
    >
      <div
        className="botpos_vlayer"
        style={{ height: "100%", maxHeight: "100%", borderRadius: "0px" }}
      >
        <div
          className={isBottomOpen ? "bg_layout" : null}
          onClick={bottomClose}
        ></div>
        <div className="botpos_vtitlow" style={{ textAlign: "center" }}>
          <p className="botpos_vtit">본인인증</p>
        </div>
        <div className="botpos_vcontlow">
          <section
            className="page_midsection"
            style={{ marginBottom: isFocus ? "10rem" : null }}
          >
            <div className="form_veritem_w">
              <div className="form_veritem" style={{ marginBottom: "1.8rem" }}>
                <div className="form_verlablow">
                  <p className="form_verlabel">이름</p>
                </div>
                <div className="form_fieldlow">
                  <div className="field_multi_fxwrap form_field na">
                    <input
                      type="text"
                      className="form_input"
                      placeholder="이름"
                      onChange={handleSetName}
                      disabled={isAutClick}
                      value={userName}
                      ref={nameInput}
                      onFocus={() => {
                        $(".na").addClass("active");
                      }}
                      onBlur={() => {
                        $(".na").removeClass("active");
                        if (!nameVali) {
                          setNameErrMsg("정확한 이름을 입력해주세요");
                        } else {
                          setNameErrMsg("");
                        }
                      }}
                    />
                  </div>
                  <span className="form_vali_msg">{nameErrMsg}</span>
                </div>
              </div>
              <div
                className="form_veritem form_jumin"
                style={{ marginBottom: "1.8rem" }}
              >
                <div className="form_verlablow">
                  <p className="form_verlabel">주민등록번호</p>
                </div>
                <div className="form_fieldlow">
                  <div className="form_inwrap">
                    <div className="form_incell_long form_field ju">
                      <input
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        min="0"
                        disabled={isAutClick}
                        className="form_input"
                        ref={frontInput}
                        placeholder="주민등록번호"
                        value={frontJumin}
                        onChange={handleSetForntJumin}
                        maxLength="6"
                        onFocus={() => {
                          $(".ju").addClass("active");
                          $(".btn_botpos_wrap").addClass("click");
                        }}
                        onBlur={() => {
                          $(".ju").removeClass("active");
                          $(".btn_botpos_wrap").removeClass("click");
                          if (!frontJuminVali) {
                            setJuminErrMsg(
                              "올바른 주민등록번호를 입력해주세요"
                            );
                          } else {
                            setJuminErrMsg("");
                          }
                        }}
                      />
                    </div>
                    <div className="form_incell_short">
                      <span className="form_inelse">-</span>
                    </div>
                    <div className="form_incell_long etri_text">
                      <div className="form_field ju2">
                        <input
                          type="number"
                          pattern="[0-9]*"
                          inputMode="numeric"
                          min="0"
                          disabled={isAutClick}
                          className="form_input ftype2"
                          placeholder="1자리"
                          value={backJumin}
                          maxLength="1"
                          ref={genderInput}
                          onChange={handleSetBackJumin}
                          onFocus={() => {
                            $(".ju2").addClass("active");
                            $(".btn_botpos_wrap").addClass("click");
                          }}
                          onBlur={() => {
                            $(".ju2").removeClass("active");
                            $(".btn_botpos_wrap").removeClass("click");
                            if (!backJuminVali) {
                              setJuminErrMsg(
                                "올바른 주민등록번호를 입력해주세요"
                              );
                            } else {
                              setJuminErrMsg("");
                            }
                          }}
                        />
                      </div>
                      <div className="form_etri_text_w">
                        <span className="form_etri_text"></span>
                        <span className="form_etri_text"></span>
                        <span className="form_etri_text"></span>
                        <span className="form_etri_text"></span>
                        <span className="form_etri_text"></span>
                        <span className="form_etri_text"></span>
                      </div>
                    </div>
                  </div>
                  <span className="form_vali_msg">{juminErrMsg}</span>
                </div>
              </div>

              <div
                className="form_veritem form_mobile"
                style={{ marginBottom: "1.8rem" }}
              >
                <div className="form_verlablow">
                  <p className="form_verlabel">통신사</p>
                </div>
                <div className="form_fieldlow">
                  <div className="form_field">
                    <button
                      type="button"
                      id="btn_tele_select"
                      className="btn_select_call d_select"
                      ref={mobileInput}
                      disabled={isAutClick}
                      onClick={handleBottomSelect}
                    >
                      <input
                        type="text"
                        disabled={isAutClick}
                        className="form_input"
                        value={mobile}
                        readOnly
                        placeholder="통신사 선택"
                      />
                    </button>
                  </div>
                </div>
                <span className="form_vali_msg">
                  {!isBottomOpen ? mobileErrMsg : null}
                </span>
              </div>

              <div
                className="form_veritem form_phone"
                style={{ marginBottom: "1.8rem" }}
              >
                <div className="form_verlablow">
                  <p className="form_verlabel">휴대폰번호</p>
                </div>
                <div className="form_fieldlow">
                  <div className="field_multi_fxwrap form_field ph">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      min="0"
                      disabled={isAutClick}
                      ref={mobileNoInput}
                      className="form_input"
                      placeholder="휴대폰번호"
                      maxLength="11"
                      value={phoneNumber}
                      onChange={handleSetPhone}
                      onFocus={() => {
                        $(".ph").addClass("active");
                        $(".btn_botpos_wrap").addClass("click");
                      }}
                      onBlur={() => {
                        $(".ph").removeClass("active");
                        $(".btn_botpos_wrap").removeClass("click");
                        if (!phoneVali) {
                          setPhoneErrMsg("올바른 휴대폰 번호를 입력해주세요");
                        } else {
                          setPhoneErrMsg("");
                        }
                      }}
                    />
                    <button
                      className="btn_formsm"
                      onClick={handleCert}
                      style={{
                        color:
                          nameVali &&
                          frontJuminVali &&
                          backJuminVali &&
                          mobileVali &&
                          phoneVali &&
                          !isAutClick
                            ? "#4144f2"
                            : null,
                        border:
                          nameVali &&
                          frontJuminVali &&
                          backJuminVali &&
                          mobileVali &&
                          phoneVali &&
                          !isAutClick
                            ? "1px solid #4144f2"
                            : null,
                      }}
                      disabled={
                        nameVali &&
                        frontJuminVali &&
                        backJuminVali &&
                        mobileVali &&
                        phoneVali &&
                        !isAutClick
                          ? false
                          : true
                      }
                    >
                      인증
                    </button>
                  </div>
                  <span className="form_vali_msg">{phoneErrMsg}</span>
                </div>
              </div>
              {isAutClick ? (
                <div
                  className="form_veritem"
                  style={{ marginBottom: "1.8rem" }}
                >
                  <div className="form_verlablow">
                    <p className="form_verlabel">인증번호</p>
                  </div>
                  <div className="form_fieldlow">
                    <div className="field_multi_fxwrap form_field cert">
                      <input
                        type="number"
                        onInput={maxLengthCheck}
                        pattern="[0-9]*"
                        inputMode="numeric"
                        min="0"
                        ref={certInput}
                        className="form_input"
                        placeholder="인증번호"
                        maxLength="6"
                        value={certKey}
                        onChange={handleSetKey}
                        onFocus={handleCertFocus}
                        onBlur={handleCertBlur}
                      />
                      <div className="cert_timer">
                        <span>{min}</span>
                        <span>:</span>
                        <span>{sec < 10 ? `0${sec}` : sec}</span>
                      </div>
                      <button
                        className="btn_sm"
                        onClick={handleReset}
                        disabled={initReset}
                        style={{
                          border:
                            isReset || initReset
                              ? "0.0625rem solid #c5c5d0"
                              : null,
                          color: isReset || initReset ? "#c5c5d0" : null,
                        }}
                      >
                        재요청
                      </button>
                    </div>
                    {
                      // nameVali
                      // <span className="form_vali_msg">{nameErrMsg}</span>
                      // : null
                    }
                  </div>
                  <span className="form_vali_msg">{errorMsg}</span>
                </div>
              ) : null}
            </div>
          </section>
        </div>
        <button type="button" className="btn_botpos_close" onClick={certClose}>
          <span className="hdtext">닫기</span>
        </button>
        <BottomSelect
          isOpen={isBottomOpen}
          bottomClose={bottomClose}
          bottomData={bottomData}
          name={"bottomRadio"}
          title={"통신사"}
          onChange={(e) => {
            const targetValue = e.target.value;

            const timeId = setInterval(() => {
              for (let i = 0; i < bottomData.length; i++) {
                const obj = bottomData[i];
                if (targetValue === obj.code) {
                  setMobile(obj.nm);
                  setMobileValue(obj.val);
                }
              }
              setIsBottomOpen(false);
              setStep3(true);
              clearTimeout(timeId);
            }, 100);
          }}
        />
      </div>
    </div>
  );
};
export default CertView;
