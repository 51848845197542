import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";

const QUERY_KEY = "billing-history";

const fetchGetBillingsHistory = async (orgCode, chargeMonth) => {
  return await axiosInstance
    .get(`/mydata/bill/${orgCode}/${chargeMonth}`)
    .then((response) => response.data)
    .then((response) => response.data);
};

const useGetBillingsHistory = (orgCode, chargeMonth) => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY, { orgCode, chargeMonth }),
    queryFn: async () => await fetchGetBillingsHistory(orgCode, chargeMonth),
    suspense: true,
  });
  return query;
};

export { useGetBillingsHistory };
