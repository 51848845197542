import { startTransition, useState } from "react";
import { useGetBilling } from "features/billing";
import { useLocate } from "lib/utils/useRouter";
import { useGetPaymentByDate, useGetRevolving } from "features/payment";
import RevolvingPopup from "containers/billing/modal/RevolvingPopup";
import PaymentSummary from "./PaymentSummary";
import BillingList from "./BillingList";

const PaymentContainer = () => {
  const [isOpenRevolving, setIsOpenRevolving] = useState(false);
  const stateData = useLocate().getStateData();
  const getBillingQuery = useGetBilling(stateData.orgCode);
  const getPaymentByDateQuery = useGetPaymentByDate(
    stateData.basDate,
    stateData.orgCode
  );
  const getRevolvingQuery = useGetRevolving(stateData.orgCode, isOpenRevolving);

  return (
    <div className="card_container">
      <PaymentSummary
        paymentByDate={getPaymentByDateQuery}
        setIsOpenRevolving={setIsOpenRevolving}
      />

      <div className="block_line"></div>
      <BillingList billings={getBillingQuery} />

      {isOpenRevolving && (
        <RevolvingPopup
          isOpen={isOpenRevolving}
          onClose={() => {
            startTransition(() => setIsOpenRevolving(false));
          }}
          revolvingQuery={getRevolvingQuery}
        />
      )}
    </div>
  );
};

export default PaymentContainer;
