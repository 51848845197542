import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";

const QUERY_KEY = "card";

const fetchGetCard = async () => {
  return await axiosInstance
    .get("/mydata/card")
    .then((response) => response.data)
    .then((response) => response.data);
};

const fetchGetPayment = async () => {
  return await axiosInstance
    .get("/mydata/payment")
    .then((response) => response.data)
    .then((response) => response.data);
};

const fetchGetPoint = async () => {
  return await axiosInstance
    .get("/mydata/card/point")
    .then((response) => response.data)
    .then((response) => response.data);
};

const useGetCard = () => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY, "card"),
    queryFn: async () => await fetchGetCard(),
    suspense: true,
  });
  return query;
};

const useGetPayment = () => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY, "payment"),
    queryFn: async () => await fetchGetPayment(),
    suspense: true,
  });

  return query;
};

const useGetPoint = () => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY, "point"),
    queryFn: async () => await fetchGetPoint(),
    suspense: true,
  });
  return query;
};

export { useGetCard, useGetPayment, useGetPoint };
