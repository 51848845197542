import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../../assets/css/add_style.css';
function Tc_wrtreviewcond() {


	//뒤로가기
	let navigate = useNavigate();
	
	//후기 남기기
	const WriteReview = () =>{
		navigate('/review/tcWriteRev')
	}

return (

<div className="front_body type2">
	<div className="page_wrap has_botpos">
		<header className="page_header">
			<button className="btn_back" onClick={()=>navigate(-1)}><span className="hdtext">뒤로가기</span></button>
		</header>
		<section className="page_midsection dtype7">
		    <div className="form_copy_review">
				<p className="sig_review_form_copy"><strong>티칭캐시의</strong> 생생한 대출 후기</p>
			</div>
			<div className="ico_column_w">
			    <p className="ico_copy03">현재 작성된 대출후기가 없습니다.</p>
				<div className="ico_obj_w ico_present">
					<div className="ico_obj"></div>
				</div>
                <p className="ico_copy03">티칭캐시에서 대출 받고 <br />생생한 대출후기를 남겨주세요! </p>
			</div>
		</section>
	</div>
	<div className="btn_botpos_wrap">
		<div className="botpos_in">
			<button className="btn_quadbox" onClick={WriteReview}>후기 남기기</button>
		</div>
	</div>
	
	<div className="dimlayer_z define_bot" id="bio_alert_layer">
		<div className="bg_dim"></div>
		<div className="botpos_vlayer">
			<div className="botpos_vcontonly">
				<div className="bvwarn_w">
					<p className="bvwarn_sigment">후기 작성은 대출을 받으신 분만 <br /> 작성 가능합니다.</p>
				</div>
				<div className="btn_botpos_fxwrap type2">
					<button type="button" className="btn_quadbox closetrigger">확인</button> 
					{/* <!-- closetrigger 닫기 --> */}
				</div>
			</div>
		</div>
	</div>
	{/* <script>
		$(function() {
			// alert 호출
			dimLayerShow({
				target: "#bio_alert_layer"
			});
			
		});
	</script> */}
</div>
	)
};
export default Tc_wrtreviewcond
{/* </html> */}