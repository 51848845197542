import NoDataBoard from "components/Board/NoDataBoard";
import HistoryItem from "./HistoryItem";

const HistoryListWrap = (props) => {
  const paymentQuery = props.paymentQuery;
  const paymentData = paymentQuery?.data;

  if (paymentData?.expenseDtoList?.length === 0) {
    return (
      <section className="payment_history_wrap">
        <NoDataBoard message={"사용 내역이 없습니다."} />
      </section>
    );
  }

  return (
    <section className="payment_history_wrap">
      <ul className="payment_history_list">
        {paymentData?.expenseDtoList?.map((expenseDto, index) => (
          <HistoryItem expenseDto={expenseDto} key={index} />
        ))}
      </ul>
    </section>
  );
};

export default HistoryListWrap;
