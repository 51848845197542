import React, { useEffect, useState } from "react";
import { Consent1, Consent2 } from "..";
import { useLocate, useRouter } from "lib/utils/useRouter";
import { CustomButton } from "components/Button";
import consentList from "assets/data/consent.json";
import useHandleButton from "hooks/useHandleButton";
import CheckboxItem from "./CheckboxItem";
import { Loading, usePostConsent } from "features/mydata";

import LoadingSpinner from "components/Modal/LoadingSpinner";

const ConsentOrganizationContainer = () => {
  const useRoute = useRouter();
  const stateData = useLocate().getStateData();
  const orgCodeList = stateData.orgList?.map((org) => {
    return { orgCode: org.org_code };
  });
  const cert = stateData.cert;
  const mutation = usePostConsent(cert, "token", orgCodeList);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const nativeEventCallbackCert = (event) => {
      useRoute.routeIncludeState("/mydata/register/asset", {
        data: { group_id: event.detail.data },
        cert: cert,
        orgList: stateData.orgList,
      });
    };

    window.addEventListener("handleCustomEventCert", nativeEventCallbackCert);

    return () => {
      window.removeEventListener(
        "handleCustomEventCert",
        nativeEventCallbackCert
      );
    };
  }, []);

  useEffect(() => {
    if (mutation?.isSuccess && (cert === "NAVER" || cert === "KAKAO")) {
      setShowLoading(false);

      useRoute.routeIncludeState("/mydata/loading", {
        naverSchemeUrl: mutation?.data?.sign_aos_app_scheme_url,
        type: "0",
        cert: cert,
        orgList: stateData?.orgList,
      });
    }

    if (mutation?.isSuccess && cert === "CERT") {
      useRoute.routeIncludeState("/mydata/register/asset", {
        data: mutation?.data,
        cert: cert,
        orgList: stateData?.orgList,
      });
    }
  }, [mutation?.isSuccess]);

  useEffect(() => {
    if (stateData.cert === "CERT" && mutation?.isPending) {
      return <Loading />;
    }
  }, [mutation?.isPending]);

  const handleAgreeEvent = () => {
    setShowLoading(true);

    const agree1 = document.querySelector("#agree1");
    const agree2 = document.querySelector("#agree2");

    if (!agree1.checked) agree1.checked = true;
    if (!agree2.checked) agree2.checked = true;

    setTimeout(() => {
      if (stateData.cert === "NAVER" || stateData.cert === "KAKAO") {
        mutation.mutate(cert, "token", orgCodeList);
      }

      if (stateData.cert === "CERT") {
        const orgCode = JSON.stringify(orgCodeList);
        const params = ["1", orgCode];
        const token = localStorage.getItem("acc_token");
        window.Android.getCert(params, token);
        setShowLoading(false);
        // mutation.mutate(cert, "token", orgCodeList);
        // oAuthMutation.mutate("token", orgCodeList);
      }
    }, 300);
  };

  const [title, renderButton] = useHandleButton(
    "동의하고 계속하기",
    handleAgreeEvent
  );

  return (
    <>
      <section className="page_midsection_mydata">
        <Consent1 orgList={stateData.orgList} />
        <Consent2 orgList={stateData.orgList} />

        <div className="terms_check_box">
          <ul>
            {consentList.consent1.map((terms) => {
              return <CheckboxItem terms={terms} key={terms.id} />;
            })}
          </ul>
        </div>
        <CustomButton
          title={title}
          onClick={renderButton}
          buttonClass={"btn_company_bottom"}
        />
      </section>
      {showLoading && <LoadingSpinner />}
    </>
  );
};

export default ConsentOrganizationContainer;
