import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";

const QUERY_KEY = "revolving";

const fetchGetRevolving = async (orgCode) => {
  return await axiosInstance
    .get(`/mydata/card/revolving/${orgCode}`)
    .then((response) => response.data)
    .then((response) => response.data);
};

const useGetRevolving = (orgCode, isOpenRevolving) => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY, orgCode),
    queryFn: async () => await fetchGetRevolving(orgCode),
    enabled: isOpenRevolving,
  });
  return query;
};

export { useGetRevolving };
