import React from "react";
import { useGetTransferHistory } from "features/transferHistory";
import TransferHistoryItem from "./TransferHistoryItem";
import NoDataBoard from "components/Board/NoDataBoard";

const TransferHistoryContainer = () => {
  const transferHistoryQuery = useGetTransferHistory();

  const transferHistoryData = transferHistoryQuery?.data?.reduce(
    (acc, curr) => {
      const { regDttm } = curr;
      if (acc[regDttm]) acc[regDttm].push(curr);
      else acc[regDttm] = [curr];

      return acc;
    },
    {}
  );

  if (transferHistoryQuery?.data?.length === 0) {
    return (
      <div className="transfer_container">
        <section className="transfer_history_wrap">
          <NoDataBoard message={"전송 내역이 없습니다."} />
        </section>
      </div>
    );
  }

  return (
    <div className="transfer_container">
      {/* <TransferSearchWrap /> */}
      <section className="transfer_history_wrap">
        <ul className="transfer_history_list">
          {transferHistoryQuery.isSuccess
            ? Object.keys(transferHistoryData).map((key, index) => {
                return (
                  <TransferHistoryItem
                    month={key}
                    item={transferHistoryData[key]}
                    key={key + index}
                  />
                );
              })
            : null}
        </ul>
      </section>
    </div>
  );
};

export default TransferHistoryContainer;
