const CardDetailsSkeleton = () => {
  return (
    <div className="card_container">
      <section className="card_wrap">
        <div className="billing_article">
          <div className="billing_title">
            <div className="skeleton skeleton_title"></div>
            <p
              className="skeleton skeleton_subtitle"
              style={{ marginTop: "12px" }}
            ></p>
          </div>
        </div>
      </section>
      <section className="billing_detail_article">
        <ul className="table_article">
          <li>
            <div className="billing_detail">
              <p className="skeleton skeleton_subtitle"></p>
              <p className="skeleton skeleton_subtitle"></p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <p className="skeleton skeleton_subtitle"></p>
              <p className="skeleton skeleton_subtitle"></p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <p className="skeleton skeleton_subtitle"></p>
              <p className="skeleton skeleton_subtitle"></p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <p className="skeleton skeleton_subtitle"></p>
              <p className="skeleton skeleton_subtitle"></p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <p className="skeleton skeleton_subtitle"></p>
              <p className="skeleton skeleton_subtitle"></p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <p className="skeleton skeleton_subtitle"></p>
              <p className="skeleton skeleton_subtitle"></p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <p className="skeleton skeleton_subtitle"></p>
              <p className="skeleton skeleton_subtitle"></p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <p className="skeleton skeleton_subtitle"></p>
              <p className="skeleton skeleton_subtitle"></p>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default CardDetailsSkeleton;
