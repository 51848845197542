import React, { useState, useEffect } from "react";
import { useRouter } from "lib/utils/useRouter";
import { ConfirmPopup } from "components/Popup";
import InputRadioContent from "./InputRadioContent";
import ButtonContainer from "./ButtonContainer";
import AssetConsent from "./AssetConsent";
import { Loading, usePostConsent } from "features/mydata";
import PeriodSelectPopup from "./PeriodSelectPopup";
import consentList from "assets/data/consent.json";
import LoadingSpinner from "components/Modal/LoadingSpinner";
const ConsentAssetContainer = (props) => {
  const [selectItem, setSelectItem] = useState(consentList.periodList[0]);
  const [isPeriod, setIsPeriod] = useState(false);
  const [isOptional, setIsOptional] = useState(false);
  const [consentDto, setConsentDto] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const useRoute = useRouter();
  const mutation = usePostConsent(props.cert, "token2", consentDto);

  useEffect(() => {
    const nativeEventCallbackCert2 = (event) => {
      useRoute.routeIncludeState("/mydata/result", {
        result: event.detail.data,
        assetList: props.assetList,
      });
    };

    window.addEventListener("handleCustomEventCert2", nativeEventCallbackCert2);

    return () => {
      window.removeEventListener(
        "handleCustomEventCert2",
        nativeEventCallbackCert2
      );
    };
  }, []);

  useEffect(() => {
    if (
      mutation?.isSuccess &&
      (props.cert === "NAVER" || props.cert === "KAKAO")
    ) {
      setShowLoading(false);
      useRoute.routeIncludeState("/mydata/loading", {
        naverSchemeUrl: mutation?.data?.sign_aos_app_scheme_url,
        cert: props.cert,
        type: "1",
        assetList: props.assetList,
      });
    }

    if (mutation?.isSuccess && props.cert === "CERT") {
      if (mutation?.data) {
        setShowLoading(false);
        useRoute.routeIncludeState("/mydata/result", {
          result: mutation?.data,
          assetList: props.assetList,
        });
      }
    }
  }, [mutation?.isSuccess]);

  const handleAgreeEvent = () => {
    const agree1 = document.querySelector("#isPrivacy");
    const agree2 = document.querySelector("#isConsentMerchantNameRegno");

    if (!agree1.checked) agree1.checked = true;

    setTimeout(() => {
      if (!agree2.checked) setIsOptional(true);
      else {
        handleSubmitConsent();
      }
    }, 300);
  };

  const handleSubmitConsent = () => {
    setShowLoading(true);
    consentDto.isScheduled = document.querySelector(
      'input[name="is_scheduled"]:checked'
    ).value;
    consentDto.period = selectItem.code;
    consentDto.isConsentMerchantNameRegno = document.querySelector(
      "#isConsentMerchantNameRegno"
    ).checked;

    setConsentDto(consentDto);

    if (props.cert === "NAVER" || props.cert === "KAKAO") {
      mutation.mutate(props.cert, "token2", consentDto);
    }

    if (props.cert === "CERT") {
      const consent = JSON.stringify(consentDto);
      const params = ["2", consent];
      const token = localStorage.getItem("acc_token");
      window.Android.getCert(params, token);
      setShowLoading(false);
      // mutation.mutate(props.cert, "token2", consentDto);
    }
  };

  const handleOption = (isChecked) => {
    const agree = document.querySelector("#isConsentMerchantNameRegno");
    agree.checked = isChecked;
    handleSubmitConsent();
    setIsOptional(false);
  };

  const onChangeSelect = (e) => {
    let value = e.target.value;
    setSelectItem(
      consentList.periodList.filter((item) => item.value === value)[0]
    );
    setIsPeriod(false);
  };

  if (props.cert === "CERT" && mutation.isPending) {
    return <Loading />;
  }

  return (
    <section className="page_midsection_mydata">
      <div className="mydata_terms_contents">
        <h2>마지막으로 동의해주세요</h2>
        <div className="consent_wrap">
          <h3>
            상세정보 전송요구 및<br></br> 개인신용정보 수집·이용
          </h3>
          뱅큐는 [신용정보의 이용 및 보호에 관한 법률], [개인정보 보호법] 등
          관련 법령에 따라 개인신용정보를 처리합니다.
          <p className="terms_title">거부할 권리 및 불이익</p>
          개인신용정보 수집이용에 관한 동의를 거부하실 수 있습니다. 하지만,
          서비스 이용을 위해 동의가 필요합니다. 동의하지 않으면 본인신용정보
          통합조회, 데이터분석 서비스의 이용이 제한될 수 있습니다.
          <InputRadioContent />
          <div className="schedule_wrap">
            <p className="terms_title">유효기간</p>
            <div
              className="period_box"
              onClick={() => {
                setIsPeriod(true);
              }}
            >
              {selectItem.title}
            </div>
          </div>
          <AssetConsent assetList={props.assetList} />
          <p className="terms_title">정확한 내역 확인을 위한 동의</p>
          <ul className="terms_detail_list">
            <li>
              결제처에는 소비생활과 관련된 정보가 포함되어 있습니다.(가맹점명,
              사업자번호)
            </li>
          </ul>
        </div>

        <div className="terms_check_box">
          <ul>
            {consentList?.consent2?.map((consent) => (
              <li key={consent.id}>
                <input
                  type="checkbox"
                  id={consent.id}
                  value={consent.value}
                  className="mydata_checkbox"
                />
                <label htmlFor={consent.id} className="mydata_checkbox_label">
                  {consent.title}
                </label>
                {consent.subTitle !== "" ? (
                  <p className="terms_agree_sub">{consent.subTitle}</p>
                ) : (
                  ""
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <ButtonContainer callback={handleAgreeEvent} />

      {isPeriod && (
        <PeriodSelectPopup
          isOpen={isPeriod}
          onClose={() => {
            setIsPeriod(false);
          }}
          bottomData={consentList?.periodList}
          title={"유효기간"}
          name={"period"}
          onChange={(e) => {
            onChangeSelect(e);
          }}
          selectItem={selectItem}
        />
      )}

      <ConfirmPopup
        isOpen={isOptional}
        yesClick={() => {
          handleOption(true);
        }}
        noClick={() => {
          handleOption(false);
        }}
      />

      {showLoading && <LoadingSpinner />}
    </section>
  );
};

export default ConsentAssetContainer;
