import { useState, useEffect, startTransition } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetPaymentHistory } from "features/payment";
import Summary from "./Summary";
import HistoryListWrap from "./HistoryListWrap";
import SearchMonthPopup from "components/Modal/SearchMonthPopup";
import { getDateFormat } from "utils/utils";

const PaymentHistoryContainer = () => {
  const nowDateFormat = getDateFormat();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSearchPopup, setIsSearchPopup] = useState(false);
  const [selectMonth, setSelectMonth] = useState(nowDateFormat);
  const getPaymentQuery = useGetPaymentHistory(selectMonth);

  useEffect(() => {
    if (searchParams.get("searchDate") != null) {
      startTransition(() => {
        setSelectMonth(searchParams.get("searchDate"));
      });
    } else {
      startTransition(() => {
        setSelectMonth(nowDateFormat);
      });
    }
  }, [searchParams.get("searchDate")]);

  return (
    <div className="payment_container">
      <Summary
        paymentQuery={getPaymentQuery}
        setIsSearchPopup={setIsSearchPopup}
        selectMonth={selectMonth}
      />

      <HistoryListWrap paymentQuery={getPaymentQuery} />

      {isSearchPopup && (
        <SearchMonthPopup
          isOpen={isSearchPopup}
          onClose={() => {
            setIsSearchPopup(false);
          }}
          onChange={(e) => {
            setTimeout(() => {
              setSelectMonth(e.target.value);
              searchParams.set("searchDate", e.target.value);
              setSearchParams(searchParams);
            }, 150);
          }}
          selectMonth={selectMonth}
        />
      )}
    </div>
  );
};

export default PaymentHistoryContainer;
