import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";

const QUERY_KEY = "payment-history";

const fetchGetPaymentHistory = async (basDt) => {
  return await axiosInstance
    .get(`/mydata/expense`, {
      params: {
        basDtYm: basDt,
      },
    })
    .then((response) => response.data)
    .then((response) => response.data);
};

const useGetPaymentHistory = (basDt) => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY, basDt),
    queryFn: async () => await fetchGetPaymentHistory(basDt),
    suspense: true,
    retry: 1,
  });
  return query;
};

export { useGetPaymentHistory };
