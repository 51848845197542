import React from "react";
import { useNavigate } from 'react-router-dom';

const Tc_noticeHistory02 = () => {

    let navigate = useNavigate();

    return (
        <div className="front_body type2">
            <div className="page_wrap has_botpos">
                <header className="page_header">
                    {/* <a href="#!" className="btn_back"><span className="hdtext">뒤로가기</span></a> */}
                    <button className="btn_back" onClick={() => navigate(-1)}><span className="hdtext">뒤로가기</span></button>
                    <h1 className="page_headtitle">셍체인식정보 설정</h1>
                </header>
                <section className="page_midsection dtype3">
                    <div className="ico_column_w type2">
                        <div className="ico_obj_w ico_phone">
                            <div className="ico_obj"></div>
                        </div>
                        <div className="ico_copy_w type2">
                            <p className="ico_copyonly">간편 비밀번호 대신 사용할<br /><strong>생체인식정보</strong>를 설정해주세요</p>
                        </div>
                    </div>
                </section>
            </div>
            <div className="btn_botpos_wrap">
                <div className="botpos_in">
                    <a href="#!" className="btn_quadbox">이벤트 혜택 알림 받기</a>
                </div>
            </div>
        </div>
    );
}
export default Tc_noticeHistory02;
