import Modal from "components/Modal/Modal";
import SelectItem from "components/Select/SelectItem";

const TelecomSelectPopup = (props) => {
  return (
    <Modal bottomModal onClose={props.onClose} title="통신사">
      <div>
        <ul>
          {props.bottomData.map((item) => (
            <SelectItem
              key={item.id}
              item={item}
              name={props.name}
              onChange={props.onChange}
              selectItem={props.selectItem}
            />
          ))}
        </ul>
      </div>
    </Modal>
  );
};

export default TelecomSelectPopup;
