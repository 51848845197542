import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";

const QUERY_KEY = "pie-statistics";

const fetchGetPieStatistics = async (basDtYm) => {
  return await axiosInstance
    .get("/mydata/expense/statistics/pie", {
      params: {
        basDtYm: basDtYm,
      },
    })
    .then((response) => response.data)
    .then((response) => response.data);
};

const useGetPieStatistics = (basDtYm) => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY, basDtYm),
    queryFn: async () => await fetchGetPieStatistics(basDtYm),
  });
  return query;
};

export { useGetPieStatistics };
