import { useQueryClient } from "@tanstack/react-query";
import MydataQueryFactory from "../queries/MydataQueryFactory";
import useMyDataMutation from "../queries/mydataMutation";
import APIInstance from "lib/apis/APIInstance";

const fetchPostAsset = async (params) => {
  const { data } = await APIInstance.post("/mydata/asset", params);
  return data?.data;
};

export const usePostAsset = (req) => {
  const queryKey = MydataQueryFactory.queries.postAsset();
  const queryClient = useQueryClient();
  const mutation = useMyDataMutation(
    queryKey,
    async () => await fetchPostAsset(req),
    (data) => data,
    () => {
      MydataQueryFactory.invalidateQueries(queryClient).postAsset();
    }
  );

  return mutation;
};
