import { useState, useEffect } from "react";
import { useParam, useRouter } from "lib/utils/useRouter";
import {
  useGetLinkedOrgDetails,
  useRevokeOrganization,
  useDeleteDataOfRevoked,
  useDeleteDataOfLinked,
  OrgDetails,
} from "features/organization";
import Button from "components/Button/Button";
import OrgRevokePopup from "./modal/OrgRevokePopup";
import { successMsg } from "utils/utils";
import * as Org from "containers/Mydata";
import DataDeletePopup from "./modal/DataDeletePopup";
import CreditDeletePopup from "./modal/CreditDeletePopup";
import { queryClient } from "lib/apis/QueryClient";
import scopeData from "assets/data/scope.json";
import LoadingSpinner from "components/Modal/LoadingSpinner";

const ManagementDetailsContainer = () => {
  const [isOpen, setOpen] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState(false);
  const [isDeleteCredit, setIsDeleteCredit] = useState(false);

  const [isCert, setIsCert] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [selectCert, setSelectCert] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const params = useParam();
  const useRoute = useRouter();
  const orgCode = params.getPathParameter().code;

  const linkedOrgDetailsQuery = useGetLinkedOrgDetails(orgCode);
  const revokeOrganitionMutation = useRevokeOrganization(orgCode); // 기관 철회
  const deleteDataOfRevokedMutation = useDeleteDataOfRevoked([orgCode]); // 철회기관 데이터 삭제
  const deleteDataOfLinkedMutation = useDeleteDataOfLinked([orgCode]); // 철회 및 데이터 삭제

  const linkedOrdDetail = linkedOrgDetailsQuery?.data?.org_list[0];

  useEffect(() => {
    if (revokeOrganitionMutation?.isSuccess && revokeOrganitionMutation?.data) {
      setOpen(!isOpen);
      setTimeout(() => {
        setShowLoading(false);
        setIsDeleteData(true);
      }, 150);
    }
  }, [revokeOrganitionMutation?.isSuccess]);

  useEffect(() => {
    if (
      deleteDataOfRevokedMutation?.isSuccess &&
      deleteDataOfRevokedMutation?.data
    ) {
      setIsDeleteData(false);
      setShowLoading(false);
      successMsg("개인신용정보를 삭제했습니다.", () => {
        queryClient.invalidateQueries({ queryKey: ["is-linked"] });
        useRoute.back();
        // useRoute.changePath("/management", { action: "delete" });
      });
    }
  }, [deleteDataOfRevokedMutation?.isSuccess]);

  useEffect(() => {
    if (
      deleteDataOfLinkedMutation?.isSuccess &&
      deleteDataOfLinkedMutation?.data
    ) {
      setIsDeleteCredit(false);
      setShowLoading(false);
      successMsg("개인신용정보를 삭제했습니다.", () => {
        queryClient.invalidateQueries({ queryKey: ["is-linked"] });
        useRoute.back();
        // useRoute.changePath("/management", { action: "delete" });
      });
    }
  }, [deleteDataOfLinkedMutation?.isSuccess]);

  return (
    <div className="asset_manage_wrap">
      <OrgDetails details={linkedOrdDetail} />
      <div className="asset_info_block">
        <div className="asset_info_header">
          <h3>
            연결한 정보
            <span
              style={{ fontSize: "14px", marginLeft: "4px", color: "#6e62e9" }}
            >
              {linkedOrdDetail?.asst_list.length}
            </span>
          </h3>
          <span
            className="info_delete"
            onClick={() => {
              setIsDeleteCredit(true);
            }}
          >
            데이터 삭제
          </span>
        </div>

        <div>
          <div style={{ color: "rgb(32, 32, 32)", fontSize: "14px" }}>
            {scopeData?.scopes?.map((item) => {
              return linkedOrdDetail?.scope.includes(item.scope) ? (
                <p key={item.scope}>{item.title}</p>
              ) : (
                ""
              );
            })}
          </div>

          <ul className="account_list">
            {linkedOrdDetail?.asst_list?.map((asset, index) => {
              return (
                <li key={index}>
                  <p>{asset.asst_nm}</p>
                  <span>{asset.asst_no}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="btn_management_wrap">
        <Button
          type={"button"}
          onClick={() => {
            setOpen(true);
          }}
          title={"연결 해제"}
          className={"btn_management_delete"}
        ></Button>
        <Button
          type={"button"}
          onClick={() => {
            setIsCert(true);
          }}
          title={"연결 정보 수정"}
          className={"btn_management_update"}
        ></Button>
      </div>

      {isOpen && (
        <OrgRevokePopup
          isOpen={isOpen}
          onClose={() => {
            setOpen(false);
          }}
          submitClick={() => {
            setShowLoading(true);
            revokeOrganitionMutation.mutate(orgCode);
          }}
        />
      )}

      {isDeleteData && (
        <DataDeletePopup
          isOpen={isDeleteData}
          onClose={() => {
            setIsDeleteData(false);
            setTimeout(() => {
              useRoute.back();
              // useRoute.changePath("/management", { action: "delete" });
            }, 150);
          }}
          submitClick={() => {
            setShowLoading(true);
            deleteDataOfRevokedMutation.mutate(orgCode);
          }}
        />
      )}

      {isDeleteCredit && (
        <CreditDeletePopup
          isOpen={isDeleteCredit}
          onClose={() => {
            setIsDeleteCredit(false);
          }}
          submitClick={() => {
            setShowLoading(true);
            deleteDataOfLinkedMutation.mutate(orgCode);
          }}
        />
      )}

      {isCert && (
        <Org.CertificatePopup
          isOpen={isCert}
          bottomClose={() => {
            setIsCert(false);
          }}
          selectCert={selectCert}
          setSelectCert={setSelectCert}
          setIsAgree={setIsAgree}
          handleAgree={() => {
            useRoute.routeIncludeState("/mydata/register/asset", {
              orgCode: linkedOrdDetail.org_code,
              type: "update",
              cert: selectCert,
            });
          }}
        />
      )}
      {showLoading && <LoadingSpinner />}
    </div>
  );
};

export default ManagementDetailsContainer;
