import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { printMsg, confirmMsg, successMsg } from "utils/utils";
import { setCookies } from "utils/Cookie";
import { CSSTransition } from "react-transition-group";
import BottomAlert from "../../components/BottomAlert";
import {commonFetch, paramFetch} from "apis/common/fetchInstance";
import Tc_pwdChange from "./tc_pwdChange";

function Tc_pwdChangeSet() {
  const navigate = useNavigate();
  const location = useLocation();

  const [numPad, setNumPad] = useState([]); //숫자 패드

  const [pwdText, setPwdText] = useState([]); //1자리씩 저장
  const [firstPwd, setFirstPwd] = useState(""); //1차 패스워드
  const [secondPwd, setSecondPwd] = useState(""); //2차 패스워드
  const [pwdChk, setPwdChk] = useState(false); //1차 패스워드 확인
  const [secondPwdChk, setSecondPwdChk] = useState(false); //2차 패스워드 확인
  const [isPwd, setIsPwd] = useState(false);
  const [password, setPassword] = useState(""); //최종 패스워드
  const [isBottomAlert, setIsBottomAlert] = useState(false);
  const [alertText, setAlertText] = useState([]);

  useEffect(() => {
    if (pwdText.length === 6) {
      handleSetPwd();
    }
  }, [pwdText]);

  useEffect(() => {
    if (secondPwdChk) {
      if (firstPwd === secondPwd) {
        setIsPwd(false);
        pwdChangeApi();
        // navigate('/join/finish');
      } else {
        setPwdText([]);
        setSecondPwd("");
        setSecondPwdChk(false);
        setIsPwd(true);
        setIsBottomAlert(true);
        let array = [];
        array.push("비밀번호가 일치하지 않습니다");
        array.push("간편 비밀번호를 다시 입력해주세요");
        setAlertText(array);
      }
    }
  }, [secondPwdChk]);

  const pwdChangeApi = async () => {
    const sendData = {
      password: secondPwd,
    };

    const { response, data } = await commonFetch(
      "/api/app/change",'put',sendData);
    if (response.status === 200) {
        successMsg("비밀번호가 변경되었습니다.", successClick);
      } else {
        successMsg("잠시후 다시 시도해주세요.", successClick);
      }
  }

  const successClick = () => {
    navigate("/menu");
  }

  useEffect(() => {
    randomNumber();
  }, []);

  const goBack = () => {
    const msg = [
      "비밀번호 변경이 완료되지 않았습니다.",
      "시작화면으로 돌아가시겠습니까?",
    ];
    confirmMsg(msg, "아니요", "예", yesClick);
  }

  const yesClick = () => {
    navigate("/menu");
  }

  const randomNumber = (array) => {
    const numArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

    setNumPad(numArr.sort(() => Math.random() - 0.5)); //숫자 패드 섞기
  }

  const onClickNumPad = (num) => {
    setPwdText(pwdText.concat(num));
  }

  const handleSetPwd = () => {
    if (!pwdChk) {
      setPwdChk(true);
      setFirstPwd(pwdText.join(""));
      setPwdText([]);
    }
    if (pwdChk) {
      setSecondPwd(pwdText.join(""));
      setSecondPwdChk(true);
    }
  }

  const handleSetClear = () => {
    if (pwdText.length > 0) {
      const backNum = pwdText.slice(0, pwdText.length - 1);
      setPwdText(backNum);
    }
  }

  const pwdReset = () => {
    setFirstPwd("");
    setPwdText([]);
    setPwdChk(false);
    setIsPwd(false);
  }

  const bottomClose = () => {
    setIsBottomAlert(false);
  }

  const NumDot = () => {
    return (
      <>
        <span
          className={"pfx_dot " + (pwdText[0] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[1] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[2] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[3] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[4] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[5] === undefined ? null : "active")}
        ></span>
      </>
    );
  }

  return (
    <div className="front_body">
      <div className="page_wrap has_botpos">
        <header className="page_header">
          <div
            className={isBottomAlert ? "bg_layout" : null}
            onClick={bottomClose}
          ></div>
          <a className="btn_back" onClick={goBack}>
            <span className="hdtext">뒤로가기</span>
          </a>
          <h1 className="page_headtitle">비밀번호 변경</h1>
        </header>
        <section className="page_fxsection">
          <div className="pfx_top">
            <div className="pfx_copy">
              <h2 className="pfx_ctitle">간편비밀번호</h2>
              {pwdChk ? (
                <p className="pfx_csub">확인을 위해 한번 더 입력해주세요.</p>
              ) : (
                <p className="pfx_csub">6자리를 비밀번호를 입력해주세요.</p>
              )}
            </div>
            <div className="pfx_dot_w">
              <NumDot></NumDot>
            </div>
            <div className="pfx_orgin_input_w">
              <input type="text" className="pfx_origin_input" />
            </div>
          </div>
          {/* {
						isPwd
							? (<div className="pfx_warnlow">
								<p className="pfx_warn01">비밀번호가 일치하지 않습니다.</p>
								<p className="pfx_warn02">다시 입력주세요</p>
							</div>)
							: null
					} */}
          <div className="pfx_bottom">
            <div className="btn_pfx_low">
              {/*<a href="#" className="btn_smquadbox">생체인증 재시도</a>*/}
              <a className="btn_smquadbox" onClick={pwdReset}>
                비밀번호 재설정
              </a>
            </div>
            <div className="pfx_numkeyboard">
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[0])}
              >
                {numPad[0]}
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[1])}
              >
                {numPad[1]}
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[2])}
              >
                {numPad[2]}
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[3])}
              >
                {numPad[3]}
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[4])}
              >
                {numPad[4]}
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[5])}
              >
                {numPad[5]}
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[6])}
              >
                {numPad[6]}
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[7])}
              >
                {numPad[7]}
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[8])}
              >
                {numPad[8]}
              </button>
              <button
                type="button"
                className="pnum_key define_text"
                onClick={goBack}
              >
                닫기
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[9])}
              >
                {numPad[9]}
              </button>
              <button
                type="button"
                className="pnum_key define_ico ico_back"
                onClick={handleSetClear}
              >
                <span className="hdtext">뒤로가기</span>
              </button>
            </div>
          </div>

          <CSSTransition in={isBottomAlert} classNames="bottom" timeout={300}>
            <BottomAlert
              isOpen={isBottomAlert}
              bottomClose={bottomClose}
              alertText={alertText}
            />
          </CSSTransition>
        </section>
      </div>
    </div>
  );
}
export default Tc_pwdChangeSet;
