import React from "react";
import { useRouter } from "lib/utils/useRouter";
import { isEmpty } from "utils/utils";

const ApprovedItem = (props) => {
  const useRoute = useRouter();
  const approvedItem = props.approvedItem;

  return (
    <li>
      <div className="payment_info ">
        <div className="payment_info_row">
          <p>
            {isEmpty(approvedItem.merchantName)
              ? "알수없음"
              : approvedItem.merchantName}
          </p>
          <strong className={approvedItem.status === "02" ? "cancel" : ""}>
            {approvedItem.approvedAmt + "원"}
          </strong>
        </div>
        <div className="payment_info_row info_desc">
          <span>{approvedItem.approvedTime}</span>
          <span>
            {approvedItem.status === "02"
              ? "결제취소"
              : approvedItem.payMethodName}
          </span>
        </div>
        <p
          className="link"
          onClick={() => {
            useRoute.routeIncludeState(
              `/card/history/${approvedItem.cardId}`,
              approvedItem
            );
          }}
        >
          <span>자세히 보기</span>
        </p>
      </div>
    </li>
  );
};

export default ApprovedItem;
