import splash_logo from "assets/images/bankq/logo-splash.png";
import splash_obj02 from "assets/images/splash_obj02.png";
import splash_obj03 from "assets/images/splash_obj03.png";
import splash_obj04 from "assets/images/splash_obj04.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { CustomButton } from "components/Button";
import { useRouter } from "lib/utils/useRouter";
import "swiper/css";
import "swiper/css/pagination";

function OnboardingSplash() {
  const useRoute = useRouter();

  localStorage.setItem("appName", "bankq");

  return (
    <div className="front_body">
      <div className="splash-swiper-wrap">
        <div className="splash-swiper-logo">
          <img src={splash_logo} className="splash-logo" alt="뱅큐" />
        </div>
        <Swiper
          modules={[Pagination]}
          pagination={{ clickable: true }}
          className="splash_swiper"
        >
          <SwiperSlide>
            <div className="swiper_splash_imglow">
              <img src={splash_obj02} alt="금융생활의 첫걸음. 베핀" />
            </div>
            <div className="swiper_splash_speclow">
              <p className="splash_spec01">금융생활의 첫걸음</p>
              <p className="splash_spec02">베핀</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper_splash_imglow">
              <img
                src={splash_obj04}
                alt="너에게 금융을 알려줄게! 최적의 대출받기"
              />
            </div>
            <div className="swiper_splash_speclow">
              <p className="splash_spec01">너에게 금융을 알려줄게!</p>
              <p className="splash_spec02">최적의 대출받기</p>
            </div>
          </SwiperSlide>
        </Swiper>
        <CustomButton
          title={"시작하기"}
          buttonClass={"btn_color_bottom"}
          onClick={() => useRoute.routeTo("/join")}
        />
      </div>
    </div>
  );
}
export default OnboardingSplash;
