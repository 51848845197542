const queryKeys = {
  MYDATA: {
    BASE: "mydata",
    JOIN: "joinMydata", // 가입
    IS_CHECKED_JOIN: "isCheckedJoin", // 가입여부
    GET_ORGANIZATIONS: "getOrganizations", // 기관 호출
    GET_CONSENT: "getConsentDetail", // 동의화면 내용 호출
    GET_HAVEING_ASSETS: "getHavingAssets", // COM006
    POST_ASSET_OBJECT: "postAssetObject", // 선택한 자산 등록
  },
  CONSENT: {
    BASE: "consent",
    POST_NAVER: "postNaverConsent", // 네이버 인증 호출
    POST_OAUTH: "postOAuthConsent", // 공동인증서 인증 호출
    CHECK_NAVER_RESULT: "checkNaverConsentResult", // 네이버 인증 결과 확인
  },
  ORGANIZATION: {
    BASE: "organization",
    LIST: "getOrganizationList",
    DETAIL: "getOrganizationDetail",
    REVOKE: "revokeOrganization", // 철회
    DELETE_OF_REVOKED: "deleteOfRevokedOrganization", // 철회된 기관의 데이터 삭제
    DELETE_OF_LINKED: "deleteOfLinkedOrganization", // 연결된 기관의 데이터 삭제
  },
};

export default queryKeys;
