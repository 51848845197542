import MainPayment from "./MainPayment";
import MainCard from "./MainCard";
import MainPoint from "./MainPoint";
import { useGetCard, useGetPayment, useGetPoint } from "features/card";

const CardContainer = () => {
  const cardQuery = useGetCard();
  const paymentQuery = useGetPayment();
  const pointQuery = useGetPoint();

  return (
    <div className="card_container">
      <MainCard cardQuery={cardQuery} />
      <div className="block_line"></div>
      <MainPayment paymentQuery={paymentQuery} />
      <div className="block_line"></div>
      <MainPoint pointQuery={pointQuery} />
    </div>
  );
};

export default CardContainer;
