import React, { useState, useEffect } from "react";
import { useLocate, useRouter } from "lib/utils/useRouter";
import { useGetNaverConsentStatus } from "features/mydata";
import { useNavigate } from "react-router-dom";

const Loading = () => {
  const stateData = useLocate().getStateData();
  const useRoute = useRouter();
  const navigate = useNavigate();

  const naverConsentStatusQuery = useGetNaverConsentStatus(
    stateData.cert.toLowerCase(),
    stateData.type
    // stateData.type === "0" ? stateData?.orgList : stateData?.assetList
  );
  const content = "금융사에 연결을 요청 중입니다.";

  // const [content, setContent] = useState("금융사에 연결을 요청 중입니다.");

  useEffect(() => {
    const nativeEventCallback = (event) => {
      if (event.detail.data) {
        naverConsentStatusQuery.refetch();
      } else {
        navigate(-1);
      }
    };

    window.addEventListener("handleCustomEvent", nativeEventCallback);
    // window.open(stateData.naverSchemeUrl);
    if(stateData.cert === 'NAVER'){
      const naverPackage = "com.nhn.android.search"
      const naverAppInstalled = window.Android.appInstalled(naverPackage);
      if (naverAppInstalled) {
        window.Android.getNaverCert(stateData.naverSchemeUrl);
      } else {
        navigate(-1);
        window.Android.getPlayStore(naverPackage);
      }
    }
    if(stateData.cert === 'KAKAO'){
      const kakaoPackage = "com.kakao.talk"
      const kakaoAppInstalled = window.Android.appInstalled(kakaoPackage);
      if (kakaoAppInstalled) {
        window.Android.getKakaoCert(stateData.naverSchemeUrl);
      } else {
        navigate(-1);
        window.Android.getPlayStore(kakaoPackage);
      }
    }

    return () => {
      window.removeEventListener("handleCustomEvent", nativeEventCallback);
    };
  }, []);

  useEffect(() => {
    if (!naverConsentStatusQuery?.isPending) {
      const resultData = naverConsentStatusQuery?.data;

      useRoute.routeIncludeState(
        stateData.type === "0" ? "/mydata/register/asset" : "/mydata/result",
        {
          data: resultData,
          cert: stateData.cert,
          orgList: stateData.orgList,
          assetList: stateData.assetList,
        }
      );
    }
  }, [naverConsentStatusQuery?.isPending]);

  return (
    <div className="front_body">
      <div className="loading_content">
        <p>{content}</p>
      </div>
    </div>
  );
};

export default Loading;
