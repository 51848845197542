import { useState, useEffect } from "react";

const useHandleButton = (callbackTitle, callback) => {
  const [title, setTitle] = useState("내려가기");
  const [handleEvent, setHandleEvent] = useState(true);

  const scrollEvent = () => {
    const headerHeight = document.querySelector("header").offsetHeight;

    let height = window.innerHeight + window.scrollY - headerHeight - 40;
    window.scrollTo({
      top: height,
      behavior: "smooth",
    });
  };

  const renderButton = () => {
    return handleEvent ? scrollEvent?.() : callback?.();
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.body.scrollHeight - 40
      ) {
        setHandleEvent(false);
        setTitle(callbackTitle);
      } else {
        setHandleEvent(true);
        setTitle("내려가기");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return [title, renderButton];
};

export default useHandleButton;
