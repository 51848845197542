import {
  ManagementContainer,
  ManagementListSkeleton,
} from "containers/Management";
import { Layout } from "layout";
import { useRouter } from "lib/utils/useRouter";
import { Suspense } from "react";

const Management = () => {
  const useRoute = useRouter();

  return (
    <Layout
      header={{
        title: "자산 연결 관리",
        onClick: () => {
          useRoute.changePath("/menu");
        },
      }}
    >
      <Suspense fallback={<ManagementListSkeleton />}>
        <ManagementContainer />
      </Suspense>
    </Layout>
  );
};

export default Management;
