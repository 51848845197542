import { RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "lib/apis/QueryClient";
import router from "routes/routing";

import "./assets/css/reset.css";
import "./assets/css/style.css";

import "./assets/css/popup.css";
import "./assets/css/mydataS.css";
import "./assets/css/mydataJ.css";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  );
}

export default App;
