import React from "react";

const OrgDetails = (props) => {
  const details = props.details;

  return (
    <div className="asset_info_contents">
      <h2 className="asset_info_title">{details.org_nm}</h2>

      <ul className="asset_info_list">
        <li>
          <strong>데이터 요청 목적</strong>
          <span>{details.purpose}</span>
        </li>
        <li>
          <strong>자동 업데이트</strong>
          <span>
            {details.is_scheduled === "false" ? "미동의" : "동의"}
            {details.is_scheduled === "true"
              ? details.fnd_cycle === "1/w"
                ? "(주 1회)"
                : "(일 1회)"
              : ""}
          </span>
        </li>
        <li>
          <strong>전송 기간</strong>
          <span>
            {details.end_date.substring(0, 4) +
              "년 " +
              details.end_date.substring(4, 6) +
              "월 " +
              details.end_date.substring(6, 8) +
              "일까지"}
          </span>
        </li>
        <li>
          <strong>정보보유 기간</strong>
          <span>서비스 이용 종료 시 또는 삭제 요구 시까지</span>
        </li>
        <li>
          <strong>개인신용정보 제공 동의</strong>
          <span>
            가맹점명 및 사업자등록번호 정보 (
            {details.merchant_name_yn === "Y" ? "동의" : "미동의"})
          </span>
        </li>
      </ul>
    </div>
  );
};

export default OrgDetails;
