import { useRouter } from "lib/utils/useRouter";
import { CustomButton } from "components/Button";
import { getDateFormat } from "utils/utils";

const CardWrap = (props) => {
  const useRoute = useRouter();
  const cardInMainData = props.cardInMainQuery?.data;
  const filteredOrg = props.filteredOrg;
  const month = getDateFormat();

  return (
    <div className="main_box asset_total_box">
      <div className="asset_title">
        <span>이 달의 지출</span>
        <p
          className="link"
          onClick={() => {
            useRoute.routeTo(`/payment?search=${month}`);
          }}
        >
          {cardInMainData?.totalAmtFormat + "원"}
        </p>

        {cardInMainData?.compareCode === "lt" ? (
          <span>
            지난달 같은 기간보다 {cardInMainData?.prevAmtCompare}원 덜 썼어요
          </span>
        ) : null}
        {cardInMainData?.compareCode === "eq" ? (
          <span>지난달 같은 기간과 비슷하게 썼어요</span>
        ) : null}
        {cardInMainData?.compareCode === "gt" ? (
          <span>
            지난달 같은 기간보다 {cardInMainData?.prevAmtCompare}원 더 썼어요
          </span>
        ) : null}
      </div>
      <ul className="table_list_article main_card_list">
        {cardInMainData?.cardAmtList
          ?.filter((value, index) => index < 2)
          .map((card, index) => {
            return (
              <li key={index}>
                <div className="table_list_item card_item has_link">
                  <div className="card_img">
                    {/* <img src={sampleCardImg} alt="" /> */}
                    <img src={card.orgImg || ""} alt="" />
                  </div>
                  <div className="card_info">
                    <p>
                      {card.cardName}

                      {filteredOrg?.filter(
                        (linked) => linked.orgCode === "DUMMYCARD"
                      ).length > 0 ? (
                        <span
                          style={{
                            color: "#666",
                            fontSize: "12px",
                            marginLeft: "12px",
                          }}
                        >
                          연결 해제된 자산
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  <div className="card_price ">
                    <p>{card.useAmt + "원"}</p>
                  </div>
                  <p
                    className="link"
                    onClick={() => {
                      useRoute.routeTo(
                        `/card/${card.cardId}/history?orgCode=${card.orgCode}&searchDate=${month}`
                      );
                    }}
                  >
                    <span>자세히 보기</span>
                  </p>
                </div>
              </li>
            );
          })}
      </ul>
      <CustomButton
        title={"내 카드 전체보기"}
        onClick={() => {
          useRoute.routeTo("/card");
        }}
        buttonClass={"btn_main_box"}
      />
    </div>
  );
};

export default CardWrap;
