import React, { useEffect, useState } from "react";

const BottomSelect = ({
  isOpen,
  bottomClose,
  bottomData,
  name,
  title,
  onChange,
}) => {
  const [list, setList] = useState([{ code: "", nm: "" }]);
  const [isAnimation, setIsAnimation] = useState(false);
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    const listArr = [];

    for (let i = 0; i < bottomData.length; i++) {
      listArr.push(bottomData[i]);
    }
    setList(listArr);
  }, [bottomData]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.cssText = `
            position:fixed; 
            width:100%;
            top: -${window.scrollY}px;
            overflow-y: scroll;
            `;

      setAnimationClass("mount2");
      setIsAnimation(true);
    } else {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);

      setAnimationClass("unmount");
      setTimeout(() => {
        setIsAnimation(false);
      }, 100);
    }
  }, [isOpen]);

  return (
    <div
      className={
        isAnimation
          ? `dimlayer_z define_bot active motion ${animationClass}`
          : `dimlayer_z define_bot ${animationClass}`
      }
    >
      <div
        className="bg_dim"
        onClick={bottomClose}
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          top: "0px",
          left: "0px",
          opacity: "0",
        }}
      ></div>
      <div className="botpos_vlayer">
        <div className="botpos_vtitlow">
          <p className="botpos_vtit">{title}</p>
        </div>
        <div className="botpos_vcontlow">
          <ul className="form_option_vlist" style={{ textAlign: "left" }}>
            {list.map((item) => (
              <li key={item.code}>
                <span className="bottom_radio">
                  <input
                    type="radio"
                    value={item.code}
                    className="form_option radio_btn"
                    name={name}
                    id={name + "_" + item.code}
                    onChange={onChange}
                  />
                  <label
                    htmlFor={name + "_" + item.code}
                    className="radio_label"
                  >
                    {item.nm}
                  </label>
                </span>
              </li>
            ))}
          </ul>
        </div>
        <button
          type="button"
          className="btn_botpos_close"
          onClick={bottomClose}
        >
          <span className="hdtext">닫기</span>
        </button>
      </div>
    </div>
  );
};
export default BottomSelect;
