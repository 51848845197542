import React from "react";

const BillingHistorySkeleton = () => {
  return (
    <div className="card_container">
      <section className="card_wrap">
        <div className="billing_org_article">
          <div className="billing_org_header">
            <div>
              <div
                className="skeleton skeleton_card_img"
                style={{ marginRight: "12px" }}
              ></div>
              <div className="skeleton skeleton_card_price"></div>
            </div>
          </div>
          <div className="billing_org_body">
            <div className="charge_info">
              <p className="skeleton skeleton_subtitle"></p>
              <div className="skeleton skeleton_title"></div>
            </div>
          </div>
        </div>
      </section>
      <div className="block_line"></div>
      <div className="card_history_article">
        <ul className="card_history_list">
          <li>
            <div className="card_history_item">
              <div className="card_history_item_summary">
                <div className="skeleton skeleton_card_price"></div>
              </div>
              <ul className="card_history_product_list">
                <li>
                  <div className="payment_info">
                    <div className="payment_info_row">
                      <p className="skeleton skeleton_subtitle"></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                    <div className="payment_info_row info_desc skeleton_row">
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="payment_info">
                    <div className="payment_info_row">
                      <p className="skeleton skeleton_subtitle"></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                    <div className="payment_info_row info_desc skeleton_row">
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="payment_info">
                    <div className="payment_info_row">
                      <p className="skeleton skeleton_subtitle"></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                    <div className="payment_info_row info_desc skeleton_row">
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div className="card_history_item">
              <div className="card_history_item_summary">
                <div className="skeleton skeleton_card_price"></div>
              </div>
              <ul className="card_history_product_list">
                <li>
                  <div className="payment_info">
                    <div className="payment_info_row">
                      <p className="skeleton skeleton_subtitle"></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                    <div className="payment_info_row info_desc skeleton_row">
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="payment_info">
                    <div className="payment_info_row">
                      <p className="skeleton skeleton_subtitle"></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                    <div className="payment_info_row info_desc skeleton_row">
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="payment_info">
                    <div className="payment_info_row">
                      <p className="skeleton skeleton_subtitle"></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                    <div className="payment_info_row info_desc skeleton_row">
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="payment_info">
                    <div className="payment_info_row">
                      <p className="skeleton skeleton_subtitle"></p>
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                    <div className="payment_info_row info_desc skeleton_row">
                      <p className="skeleton skeleton_subtitle"></p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BillingHistorySkeleton;
