import { createPortal } from "react-dom";

const LoadingSpinnerContainer = ({ children }) => {
  return createPortal(
    <>{children}</>,
    document.getElementById("loading-spinner")
  );
};

export default LoadingSpinnerContainer;
