import React from "react";

const Button = ({ onClick, title, className, disabled, type }) => {
  return (
    <button
      type={type}
      className={[
        "btn",
        className === "" ? "btn_default" : className,
        disabled ? "btn_default" : "",
      ].join(" ")}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default Button;
