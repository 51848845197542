import { React, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useLocation } from "react-router";
import { confirmMsg, printMsg } from "utils/utils";
import commonApi from "apis/common/commonApi";
import { getCookies } from "utils/Cookie";
import { commonFetch, jsonFetch, paramFetch } from "apis/common/fetchInstance";
import { CSSTransition } from "react-transition-group";
import BottomAlert from "../../components/BottomAlert";

const Tc_pwdChange = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [numPad, setNumPad] = useState([]); //숫자 패드
  const [pwdText, setPwdText] = useState([]); //패스워드 1자리씩 저장
  const [password, setPassword] = useState(""); //패스워드
  const [pwdChk, setPwdChk] = useState(false);
  const [failCnt, setFailCnt] = useState();
  const [isFailAlert, setIsFailAlert] = useState(false);
  const [alertText, setAlertText] = useState([]);

  useEffect(() => {
    if (pwdText.length === 6) {
      handleSetPwd();
    }
  }, [pwdText]);

  useEffect(() => {
    randomNumber();
  }, []);

  const randomNumber = (array) => {
    const numArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

    setNumPad(numArr.sort(() => Math.random() - 0.5)); //숫자 패드 섞기
  };

  const onClickNumPad = (num) => {
    setPwdText(pwdText.concat(num));
  };

  const handleSetPwd = () => {
    setPassword(pwdText.join(""));
    setPwdChk(true);
  };

  const handleSetClear = () => {
    if (pwdText.length > 0) {
      const backNum = pwdText.slice(0, pwdText.length - 1);
      setPwdText(backNum);
    }
  };

  const yesClick = () => {
    navigate("/reset");
  };

  const NumDot = () => {
    return (
      <>
        <span
          className={"pfx_dot " + (pwdText[0] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[1] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[2] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[3] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[4] === undefined ? null : "active")}
        ></span>
        <span
          className={"pfx_dot " + (pwdText[5] === undefined ? null : "active")}
        ></span>
      </>
    );
  };

  useEffect(() => {
    loginApi();
  }, [pwdChk]);



  const loginApi = async () => {
    if (pwdChk) {

      const { response, data } = await commonFetch(
        `/api/app/check?password=${password}`,
        "get"
      );
      if (response.status === 200) {

          navigate("/update/pwdChangeSet");
      } else {
        setFailCnt(data);
        setIsFailAlert(true);
        setPwdText([]);
        setPwdChk(!pwdChk);
      }
    }
  };

  useEffect(() => {
    if (failCnt < 5) {
      let cnt_array = [];
      cnt_array.push("비밀번호가 일치하지 않습니다(" + failCnt + "/5)");
      cnt_array.push("처음부터 다시 입력해주세요");
      setAlertText(cnt_array);
    } else {
      let fail_array = [];
      fail_array.push("비밀번호 틀린 횟수가 5회 초과되어");
      fail_array.push("간편비밀번호 재설정이 필요합니다.");
      setAlertText(fail_array);
    }
  }, [failCnt]);

  const bottomClose = () => {
    setIsFailAlert(!isFailAlert);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="front_body">
      <div className="page_wrap has_botpos">
        <header className="page_header">
          <a className="btn_back" onClick={goBack}>
            <span className="hdtext">뒤로가기</span>
          </a>
          <div
            className={isFailAlert ? "bg_layout" : null}
            onClick={bottomClose}
          ></div>
        </header>
        <section className="page_fxsection">
          <div className="pfx_top">
            <div className="pfx_copy">
              <h2 className="pfx_ctitle">비밀번호 입력</h2>
              <p className="pfx_csub">현재 6자리를 비밀번호를 입력해주세요.</p>
            </div>
            <div className="pfx_dot_w">
              <NumDot></NumDot>
            </div>

            <div className="pfx_orgin_input_w">
              <input type="text" className="pfx_origin_input" />
            </div>
          </div>
          {/* <div className="pfx_warnlow">
                        <p className="pfx_warn01">비밀번호가 일치하지 않습니다.</p>
                        <p className="pfx_warn02">다시 입력주세요(1/5)</p>
                    </div> */}
          <div className="pfx_bottom">
            <div className="pfx_numkeyboard">
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[0])}
              >
                {numPad[0]}
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[1])}
              >
                {numPad[1]}
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[2])}
              >
                {numPad[2]}
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[3])}
              >
                {numPad[3]}
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[4])}
              >
                {numPad[4]}
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[5])}
              >
                {numPad[5]}
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[6])}
              >
                {numPad[6]}
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[7])}
              >
                {numPad[7]}
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[8])}
              >
                {numPad[8]}
              </button>
              <button
                type="button"
                className="pnum_key define_text"
                onClick={randomNumber}
              >
                재배열
              </button>
              <button
                type="button"
                className="pnum_key define_num"
                onClick={() => onClickNumPad(numPad[9])}
              >
                {numPad[9]}
              </button>
              <button
                type="button"
                className="pnum_key define_ico ico_back"
                onClick={handleSetClear}
              >
                <span className="hdtext">뒤로가기</span>
              </button>
            </div>
          </div>
          {failCnt < 5 ? (
            <CSSTransition in={isFailAlert} classNames="bottom" timeout={300}>
              <BottomAlert
                isOpen={isFailAlert}
                bottomClose={bottomClose}
                alertText={alertText}
              />
            </CSSTransition>
          ) : (
            <CSSTransition in={isFailAlert} classNames="bottom" timeout={300}>
              <BottomAlert
                isOpen={isFailAlert}
                alertText={alertText}
                status="fail"
                yesClick={yesClick}
              />
            </CSSTransition>
          )}
        </section>
      </div>
    </div>
  );
};
export default Tc_pwdChange;
