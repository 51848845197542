import React from "react";
import { useGetConsent } from "features/mydata";

const Consent1 = (orgList) => {
  const { data } = useGetConsent(0);

  return (
    <div className="mydata_terms_contents has_bottom">
      <h2>정보 제공을 위해 동의해주세요</h2>
      <div className="consent_wrap">
        <h3>
          가입상품 목록 전송요구<br></br> 및 개인(신용)정보 수집이용 동의서
        </h3>
        뱅큐는 [신용정보의 이용 및 보호에 관한 법률], [개인정보 보호법] 등 관련
        법령에 따라 개인신용정보를 처리합니다.
        <p className="terms_title">거부할 권리 및 불이익</p>
        개인신용정보 수집이용에 관한 동의를 거부하실 수 있습니다. 하지만, 서비스
        이용을 위해 동의가 필요합니다. 동의하지 않으면 본인신용정보 통합조회,
        데이터분석 서비스의 이용이 제한될 수 있습니다.
        <p className="terms_title">정보를 보내는 곳</p>
        {orgList?.orgList?.map((org) => org.org_nm).join(", ")}
        <p className="terms_title">정보를 받는 곳</p>
        뱅큐
        <p className="terms_title">목적</p>
        상세정보 전송요구를 위한 가입상품목록 조회
        <p className="terms_title">종료 시점 / 보유 및 이용기간</p>
        <p className="terms_em">상세정보 전송요구시까지 또는 최대 7일</p>
        <p className="terms_title">정기적 전송</p>
        아니오
        <p className="terms_title">전송 요구 정보</p>
        <ul className="terms_detail_list">
          <li>{data?.sendReceiveInfo}</li>
        </ul>
        <p className="terms_title">수집 · 이용 정보</p>
        <p className="terms_subtitle">개인식별정보</p>
        <ul className="terms_detail_list">
          <li>전자서명, 접근토큰, 인증서, 전송요구서</li>
        </ul>
        <p className="terms_subtitle">신용거래정보</p>
        <ul className="terms_detail_list">
          <li>{data?.creditTransactionInfo}</li>
        </ul>
      </div>
    </div>
  );
};

export default Consent1;
