import { MyDataHeader } from "layout";
import { useLocate } from "lib/utils/useRouter";
import { ConsentAssetContainer } from "containers/Mydata";

const ConsentForAsset = () => {
  const stateData = useLocate().getStateData();

  return (
    <div className="front_body">
      <div className="page_wrap">
        <MyDataHeader />

        <ConsentAssetContainer
          assetList={stateData.assetList}
          cert={stateData.cert}
        />
      </div>
    </div>
  );
};

export default ConsentForAsset;
