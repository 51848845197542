import React, { useState, useEffect } from "react";
import sampleIcon from "assets/images/category/etc.png";
import { useRouter } from "lib/utils/useRouter";
import { numComma, isEmpty } from "utils/utils";

const HistoryItem = (props) => {
  const expenseDto = props.expenseDto;
  const route = useRouter();

  return (
    <li className="payment_history_item">
      <div className="payment_item_header">
        <p>{expenseDto?.approvedMd}</p>
        <div>
          <span>
            {expenseDto?.approvedAtmSumFormat +
              "원 · " +
              numComma(expenseDto?.expenseCount) +
              "건"}
          </span>
        </div>
      </div>
      <div className="payment_item_body">
        <ul className="payment_list">
          {expenseDto?.expenseList?.map((expense, index) => (
            <ExpenseItem expense={expense} key={index} route={route} />
          ))}
        </ul>
      </div>
    </li>
  );
};

export default HistoryItem;

const ExpenseItem = (props) => {
  const expense = props.expense;
  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    if (expense.status === "02") {
      setIsCancel(true);
    }
  }, [props.expense]);

  return (
    <li className="payment_list_item">
      <div className="payment_item_article">
        {/* <div className="payment_thumbnail">
          <img src={sampleIcon} alt="" />
        </div> */}
        <div className="payment_info">
          <div className="payment_info_row">
            <p>{isEmpty(expense.merchantName) ? "" : expense.merchantName}</p>
            <strong className={isCancel ? "cancel" : ""}>
              {expense.approvedAmtFormat + "원"}
            </strong>
          </div>
          <div className="payment_info_row info_desc">
            <span>기타 | {expense.cardName}</span>
            <span>{isCancel ? expense.statusName : expense.prodTypeName}</span>
          </div>
        </div>
      </div>
      <p
        onClick={() => {
          props.route.routeIncludeState(`details`, {
            orgCode: expense.orgCode,
            approvedDateSeq: expense.approvedDateSeq,
            approvedNum: expense.approvedNum,
          });
        }}
        className="payment_link"
      >
        <span>자세히 보기</span>
      </p>
    </li>
  );
};
