import loading from '../assets/images/loading_gif_test.gif'


const IsLoding = ({ }) => {

    return (
        <div className='loading_wrap'>
            <div className='loading'>
                <img src={loading} alt="잠시만 기다려주세요"></img>
            </div>
        </div>
    )

}
export default IsLoding;