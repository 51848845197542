import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

export const useRouter = () => {
  const router = useNavigate();

  return {
    currentPath: window.location.pathname,
    routeTo: (path) => router(path),
    routeIncludeState: (path, data) => {
      window.history.scrollRestoration = "manual";
      router(path, { state: data });
    },
    changePath: (path, data) => {
      window.scrollTo(0, 0);
      router(path, {
        replace: true,
        state: data,
      });
    },
    back: () => router(-1),
  };
};

export const useLocate = () => {
  const location = useLocation();

  return {
    getStateData: () => location.state,
  };
};

export const useParam = () => {
  const params = useParams();

  return {
    getPathParameter: () => params,
  };
};
