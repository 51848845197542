import React from "react";

const PointListItem = (props) => {
  const point = props.point;

  return (
    <li>
      <div className="point_item">
        <p>{point.orgName}</p>
        <div>
          <span>{point.pointName}</span>
          <p>{point.remainPointAmtFormat + "P"}</p>
        </div>
      </div>
    </li>
  );
};

export default PointListItem;
