import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";

const QUERY_KEY = "card-history";

const fetchCardHistory = async (cardId, param) => {
  return await axiosInstance
    .get(`/mydata/card/${cardId}`, {
      params: param,
    })
    .then((response) => response.data)
    .then((response) => response.data);
};

const useGetCardHistory = (cardId, param, flag) => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY, { cardId, param }),
    queryFn: async () => await fetchCardHistory(cardId, param),
    enabled: flag,
    suspense: true,
  });
  return query;
};

export { useGetCardHistory };
