import { Layout } from "layout";
import {
  ApprovedDetailContainer,
  ApprovedDetailSkeleton,
} from "containers/Card";
import { Suspense } from "react";

const CardHistoryDetail = () => {
  return (
    <Layout header={{ title: "상세내역" }}>
      <Suspense fallback={<ApprovedDetailSkeleton />}>
        <ApprovedDetailContainer />
      </Suspense>
    </Layout>
  );
};

export default CardHistoryDetail;
