import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Tc_agree = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const title = location.state.test.title;
    const content = location.state.test.content


    const goBack = () => {
        navigate(-1);
    }

    return (
        <div className="front_body type2">
            <div className="page_wrap">
                <header className="page_header">
                    <a className="btn_back" onClick={goBack}><span className="hdtext">뒤로가기</span></a>
                    <h1 className="page_headtitle">{title}</h1>
                </header>
                <section className="page_midsection">
                    <div className="page_guide_vitem_w">
                        <div className="page_guide_vitem">
                            {content}
                            {/* <h3 className="page_guide_tit">제1조(목적)</h3>
                            <div className="page_guide_cont">
                                <ul className="vitem_list">
                                    <li>고객님께서 선택하신 대출방식 및 상품별로 원금이자의 상환시기 및 방법이 일부 상이할 수 있으므로 대출취급시 작성하시는 대출거래약정서의 대출실행방법 및 이자 지급방법 내용을 참조하여 주시기 바랍니다.</li>
                                    <li>고객님께서 선택하신 대출방식 및 상품별로 원금이자의 상환시기 및 방법이 일부 상이할 수 있으므로 대출취급시 작성하시는 대출거래약정서의 대출실행방법 및 이자 지급방법 내용을 참조하여 주시기 바랍니다.</li>
                                    <li>고객님께서 선택하신 대출방식 및 상품별로 원금이자의 상환시기 및 방법이 일부 상이할 수 있으므로 대출취급시 작성하시는 대출거래약정서의 대출실행방법 및 이자 지급방법 내용을 참조하여 주시기 바랍니다.</li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                    <ul className="vdot_list define_botguide">
                        <li>공고일자 : 2022.02.24</li>
                    </ul>
                </section>
            </div>
        </div>

    )

}
export default Tc_agree;