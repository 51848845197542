import React, { Suspense } from "react";
import { ResultContainer } from "containers/Mydata";

// 통합인증처리상태코드
// 01-요청
// 02-성공
// 03-실패
// 11-취소
// 12-취소후 성공

const Result = () => {
  return (
    <Suspense fallback={<Loading />}>
      <ResultContainer />
    </Suspense>
  );
};

export default Result;

const Loading = () => {
  return (
    <div className="front_body">
      <div className="loading_content">
        <p>자산을 불러오고 있습니다.</p>
      </div>
    </div>
  );
};
