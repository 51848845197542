import React from "react";

const InputRadioContent = () => {
  return (
    <div className="schedule_wrap">
      <p className="terms_title">정기적 전송</p>
      <div className="schedule_content">
        <input
          type="radio"
          value="true"
          name="is_scheduled"
          id="1week"
          defaultChecked
        />
        <label htmlFor="1week" className="radio_label">
          주 1회
        </label>
        <input type="radio" value="false" name="is_scheduled" id="none" />
        <label htmlFor="none" className="radio_label">
          전송하지 않음
        </label>
      </div>
    </div>
  );
};

export default InputRadioContent;
