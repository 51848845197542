const OrganizationItem = ({ selectList, organization, onChange }) => {
  return (
    <li className="item_box">
      <input
        type="checkbox"
        id={organization.org_code}
        value={organization.org_code}
        className={
          organization.is_linked === "true"
            ? "select_input linked_org"
            : "select_input"
        }
        checked={selectList.includes(organization)}
        onChange={(e) => onChange(e.target.checked, organization)}
      />
      <label htmlFor={organization.org_code} className="item_check_label">
        <div className="item_img">
          <img src={organization.img_url} alt=""></img>
        </div>
        <div className="item_name">
          <p>{organization.org_nm}</p>
        </div>
      </label>
    </li>
  );
};

export default OrganizationItem;
