import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";

const QUERY_KEY = "is-linked";

const fetchIsLinked = async () => {
  return await axiosInstance
    .get("/mydata/property/is-linked")
    .then((response) => response.data)
    .then((response) => response.data);
};

const useGetIsLinked = () => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY),
    queryFn: async () => await fetchIsLinked(),
    suspense: true,
  });
  return query;
};

export default useGetIsLinked;
