import React from "react";
import { useLocate } from "lib/utils/useRouter";
import { useGetBillingDetails } from "features/billing";
import { isEmpty } from "utils/utils";

const BillingDetailsContainer = () => {
  const stateData = useLocate().getStateData();
  const billingDetailsQuery = useGetBillingDetails(stateData);
  const billingDetails = billingDetailsQuery?.data;

  return (
    <div className="card_container">
      <section className="card_wrap">
        <div className="billing_article">
          <div className="billing_title">
            <h3>{billingDetails.merchantName}</h3>
            <p>{billingDetails.paidDate}</p>
            <h2>{billingDetails.paidAmt + "원"}</h2>
          </div>
        </div>
      </section>
      <section className="billing_detail_article">
        <ul className="table_article">
          {billingDetails.cardNum !== "-" ? (
            <>
              <li>
                <div className="billing_detail">
                  <span>결제카드</span>
                  <p>{billingDetails.cardName}</p>
                </div>
              </li>
              <li>
                <div className="billing_detail">
                  <span>카드번호</span>
                  <p>{billingDetails.cardNum}</p>
                </div>
              </li>
            </>
          ) : null}

          <li>
            <div className="billing_detail">
              <span>거래번호</span>
              <p>{billingDetails.transNo}</p>
            </div>
          </li>
          {billingDetails.merchantRegno !== null ? (
            <li>
              <div className="billing_detail">
                <span>사업자등록번호</span>
                <p>{billingDetails.merchantRegno}</p>
              </div>
            </li>
          ) : null}

          <li>
            <div className="billing_detail">
              <span>통화코드</span>
              <p>{billingDetails.currencyCode}</p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <span>수수료</span>
              <p>{billingDetails.creditFeeAmt + "원"}</p>
            </div>
          </li>
          {billingDetails.totalInstallCnt > 0 ? (
            <li>
              <div className="billing_detail">
                <span>할부현황</span>
                <p>{billingDetails.installFormat}</p>
              </div>
            </li>
          ) : null}

          {billingDetails.prodType === "02" ? (
            <li>
              <div className="billing_detail">
                <span>할부 결제 후 잔액</span>
                <p>{billingDetails.balanceAmt + "원"}</p>
              </div>
            </li>
          ) : null}

          <li>
            <div className="billing_detail">
              <span>상품구분</span>
              <p>{billingDetails.prodTypeName}</p>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default BillingDetailsContainer;
