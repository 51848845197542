import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import down from '../../assets/images/addPage/Down-Arrow-PNG-Transparent-Image.png';
import { useNavigate } from 'react-router-dom';
import { paramFetch, jsonFetch ,commonFetch} from 'apis/common/fetchInstance'
import { filter } from 'lodash';


function Tc_q_and_a() {

    let navigate = useNavigate();

    const [qnaList, setQnaList] = useState([]);
    const [codeList, setCodeList] = useState([]);
    const [selectCode, setSelectCode] = useState({ no: 0, code: 'all', name: "전체" });
    const [test, setTest] = useState(false);
    const [no, setNo] = useState();
    const [isAnimation, setIsAnimation] = useState(false);

    useEffect(() => {
        codeCallApi();
    }, [])

    useEffect(() => {
        qanCallApi();
    }, [selectCode])

    const qanCallApi = async () => {
        // const sendData = {
        //     selectCode: selectCode
        // }

        const { response, data } = await commonFetch(`/api/qna?code=${selectCode.code}`, 'get');
        if (response.status === 200) {
            let tempArr = []
            for (let i = 0; i < data.length; i++) {
                const obj = data[i];
                tempArr.push({ ...obj, isToggle: false });
            }
            setQnaList(tempArr);
        }
    }

    const codeCallApi = async () => {
        const { response, data } = await commonFetch(`/api/qna/code`, 'get');
        const tempArr = [{ no: 0, code: "all", name: "전체" }]
        if (response.status === 200) {
            tempArr.push(...data);
        }
        setCodeList(tempArr);
    }

    const handleCode = (code) => {
        setSelectCode(code);
    }

    const handleQna = (toggle, no) => {
        const listObj = [...qnaList];
        const listNo = listObj.findIndex(filter => filter.no === no);

        listObj[listNo].isToggle = !listObj[listNo].isToggle;

        setTimeout(() => {
            setQnaList(listObj);
            setNo(no);
        }, 200)
    }


    return (

        <div className="front_body">
            <div className="page_wrap">
                <header className="page_header">
                    <Link to='' className="btn_back" onClick={() => navigate(-1)}><span className="hdtext">뒤로가기</span></Link>
                    <h1 className="page_headtitle">자주 묻는 질문</h1>
                </header>
                <section className="page_midsection_qna dtype7">
                    <div>
                        <div className="selectSd">
                            <ul className="clearfix bottomBtn_area">
                                {
                                    codeList.map(item => (
                                        <li className={selectCode.code === item.code ? 'bottomBtn on' : 'bottomBtn'} key={item.no}><a onClick={() => handleCode(item)}>{item.name}</a></li>
                                    ))
                                }
                            </ul>
                        </div>
                        {
                            qnaList.map((item => (
                                <ul className={item.isToggle && item.no === no ? `q_vlist on` : `q_vlist`} key={item.no}>
                                        <li>
                                            <div className="q_vitem_qna" onTouchStart={() => handleQna(item.isToggle, item.no)}>
                                                <div className="q_spec_w_qna">
                                                    <img src={down} alt="" style={{ transform: item.isToggle && item.no === no ? 'rotate(180deg)' : null }} />
                                                    <span className="annc_spec01">Q. {item.askQuestion}</span>
                                                    {
                                                        item.isToggle && item.no === no
                                                            ? <>
                                                                <div style={{ marginBottom: "10px", marginTop: "10px" }}></div>
                                                                <div className={item.isToggle && item.no === no ? "slide-fade-in-dropdown" : "slide-fade-out-dropdown"}>
                                                                    {item.askQuestionAns}
                                                                </div>
                                                            </>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                </ul>
                            )))
                        }
                    </div>
                    <div className="other_q">
                        <h1>티칭캐시 고객센터</h1>
                        <p>원하는 정보를 찾지 못하셨나요?<br />'더보기 1대1 채팅 문의'<br />로 문의사항을 보내주시면 신속하게 도와드리겠습니다.<br />이용에 불편을 드려서 죄송합니다.</p>
                    </div>
                </section>
            </div>
        </div>
    )
};

export default Tc_q_and_a
