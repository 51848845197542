import React from "react";

const ManagementDetailsSkeleton = () => {
  return (
    <div className="asset_manage_wrap">
      <div className="asset_info_contents">
        <div className="skeleton skeleton_title"></div>

        <ul className="asset_info_list">
          <li>
            <p className="skeleton skeleton_subtitle"></p>
            <p className="skeleton skeleton_subtitle"></p>
          </li>
          <li>
            <p className="skeleton skeleton_subtitle"></p>
            <p className="skeleton skeleton_subtitle"></p>
          </li>
          <li>
            <p className="skeleton skeleton_subtitle"></p>
            <p className="skeleton skeleton_subtitle"></p>
          </li>
          <li>
            <p className="skeleton skeleton_subtitle"></p>
            <p className="skeleton skeleton_subtitle"></p>
          </li>
          <li>
            <p className="skeleton skeleton_subtitle"></p>
            <p className="skeleton skeleton_subtitle"></p>
          </li>
        </ul>
      </div>
      <div className="asset_info_block">
        <div className="asset_info_header">
          <p className="skeleton skeleton_subtitle"></p>
        </div>
        <div>
          <ul className="account_list">
            <li>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
            <li>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
            <li>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
            <li>
              <p className="skeleton skeleton_subtitle"></p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ManagementDetailsSkeleton;
