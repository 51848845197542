import { Button } from "components/Button";
import { useRouter } from "lib/utils/useRouter";
import noCardImg from "assets/images/bankq/no-card.png";

const NoLinkedOrg = () => {
  const useRoute = useRouter();
  return (
    <div className="no_linked_container">
      <div className="no_linked_block">
        <img src={noCardImg} alt="" />
        <p>연결된 카드 정보가 없습니다.</p>
      </div>
      <Button
        className="btn_cert"
        title="카드 추가하기"
        onClick={() => {
          useRoute.routeTo("/mydata/register/organization");
        }}
      ></Button>
    </div>
  );
};

export default NoLinkedOrg;
