import { useState, useEffect } from "react";
import { Layout } from "layout";
import { CustomButton } from "components/Button";

import coverImg from "assets/images/bankq/money-obj.png";
import { useRouter } from "lib/utils/useRouter";
import { useMydataJoin } from "features/mydata";

import TermsContentModal from "components/Modal/TermsContentModal";
import { printMsg } from "utils/utils";

const ServiceJoin = () => {
  const { mutation } = useMydataJoin();
  const useRoute = useRouter();
  const [isPopup, setIsPopup] = useState(false);
  const mydataCenterUrl =
    "https://www.mydatacenter.or.kr:3441/myd/index/index.do";

  const handleClick = () => {
    mutation.mutate();
  };

  useEffect(() => {
    if (mutation?.data > -1) {
      useRoute.changePath("/mydata/register/organization");
    }
  }, [mutation?.isSuccess]);

  return (
    <Layout header={{ close: true }}>
      <div className="mydata_join_contents">
        <div className="mydata_join_box">
          <h1>
            나의 모든 자산, <br></br>한 눈에 보기 위해 동의가 필요해요
          </h1>

          <img src={coverImg} alt="마이데이터 가입"></img>
        </div>

        <div className="mydata_join_info">
          <p>소중한 내 정보를 지키기 위해 다음 사항을 꼭 확인해주세요.</p>
          <ul>
            <li>
              서비스 가입 전 한 번 더 고민하고, 필요한 서비스만 가입해주세요.
            </li>
            <li>잘 이용하지 않는 마이데이터 서비스는 해지해주세요.</li>
            <li>
              이용 중인 마이데이터 서비스 현황은{" "}
              <a href={mydataCenterUrl}>마이데이터종합포털</a>에서 확인 할 수
              있습니다.
            </li>
          </ul>
        </div>
      </div>

      <div className="mydata_join_bottom">
        <p
          className="link_terms"
          onClick={() => {
            setIsPopup(true);
          }}
        >
          [필수] 마이데이터 서비스 약관
        </p>

        <CustomButton
          title="동의하고 시작하기"
          buttonClass={"btn_naver_terms"}
          onClick={handleClick}
        />
      </div>
      {isPopup && (
        <TermsContentModal
          isOpen={isPopup}
          onClose={() => {
            setIsPopup(false);
          }}
          terms={{
            id: 1,

            title: "마이데이터 서비스 약관",
            contents: "",
            subType: "MYDATA",
            url: "",
          }}
        />
      )}
    </Layout>
  );
};

export default ServiceJoin;
