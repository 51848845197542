import React, { createPorta, useEffect } from "react";
import styled from "styled-components";
import LoadingSpinnerContainer from "./LoadingSpinnerContainer";

const Backdrop2 = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9999;
`;

const Wrap2 = styled.div`
  max-height: 100%;
  /* max-height: 95%; */
  max-width: 600px;
  width: 90%;
  height: fit-content;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: slide-fade-in 0.2s ease-in-out;
  overflow: hidden;
  border-radius: 10px;
  z-index: 10000;
`;

const LoadingSpinner = ({ isShow, onClose }) => {
  useEffect(() => {
    const body = document.querySelector("body");
    const overflow = body.style.overflow;
    body.style.overflow = "hidden";

    return () => {
      body.style.overflow = overflow;
    };
  }, []);

  return (
    <LoadingSpinnerContainer>
      <Backdrop2 />
      <Wrap2>
        <div className="droplet_spinner">
          <div className="droplet"></div>
          <div className="droplet"></div>
          <div className="droplet"></div>
        </div>
      </Wrap2>
    </LoadingSpinnerContainer>
  );
};

export default LoadingSpinner;
