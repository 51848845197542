import { useSearchParams } from "react-router-dom";
import "assets/css/stats.scss";
import WeekendStatistics from "./WeekendStatistics";
import CategoryStatistics from "./CategoryStatistics";

const StatisticsContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="stats_container">
      {/* <WeekendStatistics searchDate={searchParams.get("searchDate")} /> */}
      {/* <div className="block_line"></div> */}
      <CategoryStatistics searchDate={searchParams.get("searchDate")} />
    </div>
  );
};

export default StatisticsContainer;
