import React, { useState } from "react";

const RegisterAssetInfo = () => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="register_asset_info">
      <p
        onClick={() => {
          setShowInfo(!showInfo);
        }}
        className={showInfo ? "active" : ""}
      >
        선택한 자산이 없나요?
      </p>
      {showInfo ? (
        <ul>
          <li>
            연결하려는 기관에서 일시적인 오류가 있거나, 점검 중인 경우 자산을
            불러오지 못할 수 있습니다.
          </li>
          <li>법인카드, 가족카드, 재발급 이전 카드는 연결할 수 없습니다.</li>
        </ul>
      ) : null}
    </div>
  );
};

export default RegisterAssetInfo;
