import { useGetIsLinked, NoLinkedOrg } from "features/organization";
import { CardContainer } from "..";

const InitCardContainer = () => {
  const isLinkedOrganizationQuery = useGetIsLinked();

  return (
    <>{isLinkedOrganizationQuery?.data ? <CardContainer /> : <NoLinkedOrg />}</>
  );
};

export default InitCardContainer;
