import { useGetCardApproved } from "features/card";
import { useLocate } from "lib/utils/useRouter";
import { isEmpty } from "utils/utils";

const ApprovedDetailContainer = () => {
  const stateData = useLocate().getStateData();
  const approvedDetailQuery = useGetCardApproved(stateData.cardId, {
    approvedDate: stateData.approvedDate,
    seqno: stateData.seqno,
  });
  const approvedDatail = approvedDetailQuery?.data;

  return (
    <div className="card_container">
      <section className="card_wrap">
        <div className="billing_article">
          <div className="billing_title">
            <h3>
              {isEmpty(approvedDatail.merchantName)
                ? "알수없음"
                : approvedDatail.merchantName}{" "}
            </h3>
            <p>{approvedDatail.approvedDateTime}</p>
            <h2 className={approvedDatail.status === "02" ? "cancel" : ""}>
              {approvedDatail.approvedAmt + "원"}
            </h2>
          </div>
        </div>
      </section>
      <section className="billing_detail_article">
        <ul className="table_article">
          <li>
            <div className="billing_detail">
              <span>카드번호</span>
              <p>{approvedDatail.cardNum}</p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <span>결제카드</span>
              <p>{approvedDatail.cardName}</p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <span>승인번호</span>
              <p>{approvedDatail.approvedNum}</p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <span>승인상태</span>
              <p>{approvedDatail.statusName}</p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <span>사용구분</span>
              <p>{approvedDatail.payTypeName}</p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <span>결제방법</span>
              <p>{approvedDatail.payMethodName}</p>
            </div>
          </li>
          {isEmpty(approvedDatail.merchantRegno) ? (
            ""
          ) : (
            <li>
              <div className="billing_detail">
                <span>가맹점 사업자번호</span>
                <p>{approvedDatail.merchantRegno}</p>
              </div>
            </li>
          )}
        </ul>
      </section>
    </div>
  );
};

export default ApprovedDetailContainer;
