import React, { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { confirmMsg, printMsg, successMsg } from "utils/utils";
import { commonFetch, jsonFetch, paramFetch } from "apis/common/fetchInstance";
import styled from "styled-components";

const Tc_memberSecession = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const secession_check = () => {
    setIsChecked(!isChecked);
  };

  const handleSecession = () => {
    const msg = ["탈퇴하시겠습니까?", "뱅큐의 서비스를 이용할수 없습니다."];
    confirmMsg(msg, "아니요", "탈퇴하기", yesClick);
  };

  const yesClick = async () => {
    const { response, data } = await commonFetch("/api/app/secession", "put");
    if (response.status === 200) {
      window.localStorage.removeItem("acc_token");
      window.localStorage.removeItem("ref_token");
      const msg = ["탈퇴되었습니다.", "이용해주셔서 감사합니다."];
      successMsg(msg, successClick);
    }
  };

  const successClick = () => {
    window.Android.appFinish();
  };

  return (
    <div className="front_body type2" style={{ background: "none" }}>
      <div className="page_wrap">
        <header className="page_header">
          <Link to="/menu" className="btn_back">
            <span className="hdtext">뒤로가기</span>
          </Link>
          <h1 className="page_headtitle">회원탈퇴</h1>
        </header>
        <section className="page_midsection member_succession_block">
          <div className="page_guide_vitem_w">
            <div className="page_guide_vitem">
              <h3 className="secession_title">탈퇴 전에 꼭 확인해주세요</h3>
              <div className="page_guide_cont">
                <ul className="secession_cont">
                  <li>
                    - 회원탈퇴 시 개인정보 및 뱅큐에서 만들어진 모든 데이터는
                    삭제됩니다.
                  </li>
                  <li>
                    - 또한, 기존 전송요구권 행사 내용이 모두 철회되며
                    개인신용정보가 모두 삭제됩니다.
                  </li>
                  <li>
                    - 서비스 재이용을 위해서는 다시 전송동의가 필요합니다.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <ul className="vdot_list define_botguide secession_check">
            <input
              type="checkbox"
              className="chkrd"
              id="import_itemchk"
              onChange={secession_check}
            />
            <label htmlFor="import_itemchk" className="chkrd_label">
              위 내용을 모두 확인했습니다.
            </label>
          </ul>
        </section>
      </div>
      <div className="btn_botpos_wrap">
        <div className="botpos_in">
          <button
            className="btn_quadbox"
            id="btn_agreecall"
            disabled={!isChecked}
            onClick={handleSecession}
          >
            탈퇴하기
          </button>
        </div>
      </div>
    </div>
  );
};
export default Tc_memberSecession;
