import axios from "axios";

const commonApi = axios.create({
  baseURL: "/api",
  withCredentials: true,
});

commonApi.interceptors.request.use(
  function (config) {
    const access = localStorage.getItem("access");

    if (access === "" || access === undefined || access === null) {
      return config;
    }

    //const accessToken = JSON.parse(access);
    config.headers.common.Authorization = `Bearer ${access}`;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

commonApi.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    if (error.response && error.response.status === 403) {
      try {
        const originalRequest = error.config;
        // const data = await commonApi.get('auth/refreshtoken');
      } catch (error) {}
    }
  }
);

export default commonApi;
