import { useGetCardInfo } from "features/card";
import { useParam, useLocate } from "lib/utils/useRouter";

const CardDetailsContainer = () => {
  const params = useParam();
  const stateData = useLocate().getStateData();

  const cardInfoQuery = useGetCardInfo(params.getPathParameter().id, stateData);
  const cardInfo = cardInfoQuery?.data;

  return (
    <div className="card_container">
      <section className="card_wrap">
        <div className="billing_article">
          <div className="billing_title">
            <h3>{cardInfo.cardName}</h3>
            <p>{cardInfo.orgName}</p>
          </div>
        </div>
      </section>
      <section className="billing_detail_article">
        <ul className="table_article">
          <li>
            <div className="billing_detail">
              <span>발급일자</span>
              <p>{cardInfo.issueDate}</p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <span>카드구분</span>
              <p>{cardInfo.cardTypeName}</p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <span>연회비</span>
              <p>{cardInfo.annualFee === "-" ? 0 : cardInfo.annualFee}원</p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <span>교통카드 기능</span>
              <p>{cardInfo.transPayableName}</p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <span>현금카드 기능</span>
              <p>{cardInfo.cashCardName}</p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <span>결제은행</span>
              <p>{cardInfo.linkedBankCode}</p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <span>국제브랜드</span>
              <p>{cardInfo.cardBrand}</p>
            </div>
          </li>
          <li>
            <div className="billing_detail">
              <span>가족카드 여부</span>
              <p>{cardInfo.cardMember}</p>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default CardDetailsContainer;
