import { PointListItem } from "features/card";
import React from "react";

const MainPoint = (props) => {
  const pointQuery = props.pointQuery;

  return (
    <section className="card_wrap">
      <div className="card_article">
        <div className="card_summary_box">
          <p>총 보유 포인트</p>
          <h2>{pointQuery?.data?.totalPoint + "P"}</h2>
        </div>
        <ul className="table_list_article card_table_list">
          {pointQuery?.data?.pointList?.map((point, index) => {
            return <PointListItem point={point} key={index} />;
          })}
        </ul>
        <ul className="card_desc_list">
          <li>2개월 내 소멸 예정인 포인트를 알려 드립니다.</li>
          <li>제휴 포인트 등 일부 포인트는 조회되지 않을 수 있습니다.</li>
          <li>
            카드사 포인트 관련 세부 정책은 해당 카드사에서 확인할 수 있습니다.
          </li>
        </ul>
      </div>
    </section>
  );
};

export default MainPoint;
