import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";

const QUERY_KEY = "payment-main";

const fetchGetPaymentInMain = async () => {
  return await axiosInstance
    .get(`/mydata/payment/main`)
    .then((response) => response.data)
    .then((response) => response.data);
};

const useGetPaymentInMain = () => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY),
    queryFn: async () => await fetchGetPaymentInMain(),
  });
  return query;
};

export { useGetPaymentInMain };
