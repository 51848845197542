import axios from "axios";
import { printMsg } from "utils/utils";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
  withCredentials: true,
  // timeout: 5000,
});

const setRequestDefaultHeader = (requestConfig) => {
  const config = requestConfig;
  const accToken = localStorage.getItem("acc_token");

  config.headers = {
    ...config.headers,
    "Content-Type": "application/json",
    Authorization: `Bearer ${accToken}`,
  };

  return config;
};

const httpErrorHandler = (error) => {
  if (error.response.status === 401) {
    window.location.href = "/logout";
  }

  if (error.response.status === 404) {
    printMsg("서버에 오류가 발생했습니다.");
  }

  if (error.response.status === 405) {
    // printMsg("서버에 오류가 발생했습니다.");
    // window.location.href = "/home";
    // printMsg("서버에 오류가 발생했습니다.");
  }

  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(
  async (config) => {
    return setRequestDefaultHeader(config);
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  httpErrorHandler
);

export default axiosInstance;
