import React from "react";

const Loading = () => {
  return (
    <div className="front_body">
      <div className="loading_content">
        <p>자산을 불러오고 있습니다.</p>
      </div>
    </div>
  );
};

export default Loading;
