import React from "react";
import { useRouter } from "lib/utils/useRouter";
import { CustomButton } from "components/Button";

const MydataJoinInfo = () => {
  const useRoute = useRouter();

  return (
    <div className="main_box main_box_1">
      <div className="main_box_text">
        <p>
          쉽고 빠른
          <br />
          마이데이터<br></br>연결은<br></br>어디서?
        </p>
        <h1>뱅큐에서!</h1>
      </div>

      <CustomButton
        title={"마이데이터 시작하기"}
        onClick={() => {
          useRoute.routeTo("/mydata/join");
        }}
        buttonClass={"btn_main_join_box"}
      />
    </div>
  );
};

export default MydataJoinInfo;
