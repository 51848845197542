import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import IsLoding from "../../components/IsLoading";
import { commonFetch, jsonFetch, paramFetch } from "apis/common/fetchInstance";

const Tc_event_detail = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const { id } = useParams();

  const [loading, setLoading] = useState();
  const [eventData, setEventData] = useState([]);

  useEffect(() => {
    eventDetailApiCall();
  }, []);

  const eventDetailApiCall = async () => {
    setLoading(true);
    const { response, data } = await commonFetch(
      `/api/event/${id}`,
      "get"
    );
    if (response.status === 200) {
      setEventData(data);
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }
  };

  const handleLink = (link) => {};

  return (
    <>
      <div className="front_body">
        <div className="page_wrap">
          <header className="page_header_event">
            <Link to={"/event"} className="btn_back">
              <span className="hdtext">뒤로가기</span>
            </Link>
            <h1 className="page_headtitle"></h1>
          </header>
          {loading ? (
            <IsLoding />
          ) : (
            <section className="page_midsection_qna dtype7">
              <div className="page_event_detail">
                <div className="event_content">
                  <div>{eventData.eventContent}</div>
                  <img src={""} />
                  <div style={{ height: "2rem" }}></div>
                </div>
                <div className="event_link">
                  <button
                    type="button"
                    className="btn_quadbox closetrigger event_btn"
                    style={{ textAlign: "center" }}
                  >
                    이벤트 바로가기
                  </button>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    </>
  );
};

export default Tc_event_detail;
