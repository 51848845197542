import React from "react";

const ManagementListSkeleton = () => {
  return (
    <div className="asset_manage_wrap">
      <div className="asset_info_contents">
        <ul className="asset_info_list">
          <li>
            <p className="skeleton skeleton_subtitle"></p>
            <p className="skeleton skeleton_subtitle"></p>
          </li>
          <li>
            <p className="skeleton skeleton_subtitle"></p>
            <p className="skeleton skeleton_subtitle"></p>
          </li>
          <li>
            <p className="skeleton skeleton_subtitle"></p>
            <p className="skeleton skeleton_subtitle"></p>
          </li>
          <li>
            <p className="skeleton skeleton_subtitle"></p>
            <p className="skeleton skeleton_subtitle"></p>
          </li>
          <li>
            <p className="skeleton skeleton_subtitle"></p>
            <p className="skeleton skeleton_subtitle"></p>
          </li>
          <li>
            <p className="skeleton skeleton_subtitle"></p>
            <p className="skeleton skeleton_subtitle"></p>
          </li>
          <li>
            <p className="skeleton skeleton_subtitle"></p>
            <p className="skeleton skeleton_subtitle"></p>
          </li>
          <li>
            <p className="skeleton skeleton_subtitle"></p>
            <p className="skeleton skeleton_subtitle"></p>
          </li>
          <li>
            <p className="skeleton skeleton_subtitle"></p>
            <p className="skeleton skeleton_subtitle"></p>
          </li>
          <li>
            <p className="skeleton skeleton_subtitle"></p>
            <p className="skeleton skeleton_subtitle"></p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ManagementListSkeleton;
