import React from "react";
import Button from "./Button";

const CustomButton = ({ onClick, title, buttonClass, type, disabled }) => {
  const parentClass =
    buttonClass === "" ? "btn_company_bottom_wrap" : buttonClass + "_wrap";

  return (
    <div className={parentClass}>
      <Button
        title={title}
        className={buttonClass}
        type={type}
        onClick={onClick}
        disabled={disabled}
      ></Button>
    </div>
  );
};

export default CustomButton;
