import React from "react";
import { Link, useNavigate } from "react-router-dom";
// import BackImg from "assets/images/bankq/arrow.svg";
import BackImg from "assets/images/ico_back.png";
import CloseImg from "assets/images/bankq/icon-close.png";
import UpdateImg from "assets/images/bankq/rotate-right.png";

const MainLogo = () => {
  return (
    <>
      <div className="main_logo">
        <div className="scraping" />
      </div>
    </>
  );
};

const BackButton = ({ onBackEvent }) => {
  const navigate = useNavigate();

  const backEvent = () => {
    navigate(-1);
  };

  const handleCloseEvent = () => {
    if (onBackEvent !== undefined) return onBackEvent?.();

    return backEvent?.();
  };

  return (
    <button className="page_header_back" onClick={handleCloseEvent}>
      <img src={BackImg} alt="뒤로 가기" />
    </button>
  );
};

const CloseButton = () => {
  const navigate = useNavigate();
  const backEvent = () => {
    navigate(-1);
  };

  return (
    <button className="page_header_close" onClick={backEvent}>
      <img src={CloseImg} alt="뒤로 가기" />
    </button>
  );
};

const UpdateButton = ({ onClick, message }) => {
  return (
    <div className="page_header_update">
      <span>{message}</span>
      <button onClick={onClick}>
        <img src={UpdateImg} alt="새로고침" />
      </button>
    </div>
  );
};

const Header = ({
  title,
  logo,
  onClick,
  close,
  handleUpdateClick,
  message,
}) => {
  return (
    <header className={logo === undefined ? "header" : "page_header_main"}>
      {logo && <MainLogo onClick={handleUpdateClick} />}
      {!logo && !close && <BackButton onBackEvent={onClick} />}

      <div className="page_header_title">
        <h1>{title}</h1>
      </div>

      {close && <CloseButton />}

      {handleUpdateClick && (
        <UpdateButton onClick={handleUpdateClick} message={message} />
      )}
    </header>
  );
};

export default Header;
