import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";

const QUERY_KEY = "card-approved";

const fetchCardApproved = async (cardId, param) => {
  return await axiosInstance
    .get(`/mydata/card/amount/${cardId}`, {
      params: param,
    })
    .then((response) => response.data)
    .then((response) => response.data);
};

const useGetCardApproved = (cardId, param) => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY, param),
    queryFn: async () => await fetchCardApproved(cardId, param),
    suspense: true,
  });
  return query;
};

export { useGetCardApproved };
