import { useCustomMutation } from "lib/apis/QueryClient";

export default function useMyDataMutation(
  queryKey,
  queryFn,
  successCallbackFn,
  settledCallbackFn,
  errorCallbackFn
) {
  // @TODO 옵션 추가 예정
  const options = {
    useErrorBoundary: false,
    refetchOnMount: false,
    onSuccess: successCallbackFn,
    onSettle: settledCallbackFn,
    onError: errorCallbackFn,
  };
  return useCustomMutation(queryKey, queryFn, options);
}
