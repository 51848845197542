import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Header from "layout/Header";
import ModalContainer from "./ModalContainer";
import useOutSideClick from "hooks/useOutSideClick";

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
`;

const Wrap = styled.div`
  max-height: 100%;
  /* max-height: 95%; */
  max-width: 600px;
  width: 90%;
  height: fit-content;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: slide-fade-in 0.2s ease-in-out;
  overflow: hidden;
  border-radius: 10px;

  &.ModalBottom {
    bottom: 0;
    width: 100%;
    border-radius: 10px 10px 0 0;
    position: fixed;
    top: auto;
    transform: translate(-50%, 0);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.confirmModal {
    max-width: 400px;
    width: 80%;

    & > .body {
      padding: 0;
      padding-top: 30px;
      overflow-y: hidden;
    }

    p {
      width: 83%;
      margin: 0 auto;
    }

    footer {
      width: 100%;
      display: flex;
      margin-top: 24px;

      button {
        width: 100%;
        background-color: rgb(23, 111, 255);
        color: #fff;
        line-height: 56px;
        height: 56px;
        padding: 0 16px;
        font-weight: 600;
        font-size: 18px;

        &.prev {
          background-color: #fff;
          color: rgb(23, 111, 255);
          border: 1px solid rgb(23, 111, 255);
        }
      }
    }
  }

  &.fadeOut {
    animation: slide-fade-out 0.2s ease-in-out;
    opacity: 0;
  }

  & > .body {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    padding: 16px;

    &::-webkit-scrollbar {
      width: 3px;
    }
  }

  &.ModalBottom > .body {
    /* max-height: 250px; */
  }

  &.selectModal > .body {
    max-height: 330px;
  }

  & > footer {
    padding: 16px;
  }

  @keyframes slide-fade-in {
    0% {
      opacity: 0;
      transform: translate3d(-50%, 100px, 0);
    }

    30% {
      opacity: 1;
      transform: translate3d(-50%, 70px, 0);
    }

    50% {
      opacity: 1;
      transform: translate3d(-50%, 40px, 0);
    }

    80% {
      opacity: 1;
      transform: translate3d(-50%, 10px, 0);
    }

    100% {
      opacity: 1;
      transform: translate3d(-50%, 0, 0);
    }
  }

  @keyframes slide-fade-out {
    0% {
      opacity: 1;
      transform: translate3d(-50%, 0, 0);
    }

    50% {
      opacity: 1;
      transform: translate3d(-50%, 40px, 0);
    }

    100% {
      opacity: 0;
      transform: translate3d(-50%, 100px, 0);
    }
  }
`;

const Modal = ({
  title,
  confirmModal,
  bottomModal,
  children,
  onClose,
  noHeader,
  selectModal,
  changeFlag,
  noClose,
}) => {
  const [fadeOut, setFadeOut] = useState(false);
  const modalRef = useRef(null);
  const handleClose = () => {
    setFadeOut(true);
    setTimeout(() => {
      onClose?.();
    }, 250);
  };

  useOutSideClick(modalRef, handleClose);
  useEffect(() => {
    const body = document.querySelector("body");
    const overflow = body.style.overflow;
    body.style.overflow = "hidden";

    return () => {
      body.style.overflow = overflow;
    };
  }, []);

  useEffect(() => {
    if (changeFlag) {
      handleClose();
    }
  }, [changeFlag]);

  return (
    <ModalContainer>
      <Backdrop>
        <Wrap
          className={[
            bottomModal ? "ModalBottom" : "",
            fadeOut ? (confirmModal ? "" : "fadeOut") : "",
            confirmModal ? "confirmModal" : "",
            selectModal ? "selectModal" : "",
          ].join(" ")}
          ref={modalRef}
        >
          {!noHeader && (
            <div className="botpos_vtitlow popup-has-close">
              <p className="botpos_vtit sub_title">{title}</p>
              {!noClose && (
                <span className="popup-close" onClick={handleClose}></span>
              )}
            </div>
          )}

          <div className="popup_contents botpos_vcontlow body">{children}</div>
        </Wrap>
      </Backdrop>
    </ModalContainer>
  );
};

export default Modal;
