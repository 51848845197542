import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";

const QUERY_KEY = "billing";

const fetchGetBilling = async (orgCode) => {
  return await axiosInstance
    .get(`/mydata/bill/${orgCode}`)
    .then((response) => response.data)
    .then((response) => response.data);
};

const useGetBilling = (orgCode) => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY, orgCode),
    queryFn: async () => await fetchGetBilling(orgCode),
    suspense: true,
  });
  return query;
};

export { useGetBilling };
