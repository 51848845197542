import NoDataBoard from "components/Board/NoDataBoard";
import ChargeItemByDate from "./ChargeItemByDate";

const BillingHistoryList = (props) => {
  const billingHistoryQuery = props.BillingHistory;
  const billingHistory = billingHistoryQuery?.data;

  if (billingHistory.billAmtDto?.length === 0) {
    return (
      <div className="card_history_article">
        <NoDataBoard message={"이용 내역이 없습니다."} />
      </div>
    );
  }

  return (
    <div className="card_history_article">
      <ul className="card_history_list">
        {billingHistory.billAmtDto?.map((chargeByDate, index) => {
          return <ChargeItemByDate chargeByDate={chargeByDate} key={index} />;
        })}
      </ul>
    </div>
  );
};

export default BillingHistoryList;
