import { Layout } from "layout";
import React from "react";

const HomeSkeleton = () => {
  return (
    <Layout
      mainLayout={true}
      header={{
        logo: true,
      }}
      footer
    >
      <section className="main_section ">
        <div className="">
          <div className="main_box asset_total_box">
            <div className="asset_title">
              <p className="skeleton skeleton_subtitle"></p>
              <div className="skeleton skeleton_title"></div>

              <div className="skeleton skeleton_content"></div>
            </div>
            <ul className="table_list_article main_card_list">
              <li>
                <div className="table_list_item card_item has_link">
                  <div className="skeleton skeleton_card_img"></div>
                  <div className="skeleton skeleton_card_info"></div>
                  <div className="skeleton skeleton_card_price "></div>
                </div>
              </li>
              <li>
                <div className="table_list_item card_item has_link">
                  <div className="skeleton skeleton_card_img"></div>
                  <div className="skeleton skeleton_card_info"></div>
                  <div className="skeleton skeleton_card_price "></div>
                </div>
              </li>
              <li>
                <div className="table_list_item card_item has_link">
                  <div className="skeleton skeleton_card_img"></div>
                  <div className="skeleton skeleton_card_info"></div>
                  <div className="skeleton skeleton_card_price "></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="main_box asset_box">
          <div className="asset_title">
            <p className="skeleton skeleton_subtitle"></p>
            <div className="skeleton skeleton_title"></div>
          </div>
          <div className="main_asset_contents">
            <ul className="main_asset_list table_list_article">
              <li>
                <div className="table_list_item card_item has_link">
                  <div className="skeleton skeleton_card_img"></div>
                  <div className="skeleton skeleton_card_info"></div>
                  <div className="skeleton skeleton_card_price "></div>
                </div>
              </li>
              <li>
                <div className="table_list_item card_item has_link">
                  <div className="skeleton skeleton_card_img"></div>
                  <div className="skeleton skeleton_card_info"></div>
                  <div className="skeleton skeleton_card_price "></div>
                </div>
              </li>
              <li>
                <div className="table_list_item card_item has_link">
                  <div className="skeleton skeleton_card_img"></div>
                  <div className="skeleton skeleton_card_info"></div>
                  <div className="skeleton skeleton_card_price "></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default HomeSkeleton;
