import React from "react";
import { useGetConsent } from "features/mydata";

const AssetConsent = (props) => {
  const { data } = useGetConsent(1);

  return (
    <>
      <p className="terms_title">정보를 보내는 곳</p>
      {props.assetList.map((asset) => asset.orgName).join(", ")}
      <p className="terms_title">정보를 받는 곳</p>
      뱅큐
      <p className="terms_title">목적</p>
      전송요구를 통한 본인신용정보 통합조회, 데이터분석 서비스의 이용
      <p className="terms_title">보유·이용기간</p>
      <p className="terms_em">서비스 이용 종료 또는 삭제 요청시까지</p>
      <p className="terms_title">전송 요구 / 수집·이용 정보</p>
      <p className="terms_subtitle">개인식별정보</p>
      <ul className="terms_detail_list">
        {/* <li>{data?.sendReceiveInfo}</li> */}
        <li>전자서명, 접근토큰, 인증서, 전송요구서</li>
      </ul>
      <p className="terms_subtitle">개인신용정보</p>
      <ul className="terms_detail_list">
        <li>{data?.creditTransactionInfo}</li>
      </ul>
    </>
  );
};

export default AssetConsent;
