import { useState } from "react";
import Modal from "components/Modal/Modal";

const SearchtMonthPopup = (props) => {
  const [changeFlag, setChangeFlag] = useState(false);
  const onChange = props.onChange;
  const today = new Date();
  const monthList = [];

  for (let i = 0; i < 12; i++) {
    let month = i + 1;
    if (month < 10) {
      month = "0" + month;
    }
    monthList.push({
      year: "2023",
      month: month,
    });
  }

  for (let i = 0; i < today.getMonth() + 1; i++) {
    let month = i + 1;
    if (month < 10) {
      month = "0" + month;
    }

    monthList.push({
      year: "2024",
      month: month,
    });
  }

  const onChangeHandler = (e) => {
    props.onChange?.(e);
    setTimeout(() => {
      setChangeFlag(true);
    }, 100);
  };

  return (
    <Modal
      bottomModal
      selectModal
      onClose={props.onClose}
      title=""
      changeFlag={changeFlag}
    >
      <div>
        <ul>
          {monthList.reverse().map((date, index) => (
            <li className="period_list" key={index}>
              <input
                type="radio"
                id={date.year + "-" + date.month}
                value={date.year + date.month}
                name="searchDate"
                onChange={onChangeHandler}
                className="period_radio"
                checked={props.selectMonth === date.year + date.month}
              ></input>
              <label
                htmlFor={date.year + "-" + date.month}
                className="period_radio_label"
              >
                {date.year + "년 " + date.month + "월"}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  );
};

export default SearchtMonthPopup;
