import { detect } from "detect-browser";

const useDeviceInfo = () => {
  const browser = detect();

  // TODO 어플로 사용하기 전에 수정

  if (browser.os.indexOf("Android") > -1) {
    return window?.Android?.getIdentifier();
  }

  return localStorage.getItem("identifier");
};

export default useDeviceInfo;
