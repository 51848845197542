import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { paramFetch } from "apis/common/fetchInstance";
import data_logo from "./../../assets/images/addPage/data_logo.jpg";
import commonApi from "apis/common/commonApi";

function Tc_writeRev() {
  const [chkList, setChkList] = useState([]);
  const [reviewTagList, setReviewTagList] = useState([
    {
      id: 1,
      type: "tcAgree",
      name: "티캐에서만 승인",
    },
    {
      id: 2,
      type: "fastDeposit",
      name: "빠른 입금",
    },
    {
      id: 3,
      type: "highLimit",
      name: "높은 한도",
    },
    {
      id: 4,
      type: "paperless",
      name: "무서류 무방문",
    },
    {
      id: 5,
      type: "consulting",
      name: "친절한 상담",
    },
    {
      id: 6,
      type: "easyProcedure",
      name: "쉬운 절차",
    },
    {
      id: 7,
      type: "guide",
      name: "안내조건과 동일",
    },
    {
      id: 8,
      type: "reviewOther",
      name: "기타",
    },
  ]);

  //뒤로가기
  let navigate = useNavigate();

  const reviewSelect = (val, checked) => {
    if (checked) {
      setChkList([...chkList, val]);
    } else {
      setChkList(chkList.filter((el) => el !== val));
    }
  };

  // useEffect(() => {
  // 	if (chkList.length > 4) {
  // 		printMsg("최대 4개까지 선택 가능합니다.");
  // 	}
  // }, [chkList])

  //데이터 연결
  const dataAPI = async () => {};

  const handleInput = () => {};

  //등록
  const registReview = async () => {
    // if (textReview === "") {
    // 	alert("후기를 남겨주세요.")
    // }
    const param = {
      // textReview: textReview
    };
    const { data, response } = await paramFetch("/api/tcWriteRev");

    // else {
    // 	printMsg("등록 되었습니다.")
    // 	navigate("/review");
    // 	dataAPI();
    // }
  };

  return (
    <div className="front_body">
      <div className="page_wrap has_botpos">
        <header className="page_header">
          <button onClick={() => navigate(-1)} className="btn_back">
            <span className="hdtext">뒤로가기</span>
          </button>
          <h1 className="page_headtitle">대출 후기</h1>
        </header>
        <div className="loan_review_subj">
          <div className="review_img_box">
            <img src={data_logo} alt="" />
          </div>
          <div className="review_bank">
            <span className="bankN">NH저축은행</span>
            <span className="bankI">NH직장인행복대출</span>
          </div>
          <div className="review_loan_info">
            <p>500만원</p>
            <p>생활비</p>
          </div>
        </div>
        <section className="page_midsection_write_review">
          <div className="review_list">
            <div className="review_low">
              <div className="review_titlow">
                <p className="review_tit">어떤 점이 좋았나요?</p>
              </div>
              <div className="review_contlow">
                <div className="review_formitem_w">
                  {reviewTagList &&
                    reviewTagList.map((item) => (
                      <div className="review_formitem" key={item.id}>
                        <input
                          type="checkbox"
                          className="review_input"
                          name="job"
                          id={`job_check${item.id}`}
                          readOnly
                          value={item.type}
                          onChange={(e) => {
                            reviewSelect(item, e.target.checked);
                          }}
                        />
                        <label
                          htmlFor={`job_check${item.id}`}
                          className="review_label"
                        >
                          {item.name}
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="review_low">
              <div className="review_contlow">
                <p>
                  <textarea
                    id="textReview"
                    onChange={handleInput}
                    cols="50"
                    rows="15"
                    placeholder="후기를 작성해 주세요"
                    maxLength={500}
                  ></textarea>
                </p>
              </div>
              <div className="review_notice">참고해 주세요</div>
            </div>
            <div className="notice_sp">
              <p className="notice_p notice_red">
                &middot; 대출 상품 이용 경험을 알려주세요
              </p>
              <p className="notice_p">
                &middot; 작성하신 후기는 다른 회원들에게 공개됩니다.
              </p>
              <p className="notice_p">
                &middot; 욕설/비방글 개인정보 유출(전화번호, 주민번호,
                <br />
                &nbsp;&nbsp; 이름, 아이디 등),
                <br />
                &nbsp;&nbsp;&nbsp;판매/광고/홍보성 글을 등록한 경우 임의로
                삭제할 수 <br />
                &nbsp;&nbsp;&nbsp;있습니다.
              </p>
            </div>
          </div>
        </section>
      </div>
      <div className="btn_botpos_wrap">
        <button className="btn_bquadbox type2" onClick={registReview}>
          <span className="bquadbox_in">등록하기</span>
        </button>
      </div>
    </div>
  );
}
export default Tc_writeRev;
// </html>
