import { useGetIsLinked, NoLinkedOrg } from "features/organization";
import { PaymentHistoryContainer, PaymentHistorySkeleton } from "..";
import { Suspense } from "react";

const InitPaymentHistoryContainer = () => {
  const isLinkedOrganizationQuery = useGetIsLinked();

  return (
    <>
      {isLinkedOrganizationQuery?.data ? (
        <Suspense fallback={<PaymentHistorySkeleton />}>
          <PaymentHistoryContainer />
        </Suspense>
      ) : (
        <NoLinkedOrg />
      )}
    </>
  );
};

export default InitPaymentHistoryContainer;
