import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { commonFetch } from "apis/common/fetchInstance";
import { setDateFormat } from "utils/utils";
import IsLoding from "../../components/IsLoading";
import { Layout } from "layout";

const NoticeDetailPage = () => {
  const { id } = useParams();
  const [notice, setNotice] = useState([]);

  useEffect(() => {
    fetchNotice(id);
  }, [id]);

  const fetchNotice = async (id) => {
    const { response, data } = await commonFetch(`/api/notice/${id}`, "get");

    if (response.status === 200) {
      setNotice(data);
    }
  };

  return (
    <Layout header={{}}>
      <div className="notice_wrap">
        <div className="annc_spec_w_notice_detail">
          <p className="annc_spec01_notice">{notice.noticeTitle}</p>
          <p className="annc_spec03_notice">
            {setDateFormat(notice.createdDate)}
          </p>

          <hr className="notice_hr"></hr>
          <div className="annc_spec02_detail">{notice.noticeContent}</div>
        </div>
      </div>
    </Layout>
  );
};

export default NoticeDetailPage;
