import React from "react";
import obj_join_end from "assets/images/bankq/obj_join_end.png";
import splash_logo from "assets/images/bankq/logo-splash.png";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "components/Button";

function MemberJoinFinish() {
  const navigate = useNavigate();

  return (
    <div className="front_body" style={{ overflow: "hidden" }}>
      <div className="swiper-splash-logo-low">
        <img src={splash_logo} />
      </div>

      <div className="join_wrap has_botpos">
        <div className="finish_guide_wrap" style={{ margin: "0 auto" }}>
          <div className="finish_logo">
            <img src={obj_join_end} />
          </div>
          <div className="finish_speclow">
            <p className="finish_spec01">회원가입 완료!</p>
            <p className="finish_spec02">
              회원가입이 모두 완료되었습니다.
              <br />
              지금부터 뱅큐의 편리한 서비스를 이용해보세요!{" "}
            </p>
          </div>
        </div>
      </div>
      <CustomButton
        title="뱅큐 시작"
        buttonClass={"btn_color_bottom"}
        onClick={() => {
          navigate("/home");
        }}
      />
    </div>
  );
}
export default MemberJoinFinish;
