import { startTransition, useEffect, useState, Suspense } from "react";
import { useGetIsLinked, NoLinkedOrg } from "features/organization";
import MydataHomeContent from "./MydataHomeContent";

import { Layout } from "layout";
import ToastUi from "components/ToastUi";
import useAssetExecution from "hooks/useAssetExecution";
import { useLocate } from "lib/utils/useRouter";
import tempBanner from "assets/images/temp_banner.png";

const LinkedHomeContainer = (props) => {
  const [isLinked, setIsLinked] = useState(false);
  const isLinkedOrganizationQuery = useGetIsLinked();

  const [isToast, setIsToast] = useState(false);
  const [isExecution, setIsExecution] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const { message, status, refetch, invalidate } = useAssetExecution(
    isExecution,
    isUpdate
  );
  const stateData = useLocate().getStateData();

  useEffect(() => {
    if (stateData?.["register-asset"]) {
      setIsUpdate(true);
      invalidate();
    }
  }, [stateData?.["register-asset"]]);

  useEffect(() => {
    const headerUpdate = document.querySelector(".page_header_update");

    if (status === "start") {
      headerUpdate?.classList?.add("active");
    } else if (status === "end") {
      headerUpdate?.classList?.remove("active");
      setIsUpdate(false);
    } else if (status === "stale") {
      setIsToast(true);
    }
  }, [status]);

  const handleUpdateClick = () => {
    setIsUpdate(true);
    refetch();
  };

  useEffect(() => {
    if (isLinkedOrganizationQuery?.data) {
      startTransition(() => {
        setIsLinked(true);
        setTimeout(() => {
          setIsExecution(true);
        }, 1000);
      });
    } else {
      startTransition(() => {
        setIsLinked(false);
      });
    }
  }, [isLinkedOrganizationQuery?.data]);

  if (!isLinked) {
    return (
      <Layout
        mainLayout={true}
        header={{
          logo: true,
        }}
        footer
      >
        <section className="main_section ">
          <div className="main_box asset_total_box">
            <NoLinkedOrg />
          </div>
          <div className="">
            <img src={tempBanner} />
          </div>
        </section>
      </Layout>
    );
  }

  if (isLinked) {
    return (
      <Layout
        mainLayout={true}
        header={{
          logo: true,
          handleUpdateClick: handleUpdateClick,
          message: message,
        }}
        footer
      >
        <section className="main_section ">
          <MydataHomeContent />
          <div className="">
            <img src={tempBanner} />
          </div>
        </section>

        {isToast && (
          <ToastUi
            content={"10분 후 가능합니다."}
            isOpen={isToast}
            setIsOpen={setIsToast}
          />
        )}
      </Layout>
    );
  }
};

export default LinkedHomeContainer;
