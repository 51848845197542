import React, { useEffect, useState } from "react";
import {
  OrgCreditItem,
  useDeleteDataOfLinked,
  useGetLinkedOrganizations,
  useDeleteDataOfRevoked,
} from "features/organization";
import NoDataBoard from "components/Board/NoDataBoard";
import { CustomButton } from "components/Button";
import CreditDeletePopup from "containers/Management/modal/CreditDeletePopup";

import { successMsg } from "utils/utils";
import LoadingSpinner from "components/Modal/LoadingSpinner";

const CreditInformationContainer = () => {
  const linkedOrgQuery = useGetLinkedOrganizations();
  const linkedOrgData = linkedOrgQuery?.data;
  const [selectOrgList, setSelectOrgList] = useState([]);
  const [selectRevokeOrgList, setSelectRevokeOrgList] = useState([]);
  const [isShowButton, setIsShowButton] = useState(selectOrgList.length > 0);
  const [isDeleteCredit, setIsDeleteCredit] = useState(false);
  const deleteDataOfLinkedMutation = useDeleteDataOfLinked(selectOrgList);
  const deleteDataOfRevokedMutation =
    useDeleteDataOfRevoked(selectRevokeOrgList);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setIsShowButton(selectOrgList.length + selectRevokeOrgList.length > 0);
  }, [selectOrgList, selectRevokeOrgList]);

  useEffect(() => {
    if (deleteDataOfLinkedMutation?.data || deleteDataOfRevokedMutation?.data) {
      setShowLoading(false);
      successMsg("개인신용정보를 삭제했습니다.", () => {
        linkedOrgQuery.refetch();

        setSelectOrgList([]);
        setSelectRevokeOrgList([]);

        document
          .querySelectorAll("input[type='checkbox']")
          .forEach((checkbox) => (checkbox.checked = false));
      });
    }
  }, [
    deleteDataOfLinkedMutation?.isSuccess,
    deleteDataOfRevokedMutation?.isSuccess,
  ]);

  return (
    <div className="asset_manage_wrap">
      <ul className="credit_list">
        {linkedOrgData?.length === 0 ? (
          <NoDataBoard message={"연결된 자산이 없습니다."} />
        ) : null}
        {linkedOrgData?.map((org, index) => {
          return (
            <OrgCreditItem
              org={org}
              key={index}
              setSelectOrgList={setSelectOrgList}
              selectOrgList={selectOrgList}
              selectRevokeOrgList={selectRevokeOrgList}
              setSelectRevokeOrgList={setSelectRevokeOrgList}
            />
          );
        })}
      </ul>

      <CustomButton
        title="데이터 삭제하기"
        disabled={!isShowButton}
        buttonClass={isShowButton ? "btn_naver_terms" : ""}
        onClick={() => {
          setIsDeleteCredit(true);
        }}
      />

      {isDeleteCredit && (
        <CreditDeletePopup
          isOpen={isDeleteCredit}
          onClose={() => {
            setIsDeleteCredit(false);
          }}
          submitClick={() => {
            setShowLoading(true);
            if (selectOrgList.length > 0)
              deleteDataOfLinkedMutation.mutate(selectOrgList);

            if (selectRevokeOrgList.length > 0)
              deleteDataOfRevokedMutation.mutate(selectRevokeOrgList);

            setIsDeleteCredit(false);
          }}
        />
      )}
      {showLoading && <LoadingSpinner />}
    </div>
  );
};

export default CreditInformationContainer;
