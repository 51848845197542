import React from "react";
import Layout from "./Layout";

const ErrorLayout = () => {
  // TODO 수정할 것
  return (
    <Layout>
      서버에 오류가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다.
    </Layout>
  );
};

export default ErrorLayout;
