import axiosInstance from "lib/apis/AxiosInstance";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey } from "lib/apis/QueryClient";

const QUERY_KEY = "payment-details";

const fetchGetPaymentDetails = async (
  orgCode,
  approvedDateSeq,
  approvedNum
) => {
  return await axiosInstance
    .get(`/mydata/expense/${orgCode}/${approvedDateSeq}/${approvedNum}`)
    .then((response) => response?.data)
    .then((response) => response?.data);
};

const useGetPaymentDetails = (orgCode, approvedDateSeq, approvedNum) => {
  const query = useQuery({
    queryKey: getQueryKey(QUERY_KEY, approvedDateSeq),
    queryFn: async () =>
      await fetchGetPaymentDetails(orgCode, approvedDateSeq, approvedNum),
    suspense: true,
  });
  return query;
};

export { useGetPaymentDetails };
