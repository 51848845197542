import { useQueryClient } from "@tanstack/react-query";
import OrganizationQueryFactory from "../queries/OrganizationQueryFactory";
import useOrganizationMutation from "../queries/useOrganizationMutation";
import APIInstance from "lib/apis/APIInstance";

const fetchDeleteDataOfLinked = async (orgCode) => {
  const { data } = await APIInstance.delete(
    "/mydata/organization/revoke-delete",
    {
      data: JSON.stringify(orgCode),
    }
  );
  return data?.data;
};

export const useDeleteDataOfLinked = (orgCode) => {
  const queryKey =
    OrganizationQueryFactory.queries.deleteOfLinkedOrganization(orgCode);
  const queryClient = useQueryClient();

  const deleteDataOfLinkedMutation = useOrganizationMutation(
    queryKey,
    async () => await fetchDeleteDataOfLinked(orgCode),
    (data) => data
  );

  return deleteDataOfLinkedMutation;
};
