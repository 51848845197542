import { printMsg } from "utils/utils";

let originalGetRequest = async (url, config) => {
  let response;
  let data;
  try {
    response = await fetch(url, config);
    data = await response.json();
  } catch (err) {
    printMsg("서버에서 오류가 발생하였습니다.");
  }
  return { response, data };
};

let originalPostRequest = async (url, config) => {
  let response;
  let data;
  try {
    response = await fetch(url, config);
    data = await response.json();
  } catch (err) {
    printMsg("서버에서 오류가 발생하였습니다.");
  }
  return { response, data };
};

let originalRequest = async (url, config) => {
  let response;
  let data;
  try {
    response = await fetch(url, config);
    data = await response.json();
    response = data;
    data = data.data;
  } catch (err) {
    console.log(err);
    printMsg("서버에서 오류가 발생하였습니다.");
    // console.log(response.message);
    // printMsg(response.message);
  }
  return { response, data };
};

let refreshToken = async (ref_token, acc_token) => {
  // const identifier = window.Android.getIdentifier();
  let response;
  let data;


  const requestOptions = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${acc_token}`,
      "refresh-token": ref_token
    },
    method: "POST",
  };

  response = await fetch("/api/auth/reissue", requestOptions);
  data = await response.json();
  response = data;
  data = data.data;

  if (response.status === 200) {
    localStorage.setItem("acc_token", data.accessToken);
    if (data.refreshToken) {
      localStorage.setItem("ref_token", data.refreshToken);
    }
    return data.accessToken;
  }
};

export const jsonFetch = async (url, config = {}) => {
  let accToken = localStorage.getItem("acc_token");

  const requestOptions = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accToken}`,
    },
    method: "GET",
  };

  let { response, data } = await originalGetRequest(url, requestOptions);

  if (response.status === 403) {
    let ref_token = localStorage.getItem("ref_token");

    accToken = await refreshToken(ref_token);

    const requestOptions = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accToken}`,
      },
      method: "GET",
    };

    let newResponse = await originalGetRequest(url, requestOptions);
    response = newResponse.response;
    data = newResponse.data;
  }

  return { response, data };
};

export const paramFetch = async (url, sendData, config = {}) => {
  let accToken = localStorage.getItem("acc_token");

  const requestOptions = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accToken}`,
    },
    method: "POST",
    body: JSON.stringify(sendData),
  };

  let { response, data } = await originalPostRequest(url, requestOptions);

  if (response.status === 403 || response.status === 401) {
    let ref_token = localStorage.getItem("ref_token");
    accToken = await refreshToken(ref_token);
    const requestOptions = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accToken}`,
      },
      method: "POST",
      body: JSON.stringify(sendData),
    };

    let newResponse = await originalPostRequest(url, requestOptions);
    response = newResponse.response;
    data = newResponse.data;
  }

  return { response, data };
};

export const commonFetch = async (url, method, sendData) => {
  // let accToken = localStorage.getItem('acc_token');
  let accToken = localStorage.getItem("acc_token")

  const requestOptions = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accToken}`,
    },
    method: method,
    body: JSON.stringify(sendData),
  };

  let { response, data } = await originalRequest(url, requestOptions);

  
  if (response.status === 403 || response.status === 401) {
    let ref_token = localStorage.getItem("ref_token");
    let acc_token = localStorage.getItem("acc_token");
    accToken = await refreshToken(ref_token, acc_token);
    const requestOptions = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accToken}`,
      },
      method: method,
      body: JSON.stringify(sendData),
    };

    let newResponse = await originalRequest(url, requestOptions);
    response = newResponse.response;
    data = newResponse.data;
  }

  return { response, data };
};
