import { useCheckMydataJoin } from "features/mydata";
import MydataJoinInfo from "./MydataJoinInfo";
import LinkedHomeContainer from "./LinkedHomeContainer";
import { Layout } from "layout";
import tempBanner from "assets/images/temp_banner.png";

const HomeContainer = (props) => {
  const checkMydataJoinQuery = useCheckMydataJoin();

  if (!checkMydataJoinQuery?.data) {
    return (
      <Layout
        mainLayout={true}
        header={{
          logo: true,
        }}
        footer
      >
        <section className="main_section ">
          <MydataJoinInfo />
          <div className="">
            <img src={tempBanner} />
          </div>
        </section>
      </Layout>
    );
  }

  return <LinkedHomeContainer />;
};

export default HomeContainer;
