import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import $ from "jquery";
import IsLoding from "../../components/IsLoading";
import { commonFetch, paramFetch } from "apis/common/fetchInstance";
import { printMsg, setDateFormat } from "utils/utils";
import { throttle } from "lodash";
import down from "../../assets/images/addPage/Down-Arrow-PNG-Transparent-Image.png";
import answer_img from "../../assets/images/addPage/reply_icon_152546.png";
// import { ScrollLink, Element } from "react-scroll";

const Tc_inquiry = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const buttonDiv = useRef(null);
  const refTest = useRef(null);

  const [loading, setLoading] = useState();
  const [isTab, setIsTab] = useState(0); // 탭 여부
  const [user, setUser] = useState([]);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [inquiryList, setInquiryList] = useState([]);
  const [selectNo, setSelectNo] = useState();
  const [focusTest, setFocusTest] = useState(false);

  const divRef = useRef();
  const scrollRef = useRef < HTMLDivElement > null;

  const defaultPage = 1;
  const defaultRows = 10;

  const [page, setPage] = useState(defaultPage);
  const [pageing, setPageging] = useState(true);

  const lastRef = useRef(null);

  const handleScroll = useMemo(
    () =>
      throttle(() => {
        if (pageing && lastRef.current !== null) {
          let scrollTop = Math.max(
            document.scrollingElement.scrollTop,
            document.body.scrollTop
          );
          let clientHeight = document.documentElement.clientHeight;
          if (scrollTop + clientHeight >= lastRef.current.offsetTop) {
            setPage((prev) => prev + 1);
          }
        }
      }, 500),
    [pageing]
  );

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll); //clean up
    };
  }, [handleScroll]);

  useEffect(() => {
    if (isTab === 1) {
      inquiryListApi();
    }
  }, [isTab]);

  const inquiryListApi = async () => {
    const { response, data } = await commonFetch(
      `/api/inquiry?page=${page}&rows=${defaultRows}`,
      "get"
    );
    let tempData = [];
    if (response.status === 200) {
      for (let i = 0; i < data.length; i++) {
        const obj = data[i];
        tempData.push({
          no: obj.no,
          answerContent: obj.answerContent,
          content: obj.content,
          createdDate: obj.createdDate,
          status: obj.status,
          answerDate: obj.answerDate,
          title: obj.title,
          isToggle: false,
        });
      }
      setInquiryList(tempData);
    }
  };

  const insertInquiry = async () => {
    if (!!!title) {
      printMsg("제목을 입력해주세요.");
      return;
    }

    if (!!!content) {
      printMsg("문의 내용을 입력해주세요.");
      return;
    }

    const sendData = {
      title: title,
      content: content,
    };

    const { response, data } = await commonFetch(
      "/api/inquiry",
      "post",
      sendData
    );
    if (response.status === 200) {
      printMsg("등록되었습니다.");
      setTitle("");
      setContent("");
    }
  };

  const handleInquiryTab = (tabIndex) => {
    setIsTab(tabIndex);
    setPage(defaultPage);
  };

  const handleInquiryToggle = (no) => {
    const listObj = [...inquiryList];
    const listNo = listObj.findIndex((filter) => filter.no === no);

    listObj[listNo].isToggle = !listObj[listNo].isToggle;

    if (isTab === 1) {
      setSelectNo();
    }

    setInquiryList(listObj);
    setSelectNo(no);
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleContent = (e) => {
    setContent(e.target.value);
  };

  const titleFocus = () => {
    $(".btn_botpos_wrap").addClass("click");
    $(".inquiry_temp").css("display", "none");
  };

  const titleBlur = () => {
    $(".btn_botpos_wrap").removeClass("click");
    $(".inquiry_temp").css("display", "block");
  };

  const contentFocus = () => {
    $(".btn_botpos_wrap").addClass("click");
    setFocusTest(true);
    $(".inquiry_temp").css("display", "none");

    setTimeout(() => {
      const inputHeight = $(".form_input_inquiry").offset().top;
      $(".front_body").animate({ scrollTop: inputHeight }, 600);
    }, 400);
  };

  const contentBulr = () => {
    $(".btn_botpos_wrap").removeClass("click");
    setFocusTest(false);
    $(".inquiry_temp").css("display", "block");
  };

  const contentClick = () => {
    // setTimeout(() => {
    //     const inputHeight = $(".form_input_inquiry").offset().top;
    //     $(".front_body").animate({ scrollTop: inputHeight }, 600);
    // }, 400);
  };

  return (
    <>
      <div className="front_body" style={{ overflow: "auto" }}>
        <div className="page_wrap" ref={buttonDiv}>
          <header className="page_header_event">
            <Link to="/menu" className="btn_back">
              <span className="hdtext">뒤로가기</span>
            </Link>
            <h1 className="page_headtitle">1:1문의</h1>
          </header>
          {loading ? (
            <IsLoding />
          ) : (
            <section className="page_midsection_qna dtype7">
              <div className="event_tab">
                <div
                  className={
                    isTab === 0 ? "event_tab_text on" : "event_tab_text"
                  }
                  onClick={() => handleInquiryTab(0)}
                >
                  <p>1:1문의 작성</p>
                </div>
                <div
                  className={
                    isTab === 1 ? "event_tab_text on" : "event_tab_text"
                  }
                  onClick={() => handleInquiryTab(1)}
                >
                  <p>1:1문의 내역</p>
                </div>
              </div>
              {isTab === 0 ? (
                <React.Fragment>
                  <div className="form_veritem_w inquiry_form">
                    <div className="form_veritem">
                      <div className="form_verlablow">
                        <p className="form_verlabel_inquiry">제목</p>
                      </div>
                      <div className="form_fieldlow">
                        <div className="form_inwrap">
                          <div className="form_incell_long">
                            <input
                              type="text"
                              className="form_input_inquiry"
                              value={title}
                              placeholder="제목을 입력해 주세요(50자 이내)"
                              onChange={handleTitle}
                              onFocus={titleFocus}
                              onBlur={titleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form_veritem_w inquiry_form2">
                    <div className="form_veritem">
                      <div className="form_verlablow">
                        <p className="form_verlabel_inquiry">문의 내용</p>
                      </div>
                      <div className="form_fieldlow">
                        <div className="form_inwrap">
                          <div className="form_incell_long">
                            <textarea
                              className="inquiry_text"
                              value={content}
                              onChange={handleContent}
                              placeholder="문의하실 내용을 입력해 주세요"
                              cols="50"
                              rows="15"
                              onFocus={contentFocus}
                              onBlur={contentBulr}
                              ref={refTest}
                              onClick={contentClick}
                              maxLength="500"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn_botpos_wrap">
                    <button
                      className="btn_bquadbox"
                      id="btn_myoptimum_search"
                      onClick={insertInquiry}
                    >
                      <span className="inquiry_bquadbox_in">문의하기</span>
                    </button>
                  </div>
                </React.Fragment>
              ) : (
                <InquiryList
                  list={inquiryList}
                  toggle={inquiryList.isToggle}
                  handleInquiryToggle={handleInquiryToggle}
                  selectNo={selectNo}
                />
              )}
              <div
                style={{ height: "5rem" }}
                ref={divRef}
                className="inquiry_temp"
              ></div>
            </section>
          )}
        </div>
      </div>
    </>
  );
};

const InquiryList = ({ list, toggle, handleInquiryToggle, selectNo }) => {
  let listTag = [];
  const [isToggle, setIsToggle] = useState(toggle);
  const [isAnimation, setIsAnimation] = useState(false);

  useEffect(() => {
    if (isToggle) {
      setIsAnimation(true);
    } else {
      setTimeout(() => {
        setIsAnimation(false);
      }, 100);
    }
  }, [isToggle]);

  for (let i = 0; i < list.length; i++) {
    let obj = list[i];

    const innerTAG = (
      <React.Fragment key={obj.no}>
        <li className="inquiry_item">
          <div
            className="annc_vitem_inquiry"
            style={{
              backgroundColor:
                obj.isToggle && obj.no === selectNo ? "#efefef6b" : null,
            }}
          >
            <div
              className="annc_spec_w_inquiry"
              onClick={() => handleInquiryToggle(obj.no)}
            >
              <img
                src={down}
                className="open_img"
                style={{
                  transform:
                    obj.isToggle && obj.no === selectNo
                      ? "rotate(180deg)"
                      : null,
                }}
              />
              <span className="inquiry_title">
                <div className="inquiry_status">
                  {obj.status === "N" ? (
                    <p style={{ color: "#FF4545" }}>진행중</p>
                  ) : (
                    <p style={{ color: "#4144f2" }}>답변완료</p>
                  )}
                </div>
                <p
                  className={`${
                    obj.isToggle && obj.no === selectNo
                      ? "inquiry_title_text_on"
                      : "inquiry_title_text"
                  }`}
                >
                  {obj.title}
                </p>
              </span>
              <p className="annc_spec02">{setDateFormat(obj.createdDate)}</p>
            </div>
            {obj.isToggle && selectNo === obj.no ? (
              <div className="inquiry_content_form">
                <p>{obj.content}</p>
                {obj.status === "Y" ? (
                  <>
                    <div className="inquiry_ans_line"></div>
                    <div>
                      <img src={answer_img} className="answer_img" />
                      <p className="inquiry_answer_text">{obj.answerContent}</p>
                    </div>
                    <div>
                      <p className="inquiry_answer_date">
                        {setDateFormat(obj.answerDate)}
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
        </li>
      </React.Fragment>
    );
    listTag.push(innerTAG);
  }

  if (list.length === 0) {
    return (
      <div className="no_data">
        <div
          className="inner"
          style={{ marginTop: "7rem", textAlign: "center" }}
        >
          <h3>등록된 문의내역이 없습니다.</h3>
        </div>
      </div>
    );
  } else {
    return <ul className="annc_vlist_inquiry">{listTag}</ul>;
  }
};

export default Tc_inquiry;
