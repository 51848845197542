const CardSkeleton = () => {
  return (
    <div className="card_container">
      <section className="card_wrap">
        <div className="card_article ">
          <div className="card_summary_box ">
            <p className="skeleton skeleton_subtitle"></p>
            <div className="skeleton skeleton_title"></div>
          </div>
          <ul className="table_list_article">
            <li>
              <div className="table_list_item card_item has_link">
                <div className="skeleton skeleton_card_img"></div>
                <div className="skeleton skeleton_card_info"></div>
                <div className="skeleton skeleton_card_price "></div>
              </div>
            </li>
            <li>
              <div className="table_list_item card_item has_link">
                <div className="skeleton skeleton_card_img"></div>
                <div className="skeleton skeleton_card_info"></div>
                <div className="skeleton skeleton_card_price "></div>
              </div>
            </li>
            <li>
              <div className="table_list_item card_item has_link">
                <div className="skeleton skeleton_card_img"></div>
                <div className="skeleton skeleton_card_info"></div>
                <div className="skeleton skeleton_card_price "></div>
              </div>
            </li>
          </ul>
        </div>
        <div className="block_line"></div>
      </section>
      <section className="card_wrap">
        <div className="card_article">
          <div className="card_summary_box">
            <p className="skeleton skeleton_subtitle"></p>
            <div className="skeleton skeleton_title"></div>
          </div>
          <div className="billing_summary_article">
            <div className="billing_summary_box">
              <div className="billing_summary_date">
                <div className="skeleton skeleton_card_price "></div>
              </div>
              <ul className="billing_summary_list">
                <li>
                  <div className="skeleton_list_block">
                    <p className="skeleton skeleton_subtitle"></p>
                    <p className="skeleton skeleton_content"></p>
                  </div>
                </li>
                <li>
                  <div className="skeleton_list_block">
                    <p className="skeleton skeleton_subtitle"></p>
                    <p className="skeleton skeleton_content"></p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="billing_summary_box">
              <div className="billing_summary_date">
                <div className="skeleton skeleton_card_price "></div>
              </div>
              <ul className="billing_summary_list">
                <li>
                  <div className="skeleton_list_block">
                    <p className="skeleton skeleton_subtitle"></p>
                    <p className="skeleton skeleton_content"></p>
                  </div>
                </li>
                <li>
                  <div className="skeleton_list_block">
                    <p className="skeleton skeleton_subtitle"></p>
                    <p className="skeleton skeleton_content"></p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="card_desc_list">
            <p className="skeleton skeleton_subtitle"></p>
          </div>
        </div>
      </section>
      <div className="block_line"></div>
      <section className="card_wrap">
        <div className="card_article">
          <div className="card_summary_box">
            <p className="skeleton skeleton_subtitle"></p>
            <div className="skeleton skeleton_title"></div>
          </div>
          <ul className="table_list_article card_table_list">
            <li>
              <div className="point_item">
                <p className="skeleton skeleton_subtitle"></p>
                <div>
                  <div className="skeleton skeleton_card_price "></div>
                  <div className="skeleton skeleton_card_price "></div>
                </div>
              </div>
            </li>
          </ul>
          <div className="card_desc_list">
            <p className="skeleton skeleton_content"></p>
            <p className="skeleton skeleton_content"></p>
            <p className="skeleton skeleton_content"></p>
            <p className="skeleton skeleton_content"></p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CardSkeleton;
