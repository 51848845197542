import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import './../../assets/css/reset.css';
import './../../assets/css/swiper.min.css';
import './../../assets/css/style.css';
import './../../assets/css/add_style.css';
import './../../assets/css/coin.css';

import { useNavigate } from 'react-router-dom';

import refresh from './../../assets/images/coin_img/coin_refresh_white.png';
import 돈주머니 from './../../assets/images/addPage/돈주머니.png';

import Get_coinAPI from './../../components/Get_coinAPI';

function Tc_infcredt() {

	let navigate = useNavigate();

    const [get_coinList,setGet_coinList] = useState([]);

    const Coins = [
        {
            id:0,
            coin_initial:'ATOLO',
            coin_name:'라이즌'
            
        },
        {
            id:1,
            coin_initial:'btc',
            coin_name:'비트코인'
        },
        {
            id:2,
            coin_initial:'eth',
            coin_name:'이더리움'    
        },
        {
            id:3,
            
            coin_initial:'xrp',
            coin_name:'리플'
        },
        {
            id:4,
            coin_initial:'bch',
            coin_name:'비트코인캐시'
        },
        {
            id:5,
            coin_initial:'etc',
            coin_name:'이더리움클래식'
        },
        {
            id:6,
            coin_initial:'sol',
            coin_name:'솔라나'
        },
        {
            id:7,
            coin_initial:'atom',
            coin_name:'코스모스'
        },
        {
            id:8,
            coin_initial:'klay',
            coin_name:'클레이튼'
        },
        {
            id:9,
            coin_initial:'ada',
            coin_name:'에이다'
        },
        {
            id:10,
            coin_initial:'uni',
            coin_name:'유니스왑'
        },
        {
            id:11,
            coin_initial:'qtum',
            coin_name:'퀀텀'
        },
        {
            id:12,
            coin_initial:'wemix',
            coin_name:'위믹스'
        },
        {
            id:13,
            coin_initial:'omg',
            coin_name:'오미세고'
        },
        {
            id:14,
            coin_initial:'xtz',
            coin_name:'테조스'
        },
        {
            id:15,
            coin_initial:'eos',
            coin_name:'이오스'
        },
        {
            id:16,
            coin_initial:'sushi',
            coin_name:'스시스왑'
        },
        {
            id:17,
            coin_initial:'theta',
            coin_name:'쎄타토큰'
        },
        {
            id:18,
            coin_initial:'sand',
            coin_name:'샌드박스'
        },
        {
            id:19,
            coin_initial:'matic',
            coin_name:'폴리곤'
        },
        {
            id:20,
            coin_initial:'srm',
            coin_name:'세럼'
        },
        {
            id:21,
            coin_initial:'mana',
            coin_name:'디센트럴랜드'
        },
        {
            id:22,
            coin_initial:'pundix',
            coin_name:'펀디엑스'
        },
        {
            id:23,
            coin_initial:'dot',
            coin_name:'폴카닷'
        },
        {
            id:24,
            coin_initial:'bnb',
            coin_name:'바이낸스코인'
        },
        {
            id:25,
            coin_initial:'mlk',
            coin_name:'밀크'
        },
        {
            id:26,
            coin_initial:'pci',
            coin_name:'페이코인'
        },
        {
            id:27,
            coin_initial:'icx',
            coin_name:'아이콘'
        },
        {
            id:28,
            coin_initial:'ont',
            coin_name:'온톨로지'
        },
        {
            id:29,
            coin_initial:'steem',
            coin_name:'스팀'
        },
        {
            id:30,
            coin_initial:'powr',
            coin_name:'파워렛저'
        },
        {
            id:31,
            coin_initial:'chz',
            coin_name:'칠리즈'
        },
        {
            id:32,
            coin_initial:'link',
            coin_name:'체인링크'
        },
        {
            id:33,
            coin_initial:'trx',
            coin_name:'트론'
        },
        {
            id:34,
            coin_initial:'doge',
            coin_name:'도지코인'
        },
        {
            id:35,
            coin_initial:'ankr',
            coin_name:'앵커'
        },
        {
            id:36,
            coin_initial:'med',
            coin_name:'메디블록' 
        },
        {
            id:37,
            coin_initial:'avax',
            coin_name:'아발란체'
        },
    ];

	const fetchCoin = async(coin) => {
        let coin_url = await ('https://api.bithumb.com/public/ticker/' + coin.coin_initial);
		let priceArray={};
        let color = '';
        let beforePer = 0;
        const res = await fetch(coin_url)
            .then((res) => res.json());
            beforePer = (100 * ((res['data']['closing_price'] - res['data']['opening_price'])  / res['data']['opening_price'])).toFixed(2);
            if(beforePer < 0){
                color = 'blue';
            } else{
                color = 'red';
            }
            priceArray['name'] = coin.coin_name;
            priceArray['initial'] = coin.coin_initial;
            priceArray['opening_price'] = res['data']['opening_price'];
            priceArray['closing_price'] = ((Number(res['data']['closing_price'])*1.007).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            priceArray['min_price'] = res['data']['min_price'];
            priceArray['max_price'] = res['data']['max_price'];
            priceArray['date'] = res['data']['date'];
            priceArray['percent'] = beforePer;
            priceArray['coin_id'] = coin.id;
            priceArray['coin_color'] = color;

            setGet_coinList(get_coinList => [...get_coinList,priceArray]);
           

	}	

	useEffect(()=>{
        Coins.map(coin=>{
            fetchCoin(coin);
        });
    },[]);

    const onRefresh = () =>{
        setGet_coinList([]);
        Coins.map(coin=>{
            fetchCoin(coin);
        });
    }
    
	return (

<div className="front_body type2">
	<div className="page_wrap has_bgobj">
		<header className="page_header type2">
			<Link to="#" className="btn_back" onClick={() => navigate(-1)}><span className="hdtext">뒤로가기</span></Link>
			<div className="head_dtitle_inwrap">
				<h1 className="head_dtitle" id="coin_title_info"> 오늘의 <strong>코인 정보</strong></h1>
                <button onClick={onRefresh} className="coin_refresh"><img src={refresh} className="coin_refresh_img"/></button>
			</div>
		</header>
		
		<section id="coin_page_midsection">
			<div className="chart_wbox">
                <Get_coinAPI get_coinList = {get_coinList}/>
				
			</div>
		</section>
		
	</div>
</div>
	)
};
export default Tc_infcredt


{/* </html> */}

// 효동씨 작업물
{/* <div className="chart_vbox">
					
					<div className="chart_zone"> */}
						{/* <img src="images/temp_chart.png" alt="" /> */}

				







								{/* <div style={{marginTop: '50px', marginLeft: '-15px'}}>
                                    <div style={{ width: '200px', height: '200px', }}>
                                        <svg viewBox="0 0 200 200">
                                    <circle
                                        cx="100"
                                        cy="100"
                                        r="80"
                                        fill="none"
                                        stroke="red"
                                        strokeWidth="15"
                                        strokeDasharray={`${2 * Math.PI * 90 * 0.75} ${2 * Math.PI * 90 * 0.25}`}
                                        strokeDashoffset={2 * Math.PI * 90 * 0.655}
                                    ></circle>

                                    <circle

                                        cx="100"
                                        cy="100"
                                        r="80"
                                        fill="none"
                                        stroke="blue"
                                        strokeWidth="15"
                                        strokeDasharray={`${2 * Math.PI * 90 * 0.67} ${2 * Math.PI * 90 * 0.33}`}
                                        strokeDashoffset={2 * Math.PI * 90 * 0.655}
                                    ></circle>

                                        </svg>
                                    </div>
                                </div> */}

								{/* <svg viewBox="0 0 100 100">

									<foreignObject className="logoBack" x="0" y="0" width="100" height="100">
  										<div className="logoGradient" xmlns="http://www.w3.org/1999/xhtml"></div>
									</foreignObject>
								<g className="logoBlend">
									<rect x="0" y="0" width="100" height="100" />
										<path d="M 50 96 a 46 46 0 0 1 0 -92 46 46 0 0 1 0 92" />
								</g>
								</svg> */}

					{/* </div>
					
					<div className="chart_vspec_w">
						<p className="chart_vspec_label">내 신용점수</p>
						<p className="chart_vspec_value_w">
							<span className="chart_text_value">974</span>
							<span className="chart_text_unit">점</span>
						</p>
					</div>
				</div>
				<ul className="chart_else_hlist">
					<li>
						<div className="chart_else_value_w"><span className="chart_else_value">8</span><span className="chart_else_unit">%</span></div>
						<div className="chart_else_label">상위</div>
					</li>
					<li>
						<div className="chart_else_value_w"><span className="chart_else_value">0.04</span><span className="chart_else_unit">%</span></div>
						<div className="chart_else_label">연체가능성</div>
					</li>
				</ul>
			</div> */}
			
			{/* <div className="see_my_rate"> */}
			    {/* <div className="my_point">
			        <p>3.14</p>
			    </div> */}
			    {/* <div className="my_point_img">
                   <ul className="point_else_hlist">
                        <li>
                            <div className="my_point_text_box">
                               <span className="point_else_value_w">내 점수</span>
                               <span className="point_else_value_w point_else_value">974</span>
                               <span className="point_else_value_w">점 으로</span>
                               <span className="point_else_value_label">받을 수 있는 최저 금리는?</span>
                                
                            </div>
                        </li>
                        <li>
                            <div className="img_box">
                                <img src={돈주머니} alt="" />
                            </div>
                        </li>
                   </ul>
                    
			        
			        
			    </div>
			    <div className="btn_my_point">
			        <a href="">내 대출 최저 금리 보러가기</a>
			    </div>
			</div> */}
			
			{/* <div className="btn_nposquad_w">
				<Link to="#" className="btn_quadbox dtype2 smtype2">조회변동 내역</Link> */}