import React from "react";
import { Routes, Route } from "react-router-dom";

import {
  MenuPage,
  NoticeHistory,
  NoticeHistory01,
  NoticeHistory02,
  QandA,
  InfCredt,
  CalcLoan,
  Event,
  EventDetail,
  MyPage,
  // Myloan,
  UserInquiry,
} from "pages/menu";

import {
  JoinPage,
  MemberJoinFinish,
  PasswordSetting,
  Agree,
  PwdChange,
  ResetPage,
  MemberResetPw,
  PwdChangeSet,
  MemberSecession,
  PhoneCert,
  LoginPage,
  PhoneCertReset,
} from "pages/user";

import { Tc_writeRev, Tc_wrtreviewcond, Tc_review } from "pages/review";

// import * as Mydata from "pages/mydata";

import { NoticePage, NoticeDetailPage } from "pages/notice";
// import { TransferHistory, TransferHistoryDetail } from "pages/transferHistory";
// import ManagementMainPage from "pages/management";
// import TermsMainPage from "pages/terms";

// import Statistics from "pages/statistics";

const Root = () => {
  return (
    <Routes>
      {/* loan */}
      {/* <Route path="/loan/residentForm" element={<LoanResidentForm />}></Route>
        <Route path="/loan/loanCellPhone" element={<LoanCellPhone />}></Route>
        <Route path="/loan/loanPurpose" element={<LoanPurpose />}></Route>
        <Route path="/loan/loanIncome" element={<LoanIncome />}></Route>
        <Route path="/loan/loanAddData" element={<LoanAddData />}></Route>
        <Route path="/loan/loanList" element={<LoanList />}></Route>
        <Route
          path="/loan/loanOfficeSearch"
          element={<LoanOfficeSearch />}
        ></Route>
        <Route path="/loan/loanMain" element={<LoanFind />}></Route>
        <Route path="/loan/product" element={<LoanProductDetail />}></Route>
        <Route path="/loan/loading" element={<LoanCalcLoanget />}></Route> */}

      {/* user */}
      <Route path="/login" element={<LoginPage />}></Route>
      <Route path="/join" element={<JoinPage />}></Route>
      <Route path="/join/cert" element={<PhoneCert />}></Route>
      <Route path="/join/pwd" element={<PasswordSetting />}></Route>
      <Route path="/join/finish" element={<MemberJoinFinish />}></Route>
      <Route path="/reset" element={<ResetPage />}></Route>
      <Route path="/memberSecession" element={<MemberSecession />}></Route>
      <Route path="/join/resetpwd" element={<MemberResetPw />}></Route>
      <Route path="/reset/cert" element={<PhoneCertReset />}></Route>
      <Route path="/memberSecession" element={<MemberSecession />}></Route>
      <Route path="/reset/pwd" element={<MemberResetPw />}></Route>
      <Route path="/join/agree/:agreeNo" element={<Agree />}></Route>
      <Route path="/update/pwdChange" element={<PwdChange />}></Route>
      <Route path="/update/pwdChangeSet" element={<PwdChangeSet />}></Route>

      {/* menu */}
      <Route path="/noticeHistory" element={<NoticeHistory />}></Route>
      <Route path="/noticeHistory/1" element={<NoticeHistory01 />}></Route>
      <Route path="/noticeHistory/2" element={<NoticeHistory02 />}></Route>
      <Route path="/menu" element={<MenuPage />}></Route>

      <Route path="notice" element={<NoticePage />}></Route>
      <Route path="notice/:id" element={<NoticeDetailPage />}></Route>

      <Route path="/event" element={<Event />}></Route>
      <Route path="/event/:id" element={<EventDetail />}></Route>
      <Route path="/mypage" element={<MyPage />}></Route>
      {/* <Route path="/myloan" element={<Myloan />}></Route> */}
      <Route path="/inquiry" element={<UserInquiry />}></Route>

      {/* review */}
      <Route path="/review/tcWriteRev" element={<Tc_writeRev />}></Route>
      <Route
        path="/review/tcWrtReviewCond"
        element={<Tc_wrtreviewcond />}
      ></Route>
      <Route path="/review" element={<Tc_review />}></Route>

      {/* calculate */}
      <Route path="/calc" element={<CalcLoan />}></Route>
      <Route path="/infCredt" element={<InfCredt />}></Route>
      <Route path="/QandA" element={<QandA />}></Route>
    </Routes>
  );
};

export default Root;
