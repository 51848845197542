import { CustomButton } from "components/Button";
import { Layout } from "layout";
import { printMsg } from "utils/utils";

const AddCertification = () => {
  return (
    <Layout header={{ title: "공동인증서 복사" }}>
      <div className="cert_container">
        <div className="cert_number_wrap">
          <p>인증번호</p>
          <div className="number_wrap">
            <p>1234-4567-1232</p>
          </div>
        </div>
        <div className="cert_info_wrap">
          <ol className="cert_info_list">
            <li>
              PC로 뱅큐 홈페이지에 접속<br></br>
              <p>(https://bankq.co.kr)</p>
            </li>
            <li>인증서 복사 클릭 → 인증서 복사 프로그램 실행</li>
            <li>가져올 인증서 선택 후 비밀번호 입력</li>
            <li>PC 화면에 보이는 인증번호 입력란에 위 12자리 인증번호 입력</li>
          </ol>
        </div>
        <CustomButton
          buttonClass="btn_company_bottom"
          title="완료"
          onClick={() => {
            printMsg("인증서를 받아오는데 문제가 발생하였습니다.");
          }}
        />
      </div>
    </Layout>
  );
};

export default AddCertification;
