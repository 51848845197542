import { useRouter } from "lib/utils/useRouter";

const Summary = (props) => {
  const useRoute = useRouter();
  const paymentQuery = props.paymentQuery;

  return (
    <section className="payment_wrap">
      <div className="payment_search_wrap">
        <div
          className="search_box"
          onClick={() => {
            props.setIsSearchPopup(true);
          }}
        >
          <h2>
            {paymentQuery?.data?.basYear +
              "년 " +
              paymentQuery?.data?.basMon +
              "월"}
          </h2>
        </div>

        <button
          onClick={() => {
            useRoute.routeTo(`/statistics?searchDate=${props.selectMonth}`);
          }}
        >
          지출 분석 보기
        </button>
      </div>

      <div className="payment_summary_box">
        <div className="payment_summary">
          <p>총 사용 금액</p>
          <div>
            <span>{paymentQuery?.data?.totalApprovedAmt + "원"}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Summary;
