import React from "react";
import Modal from "components/Modal/Modal";
import { Button } from "components/Button";

const CreditDeletePopup = (props) => {
  return (
    <Modal onClose={props.onClose} title="데이터 삭제 안내">
      <div style={{ color: "#333", marginBottom: "24px" }}>
        선택한 카드사의 신용정보 데이터를 삭제할까요? <br></br>
        연결 해제 후 수집한 개인신용정보는 삭제되며 삭제된 데이터는 복구되지
        않습니다.
      </div>

      <div className="btn_management_wrap">
        <Button
          type={"button"}
          onClick={props.submitClick}
          title={"삭제하기"}
          className={"btn_management_delete"}
        ></Button>
        <Button
          type={"button"}
          onClick={props.onClose}
          title={"유지하기"}
          className={"btn_management_update"}
        ></Button>
      </div>
    </Modal>
  );
};

export default CreditDeletePopup;
