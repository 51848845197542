import { Layout } from "layout";
import { CardHistoryContainer, CardHistorySkeleton } from "containers/Card";
import "assets/css/card.scss";
import { useRouter } from "lib/utils/useRouter";
import { Suspense } from "react";

const CardHistory = () => {
  const useRoute = useRouter();
  return (
    <Layout
      header={{
        onClick: () => {
          useRoute.routeTo("/card");
        },
      }}
    >
      <Suspense fallback={<CardHistorySkeleton />}>
        <CardHistoryContainer />
      </Suspense>
    </Layout>
  );
};

export default CardHistory;
