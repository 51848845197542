import React from "react";

const PaymentSkeleton = () => {
  return (
    <div className="card_container">
      <section className="card_wrap">
        <div className="billing_org_article">
          <div className="billing_org_header">
            <div>
              <div
                className="skeleton skeleton_card_img"
                style={{ marginRight: "12px" }}
              ></div>

              <div className="skeleton skeleton_card_price"></div>
            </div>
          </div>
          <div className="billing_org_body">
            <div className="charge_info">
              <p className="skeleton skeleton_subtitle"></p>
              <div className="skeleton skeleton_title"></div>
            </div>
            <div className="charge_desc_list">
              <ul className="table_list_article">
                <li>
                  <ul className="charge_desc_item_list">
                    <li>
                      <div className="charge_desc_box">
                        <div className="skeleton skeleton_card_price"></div>
                        <div className="skeleton skeleton_subtitle"></div>
                      </div>
                    </li>
                    <li>
                      <div className="charge_desc_box">
                        <div className="skeleton skeleton_card_price"></div>
                        <div className="skeleton skeleton_subtitle"></div>
                      </div>
                    </li>
                    <li>
                      <div className="charge_desc_box">
                        <div className="skeleton skeleton_card_price"></div>
                        <div className="skeleton skeleton_subtitle"></div>
                      </div>
                    </li>

                    <li>
                      <div className="has_revolving ">
                        <div
                          className="skeleton skeleton_subtitle"
                          style={{ marginLeft: "auto" }}
                        ></div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="block_line"></div>
      <section className="charge_month_wrap">
        <ul className="table_list_article">
          <li>
            <div className="charge_month_item ">
              <p className="skeleton skeleton_subtitle"></p>
              <div className="skeleton skeleton_title"></div>
            </div>
          </li>
          <li>
            <div className="charge_month_item ">
              <p className="skeleton skeleton_subtitle"></p>
              <div className="skeleton skeleton_title"></div>
            </div>
          </li>
          <li>
            <div className="charge_month_item ">
              <p className="skeleton skeleton_subtitle"></p>
              <div className="skeleton skeleton_title"></div>
            </div>
          </li>
          <li>
            <div className="charge_month_item ">
              <p className="skeleton skeleton_subtitle"></p>
              <div className="skeleton skeleton_title"></div>
            </div>
          </li>
          <li>
            <div className="charge_month_item ">
              <p className="skeleton skeleton_subtitle"></p>
              <div className="skeleton skeleton_title"></div>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default PaymentSkeleton;
